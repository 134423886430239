import { useState, useEffect } from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';
import axios from 'axios';
import { appVersion, releaseNoteAppId } from 'src/Constants';

export default function useWhatsNewPopup() {
	const [localVersion, setLocalVersion] = useLocalStorage('appVersion', '0.0.0');
	const [loading, setLoading] = useState(false);
	const [latestChangelogItem, setLatestChangelogItem] = useState(null);
    const cleanedAppVersion = appVersion?.replace('v', '') ?? '';

	const isOnLatestVersion = localVersion === cleanedAppVersion;

	useEffect(() => {
		if (!isOnLatestVersion) {
			(async () => {
				setLoading(true);
				const response = await axios.get(
					`/api/emailSaver/releaseNotes/${releaseNoteAppId}/1`
				);
				if (response) {
					setLatestChangelogItem(response.data.payload[0]);
				}
				setLoading(false);
			})();
		}
	}, []);

	const onCloseWhatsNewPanel = () => {
		setLocalVersion(cleanedAppVersion);
	};

	return {
		loading,
		isOnLatestVersion,
		latestChangelogItem,
		onCloseWhatsNewPanel
	};
}
