import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChangelogIcon from './ChangelogIcon';

const useStyles = makeStyles({
	changelogItemIcon: {
		marginRight: '5px',
		width: '80px'
	}
});

export default function ChangelogListItem({ change }) {
	const classes = useStyles();

	return (
		<ListItem
			key={change.id}
			dense
		>
			<ListItemIcon className={classes.changelogItemIcon}>
				<ChangelogIcon type={change.type} />
			</ListItemIcon>
			<ListItemText primary={change.description} />
		</ListItem>
	);
}
