import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	projects: null,
	selectedProject: null,
	options: null
};

const projectReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PROJECT_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.PROJECT_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				projects: action.projects,
				error: null
			};
		}

		case actionTypes.PROJECT_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CHANGE_SELECTED_PROJECT: {
			return {
				...state,
				selectedProject: action.project
			};
		}

		case actionTypes.OPTIONS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.OPTIONS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				options: action.options,
				error: null
			};
		}

		case actionTypes.OPTIONS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default projectReducer;
