/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-multi-comp */
import BarChartIcon from '@material-ui/icons/BarChart';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link } from 'react-router-dom';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Drawer,
	Divider,
	Avatar,
	List,
	Typography,
	Hidden,
	IconButton,
	colors,
	MenuItem,
	Menu,
	CircularProgress
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import NavItem from 'src/components/NavItem';
import ProtectedComponent from 'src/customComponents/ProtectedComponent';
import { defaultSideMenu, fetchNotifications, fetchSideMenu, updateSideMenu } from 'src/thunks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { appVersion } from 'src/Constants';
import Changelog from 'src/customComponents/Changelog/Changelog';
import { getItemIcon } from './navConfig';
import { getAuth, getSelectedProject, getSideMenu, getSideMenuPending } from '../../selectors';
import { isAdmin } from '../../utils';

const FETCH_MESSAGES_RATE = 3000;
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '0.825rem'
	},
	mobileDrawer: {
		width: 192
	},
	a: {
		fontSize: '0.825rem',
		fontWeight: '500'
	},
	desktopDrawer: {
		width: 192,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	navigation: {
		overflow: 'auto',
		padding: theme.spacing(0, 1, 1, 1),
		flexGrow: 1
	},
	profile: {
		padding: theme.spacing(1),
		display: 'flex',
		alignItems: 'center'
	},
	badge: {
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
	},
	badgeDot: {
		height: 9,
		minWidth: 9
	},
	onlineBadge: {
		backgroundColor: colors.green[600]
	},
	awayBadge: {
		backgroundColor: colors.orange[600]
	},
	busyBadge: {
		backgroundColor: colors.red[600]
	},
	offlineBadge: {
		backgroundColor: colors.grey[300]
	},
	avatar: {
		width: 50,
		height: 50,
		cursor: 'pointer'
	},
	details: {
		marginLeft: theme.spacing(1)
	},
	moreButton: {
		marginLeft: 'auto',
		color: colors.blueGrey[200]
	},
	loaderContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	changelogLink: {
		cursor: 'pointer',
		textDecoration: 'underline'
	}
}));

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (draggableStyle) => ({
	...draggableStyle,
	userSelect: 'none',
	position: 'static'
});

function renderNavItems({
	// eslint-disable-next-line react/prop-types
	items,
	subheader,
	key,
	auth,
	user,
	setReorderedData,
	onMenuItemClick,
	isEditing,
	...rest
}) {
	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const reordered = reorder(items, result.source.index, result.destination.index);

		setReorderedData(reordered);
	};

	return (
		<DragDropContext
			onDragEnd={onDragEnd}
			key={key}
		>
			<List>
				<Droppable droppableId="droppable">
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{ width: '100%' }}
						>
							{user?.projects?.length === 0
								? []
								: items.reduce(
										// eslint-disable-next-line no-use-before-define
										(acc, item, index) =>
											reduceChildRoutes({
												acc,
												item,
												auth,
												index,
												onMenuItemClick,
												isEditing,
												setReorderedData,
												...rest
											}),
										[]
								  )}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</List>
		</DragDropContext>
	);
}

const renderNestedMenuItems = (
	depth,
	item,
	open,
	pathname,
	onMenuItemClick,
	isEditing,
	setReorderedData
) => (
	<ProtectedComponent
		key={item.href + item.title}
		component={() => (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'start-flex',
					cursor: 'pointer'
				}}
			>
				{isEditing && <DragHandleIcon style={{ marginTop: '8px' }} />}
				<NavItem
					depth={depth}
					icon={item?.icon ?? BarChartIcon}
					label={item.title}
					open={Boolean(open)}
					title={item.title}
				>
					{renderNavItems({
						depth: depth + 1,
						pathname,
						items: item.items,
						key: item.href + item.title,
						onMenuItemClick,
						isEditing,
						setReorderedData: (val) => setReorderedData([{ config: item }, ...val])
					})}
				</NavItem>
			</div>
		)}
		permission={item.permission}
	/>
);

function renderMenuItem({ item, depth, onMenuItemClick, isEditing }) {
	return (
		<ProtectedComponent
			component={() => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer'
					}}
				>
					{isEditing && <DragHandleIcon />}
					<NavItem
						depth={depth}
						href={item.href}
						icon={item?.icon ?? BarChartIcon}
						key={item.href}
						label={item.label}
						title={item.title}
						onClick={onMenuItemClick}
					/>
				</div>
			)}
			permission={item.permission}
			key={item.href}
		/>
	);
}

function reduceChildRoutes({
	acc,
	pathname,
	item,
	auth,
	index,
	depth = 0,
	onMenuItemClick,
	isEditing,
	setReorderedData
}) {
	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false
		});
		if (item.isAdmin && auth) {
			acc.push(
				renderNestedMenuItems(
					depth,
					item,
					open || isEditing,
					pathname,
					onMenuItemClick,
					isEditing,
					setReorderedData
				)
			);
		} else {
			acc.push(
				renderNestedMenuItems(
					depth,
					item,
					open || isEditing,
					pathname,
					onMenuItemClick,
					isEditing,
					setReorderedData
				)
			);
		}
	} else {
		acc.push(
			isEditing ? (
				<Draggable
					key={item.href}
					draggableId={item.href}
					index={index}
				>
					{(provider) => (
						<div
							ref={provider.innerRef}
							{...provider.draggableProps}
							{...provider.dragHandleProps}
							style={getItemStyle(provider.draggableProps.style)}
						>
							{renderMenuItem({
								item,
								depth,
								onMenuItemClick,
								isEditing
							})}
						</div>
					)}
				</Draggable>
			) : (
				renderMenuItem({
					item,
					depth,
					onMenuItemClick,
					isEditing
				})
			)
		);
	}
	return acc;
}

function transformItems(item) {
	const copyItem = JSON.parse(JSON.stringify(item));
	const tmpItem = getItemIcon(copyItem);
	if (tmpItem?.items?.length) {
		tmpItem.items = tmpItem.items.map((innerItem) => transformItems(innerItem));
		return { ...tmpItem };
	}
	const { items, ...data } = tmpItem;
	return data;
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
	const dispatch = useDispatch();
	const menuItems = useSelector(getSideMenu);
	const menuItemsPending = useSelector(getSideMenuPending);
	const classes = useStyles();
	const location = useLocation();
	const auth = useSelector(getAuth);
	const selectedProject = useSelector(getSelectedProject);
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuData, setMenuData] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [iconDict, setIconDict] = useState({});
	const [showChangelog, setShowChangelog] = useState(false);

	let notificationsTimeout = useRef();

	const onRouteChange = useCallback(() => {
		clearTimeout(notificationsTimeout);
		if (selectedProject) {
			notificationsTimeout = setTimeout(() => {
				dispatch(fetchNotifications(selectedProject));
			}, FETCH_MESSAGES_RATE);
		}
	}, [dispatch, selectedProject]);

	useEffect(() => {
		if (auth) {
			dispatch(fetchSideMenu(auth._id));
		}
	}, [dispatch, auth]);

	useEffect(() => {
		onRouteChange();
	}, [onRouteChange]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	useEffect(() => {
		if (menuItems.items) {
			// need an update to recursive if will be more 2 inner items
			const iconDictionary = menuItems.items.reduce((acc, item) => {
				if (item.items.length) {
					return {
						...acc,
						[item.id]: { icon: item.icon },
						...item.items.reduce(
							(innerAcc, innerItem) => ({
								...innerAcc,
								[innerItem.href]: { icon: innerItem.icon }
							}),
							{}
						)
					};
				}
				return { ...acc, [item.id]: { icon: item.icon } };
			}, {});
			setIconDict(iconDictionary);

			const newItems = menuItems.items.map((item) => transformItems(item));
			setMenuData([{ ...menuItems, items: newItems }]);
		}
	}, [menuItems]);

	const reorderMenu = (value) => {
		let tmpItems = JSON.parse(JSON.stringify(menuData));
		const valueCopy = JSON.parse(JSON.stringify(value));
		const [isConfig, ...other] = valueCopy;
		if (isConfig?.config) {
			const { config } = isConfig;
			tmpItems[0].items.forEach((navItem) => {
				if (navItem?.items && navItem.id === config.id) {
					// eslint-disable-next-line no-param-reassign
					navItem.items = other;
				}
			});
		} else {
			tmpItems[0].items = valueCopy;
		}

		tmpItems = tmpItems.map((el) => {
			el.items.map((item) => {
				item.icon = iconDict[item.id].icon;
				if (item?.items?.length) {
					item?.items.map((innerItem) => {
						innerItem.icon = iconDict[innerItem.href].icon;
						return innerItem;
					});
				}
				return item;
			});
			return el;
		});
		tmpItems[0].items = tmpItems[0].items.map((item) => transformItems(item));
		setMenuData(tmpItems);
	};

	const onEditMenu = (event, action) => {
		switch (action) {
			case 'save': {
				setIsEditing(false);
				const copyMenu = JSON.parse(JSON.stringify(menuData)).map((el) => {
					const items = el.items.map((item, idx) => {
						item.icon = iconDict[item.id].icon;
						item.order = idx + 1;
						if (item?.items?.length) {
							return {
								...item,
								items: item.items.map((innerItem, innerIdx) => ({
									...innerItem,
									icon: iconDict[innerItem.href].icon,
									order: innerIdx + 1
								}))
							};
						}
						return item;
					});
					return { ...el, items };
				});

				dispatch(updateSideMenu(auth._id, copyMenu));
				break;
			}
			case 'cancel': {
				const newItems = menuItems.items.map((item) => transformItems(item));
				setMenuData([{ ...menuItems, items: newItems }]);
				setIsEditing(false);
				break;
			}
			case 'default': {
				setIsEditing(false);
				dispatch(defaultSideMenu(auth._id));
				break;
			}
			case 'edit': {
				setIsEditing(true);
				break;
			}
			default:
				'';
		}
	};

	const renderContent = () => (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<nav className={classes.navigation}>
				{menuItemsPending ? (
					<div className={classes.loaderContainer}>
						<CircularProgress />
					</div>
				) : (
					menuData.map((list) =>
						renderNavItems({
							items: list.items,
							subheader: list.subheader,
							pathname: location.pathname,
							key: list.id,
							auth: isAdmin(),
							user: auth,
							setReorderedData: reorderMenu,
							onMenuItemClick: onRouteChange,
							isEditing
						})
					)
				)}
			</nav>
			<Divider className={classes.divider} />
			<div className={classes.profile}>
				<Avatar
					alt="Person"
					className={classes.avatar}
					src={auth && auth.avatar?.url}
					onClick={handleClick}
				/>
				<div className={classes.details}>
					<div
						variant="h5"
						color="textPrimary"
						underline="none"
					>
						{auth && `${auth.name}`}
					</div>
					<Typography variant="body2">{auth && auth.position}</Typography>
					<Typography
						variant="caption"
						onClick={() => setShowChangelog(true)}
						className={classes.changelogLink}
					>
						Intrahub {appVersion}
					</Typography>
				</div>
				<Menu
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem>
						<Link
							to="/admin/profile/edit"
							onClick={handleClose}
						>
							Edit Profile
						</Link>
					</MenuItem>

					{isEditing ? (
						<div>
							<MenuItem onClick={(e) => onEditMenu(e, 'save')}>Save Changes</MenuItem>
							<MenuItem onClick={(e) => onEditMenu(e, 'cancel')}>Cancel</MenuItem>
							<MenuItem onClick={(e) => onEditMenu(e, 'default')}>
								Reset to Default
							</MenuItem>
						</div>
					) : (
						<MenuItem onClick={(e) => onEditMenu(e, 'edit')}>Edit Menu</MenuItem>
					)}
				</Menu>
			</div>
		</div>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{
						paper: classes.mobileDrawer
					}}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{renderContent()}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{
						paper: classes.desktopDrawer
					}}
					open
					variant="persistent"
				>
					{renderContent()}
				</Drawer>
			</Hidden>
			<Changelog
				show={showChangelog}
				onClose={() => setShowChangelog(false)}
			/>
		</>
	);
}

NavBar.propTypes = {
	className: PropTypes.string,
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
