/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
	Modal,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
	Divider,
	TextField,
	Button,
	TextareaAutosize
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker
} from '@material-ui/pickers';
import { getSelectedProject, getAuth } from 'src/selectors';
import { showToast } from 'src/actions';
import { saveWalkIn, getWalkInList, updateWalkIn } from 'src/thunks';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 800,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%'
	},
	actions: {
		justifyContent: 'flex-end'
	},
	hideInputArrows: {
		webkitAppearance: 'none'
	},
	textareaAutosize: {
		padding: '10px',
		width: '100%'
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	switchBlock: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '50%'
	}
}));

const schema = {
	userId: {
		presence: { allowEmpty: false, message: 'is required' }
	},
	persons: {
		presence: { allowEmpty: false, message: 'is required' },
		numericality: {
			onlyInteger: true,
			greaterThan: 0,
			lessThanOrEqualTo: 9
		}
	},
	date: {
		presence: { allowEmpty: false, message: 'is required' }
	},
	projectId: {
		presence: { allowEmpty: false }
	}
};

function WalkInModal({ open, onClose, className, item, contact, data, ...rest }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const selectedProject = useSelector(getSelectedProject);
	const auth = useSelector(getAuth);
	const actualDate = new Date();
	const [values, setValues] = useState({ date: actualDate });
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (item || data) {
			setValues(item || data);
		}
	}, [item, data]);

	const handleChange = (e) => {
		setValues({
			...values,
			[e.target.name]: e.target.value
		});
	};

	const handleDateChange = (date, type) => {
		setValues((values) => ({
			...values,
			[type]: date
		}));
	};

	const onSave = async () => {
		const reqObj = {
			...values,
			userId: auth._id,
			projectId: selectedProject
		};
		const errors = validate(reqObj, schema);

		setErrors(errors);

		if (errors) {
			dispatch(
				showToast({
					type: 'error',
					body: 'Please select All options'
				})
			);
		} else {
			if (item || data) {
				await dispatch(updateWalkIn(reqObj));
			} else {
				await dispatch(saveWalkIn(reqObj));
			}
			await dispatch(getWalkInList(selectedProject));
			setValues({});
			onClose();
		}
	};

	return (
		<Modal
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					onClose(event, reason);
				}
			}}
			open={open}
		>
			<Card
				{...rest}
				className={clsx(classes.root, className)}
			>
				<form>
					<CardHeader title="Log Walk-in" />
					<Divider />
					<CardContent>
						<Grid
							container
							spacing={3}
						>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid
									item
									md={4}
									xs={12}
								>
									<KeyboardDatePicker
										fullWidth
										margin="normal"
										id="date-picker-dialog"
										label="Date"
										format="dd/MM/yyyy"
										name="date"
										value={values.date}
										onChange={(date) => handleDateChange(date, 'date')}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
										error={errors?.date}
										helperText={errors?.date ? errors.date[0] : false}
									/>
								</Grid>
								<Grid
									item
									md={4}
									xs={12}
								>
									<KeyboardTimePicker
										fullWidth
										margin="normal"
										id="time-picker"
										label="Time"
										value={values.date}
										name="date"
										onChange={(date) => handleDateChange(date, 'date')}
										KeyboardButtonProps={{
											'aria-label': 'change time'
										}}
										error={errors?.date}
										helperText={errors?.date ? errors.date[0] : false}
									/>
								</Grid>
							</MuiPickersUtilsProvider>
							<Grid
								item
								md={4}
								xs={12}
							>
								<TextField
									fullWidth
									label="No. of Persons"
									name="persons"
									onChange={handleChange}
									variant="outlined"
									type="number"
									InputProps={{ inputProps: { min: 0, max: 9 } }}
									value={values.persons}
									error={errors?.persons}
									helperText={errors?.persons ? errors.persons[0] : false}
								/>
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
							>
								<TextareaAutosize
									className={classes.textareaAutosize}
									rowsMin={3}
									placeholder="Type note here..."
									onChange={handleChange}
									name="note"
									value={values.note}
								/>
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
					<CardActions className={classes.actions}>
						<Button onClick={onClose}>Close</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={onSave}
						>
							Save
						</Button>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
}

WalkInModal.propTypes = {
	className: PropTypes.string,
	item: PropTypes?.object,
	contact: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	data: PropTypes.object
};

WalkInModal.defaultProps = {
	open: false,
	onClose: () => {}
};

export default WalkInModal;
