export const BUILDER_CONTACTS_FETCH_PENDING = 'BUILDER_CONTACTS_FETCH_PENDING';
export const BUILDER_CONTACTS_FETCH_SUCCESS = 'BUILDER_CONTACTS_FETCH_SUCCESS';
export const BUILDER_CONTACTS_FETCH_ERROR = 'BUILDER_CONTACTS_FETCH_ERROR';

export const BUILDER_USERS_FETCH_PENDING = 'BUILDER_USERS_FETCH_PENDING';
export const BUILDER_USERS_FETCH_SUCCESS = 'BUILDER_USERS_FETCH_SUCCESS';
export const BUILDER_USERS_FETCH_ERROR = 'BUILDER_USERS_FETCH_ERROR';

export const BUILDER_USER_FETCH_PENDING = 'BUILDER_USER_FETCH_PENDING';
export const BUILDER_USER_FETCH_SUCCESS = 'BUILDER_USER_FETCH_SUCCESS';
export const BUILDER_USER_FETCH_ERROR = 'BUILDER_USER_FETCH_ERROR';

export const BUILDER_USER_APP_FETCH_PENDING = 'BUILDER_USER_APP_FETCH_PENDING';
export const BUILDER_USER_APP_FETCH_SUCCESS = 'BUILDER_USER_APP_FETCH_SUCCESS';
export const BUILDER_USER_APP_FETCH_ERROR = 'BUILDER_USER_APP_FETCH_ERROR';

export const DOCUMENT_TRACKING_FETCH_PENDING = 'DOCUMENT_TRACKING_FETCH_PENDING';
export const DOCUMENT_TRACKING_FETCH_SUCCESS = 'DOCUMENT_TRACKING_FETCH_SUCCESS';
export const DOCUMENT_TRACKING_FETCH_ERROR = 'DOCUMENT_TRACKING_FETCH_ERROR';

export const builderContactsFetchPending = () => ({
	type: BUILDER_CONTACTS_FETCH_PENDING
});

export const builderContactsFetchSuccess = (builderContacts) => ({
	type: BUILDER_CONTACTS_FETCH_SUCCESS,
	builderContacts
});

export const builderContactsFetchError = (error) => ({
	type: BUILDER_CONTACTS_FETCH_ERROR,
	error
});

export const builderUsersFetchPending = () => ({
	type: BUILDER_USERS_FETCH_PENDING
});

export const builderUsersFetchSuccess = (builderUsers) => ({
	type: BUILDER_USERS_FETCH_SUCCESS,
	builderUsers
});

export const builderUsersFetchError = (error) => ({
	type: BUILDER_USERS_FETCH_ERROR,
	error
});

export const builderUserFetchPending = () => ({
	type: BUILDER_USER_FETCH_PENDING
});

export const builderUserFetchSuccess = (builderUser) => ({
	type: BUILDER_USER_FETCH_SUCCESS,
	builderUser
});

export const builderUserFetchError = (error) => ({
	type: BUILDER_USER_FETCH_ERROR,
	error
});

export const builderUserAppFetchPending = () => ({
	type: BUILDER_USER_APP_FETCH_PENDING
});

export const builderUserAppFetchSuccess = (builderUserApp) => ({
	type: BUILDER_USER_APP_FETCH_SUCCESS,
	builderUserApp
});

export const builderUserAppFetchError = (error) => ({
	type: BUILDER_USER_APP_FETCH_ERROR,
	error
});

export const documentTrackingFetchPending = () => ({
	type: DOCUMENT_TRACKING_FETCH_PENDING
});

export const documentTrackingFetchSuccess = (documentTracking) => ({
	type: DOCUMENT_TRACKING_FETCH_SUCCESS,
	documentTracking
});

export const documentTrackingFetchError = (error) => ({
	type: DOCUMENT_TRACKING_FETCH_ERROR,
	error
});
