export const FETCH_SALE_STATS_PENDING = 'FETCH_SALE_STATS_PENDING';
export const FETCH_SALE_STATS_SUCCESS = 'FETCH_SALE_STATS_SUCCESS';
export const FETCH_SALE_STATS_ERROR = 'FETCH_SALE_STATS_ERROR';

export const FETCH_PAST_SETTLEMENTS_PENDING = 'FETCH_PAST_SETTLEMENTS_PENDING';
export const FETCH_PAST_SETTLEMENTS_SUCCESS = 'FETCH_PAST_SETTLEMENTS_SUCCESS';
export const FETCH_PAST_SETTLEMENTS_ERROR = 'FETCH_PAST_SETTLEMENTS_ERROR';

export const FETCH_DUE_COMMISSIONS_STATS_PENDING = 'FETCH_DUE_COMMISSIONS_STATS_PENDING';
export const FETCH_DUE_COMMISSIONS_STATS_SUCCESS = 'FETCH_DUE_COMMISSIONS_STATS_SUCCESS';
export const FETCH_DUE_COMMISSIONS_STATS_ERROR = 'FETCH_DUE_COMMISSIONS_STATS_ERROR';

export const FETCH_OUTSTANDING_CONTRACTS_PENDING = 'FETCH_OUTSTANDING_CONTRACTS_PENDING';
export const FETCH_OUTSTANDING_CONTRACTS_SUCCESS = 'FETCH_OUTSTANDING_CONTRACTS_SUCCESS';
export const FETCH_OUTSTANDING_CONTRACTS_ERROR = 'FETCH_OUTSTANDING_CONTRACTS_ERROR';

export const FETCH_SALE_STATUSES_PENDING = 'FETCH_SALE_STATUSES_PENDING';
export const FETCH_SALE_STATUSES_SUCCESS = 'FETCH_SALE_STATUSES_SUCCESS';
export const FETCH_SALE_STATUSES_ERROR = 'FETCH_SALE_STATUSES_ERROR';

export const FETCH_CONDITIONAL_CONTRACTS_PENDING = 'FETCH_CONDITIONAL_CONTRACTS_PENDING';
export const FETCH_CONDITIONAL_CONTRACTS_SUCCESS = 'FETCH_CONDITIONAL_CONTRACTS_SUCCESS';
export const FETCH_CONDITIONAL_CONTRACTS_ERROR = 'FETCH_CONDITIONAL_CONTRACTS_ERROR';

export const FETCH_SUNSET_DATES_PENDING = 'FETCH_SUNSET_DATES_PENDING';
export const FETCH_SUNSET_DATES_SUCCESS = 'FETCH_SUNSET_DATES_SUCCESS';
export const FETCH_SUNSET_DATES_ERROR = 'FETCH_SUNSET_DATES_ERROR';

export const FETCH_OVERDUE_DEPOSITS_PENDING = 'FETCH_OVERDUE_DEPOSITS_PENDING';
export const FETCH_OVERDUE_DEPOSITS_SUCCESS = 'FETCH_OVERDUE_DEPOSITS_SUCCESS';
export const FETCH_OVERDUE_DEPOSITS_ERROR = 'FETCH_OVERDUE_DEPOSITS_ERROR';

export const FETCH_OVERDUE_CONDITIONS_PENDING = 'FETCH_OVERDUE_CONDITIONS_PENDING';
export const FETCH_OVERDUE_CONDITIONS_SUCCESS = 'FETCH_OVERDUE_CONDITIONS_SUCCESS';
export const FETCH_OVERDUE_CONDITIONS_ERROR = 'FETCH_OVERDUE_CONDITIONS_ERROR';

export const FETCH_SETTLEMENT_DATES_PENDING = 'FETCH_SETTLEMENT_DATES_PENDING';
export const FETCH_SETTLEMENT_DATES_SUCCESS = 'FETCH_SETTLEMENT_DATES_SUCCESS';
export const FETCH_SETTLEMENT_DATES_ERROR = 'FETCH_SETTLEMENT_DATES_ERROR';

export const FETCH_REMINDERS_DASHBOARD_PENDING = 'FETCH_REMINDERS_DASHBOARD_PENDING';
export const FETCH_REMINDERS_DASHBOARD_SUCCESS = 'FETCH_REMINDERS_DASHBOARD_SUCCESS';
export const FETCH_REMINDERS_DASHBOARD_ERROR = 'FETCH_REMINDERS_DASHBOARD_ERROR';

export const FETCH_NEW_ENQUIRIES_PENDING = 'FETCH_NEW_ENQUIRIES_PENDING';
export const FETCH_NEW_ENQUIRIES_SUCCESS = 'FETCH_NEW_ENQUIRIES_SUCCESS';
export const FETCH_NEW_ENQUIRIES_ERROR = 'FETCH_NEW_ENQUIRIES_ERROR';

export const FETCH_REPEAT_ENQUIRY_PENDING = 'FETCH_REPEAT_ENQUIRY_PENDING';
export const FETCH_REPEAT_ENQUIRY_SUCCESS = 'FETCH_REPEAT_ENQUIRY_SUCCESS';
export const FETCH_REPEAT_ENQUIRY_ERROR = 'FETCH_REPEAT_ENQUIRY_ERROR';

export const FETCH_FULL_DEPOSIT_PAID_PENDING = 'FETCH_FULL_DEPOSIT_PAID_PENDING';
export const FETCH_FULL_DEPOSIT_PAID_SUCCESS = 'FETCH_FULL_DEPOSIT_PAID_SUCCESS';
export const FETCH_FULL_DEPOSIT_PAID_ERROR = 'FETCH_FULL_DEPOSIT_PAID_ERROR';

export const FETCH_CONTRACTS_EXCHANGE_PENDING = 'FETCH_CONTRACTS_EXCHANGE_PENDING';
export const FETCH_CONTRACTS_EXCHANGE_SUCCESS = 'FETCH_CONTRACTS_EXCHANGE_SUCCESS';
export const FETCH_CONTRACTS_EXCHANGE_ERROR = 'FETCH_CONTRACTS_EXCHANGE_ERROR';

export const FETCH_CANCELED_SALES_PENDING = 'FETCH_CANCELED_SALES_PENDING';
export const FETCH_CANCELED_SALES_SUCCESS = 'FETCH_CANCELED_SALES_SUCCESS';
export const FETCH_CANCELED_SALES_ERROR = 'FETCH_CANCELED_SALES_ERROR';

export const FETCH_WALK_IN_PENDING = 'FETCH_WALK_IN_PENDING';
export const FETCH_WALK_IN_SUCCESS = 'FETCH_WALK_IN_SUCCESS';
export const FETCH_WALK_IN_ERROR = 'FETCH_WALK_IN_ERROR';

export const FETCH_DASHBOARD_SETTINGS_PENDING = 'FETCH_DASHBOARD_SETTINGS_PENDING';
export const FETCH_DASHBOARD_SETTINGS_SUCCESS = 'FETCH_DASHBOARD_SETTINGS_SUCCESS';
export const FETCH_DASHBOARD_SETTINGS_ERROR = 'FETCH_DASHBOARD_SETTINGS_ERROR';

export const FETCH_WEEK_SALES_PENDING = 'FETCH_WEEK_SALES_PENDING';
export const FETCH_WEEK_SALES_SUCCESS = 'FETCH_WEEK_SALES_SUCCESS';
export const FETCH_WEEK_SALES_ERROR = 'FETCH_WEEK_SALES_ERROR';

export const REMOVE_DASHBOARD_ITEM = 'REMOVE_DASHBOARD_ITEM';
export const SET_REORDERED_ITEMS = 'SET_REORDERED_ITEMS';
export const HANDLE_GRID_CHANGE = 'HANDLE_GRID_CHANGE';

export const FETCH_WEEK_SIGNED_CONTRACTS_PENDING = 'FETCH_WEEK_SIGNED_CONTRACTS_PENDING';
export const FETCH_WEEK_SIGNED_CONTRACTS_SUCCESS = 'FETCH_WEEK_SIGNED_CONTRACTS_SUCCESS';
export const FETCH_WEEK_SIGNED_CONTRACTS_ERROR = 'FETCH_WEEK_SIGNED_CONTRACTS_ERROR';

export const FETCH_WEEK_ENQUIRES_PENDING = 'FETCH_WEEK_ENQUIRES_PENDING';
export const FETCH_WEEK_ENQUIRES_SUCCESS = 'FETCH_WEEK_ENQUIRES_SUCCESS';
export const FETCH_WEEK_ENQUIRES_ERROR = 'FETCH_WEEK_ENQUIRES_ERROR';

export const FETCH_WEEK_CANCELATIONS_PENDING = 'FETCH_WEEK_CANCELATIONS_PENDING';
export const FETCH_WEEK_CANCELATIONS_SUCCESS = 'FETCH_WEEK_CANCELATIONS_SUCCESS';
export const FETCH_WEEK_CANCELATIONS_ERROR = 'FETCH_WEEK_CANCELATIONS_ERROR';

export const FETCH_WEEK_OVERDUE_DEPOSITS_PENDING = 'FETCH_WEEK_OVERDUE_DEPOSITS_PENDING';
export const FETCH_WEEK_OVERDUE_DEPOSITS_SUCCESS = 'FETCH_WEEK_OVERDUE_DEPOSITS_SUCCESS';
export const FETCH_WEEK_OVERDUE_DEPOSITS_ERROR = 'FETCH_WEEK_OVERDUE_DEPOSITS_ERROR';

export const FETCH_WEEK_CONTACT_NOTES_PENDING = 'FETCH_WEEK_CONTACT_NOTES_PENDING';
export const FETCH_WEEK_CONTACT_NOTES_SUCCESS = 'FETCH_WEEK_CONTACT_NOTES_SUCCESS';
export const FETCH_WEEK_CONTACT_NOTES_ERROR = 'FETCH_WEEK_CONTACT_NOTES_ERROR';

export const FETCH_WEEK_TOBE_SIGNED_CONTRACTS_PENDING = 'FETCH_WEEK_TOBE_SIGNED_CONTRACTS_PENDING';
export const FETCH_WEEK_TOBE_SIGNED_CONTRACTS_SUCCESS = 'FETCH_WEEK_TOBE_SIGNED_CONTRACTS_SUCCESS';
export const FETCH_WEEK_TOBE_SIGNED_CONTRACTS_ERROR = 'FETCH_WEEK_TOBE_SIGNED_CONTRACTS_ERROR';

export const FETCH_PROJECT_STATUSES_PENDING = 'FETCH_PROJECT_STATUSES_PENDING';
export const FETCH_PROJECT_STATUSES_SUCCESS = 'FETCH_PROJECT_STATUSES_SUCCESS';
export const FETCH_PROJECT_STATUSES_ERROR = 'FETCH_PROJECT_STATUSES_ERROR';

export const FETCH_LIST_OVERDUE_DEPOSITS_PENDING = 'FETCH_LIST_OVERDUE_DEPOSITS_PENDING';
export const FETCH_LIST_OVERDUE_DEPOSITS_SUCCESS = 'FETCH_LIST_OVERDUE_DEPOSITS_SUCCESS';
export const FETCH_LIST_OVERDUE_DEPOSITS_ERROR = 'FETCH_LIST_OVERDUE_DEPOSITS_ERROR';

export const FETCH_LIST_TOBE_SIGNED_CONTRACTS_PENDING = 'FETCH_LIST_TOBE_SIGNED_CONTRACTS_PENDING';
export const FETCH_LIST_TOBE_SIGNED_CONTRACTS_SUCCESS = 'FETCH_LIST_TOBE_SIGNED_CONTRACTS_SUCCESS';
export const FETCH_LIST_TOBE_SIGNED_CONTRACTS_ERROR = 'FETCH_LIST_TOBE_SIGNED_CONTRACTS_ERROR';

export const FETCH_OVERDUE_CONTRACTS_PENDING = 'FETCH_OVERDUE_CONTRACTS_PENDING';
export const FETCH_OVERDUE_CONTRACTS_SUCCESS = 'FETCH_OVERDUE_CONTRACTS_SUCCESS';
export const FETCH_OVERDUE_CONTRACTS_ERROR = 'FETCH_OVERDUE_CONTRACTS_ERROR';

export const FETCH_UPCOMING_SETTLEMENTS_PENDING = 'FETCH_UPCOMING_SETTLEMENTS_PENDING';
export const FETCH_UPCOMING_SETTLEMENTS_SUCCESS = 'FETCH_UPCOMING_SETTLEMENTS_SUCCESS';
export const FETCH_UPCOMING_SETTLEMENTS_ERROR = 'FETCH_UPCOMING_SETTLEMENTS_ERROR';

export const fetchSaleStatsPending = () => ({
	type: FETCH_SALE_STATS_PENDING
});

export const fetchSaleStatsSuccess = (data) => ({
	type: FETCH_SALE_STATS_SUCCESS,
	data
});

export const fetchSaleStatsError = (error) => ({
	type: FETCH_SALE_STATS_ERROR,
	error
});

export const fetchPastSettlementsPending = () => ({
	type: FETCH_PAST_SETTLEMENTS_PENDING
});

export const fetchPastSettlementsSuccess = (data) => ({
	type: FETCH_PAST_SETTLEMENTS_SUCCESS,
	data
});

export const fetchPastSettlementsError = (error) => ({
	type: FETCH_PAST_SETTLEMENTS_ERROR,
	error
});

export const fetchDueCommissionsStatsPending = () => ({
	type: FETCH_DUE_COMMISSIONS_STATS_PENDING
});

export const fetchDueCommissionsStatsSuccess = (data) => ({
	type: FETCH_DUE_COMMISSIONS_STATS_SUCCESS,
	data
});

export const fetchDueCommissionsStatsError = (error) => ({
	type: FETCH_DUE_COMMISSIONS_STATS_ERROR,
	error
});

export const fetchOutsdandingContractsPending = () => ({
	type: FETCH_OUTSTANDING_CONTRACTS_PENDING
});

export const fetchOutsdandingContractsSuccess = (data) => ({
	type: FETCH_OUTSTANDING_CONTRACTS_SUCCESS,
	data
});

export const fetchOutsdandingContractsError = (error) => ({
	type: FETCH_OUTSTANDING_CONTRACTS_ERROR,
	error
});

export const fetchSaleStatusesPending = () => ({
	type: FETCH_SALE_STATUSES_PENDING
});

export const fetchSaleStatusesSuccess = (data) => ({
	type: FETCH_SALE_STATUSES_SUCCESS,
	data
});

export const fetchSaleStatusesError = (error) => ({
	type: FETCH_SALE_STATUSES_ERROR,
	error
});

export const fetchOverdueDepositsPending = () => ({
	type: FETCH_OVERDUE_DEPOSITS_PENDING
});

export const fetchOverdueDepositsSuccess = (data) => ({
	type: FETCH_OVERDUE_DEPOSITS_SUCCESS,
	data
});

export const fetchOverdueDepositsError = (error) => ({
	type: FETCH_OVERDUE_DEPOSITS_ERROR,
	error
});

export const fetchConditionalContractsPending = () => ({
	type: FETCH_CONDITIONAL_CONTRACTS_PENDING
});

export const fetchConditionalContractsSuccess = (data) => ({
	type: FETCH_CONDITIONAL_CONTRACTS_SUCCESS,
	data
});

export const fetchConditionalContractsError = (error) => ({
	type: FETCH_CONDITIONAL_CONTRACTS_ERROR,
	error
});

export const fetchSunsetDatesPending = () => ({
	type: FETCH_SUNSET_DATES_PENDING
});

export const fetchSunsetDatesSuccess = (data) => ({
	type: FETCH_SUNSET_DATES_SUCCESS,
	data
});

export const fetchSunsetDatesError = (error) => ({
	type: FETCH_SUNSET_DATES_ERROR,
	error
});

export const fetchOverdueConditionsPending = () => ({
	type: FETCH_OVERDUE_CONDITIONS_PENDING
});

export const fetchOverdueConditionsSuccess = (data) => ({
	type: FETCH_OVERDUE_CONDITIONS_SUCCESS,
	data
});

export const fetchOverdueConditionsError = (error) => ({
	type: FETCH_OVERDUE_CONDITIONS_ERROR,
	error
});

export const fetchSettlementDatesPending = () => ({
	type: FETCH_SETTLEMENT_DATES_PENDING
});

export const fetchSettlementDatesSuccess = (data) => ({
	type: FETCH_SETTLEMENT_DATES_SUCCESS,
	data
});

export const fetchSettlementDatesError = (error) => ({
	type: FETCH_SETTLEMENT_DATES_ERROR,
	error
});

export const fetchRemindersDashboardPending = () => ({
	type: FETCH_REMINDERS_DASHBOARD_PENDING
});

export const fetchRemindersDashboardSuccess = (data) => ({
	type: FETCH_REMINDERS_DASHBOARD_SUCCESS,
	data
});

export const fetchRemindersDashboardError = (error) => ({
	type: FETCH_REMINDERS_DASHBOARD_ERROR,
	error
});

export const fetchNewEnquiriesPending = () => ({
	type: FETCH_NEW_ENQUIRIES_PENDING
});

export const fetchNewEnquiriesSuccess = (data) => ({
	type: FETCH_NEW_ENQUIRIES_SUCCESS,
	data
});

export const fetchNewEnquiriesError = (error) => ({
	type: FETCH_NEW_ENQUIRIES_ERROR,
	error
});

export const fetchRepeatEnquiryPending = () => ({
	type: FETCH_REPEAT_ENQUIRY_PENDING
});

export const fetchRepeatEnquirySuccess = (data) => ({
	type: FETCH_REPEAT_ENQUIRY_SUCCESS,
	data
});

export const fetchRepeatEnquiryError = (error) => ({
	type: FETCH_REPEAT_ENQUIRY_ERROR,
	error
});

export const fetchFullDepositPaidPending = () => ({
	type: FETCH_FULL_DEPOSIT_PAID_PENDING
});

export const fetchFullDepositPaidSuccess = (data) => ({
	type: FETCH_FULL_DEPOSIT_PAID_SUCCESS,
	data
});

export const fetchFullDepositPaidError = (error) => ({
	type: FETCH_FULL_DEPOSIT_PAID_ERROR,
	error
});

export const fetchContractsExchangePending = () => ({
	type: FETCH_CONTRACTS_EXCHANGE_PENDING
});

export const fetchContractsExchangeSuccess = (data) => ({
	type: FETCH_CONTRACTS_EXCHANGE_SUCCESS,
	data
});

export const fetchContractsExchangeError = (error) => ({
	type: FETCH_CONTRACTS_EXCHANGE_ERROR,
	error
});

export const fetchCanceledSalesPending = () => ({
	type: FETCH_CANCELED_SALES_PENDING
});

export const fetchCanceledSalesSuccess = (data) => ({
	type: FETCH_CANCELED_SALES_SUCCESS,
	data
});

export const fetchCanceledSalesError = (error) => ({
	type: FETCH_CANCELED_SALES_ERROR,
	error
});

export const fetchWalkInPending = () => ({
	type: FETCH_WALK_IN_PENDING
});

export const fetchWalkInSuccess = (data) => ({
	type: FETCH_WALK_IN_SUCCESS,
	data
});

export const fetchWalkInError = (error) => ({
	type: FETCH_WALK_IN_ERROR,
	error
});

export const fetchDashboardSettingsPending = () => ({
	type: FETCH_DASHBOARD_SETTINGS_PENDING
});

export const fetchDashboardSettingsSuccess = (data) => ({
	type: FETCH_DASHBOARD_SETTINGS_SUCCESS,
	data
});

export const fetchDashboardSettingsError = (error) => ({
	type: FETCH_DASHBOARD_SETTINGS_ERROR,
	error
});

export const removeDashboardItem = (id) => ({
	type: REMOVE_DASHBOARD_ITEM,
	id
});

export const setReorderedItems = (arr) => ({
	type: SET_REORDERED_ITEMS,
	arr
});

export const handleGridChange = (data) => ({
	type: HANDLE_GRID_CHANGE,
	data
});

export const fetchWeekSalesPending = () => ({
	type: FETCH_WEEK_SALES_PENDING
});

export const fetchWeekSalesSuccess = (data) => ({
	type: FETCH_WEEK_SALES_SUCCESS,
	data
});

export const fetchWeekSalesError = (err) => ({
	type: FETCH_WEEK_SALES_ERROR,
	err
});

export const fetchWeekSignedContractsPending = () => ({
	type: FETCH_WEEK_SIGNED_CONTRACTS_PENDING
});

export const fetchWeekSignedContractsSuccess = (data) => ({
	type: FETCH_WEEK_SIGNED_CONTRACTS_SUCCESS,
	data
});

export const fetchWeekSignedContractsError = (err) => ({
	type: FETCH_WEEK_SIGNED_CONTRACTS_ERROR,
	err
});

export const fetchWeekEnquiresPending = () => ({
	type: FETCH_WEEK_ENQUIRES_PENDING
});

export const fetchWeekEnquiresSuccess = (data) => ({
	type: FETCH_WEEK_ENQUIRES_SUCCESS,
	data
});

export const fetchWeekEnquiresError = (err) => ({
	type: FETCH_WEEK_ENQUIRES_ERROR,
	err
});

export const fetchWeekCancelationsPending = () => ({
	type: FETCH_WEEK_CANCELATIONS_PENDING
});

export const fetchWeekCancelationsSuccess = (data) => ({
	type: FETCH_WEEK_CANCELATIONS_SUCCESS,
	data
});

export const fetchWeekCancelationsError = (error) => ({
	type: FETCH_WEEK_CANCELATIONS_ERROR,
	error
});

export const fetchWeekOverdueDepositsPending = () => ({
	type: FETCH_WEEK_OVERDUE_DEPOSITS_PENDING
});

export const fetchWeekOverdueDepositsSuccess = (data) => ({
	type: FETCH_WEEK_OVERDUE_DEPOSITS_SUCCESS,
	data
});

export const fetchWeekOverdueDepositsError = (err) => ({
	type: FETCH_WEEK_OVERDUE_DEPOSITS_ERROR,
	err
});

export const fetchWeekContactNotesPending = () => ({
	type: FETCH_WEEK_CONTACT_NOTES_PENDING
});

export const fetchWeekContactNotesSuccess = (data) => ({
	type: FETCH_WEEK_CONTACT_NOTES_SUCCESS,
	data
});

export const fetchWeekContactNotesError = (err) => ({
	type: FETCH_WEEK_CONTACT_NOTES_ERROR,
	err
});

export const fetchWeekToBeSignedContractsPending = () => ({
	type: FETCH_WEEK_TOBE_SIGNED_CONTRACTS_PENDING
});

export const fetchWeekToBeSignedContractsSuccess = (data) => ({
	type: FETCH_WEEK_TOBE_SIGNED_CONTRACTS_SUCCESS,
	data
});

export const fetchWeekToBeSignedContractsError = (error) => ({
	type: FETCH_WEEK_TOBE_SIGNED_CONTRACTS_ERROR,
	error
});

export const fetchProjectStatusesPending = () => ({
	type: FETCH_PROJECT_STATUSES_PENDING
});

export const fetchProjectStatusesSuccess = (data) => ({
	type: FETCH_PROJECT_STATUSES_SUCCESS,
	data
});

export const fetchProjectStatusesError = (error) => ({
	type: FETCH_PROJECT_STATUSES_ERROR,
	error
});

export const fetchListOverdueDepositsPending = () => ({
	type: FETCH_LIST_OVERDUE_DEPOSITS_PENDING
});

export const fetchListOverdueDepositsSuccess = (data) => ({
	type: FETCH_LIST_OVERDUE_DEPOSITS_SUCCESS,
	data
});

export const fetchListOverdueDepositsError = (error) => ({
	type: FETCH_LIST_OVERDUE_DEPOSITS_ERROR,
	error
});

export const fetchListToBeSignedContractsPending = () => ({
	type: FETCH_LIST_TOBE_SIGNED_CONTRACTS_PENDING
});

export const fetchListToBeSignedContractsSuccess = (data) => ({
	type: FETCH_LIST_TOBE_SIGNED_CONTRACTS_SUCCESS,
	data
});

export const fetchListToBeSignedContractsError = (error) => ({
	type: FETCH_LIST_TOBE_SIGNED_CONTRACTS_ERROR,
	error
});

export const fetchOverdueContractsPending = () => ({
	type: FETCH_OVERDUE_CONTRACTS_PENDING
});

export const fetchOverdueContractsSuccess = (data) => ({
	type: FETCH_OVERDUE_CONTRACTS_SUCCESS,
	data
});

export const fetchOverdueContractsError = (error) => ({
	type: FETCH_OVERDUE_CONTRACTS_ERROR,
	error
});

export const fetchUpcomingSettlementsPending = () => ({
	type: FETCH_UPCOMING_SETTLEMENTS_PENDING
});

export const fetchUpcomingSettlementsSuccess = (data) => ({
	type: FETCH_UPCOMING_SETTLEMENTS_SUCCESS,
	data
});

export const fetchUpcomingSettlementsError = (error) => ({
	type: FETCH_UPCOMING_SETTLEMENTS_ERROR,
	error
});
