export const REPLY_ACTION_PENDING = 'REPLY_ACTION_PENDING';
export const REPLY_ACTION_SUCCESS = 'REPLY_ACTION_SUCCESS';
export const REPLY_ACTION_ERROR = 'REPLY_ACTION_ERROR';

export const replyActionPending = () => ({
	type: REPLY_ACTION_PENDING
});

export const replyActionSuccess = (result) => ({
	type: REPLY_ACTION_SUCCESS,
	result
});

export const replyActionError = (error) => ({
	type: REPLY_ACTION_ERROR,
	error
});
