import { Chip } from '@material-ui/core';
import React from 'react';

function typeToStyle(type) {
	switch (type) {
		case 'feature':
			return { backgroundColor: 'rgb(102, 187, 106)', color: 'rgba(0, 0, 0, 0.87)' };
		case 'bug':
			return { backgroundColor: 'rgb(244, 67, 54)', color: 'rgb(255, 255, 255)' };
		case 'change':
			return { backgroundColor: 'rgb(144, 202, 249)', color: 'rgba(0, 0, 0, 0.87)' };
		case 'removed':
			return { backgroundColor: 'rgb(255, 167, 38)', color: 'rgba(0, 0, 0, 0.87)' };
		case 'other':
			return { backgroundColor: 'rgba(255, 255, 255, 0.16)', color: 'rgb(255, 255, 255)' };
		default:
			return { backgroundColor: 'rgba(255, 255, 255, 0.16)', color: 'rgb(255, 255, 255)' };
	}
}

// 'feature' | 'bug' | 'change' | 'removed' | 'other'

export default function ChangelogIcon({ type, size = 'small' }) {
	return (
		<Chip
			label={type}
			size={size}
			style={{ width: '100%', ...typeToStyle(type) }}
		/>
	);
}
