export const getHouseTypes = (state) => state.packages.packages;

export const getLotPackages = (state) =>
	state.packages.lotPackages ? state.packages.lotPackages.data : [];

export const getBuilders = (state) => state.packages.builders;
export const getBuildersAll = (state) => state.packages.buildersAll;
export const getBuilder = (state) => state.packages.builder;
export const getBuilderPending = (state) => state.packages.pending;
export const getBuildersValues = (state) =>
	state.packages.builders && state.packages.builders.data
		? state.packages.builders.data
				.filter((builder) => builder.disabled !== true)
				.map((builder) => ({
					// eslint-disable-next-line no-underscore-dangle
					value: builder._id,
					label: builder.name
				}))
		: [];

export const getBuildersHouseTypes = (state) => state.packages.buildersHouseTypes;
export const getBuildersPackages = (state) => state.packages.buildersPackages;
export const getBuildersContacts = (state) => state.packages.buildersContacts;
export const getBuildersLots = (state) =>
	state.packages.buildersLots && state.packages.buildersLots.data;
export const getPackages = (state) => state.packages.allPackages;
export const getPackage = (state) => state.packages.package;
export const getPackagePending = (state) => state.packages.pending;
export const getHousePackages = (state) => state.packages.housePackages;
export const getPackageHistory = (state) =>
	state.packages.history && state.packages.history.data.items;
