import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	notifications: {},
	error: null
};

const notificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_NOTIFICATIONS_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				pending: false,
				notifications: action.data
			};
		}
		case actionTypes.FETCH_NOTIFICATIONS_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default notificationsReducer;
