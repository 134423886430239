/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField, Grid, CircularProgress } from '@material-ui/core';
import { getSelectedProject, getUserRows, getAuth } from 'src/selectors';
import _ from 'lodash';
import { fetchCustomersData, fetchLotsPaginateData, fetchReminderPaginateData } from 'src/api';
import { getPrimaryPhone, priceFormatter } from 'src/utils';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles(() => ({
	root: {
		padding: 0,
		width: '400px',
		marginRight: '30px'
	},
	rootContainer: {
		padding: 0
	},
	input: {
		margin: 0,
		background: 'white',
		borderRadius: '5px'
	}
}));

const searchType = [
	{
		name: 'Contact',
		id: 'contacts'
	},
	{
		name: 'Lot',
		id: 'lots'
	},
	{
		name: 'Stage',
		id: 'stages'
	},
	{
		name: 'Sale',
		id: 'sales'
	},
	{
		name: 'Package',
		id: 'packages'
	},
	{
		name: 'Reminders',
		id: 'reminders'
	}
];

function SearchBar() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const selectedProject = useSelector(getSelectedProject);
	const userId = useSelector(getAuth);
	const userRows = useSelector(getUserRows);

	const [searchString, setSearchString] = useState('');
	const [searchPage, setSearchPage] = useState('');
	const [options, setOptions] = useState([]);
	const [values, setValues] = useState({});
	const selectedOption = null;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setValues({});
	}, [selectedProject]);

	useEffect(() => {
		if (selectedProject && searchString) {
			if (values[searchPage] && values[searchPage].length) {
				setOptions([...values[searchPage]]);
			} else {
				switch (searchPage) {
					case 'stages': {
						setLoading(true);
						axios
							.get(`/api/projects/${selectedProject}/stages`)
							.then((data) => {
								setOptions(data?.data?.items || []);
								setValues((state) => ({
									...state,
									stage: data?.data?.items || []
								}));
							})
							.finally(() => setLoading(false));
						break;
					}
					case 'sales': {
						setLoading(true);
						axios
							.put(`/api/projects/${selectedProject}/sale`)
							.then((data) => {
								setOptions(data?.data || []);
								setValues((state) => ({ ...state, sales: data?.data || [] }));
							})
							.finally(() => setLoading(false));
						break;
					}
					case 'packages': {
						setLoading(true);
						axios
							.get(`/api/projects/${selectedProject}/packages`)
							.then((data) => {
								setOptions(data?.data?.items || []);
								setValues((state) => ({
									...state,
									packages: data?.data?.items || []
								}));
							})
							.finally(() => setLoading(false));
						break;
					}
					default:
						break;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectedProject, searchPage, searchString, userId]);

	useEffect(() => {
		if (!searchString) {
			setOptions([]);
		}
		if (selectedProject && searchString) {
			switch (searchPage) {
				case 'contacts': {
					setLoading(true);
					dispatch(
						fetchCustomersData(selectedProject, undefined, 50, { search: searchString })
					)
						.then((data) => {
							setOptions(data?.data?.items || []);
						})
						.finally(() => setLoading(false));
					break;
				}
				case 'lots': {
					setLoading(true);
					dispatch(
						fetchLotsPaginateData(selectedProject, 1, userRows, {
							search: searchString
						})
					)
						.then((data) => {
							setOptions(data?.data?.items || []);
						})
						.finally(() => setLoading(false));
					break;
				}
				case 'reminders': {
					setLoading(true);
					dispatch(
						fetchReminderPaginateData(selectedProject, 1, userRows, {
							search: searchString
						})
					)
						.then((data) => {
							setOptions(data?.data?.items || []);
						})
						.finally(() => setLoading(false));
					break;
				}
				default:
					break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectedProject, searchString, searchPage]);

	const handleSearchDebounce = _.debounce(
		(v) => {
			setSearchString(v);
		},
		300,
		{ trailing: true }
	);

	const handleFieldChange = (event) => {
		event.persist();
		setOptions([]);
		setSearchPage(event.target.value);
	};

	const getContacts = (contacts) => {
		const contactsArr = contacts.purchaserContact
			.map((el) => (contacts.companyPurchaser ? el.companyName : el.fullName))
			.join(',');

		return contactsArr;
	};

	const getOptionLabel = (option) => {
		switch (searchPage) {
			case 'contacts':
				return `${option?.fullName} (${option?.email}) ${getPrimaryPhone(option.phones)}`;
			case 'lots':
				return (
					`#${option?.humanId} (${option?.stage?.number}) ${priceFormatter(
						option.price
					)} ${option.sqm}m2` || ''
				);
			case 'stages':
				return `${option?.number} ${option?.name ? `(${option?.name})` : ''}` || '';
			case 'sales':
				return `#${option?.lotNumber?.humanId} ${getContacts(option)}` || '';
			case 'packages':
				return (
					`#${option?.lot?.humanId.toString()} ${option?.houseType?.name} (${
						option?.houseType?.builder?.name
					})` || ''
				);
			case 'reminders':
				return `${option?.note} (${option?.type})` || '';
			default:
				return null;
		}
	};

	const filterOptions = () => {
		switch (searchPage) {
			case 'contacts':
				return createFilterOptions({
					stringify: (option) =>
						option?.fullName + option?.email + getPrimaryPhone(option.phones) || ''
				});
			case 'lots':
				return createFilterOptions({
					stringify: (option) =>
						`#${option?.humanId}${option?.stage?.number}${option?.price}${option.sqm}` ||
						''
				});
			case 'stages':
				return createFilterOptions({
					stringify: (option) => option?.name + option?.number + option.releaseSatus || ''
				});
			case 'sales':
				return createFilterOptions({
					stringify: (option) =>
						`#${option?.lotNumber?.humanId}${getContacts(option)}` || ''
				});
			case 'packages':
				return createFilterOptions({
					stringify: (option) =>
						`#${option?.lot?.humanId.toString()}${option?.houseType?.name}${
							option?.houseType?.builder?.name
						}` || ''
				});
			case 'reminders':
				return createFilterOptions({
					stringify: (option) => option?.note + option?.type || ''
				});
			default:
				return () => '';
		}
	};

	const onSelectDetail = (e, v) => {
		e.persist();

		if (v) {
			switch (searchPage) {
				case 'contacts':
					history.push(`/admin/contacts/${v.id}`);
					break;
				case 'lots':
					history.push(`/admin/lots/${v.id}`);
					break;
				case 'stages':
					history.push(`/admin/stages/${v.id}`);
					break;
				case 'sales':
					history.push(`/admin/sales-details/${v.id}`);
					break;
				case 'packages':
					history.push(`/admin/packages/${v.id}`);
					break;
				case 'reminders':
					history.push(`/admin/reminders/${v._id}`);
					break;
				default:
					break;
			}
		}
	};

	return (
		<div className={clsx(classes.root)}>
			<Grid
				container
				spacing={2}
				className={classes.rootContainer}
				alignItems="center"
			>
				<Grid
					item
					md={4}
					xs={3}
				>
					<TextField
						className={classes.input}
						fullWidth
						name="type"
						variant="outlined"
						onChange={handleFieldChange}
						SelectProps={{ native: true }}
						select
						value={searchPage}
					>
						<option
							disabled
							value=""
						>
							Select Search
						</option>
						{searchType.map((option) => (
							<option
								key={option.id}
								value={option.id}
							>
								{option.name}
							</option>
						))}
					</TextField>
				</Grid>
				<Grid
					item
					md={8}
					xs={9}
				>
					<Autocomplete
						noOptionsText="No Results"
						value={selectedOption}
						onChange={onSelectDetail}
						fullWidth
						blurOnSelect
						filterOptions={filterOptions()}
						options={options}
						loading={loading}
						getOptionLabel={(option) => getOptionLabel(option)}
						onInputChange={(event, newInputValue) => {
							if (event?.target?.name === 'autocompleteSearch' || !newInputValue) {
								handleSearchDebounce(newInputValue);
							}
						}}
						name="autocompleteSearch"
						renderInput={(params) => (
							<TextField
								{...params}
								className={classes.input}
								fullWidth
								label="Search"
								name="autocompleteSearch"
								variant="outlined"
								value={params}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loading ? (
												<CircularProgress
													color="inherit"
													size={20}
												/>
											) : null}
											{params.InputProps.endAdornment}
										</>
									)
								}}
							/>
						)}
						renderOption={(option) => {
							const s = getOptionLabel(option);
							const matches = match(s, searchString);
							const parts = parse(s, matches);
							return (
								<div>
									{parts.map((part, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<span
											key={index}
											style={{ fontWeight: part.highlight ? 700 : 400 }}
										>
											{part.text}
										</span>
									))}
								</div>
							);
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default SearchBar;
