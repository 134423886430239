import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	confirmationResult: null,
	error: null
};

const confirmationActionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REPLY_ACTION_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.REPLY_ACTION_SUCCESS: {
			return {
				...state,
				pending: false,
				confirmationResult: action.result,
				error: null
			};
		}

		case actionTypes.REPLY_ACTION_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default confirmationActionsReducer;
