import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	IconButton,
	List,
	Chip,
	CircularProgress,
	Divider,
	Card,
	CardContent
} from '@material-ui/core';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import useWhatsNewPopup from './useWhatsNewPopup';
import ChangelogChangeListItem from '../Changelog/ChangelogChangeListItem';

export default function WhatsNewPopup() {
	const { isOnLatestVersion, loading, latestChangelogItem, onCloseWhatsNewPanel } =
		useWhatsNewPopup();
	const Loading = <CircularProgress />;

	const ChangelogDetails = (
		<Card>
			<CardContent>
				<Typography variant="subtitle2">
					Release Date: {moment(latestChangelogItem?.date).format('DD/MM/YYYY')}
				</Typography>
				<br />
				<Typography variant="body2">Changes</Typography>
				<Divider />
				<List dense>
					{latestChangelogItem?.changes.map((change, index) => (
						<div key={change.id}>
							<ChangelogChangeListItem change={change} />
							<Divider style={{ width: '96%', marginLeft: '2%' }} />
						</div>
					))}
				</List>
			</CardContent>
		</Card>
	);

	const currentView = loading ? Loading : ChangelogDetails;

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			onClose={onCloseWhatsNewPanel}
			open={!isOnLatestVersion}
		>
			<DialogTitle disableTypography>
				<Typography variant="h2">
					Whats new in v{latestChangelogItem?.version}
					{latestChangelogItem?.hotfix && (
						<Chip
							label="Hotfix"
							size="small"
							style={{
								backgroundColor: 'rgb(244, 67, 54)',
								color: 'rgb(255, 255, 255)',
								marginLeft: 10
							}}
						/>
					)}
				</Typography>

				<IconButton
					onClick={onCloseWhatsNewPanel}
					style={{ position: 'absolute', right: 10, top: 10 }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ minHeight: '700px', padding: '10px', backgroundColor: '#f4f6f8' }}
			>
				{currentView}
			</DialogContent>
		</Dialog>
	);
}
