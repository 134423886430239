export const getOutlookEventsSelector = (state) => state.outlook.events;

export const getOutlookMessagesSelector = (state) => state.outlook.messages;

export const getOutlookMailFoldersSelector = (state) => state.outlook.mailFolders;

export const getFoundUser = (state) => state.outlook.foundUser;

export const getSelectedSharedUser = (state) => state.outlook.selectedSharedUserFolder;

export const getSharedMailBoxes = (state) => state.outlook.sharedMailBoxes;

export const getAmountFetchMessages = (state) => state.outlook.amountFetchMessages;

export const getLoggedOutlookUser = (state) => state.outlook.loggedOutlookUser;
