/* eslint-disable import/prefer-default-export */
import {
	createSurveyApi,
	getSurveyApi,
	deleteSurveyApi,
	editSurveyApi,
	duplicateSurveyApi,
	assignSurveyApi,
	getAssignedSurveyApi,
	saveAnswersApi,
	getPurchaserProfilesApi,
	getPurchaserProfileByIdApi,
	deletePurchaserProfileApi,
	updateAnswersApi,
	sendUrlApi,
	checkTokenApi
} from 'src/api/surveyApi';
import * as actionTypes from '../actions';

export const getSurveysRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.getSurveyPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const data = await getSurveyApi(projectId);
		await dispatch(actionTypes.getSurveySuccess(data));
	} catch (error) {
		await dispatch(actionTypes.getSurveyError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const createSurvey = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await createSurveyApi(data);
		await dispatch(getSurveysRequest(data.projectId));
		await dispatch(
			actionTypes.showToast({
				body: 'Create Survey success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Create Survey error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteSurveysRequest = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await deleteSurveyApi(projectId, id, data);
		await dispatch(getSurveysRequest(projectId));
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message || 'Delete Survey error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const editSurvey = (data, surveyId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await editSurveyApi(data, surveyId);
		await dispatch(getSurveysRequest(data.projectId));
		await dispatch(
			actionTypes.showToast({
				body: 'Edit Survey success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Edit Survey error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const duplicateSurveyRequest = (projectId, surveyId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await duplicateSurveyApi(projectId, surveyId);
		await dispatch(getSurveysRequest(projectId));
		await dispatch(
			actionTypes.showToast({
				body: 'Duplicate Survey success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Duplicate Survey error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getAssignedSurveyRequest = (projectId, surveyId) => async (dispatch) => {
	await dispatch(actionTypes.getAssignedSurveyPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const data = await getAssignedSurveyApi(projectId, surveyId);
		await dispatch(actionTypes.getAssignedSurveySuccess(data));
	} catch (error) {
		await dispatch(actionTypes.getAssignedSurveyError());
	}
	await dispatch(actionTypes.setLoader(false));
};

export const assignToSurveyRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await assignSurveyApi(data);
		await dispatch(getAssignedSurveyRequest(data.selectedProject, data.surveyId));
		await dispatch(
			actionTypes.showToast({
				body: 'Assign Survey success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Assign Survey error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const saveAnswersRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await saveAnswersApi(data);
		await dispatch(getAssignedSurveyRequest(data.projectId, data.surveyId));
		await dispatch(
			actionTypes.showToast({
				body: 'Save Answers success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save Answers error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getPurchaserProfilesRequest = (selectedProject) => async (dispatch) => {
	await dispatch(actionTypes.getPurchaserProfilesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const data = await getPurchaserProfilesApi(selectedProject);
		await dispatch(actionTypes.getPurchaserProfilesSuccess(data));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Answers error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getPurchaserProfileByIdRequest = (selectedProject, profileId) => async (dispatch) => {
	await dispatch(actionTypes.getPurchaserProfileByIdPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const res = await getPurchaserProfileByIdApi(selectedProject, profileId);
		await dispatch(actionTypes.getPurchaserProfileByIdSuccess(res.data));
		return res.data;
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Answers error'
			})
		);
		await dispatch(actionTypes.getPurchaserProfileByIdSuccess(error || 'Get Answers error'));
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const deletePurchaserProfilesRequest =
	(projectId, id, contactId, data) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			const response = await deletePurchaserProfileApi(projectId, id, contactId, data);
			await dispatch(
				actionTypes.showToast({
					type: 'success',
					body: response.data?.message ?? 'Your request has been successfully sent'
				})
			);
		} catch (error) {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: error?.response?.data?.message ?? 'Delete Profile error'
				})
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const updateAnswersRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await updateAnswersApi(data);
		await dispatch(getPurchaserProfilesRequest(data.projectId));
		await dispatch(
			actionTypes.showToast({
				body: 'Save Answers success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save Answers error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const sendUrlRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await sendUrlApi(data);
		await dispatch(
			actionTypes.showToast({
				body: 'Send URL success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Send URL error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const checkContactToken = (data) => async (dispatch) => {
	try {
		await checkTokenApi(data);
	} catch (error) {
		data.history.push('/');
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'URL error'
			})
		);
	}
};
