import axios from 'axios';

export const fetchProjectPresentations = ({ projectId }) =>
	axios.get(`/api/projects/${projectId}/presentations`);

export const fetchPresentation = ({ projectId, presentationId }) =>
	axios.get(`/api/projects/${projectId}/presentations/${presentationId}`);

export const createPresentation = ({ projectId, ...rest }) =>
	axios.post(`/api/projects/${projectId}/presentations`, rest);

export const updatePresentation = ({ projectId, presentationId, ...rest }) =>
	axios.put(`/api/projects/${projectId}/presentations/${presentationId}`, { ...rest, projectId });

export const deletePresentation = ({ projectId, presentationId, ...rest }) =>
	axios.delete(`/api/projects/${projectId}/presentations/${presentationId}`, { projectId });

export const fetchPresentationImageUploadSignature = ({ projectId, presentationId }) =>
	axios.get(`/api/projects/${projectId}/presentations/${presentationId}/signUploadSlideImage`);
