import axios from 'axios';

export const renameContactGroups = (projectId, data) =>
	axios.put(`/api/projects/${projectId}/rename-contact-group`, data);

export const resyncContactGroups = (projectId, contactGroupId) =>
	axios.put(`/api/projects/${projectId}/contact-groups/resyncWithSib/${contactGroupId}`);

export const fetchContactGroupsList = (projectId) =>
	axios.get(`/api/projects/${projectId}/contact-groups-list`);
