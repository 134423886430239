import * as actionTypes from '../actions/index';

const initialState = {
	projectPresentations: null,
	selectedPresentation: null,
	pending: false,
	error: false
};

const presentationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PROJECT_PRESENTATIONS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.PROJECT_PRESENTATIONS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				projectPresentations: action.presentations,
				error: null
			};
		}

		case actionTypes.PROJECT_PRESENTATIONS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.PRESENTATION_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.PRESENTATION_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				selectedPresentation: action.presentation,
				error: null
			};
		}

		case actionTypes.PRESENTATION_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default presentationReducer;
