export const FETCH_NOTIFICATIONS_PENDING = 'FETCH_NOTIFICATIONS_PENDING';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

export const SET_NOTIFICATIONS_PAGINATION = 'SET_NOTIFICATIONS_PAGINATION';

export const fetchNotificationsPending = () => ({
	type: FETCH_NOTIFICATIONS_PENDING
});

export const fetchNotificationsSuccess = (data) => ({
	type: FETCH_NOTIFICATIONS_SUCCESS,
	data
});

export const fetchNotificationsError = (error) => ({
	type: FETCH_NOTIFICATIONS_ERROR,
	error
});
