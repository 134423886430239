import {
	fetchReportSystem,
	fetchSalesSummary,
	fetchWeeklySales,
	fetchCancellations,
	fetchFilteredSales,
	fetchPastSettlement,
	fetchFutureSettlement,
	fetchStageAnalysis,
	fetchSaleSummaryAll,
	fetchWeekReportAll,
	fetchSettlementAll,
	fetchPurchaserProfiles,
	fetchContactSources,
	fetchPurchaserMapping,
	fetchBuilderDocuments,
	fetchWeekReportAllConditional,
	fetchWeekReportAllWeek,
	saveReportNoteApi,
	fetchReportNoteApi,
	fetchWeekReportAllStage,
	fetchSaleSettlementsApi,
	fetchHometownPackages,
	getAllOverdueDeposits,
	fetchSunsetDatesReport,
	fetchCommissionsReport
} from 'src/api/reportSystemApi';
import { sliceIntoChunks } from 'src/utils';
import * as actionTypes from '../actions';
import { setLoader, showToast } from '../actions';

export const fetchReportSystemRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchReportSystemPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const systems = await fetchReportSystem(data);
		await dispatch(actionTypes.fetchReportSystemSuccess(systems));
	} catch (error) {
		await dispatch(actionTypes.fetchReportSystemError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get reports error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchWeeklySalesRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchWeeklySalesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const weeklySales = await fetchWeeklySales(data);
		await dispatch(actionTypes.fetchWeeklySalesSuccess(weeklySales));
	} catch (error) {
		await dispatch(actionTypes.fetchWeeklySalesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Weekly Sales error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchSalesSummaryRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchSalesSummaryPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const salesSummary = await fetchSalesSummary(data);
		await dispatch(actionTypes.fetchSalesSummarySuccess(salesSummary));
	} catch (error) {
		await dispatch(actionTypes.fetchSalesSummaryError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Weekly Sales error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchCancellationsRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchCancellationsPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const cancellations = await fetchCancellations(data);
		await dispatch(actionTypes.fetchCancellationsSuccess(cancellations));
	} catch (error) {
		await dispatch(actionTypes.fetchCancellationsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Cancelled Sales error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchHometownPackagesRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchHometownPackagesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const packages = await fetchHometownPackages(data);
		await dispatch(actionTypes.fetchHometownPackagesSuccess(packages));
	} catch (error) {
		await dispatch(actionTypes.fetchHometownPackagesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Sales error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getAllOverdueDepositsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchAllOverdueDepositsPending());
	try {
		const resData = await getAllOverdueDeposits();
		await dispatch(actionTypes.fetchAllOverdueDepositsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchAllOverdueDepositsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getSunsetDatesReportRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchSunsetDatesReportPending(true));
	try {
		const resData = await fetchSunsetDatesReport(data);
		await dispatch(actionTypes.fetchSunsetDatesReportSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchSunsetDatesReportError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchFilteredSalesRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchFilteredSalesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const filteredSales = await fetchFilteredSales(data);
		await dispatch(actionTypes.fetchFilteredSalesSuccess(filteredSales));
	} catch (error) {
		await dispatch(actionTypes.fetchFilteredSalesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Sales error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchPastSettlementRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchPastSettlementPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const pastSettlement = await fetchPastSettlement(data);
		await dispatch(actionTypes.fetchPastSettlementSuccess(pastSettlement));
	} catch (error) {
		await dispatch(actionTypes.fetchPastSettlementError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Settlement error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchFutureSettlementRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchFutureSettlementPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const futureSettlement = await fetchFutureSettlement(data);
		await dispatch(actionTypes.fetchFutureSettlementSuccess(futureSettlement));
	} catch (error) {
		await dispatch(actionTypes.fetchFutureSettlementError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Settlement error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchStageAnalysisRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchStageAnalysisPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const stageAnalysis = await fetchStageAnalysis(data);
		await dispatch(actionTypes.fetchStageAnalysisSuccess(stageAnalysis));
	} catch (error) {
		await dispatch(actionTypes.fetchStageAnalysisError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Stage Analysis error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchPurchaserProfilesRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchPurchaserProfilesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const purchaserProfiles = await fetchPurchaserProfiles(data);
		await dispatch(actionTypes.fetchPurchaserProfilesSuccess(purchaserProfiles));
	} catch (error) {
		await dispatch(actionTypes.fetchPurchaserProfilesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Contact Profiles error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactSourcesRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchContactSourcesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const contactSources = await fetchContactSources(data);
		await dispatch(actionTypes.fetchContactSourcesSuccess(contactSources));
	} catch (error) {
		await dispatch(actionTypes.fetchContactSourcesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Enquiry Sources error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchPurchaserMappingRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchPurchaserMappingPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const purchaserMapping = await fetchPurchaserMapping(data);
		await dispatch(actionTypes.fetchPurchaserMappingSuccess(purchaserMapping));
	} catch (error) {
		await dispatch(actionTypes.fetchPurchaserMappingError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Purchaser Mapping error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuilderDocumentsRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchBuilderDocumentsPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const builderDocuments = await fetchBuilderDocuments(data);
		await dispatch(actionTypes.fetchBuilderDocumentsSuccess(builderDocuments));
	} catch (error) {
		await dispatch(actionTypes.fetchBuilderDocumentsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Builder Documents error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchWeekReportAllRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekReportAllPending());
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchWeekReportAllContractsPending(true));
	await dispatch(actionTypes.fetchWeekReportAllWeekSummaryPending(true));
	let weekReportAll = null;
	let weekReportConditional = null;
	let weekReportWeek = null;
	try {
		weekReportAll = await fetchWeekReportAll(data);
		await dispatch(actionTypes.fetchWeekReportAllSuccess(weekReportAll));
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekReportAllError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Sales Summary All error'
			})
		);
	}

	try {
		weekReportWeek = await fetchWeekReportAllWeek(data);
		await dispatch(
			actionTypes.fetchWeekReportAllSuccess({
				data: { ...weekReportAll.data, ...weekReportWeek.data }
			})
		);
		await dispatch(actionTypes.fetchWeekReportAllWeekSummaryPending(false));
	} catch (e) {
		await dispatch(actionTypes.fetchWeekReportAllWeekSummaryPending(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Week Summary All error'
			})
		);
	}

	try {
		weekReportConditional = await fetchWeekReportAllConditional(data);
		await dispatch(
			actionTypes.fetchWeekReportAllSuccess({
				data: {
					...weekReportAll.data,
					...weekReportWeek.data,
					...weekReportConditional.data
				}
			})
		);
		await dispatch(actionTypes.fetchWeekReportAllContractsPending(false));
	} catch (e) {
		await dispatch(actionTypes.fetchWeekReportAllWeekSummaryPending(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Contracts All error'
			})
		);
	}

	try {
		let result = [];
		const chunk = sliceIntoChunks(data.projectsList, 2);
		for (let i = 0; i < chunk.length; i++) {
			// eslint-disable-next-line no-await-in-loop
			const {
				data: { stages }
			} = await fetchWeekReportAllStage({ ...data, fetchProject: chunk[i] });
			result = [...result, ...stages];
		}

		return result;
	} catch (e) {
		actionTypes.showToast({
			type: 'error',
			body: 'Get Week Summary All error'
		});
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchSaleSummaryAllRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchSalesSummaryAllPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const saleSummaryAll = await fetchSaleSummaryAll(data);
		await dispatch(actionTypes.fetchSalesSummaryAllSuccess(saleSummaryAll));
	} catch (error) {
		await dispatch(actionTypes.fetchSalesSummaryAllError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Sales Summary All error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchSettlementAllRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchSettlementAllPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const settlementAll = await fetchSettlementAll(data);
		await dispatch(actionTypes.fetchSettlementAllSuccess(settlementAll));
	} catch (error) {
		await dispatch(actionTypes.fetchSettlementAllError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Settlement error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchReportNote = (passData) => async (dispatch) => {
	try {
		const { data } = await fetchReportNoteApi(passData);
		return data;
	} catch (e) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save report note error'
			})
		);
	}
};

export const saveReportNote = (data) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const {
			data: { message }
		} = await saveReportNoteApi(data);
		await dispatch(
			actionTypes.showToast({
				body: message
			})
		);
	} catch (e) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save report note error'
			})
		);
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const fetchSaleSettlements = (values) => async (dispatch) => {
	try {
		await dispatch(setLoader(true));
		const { data } = await fetchSaleSettlementsApi(values);
		return data;
	} catch (error) {
		await dispatch(showToast({ type: 'error', body: error.message || 'Get Settlement error' }));
	} finally {
		await dispatch(setLoader(false));
	}
};

export const fetchCommissionsReportRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchCommissionsReportPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const commissionsReport = await fetchCommissionsReport(data);
		await dispatch(actionTypes.fetchCommissionsReportSuccess(commissionsReport));
	} catch (error) {
		await dispatch(actionTypes.fetchCommissionsReportError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Commissions error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};
