import React, { useState } from 'react';
import uuid from 'uuid';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

export default function ErrorBoundary({ children, FallbackComponent, ...props }) {
	const [errorCode, setErrorCode] = useState(uuid.v4());

	const onError = (error, info) => {
		const code = uuid.v4();
		setErrorCode(code);

		if (props.onError) {
			props.onError(error, info, code);
		}
	};

	const onReset = () => {
		if (props.onReset) {
			props.onReset();
		}
	};

	if (!FallbackComponent) {
		return null;
	}

	return (
		<ReactErrorBoundary
			onError={onError}
			onReset={onReset}
		>
			{children}
		</ReactErrorBoundary>
	);
}
