import { Config } from './config';

export const config = {
	appId: Config.AZURE_APP_ID,
	redirectUri: `${Config.APP_URL}/admin/dashboard`
};

export const msalConfig = {
	auth: {
		authority: 'https://login.microsoftonline.com/common',
		clientId: config.appId,
		redirectUri: config.redirectUri
	},
	cache: {
		cacheLocation: 'localStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
	}
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: [
		'User.Read',
		'mailboxsettings.read',
		'mail.read',
		'Mail.Read.Shared',
		'Mail.Send.Shared',
		'Mail.ReadWrite.Shared',
		'calendars.readwrite',
		'User.Read.All',
		'Mail.Send',
		'Mail.ReadWrite'
	]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me'
};
