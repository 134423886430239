export const FETCH_REPORT_SYSTEM_PENDING = 'FETCH_REPORT_SYSTEM_PENDING';
export const FETCH_REPORT_SYSTEM_SUCCESS = 'FETCH_REPORT_SYSTEM_SUCCESS';
export const FETCH_REPORT_SYSTEM_ERROR = 'FETCH_REPORT_SYSTEM_ERROR';

export const FETCH_WEEKLY_SALES_PENDING = 'FETCH_WEEKLY_SALES_PENDING';
export const FETCH_WEEKLY_SALES_SUCCESS = 'FETCH_WEEKLY_SALES_SUCCESS';
export const FETCH_WEEKLY_SALES_ERROR = 'FETCH_WEEKLY_SALES_ERROR';

export const FETCH_SALES_SUMMARY_PENDING = 'FETCH_SALES_SUMMARY_PENDING';
export const FETCH_SALES_SUMMARY_SUCCESS = 'FETCH_SALES_SUMMARY_SUCCESS';
export const FETCH_SALES_SUMMARY_ERROR = 'FETCH_SALES_SUMMARY_ERROR';

export const FETCH_CANCELLATIONS_PENDING = 'FETCH_CANCELLATIONS_PENDING';
export const FETCH_CANCELLATIONS_SUCCESS = 'FETCH_CANCELLATIONS_SUCCESS';
export const FETCH_CANCELLATIONS_ERROR = 'FETCH_CANCELLATIONS_ERROR';

export const FETCH_FILTERED_SALES_PENDING = 'FETCH_FILTERED_SALES_PENDING';
export const FETCH_FILTERED_SALES_SUCCESS = 'FETCH_FILTERED_SALES_SUCCESS';
export const FETCH_FILTERED_SALES_ERROR = 'FETCH_FILTERED_SALES_ERROR';

export const FETCH_PAST_SETTLEMENT_PENDING = 'FETCH_PAST_SETTLEMENT_PENDING';
export const FETCH_PAST_SETTLEMENT_SUCCESS = 'FETCH_PAST_SETTLEMENT_SUCCESS';
export const FETCH_PAST_SETTLEMENT_ERROR = 'FETCH_PAST_SETTLEMENT_ERROR';

export const FETCH_FUTURE_SETTLEMENT_PENDING = 'FETCH_FUTURE_SETTLEMENT_PENDING';
export const FETCH_FUTURE_SETTLEMENT_SUCCESS = 'FETCH_FUTURE_SETTLEMENT_SUCCESS';
export const FETCH_FUTURE_SETTLEMENT_ERROR = 'FETCH_FUTURE_SETTLEMENT_ERROR';

export const FETCH_STAGE_ANALYSIS_PENDING = 'FETCH_STAGE_ANALYSIS_PENDING';
export const FETCH_STAGE_ANALYSIS_SUCCESS = 'FETCH_STAGE_ANALYSIS_SUCCESS';
export const FETCH_STAGE_ANALYSIS_ERROR = 'FETCH_STAGE_ANALYSIS_ERROR';

export const FETCH_PURCHASER_PROFILES_PENDING = 'FETCH_PURCHASER_PROFILES_PENDING';
export const FETCH_PURCHASER_PROFILES_SUCCESS = 'FETCH_PURCHASER_PROFILES_SUCCESS';
export const FETCH_PURCHASER_PROFILES_ERROR = 'FETCH_PURCHASER_PROFILES_ERROR';

export const FETCH_CONTACT_SOURCES_PENDING = 'FETCH_CONTACT_SOURCES_PENDING';
export const FETCH_CONTACT_SOURCES_SUCCESS = 'FETCH_CONTACT_SOURCES_SUCCESS';
export const FETCH_CONTACT_SOURCES_ERROR = 'FETCH_CONTACT_SOURCES_ERROR';

export const FETCH_PURCHASER_MAPPING_PENDING = 'FETCH_PURCHASER_MAPPING_PENDING';
export const FETCH_PURCHASER_MAPPING_SUCCESS = 'FETCH_PURCHASER_MAPPING_SUCCESS';
export const FETCH_PURCHASER_MAPPING_ERROR = 'FETCH_PURCHASER_MAPPING_ERROR';

export const FETCH_BUILDER_DOCUMENTS_PENDING = 'FETCH_BUILDER_DOCUMENTS_PENDING';
export const FETCH_BUILDER_DOCUMENTS_SUCCESS = 'FETCH_BUILDER_DOCUMENTS_SUCCESS';
export const FETCH_BUILDER_DOCUMENTS_ERROR = 'FETCH_BUILDER_DOCUMENTS_ERROR';

export const FETCH_SALES_SUMMARY_ALL_PENDING = 'FETCH_SALES_SUMMARY_ALL_PENDING';
export const FETCH_SALES_SUMMARY_ALL_SUCCESS = 'FETCH_SALES_SUMMARY_ALL_SUCCESS';
export const FETCH_SALES_SUMMARY_ALL_ERROR = 'FETCH_SALES_SUMMARY_ALL_ERROR';

export const FETCH_WEEK_REPORT_ALL_PENDING = 'FETCH_WEEK_REPORT_ALL_PENDING';
export const FETCH_WEEK_REPORT_ALL_SUCCESS = 'FETCH_WEEK_REPORT_ALL_SUCCESS';
export const FETCH_WEEK_REPORT_ALL_ERROR = 'FETCH_WEEK_REPORT_ALL_ERROR';

export const FETCH_SETTLEMENT_ALL_PENDING = 'FETCH_SETTLEMENT_ALL_PENDING';
export const FETCH_SETTLEMENT_ALL_SUCCESS = 'FETCH_SETTLEMENT_ALL_SUCCESS';
export const FETCH_SETTLEMENT_ALL_ERROR = 'FETCH_SETTLEMENT_ALL_ERROR';

export const FETCH_WEEK_REPORT_ALL_WEEK_SUMMARY_PENDING =
	'FETCH_WEEK_REPORT_ALL_WEEK_SUMMARY_PENDING';

export const FETCH_WEEK_REPORT_ALL_CONTRACTS_PENDING = 'FETCH_WEEK_REPORT_ALL_CONTRACTS_PENDING';

export const SET_SALES_SUMMARY = 'SET_SALES_SUMMARY';

export const FETCH_HOMETOWNPACKAGE_PENDING = 'FETCH_HOMETOWNPACKAGE_PENDING';
export const FETCH_HOMETOWNPACKAGE_ERROR = 'FETCH_HOMETOWNPACKAGE_ERROR';
export const FETCH_HOMETOWNPACKAGE_SUCCESS = 'FETCH_HOMETOWNPACKAGE_SUCCESS';

export const FETCH_ALL_OVERDUE_DEPOSITS_PENDING = 'FETCH_ALL_OVERDUE_DEPOSITS_PENDING';
export const FETCH_ALL_OVERDUE_DEPOSITS_ERROR = 'FETCH_ALL_OVERDUE_DEPOSITS_ERROR';
export const FETCH_ALL_OVERDUE_DEPOSITS_SUCCESS = 'FETCH_ALL_OVERDUE_DEPOSITS_SUCCESS';

export const FETCH_SUNSET_DATES_REPORT_PENDING = 'FETCH_SUNSET_DATES_REPORT_PENDING';
export const FETCH_SUNSET_DATES_REPORT_ERROR = 'FETCH_SUNSET_DATES_REPORT_ERROR';
export const FETCH_SUNSET_DATES_REPORT_SUCCESS = 'FETCH_SUNSET_DATES_REPORT_SUCCESS';

export const FETCH_COMMISSIONS_REPORT_PENDING = 'FETCH_COMMISSIONS_REPORT_PENDING';
export const FETCH_COMMISSIONS_REPORT_SUCCESS = 'FETCH_COMMISSIONS_REPORT_SUCCESS';
export const FETCH_COMMISSIONS_REPORT_ERROR = 'FETCH_COMMISSIONS_REPORT_ERROR';

export const fetchAllOverdueDepositsError = (error) => ({
	type: FETCH_ALL_OVERDUE_DEPOSITS_ERROR,
	error
});
export const fetchAllOverdueDepositsPending = () => ({
	type: FETCH_ALL_OVERDUE_DEPOSITS_PENDING
});
export const fetchAllOverdueDepositsSuccess = (allOutstandingDeposits) => ({
	type: FETCH_ALL_OVERDUE_DEPOSITS_SUCCESS,
	allOutstandingDeposits
});

export const fetchSunsetDatesReportError = (error) => ({
	type: FETCH_SUNSET_DATES_REPORT_ERROR,
	error
});
export const fetchSunsetDatesReportPending = (pending) => ({
	type: FETCH_SUNSET_DATES_REPORT_PENDING,
	pending
});
export const fetchSunsetDatesReportSuccess = (sunsetDates) => ({
	type: FETCH_SUNSET_DATES_REPORT_SUCCESS,
	sunsetDates
});

export const fetchReportSystemPending = () => ({
	type: FETCH_REPORT_SYSTEM_PENDING
});

export const fetchReportSystemSuccess = (reportSystem) => ({
	type: FETCH_REPORT_SYSTEM_SUCCESS,
	reportSystem
});

export const fetchReportSystemError = (error) => ({
	type: FETCH_REPORT_SYSTEM_ERROR,
	error
});

export const fetchWeeklySalesPending = () => ({
	type: FETCH_WEEKLY_SALES_PENDING
});

export const fetchWeeklySalesSuccess = (weeklySales) => ({
	type: FETCH_WEEKLY_SALES_SUCCESS,
	weeklySales
});

export const fetchWeeklySalesError = (error) => ({
	type: FETCH_WEEKLY_SALES_ERROR,
	error
});

export const fetchSalesSummaryPending = () => ({
	type: FETCH_SALES_SUMMARY_PENDING
});

export const fetchSalesSummarySuccess = (salesSummary) => ({
	type: FETCH_SALES_SUMMARY_SUCCESS,
	salesSummary
});

export const fetchSalesSummaryError = (error) => ({
	type: FETCH_SALES_SUMMARY_ERROR,
	error
});

export const setSalesSummary = (setSales) => ({
	type: SET_SALES_SUMMARY,
	setSales
});

export const fetchHometownPackagesPending = () => ({
	type: FETCH_HOMETOWNPACKAGE_PENDING
});
export const fetchHometownPackagesError = (error) => ({
	type: FETCH_HOMETOWNPACKAGE_ERROR,
	error
});
export const fetchHometownPackagesSuccess = (packageTownhome) => ({
	type: FETCH_HOMETOWNPACKAGE_SUCCESS,
	packageTownhome
});
export const fetchCancellationsPending = () => ({
	type: FETCH_CANCELLATIONS_PENDING
});

export const fetchCancellationsSuccess = (cancellations) => ({
	type: FETCH_CANCELLATIONS_SUCCESS,
	cancellations
});

export const fetchCancellationsError = (error) => ({
	type: FETCH_CANCELLATIONS_ERROR,
	error
});

export const fetchFilteredSalesPending = () => ({
	type: FETCH_FILTERED_SALES_PENDING
});

export const fetchFilteredSalesSuccess = (filteredSales) => ({
	type: FETCH_FILTERED_SALES_SUCCESS,
	filteredSales
});

export const fetchFilteredSalesError = (error) => ({
	type: FETCH_FILTERED_SALES_ERROR,
	error
});

export const fetchPastSettlementPending = () => ({
	type: FETCH_PAST_SETTLEMENT_PENDING
});

export const fetchPastSettlementSuccess = (pastSettlement) => ({
	type: FETCH_PAST_SETTLEMENT_SUCCESS,
	pastSettlement
});

export const fetchPastSettlementError = (error) => ({
	type: FETCH_PAST_SETTLEMENT_ERROR,
	error
});

export const fetchFutureSettlementPending = () => ({
	type: FETCH_FUTURE_SETTLEMENT_PENDING
});

export const fetchFutureSettlementSuccess = (futureSettlement) => ({
	type: FETCH_FUTURE_SETTLEMENT_SUCCESS,
	futureSettlement
});

export const fetchFutureSettlementError = (error) => ({
	type: FETCH_FUTURE_SETTLEMENT_ERROR,
	error
});

export const fetchStageAnalysisPending = () => ({
	type: FETCH_STAGE_ANALYSIS_PENDING
});

export const fetchStageAnalysisSuccess = (stageAnalysis) => ({
	type: FETCH_STAGE_ANALYSIS_SUCCESS,
	stageAnalysis
});

export const fetchStageAnalysisError = (error) => ({
	type: FETCH_STAGE_ANALYSIS_ERROR,
	error
});

export const fetchPurchaserProfilesPending = () => ({
	type: FETCH_PURCHASER_PROFILES_PENDING
});

export const fetchPurchaserProfilesSuccess = (purchaserProfiles) => ({
	type: FETCH_PURCHASER_PROFILES_SUCCESS,
	purchaserProfiles
});

export const fetchPurchaserProfilesError = (error) => ({
	type: FETCH_PURCHASER_PROFILES_ERROR,
	error
});

export const fetchContactSourcesPending = () => ({
	type: FETCH_CONTACT_SOURCES_PENDING
});

export const fetchContactSourcesSuccess = (contactSources) => ({
	type: FETCH_CONTACT_SOURCES_SUCCESS,
	contactSources
});

export const fetchContactSourcesError = (error) => ({
	type: FETCH_CONTACT_SOURCES_ERROR,
	error
});

export const fetchPurchaserMappingPending = () => ({
	type: FETCH_PURCHASER_MAPPING_PENDING
});

export const fetchPurchaserMappingSuccess = (purchaserMapping) => ({
	type: FETCH_PURCHASER_MAPPING_SUCCESS,
	purchaserMapping
});

export const fetchPurchaserMappingError = (error) => ({
	type: FETCH_PURCHASER_MAPPING_ERROR,
	error
});

export const fetchBuilderDocumentsPending = () => ({
	type: FETCH_BUILDER_DOCUMENTS_PENDING
});

export const fetchBuilderDocumentsSuccess = (builderDocuments) => ({
	type: FETCH_BUILDER_DOCUMENTS_SUCCESS,
	builderDocuments
});

export const fetchBuilderDocumentsError = (error) => ({
	type: FETCH_BUILDER_DOCUMENTS_ERROR,
	error
});

export const fetchWeekReportAllPending = () => ({
	type: FETCH_WEEK_REPORT_ALL_PENDING
});

export const fetchWeekReportAllSuccess = (weekReportAll) => ({
	type: FETCH_WEEK_REPORT_ALL_SUCCESS,
	weekReportAll
});

export const fetchWeekReportAllError = (error) => ({
	type: FETCH_WEEK_REPORT_ALL_ERROR,
	error
});

export const fetchSalesSummaryAllPending = () => ({
	type: FETCH_SALES_SUMMARY_ALL_PENDING
});

export const fetchSalesSummaryAllSuccess = (salesSummaryAll) => ({
	type: FETCH_SALES_SUMMARY_ALL_SUCCESS,
	salesSummaryAll
});

export const fetchSalesSummaryAllError = (error) => ({
	type: FETCH_SALES_SUMMARY_ALL_ERROR,
	error
});

export const fetchSettlementAllPending = () => ({
	type: FETCH_SETTLEMENT_ALL_PENDING
});

export const fetchSettlementAllSuccess = (settlementAll) => ({
	type: FETCH_SETTLEMENT_ALL_SUCCESS,
	settlementAll
});

export const fetchSettlementAllError = (error) => ({
	type: FETCH_SETTLEMENT_ALL_ERROR,
	error
});

export const fetchWeekReportAllWeekSummaryPending = (pending) => ({
	type: FETCH_WEEK_REPORT_ALL_WEEK_SUMMARY_PENDING,
	pending
});

export const fetchWeekReportAllContractsPending = (pending) => ({
	type: FETCH_WEEK_REPORT_ALL_CONTRACTS_PENDING,
	pending
});

export const fetchCommissionsReportPending = () => ({
	type: FETCH_COMMISSIONS_REPORT_PENDING
});

export const fetchCommissionsReportSuccess = (filteredSales) => ({
	type: FETCH_COMMISSIONS_REPORT_SUCCESS,
	filteredSales
});

export const fetchCommissionsReportError = (error) => ({
	type: FETCH_COMMISSIONS_REPORT_ERROR,
	error
});
