import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-block',
		borderRadius: '50%',
		flexGrow: 0,
		flexShrink: 0,
		marginRight: 5
	},
	small: {
		height: theme.spacing(1),
		width: theme.spacing(1)
	},
	medium: {
		height: theme.spacing(2),
		width: theme.spacing(2)
	},
	large: {
		height: theme.spacing(3),
		width: theme.spacing(3)
	},
	default: {
		backgroundColor: colors.grey[50]
	},
	defaultGrey: {
		backgroundColor: colors.grey[500]
	},
	primary: {
		backgroundColor: theme.palette.primary.main
	},
	info: {
		backgroundColor: colors.lightBlue[600]
	},
	warning: {
		backgroundColor: colors.orange[400]
	},
	error: {
		backgroundColor: theme.palette.error.main
	},
	success: {
		backgroundColor: colors.green[400]
	},
	onHold: {
		backgroundColor: colors.purple[500]
	},
	settled: {
		backgroundColor: colors.yellow[500]
	},
	black: {
		backgroundColor: colors.grey[900]
	},
	transferred: {
		backgroundColor: colors.cyan[500]
	}
}));

function StatusBullet({ className, size, color, ...rest }) {
	const classes = useStyles();
	const rootClassName = clsx(
		{
			[classes.root]: true,
			[classes[size]]: size,
			[classes[color]]: color
		},
		className
	);

	return (
		<span
			{...rest}
			className={rootClassName}
		/>
	);
}

StatusBullet.propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'default',
		'defaultGrey',
		'primary',
		'info',
		'success',
		'warning',
		'error',
		'settled',
		'onHold',
		'black',
		'transferred'
	]),
	size: PropTypes.oneOf(['small', 'medium', 'large'])
};

StatusBullet.defaultProps = {
	size: 'medium',
	color: 'default'
};

export default StatusBullet;
