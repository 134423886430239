export const getStages = (state) => state.stages.stages;
export const getStagePending = (state) => state.stages.pending;
export const getStagesValues = (state) =>
	state.stages.stages &&
	state.stages.stages.data.items.map((stage) => ({
		value: stage.id,
		label: stage.name
			? `${stage.number} (${stage.name})`
			: stage.number /** TODO: needs to be stage.number */
	}));
export const getStagesLots = (state) =>
	state.stages.stages &&
	state.stages.stages.data.items.map((stage) => [...stage.lots]).flatMap((x) => x);
export const getStageDetails = (state) => state.stages.details && state.stages.details.data;
