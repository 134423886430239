export const GET_WALK_IN_PENDING = 'GET_WALK_IN_PENDING';
export const GET_WALK_IN_SUCCESS = 'GET_WALK_IN_SUCCESS';
export const GET_WALK_IN_ERROR = 'GET_WALK_IN_ERROR';

export const getWalkInPending = () => ({
	type: GET_WALK_IN_PENDING
});

export const getWalkInSuccess = (data) => ({
	type: GET_WALK_IN_SUCCESS,
	data
});

export const getWalkInError = (error) => ({
	type: GET_WALK_IN_ERROR,
	error
});
