import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	commissions: null,
	commissionsPagination: null,
	commissionsData: null,
	commissionsItem: null,
	commissionsDetails: null,
	usersCommissions: null,
	error: null,
	commissionsDataPending: false,
	commissionsFilters: {
		includePaid: false
	}
};

const commissionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.COMMISSIONS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.COMMISSIONS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				commissions: action.commissions,
				error: null
			};
		}

		case actionTypes.COMMISSIONS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.SET_COMMISSIONS_FILTERS: {
			return {
				...state,
				commissionsFilters: {
					...state.commissionsFilters,
					[action.filterType]: action.data
				}
			};
		}

		case actionTypes.CLEAR_COMMISSIONS_FILTERS: {
			return {
				...state,
				commissionsFilters: {
					includePaid: false
				}
			};
		}

		case actionTypes.COMMISSIONS_PAGINATE_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				error: null,
				commissions: action.commissions,
				commissionsPagination: action.commissions.data.pagination
			};
		}

		case actionTypes.COMMISSIONS_DATA_FETCH_PENDING: {
			return {
				...state,
				commissionsDataPending: true
			};
		}

		case actionTypes.COMMISSIONS_DATA_FETCH_SUCCESS: {
			return {
				...state,
				commissionsDataPending: false,
				commissionsData: action.commissionsData,
				error: null
			};
		}

		case actionTypes.COMMISSIONS_DATA_FETCH_ERROR: {
			return {
				...state,
				commissionsDataPending: false,
				error: action.error
			};
		}

		case actionTypes.COMMISSIONS_ITEM_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.COMMISSIONS_ITEM_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				commissionsItem: action.commissionsItem,
				error: null
			};
		}

		case actionTypes.COMMISSIONS_ITEM_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.COMMISSIONS_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.COMMISSIONS_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				commissionsDetails: action.commissionsDetails,
				error: null
			};
		}

		case actionTypes.COMMISSIONS_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.COMMISSIONS_DETAILS_RESET: {
			return {
				...state,
				commissionsDetails: null
			};
		}

		case actionTypes.USERS_COMMISSIONS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USERS_COMMISSIONS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				usersCommissions: action.usersCommissions,
				error: null
			};
		}

		case actionTypes.USERS_COMMISSIONS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default commissionsReducer;
