import axios from 'axios';

export const getSaleStats = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/sale-stats`);
export const getPastSettlements = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/past-settlements`);
export const getDueCommissionsStats = (projectId, userId) =>
	axios.get(`/api/projects/${projectId}/dashboard/due-commissions-stats/${userId}`);
export const getOutstandingContracts = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/outstanding-contracts`);
export const getSaleStatuses = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/sale-statuses`);
export const getOverdueDeposits = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/overdue-deposits`);
export const getConditionalContracts = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/conditional-contracts`);
export const getSunsetDates = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/sunset-dates`);
export const getOverdueConditions = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/overdue-conditions`);
export const getSettlementDates = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/settlement-dates`);
export const getRemindersDashboard = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/reminders-dashboard`);
export const getNewEnquiries = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/new-enquiries`);
export const getRepeatEnquiry = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/repeat-enquiry`);
export const getFullDepositPaid = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/full-deposits`);
export const getContractsExchange = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/contracts-exchange`);
export const getCanceledSales = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/canceled-sales`);
export const getWalkInStats = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/walk-in`);
export const fetchDashboardSettingsApi = (projectId) =>
	axios.get(`/api/projects/${projectId}/dashboard/settings`);
export const saveDashboardApi = ({ projectId, settings }) =>
	axios.post(`/api/projects/${projectId}/dashboard/settings`, { settings });
export const addDashboardApi = (selectedProject, rest) =>
	axios.put(`/api/projects/${selectedProject}/dashboard/settings`, { rest });
export const getWeekSales = () => axios.get('/api/projects/dashboard/week-sales');
export const getWeekSignedContracts = () => axios.get('/api/projects/dashboard/signed-contracts');
export const getWeekEnquires = () => axios.get('/api/projects/dashboard/new-enquiries');
export const getWeekCancelations = () => axios.get('/api/projects/dashboard/week-cancelations');
export const getWeekOverdueDeposits = () =>
	axios.get('/api/projects/dashboard/week-overdue-deposits');
export const getWeekContactNotes = () => axios.get('/api/projects/dashboard/week-contact-notes');
export const getWeekToBeSignedContracts = () =>
	axios.get('/api/projects/dashboard/tobe-signed-contracts');
export const getProjectStatuses = () => axios.get('/api/projects/dashboard/project-statuses');
export const getListOverdueDeposits = () =>
	axios.get('/api/projects/dashboard/list-overdue-deposits');
export const fetchLotStatusDashboardApi = ({ ...rest }) =>
	axios.put('/api/projects/dashboard/dashboard-lot-stages', { ...rest });
export const getListToBeSignedContracts = () =>
	axios.get('/api/projects/dashboard/list-tobe-signed-contracts');
export const getOverdueContracts = () => axios.get('/api/projects/dashboard/overdue-contracts');
export const getUpcomingSettlements = () =>
	axios.get('/api/projects/dashboard/upcoming-settlements');
