export const CREATE_REMINDER_PENDING = 'CREATE_REMINDER_PENDING';
export const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';
export const CREATE_REMINDER_ERROR = 'CREATE_REMINDER_ERROR';

export const FETCH_REMINDER_PENDING = 'FETCH_REMINDER_PENDING';
export const FETCH_REMINDER_SUCCESS = 'FETCH_REMINDER_SUCCESS';
export const FETCH_REMINDER_ERROR = 'FETCH_REMINDER_ERROR';

export const FETCH_REMINDER_ITEM_PENDING = 'FETCH_REMINDER_ITEM_PENDING';
export const FETCH_REMINDER_ITEM_SUCCESS = 'FETCH_REMINDER_ITEM_SUCCESS';
export const FETCH_REMINDER_ITEM_ERROR = 'FETCH_REMINDER_ITEM_ERROR';

// fetch reminder by id
export const FETCH_REMINDER_ELEM_PENDING = 'FETCH_REMINDER_ELEM_PENDING';
export const FETCH_REMINDER_ELEM_SUCCESS = 'FETCH_REMINDER_ELEM_SUCCESS';
export const FETCH_REMINDER_ELEM_ERROR = 'FETCH_REMINDER_ELEM_ERROR';

export const DELETE_REMINDER_PENDING = 'DELETE_REMINDER_PENDING';
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export const DELETE_REMINDER_ERROR = 'DELETE_REMINDER_ERROR';

export const UPDATE_REMINDER_PENDING = 'UPDATE_REMINDER_PENDING';
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';
export const UPDATE_REMINDER_ERROR = 'UPDATE_REMINDER_ERROR';

export const createReminderPending = () => ({
	type: CREATE_REMINDER_PENDING
});

export const createReminderSuccess = () => ({
	type: CREATE_REMINDER_SUCCESS
});

export const createReminderError = (error) => ({
	type: CREATE_REMINDER_ERROR,
	error
});

export const fetchReminderPending = () => ({
	type: FETCH_REMINDER_PENDING
});

export const fetchReminderSuccess = (reminders) => ({
	type: FETCH_REMINDER_SUCCESS,
	reminders
});

export const fetchReminderError = (error) => ({
	type: FETCH_REMINDER_ERROR,
	error
});

export const fetchReminderItemPending = () => ({
	type: FETCH_REMINDER_ITEM_PENDING
});

export const fetchReminderItemSuccess = (remindersItem) => ({
	type: FETCH_REMINDER_ITEM_SUCCESS,
	remindersItem
});

export const fetchReminderItemError = (error) => ({
	type: FETCH_REMINDER_ITEM_ERROR,
	error
});

// fetch reminder by id
export const fetchReminderElemPending = () => ({
	type: FETCH_REMINDER_ELEM_PENDING
});

export const fetchReminderElemSuccess = (reminder) => ({
	type: FETCH_REMINDER_ELEM_SUCCESS,
	reminder
});

export const fetchReminderElemError = (error) => ({
	type: FETCH_REMINDER_ELEM_ERROR,
	error
});

export const deleteReminderPending = () => ({
	type: DELETE_REMINDER_PENDING
});

export const deleteReminderSuccess = () => ({
	type: DELETE_REMINDER_SUCCESS
});

export const deleteReminderError = (error) => ({
	type: DELETE_REMINDER_ERROR,
	error
});

export const updateReminderPending = () => ({
	type: UPDATE_REMINDER_PENDING
});

export const updateReminderSuccess = () => ({
	type: UPDATE_REMINDER_SUCCESS
});

export const updateReminderError = (error) => ({
	type: UPDATE_REMINDER_ERROR,
	error
});
