/* eslint-disable max-len */
export const getCommissions = (state) => state.commissions.commissions;

export const getCommissionsPending = (state) => state.commissions.pending;

export const getCommissionsFilters = (state) => state.commissions.commissionsFilters;

export const getDataCommissions = (state) => state.commissions.commissionsData;

export const getDataCommissionsPending = (state) => state.commissions.commissionsDataPending;

export const getCommissionsItem = (state) => state.commissions.commissionsItem;

export const getCommissionsDetails = (state) => state.commissions.commissionsDetails;

export const getCommissionsPagination = (state) =>
	state.commissions.commissionsPagination && state.commissions.commissionsPagination;

export const getUsersCommissions = (state) => state.commissions.usersCommissions;

export const getUsersCommissionsPending = (state) => state.commissions.pending;
