export const STAGES_FETCH_PENDING = 'STAGES_FETCH_PENDING';
export const STAGES_FETCH_SUCCESS = 'STAGES_FETCH_SUCCESS';
export const STAGES_FETCH_ERROR = 'STAGES_FETCH_ERROR';
export const STAGES_DETAILS_FETCH_PENDING = 'STAGE_DETAILS_FETCH_PENDING';
export const STAGES_DETAILS_FETCH_SUCCESS = 'STAGE_DETAILS_FETCH_SUCCESS';
export const STAGES_DETAILS_FETCH_ERROR = 'STAGE_DETAILS_FETCH_ERROR';
export const STAGES_RESET = 'STAGES_RESET';

export const stagesFetchPending = () => ({
	type: STAGES_FETCH_PENDING
});

export const stagesFetchSuccess = (stages) => ({
	type: STAGES_FETCH_SUCCESS,
	stages
});

export const stagesFetchError = (error) => ({
	type: STAGES_FETCH_ERROR,
	error
});

export const stagesDetailsFetchPending = () => ({
	type: STAGES_DETAILS_FETCH_PENDING
});

export const stagesDetailsFetchSuccess = (details) => ({
	type: STAGES_DETAILS_FETCH_SUCCESS,
	details
});

export const stagesDetailsFetchError = (error) => ({
	type: STAGES_DETAILS_FETCH_ERROR,
	error
});

export const stagesReset = () => ({
	type: STAGES_RESET
});
