import { multiUpdate } from 'src/api/commonApi';
import * as actionTypes from '../actions';

export const multiUpdateRequest = (data) => async (dispatch) => {
	await actionTypes.setLoader(true);
	try {
		await multiUpdate(data);
		await dispatch(
			actionTypes.showToast({
				body: `Updating ${data.type} success`
			})
		);
	} catch (e) {
		await actionTypes.setLoader(false);
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: `Updating ${data.type} error`
			})
		);
		throw e;
	}
	await actionTypes.setLoader(false);
};

export const ds = () => false;
