export const PROJECT_PRESENTATIONS_FETCH_PENDING = 'PROJECT_PRESENTATIONS_FETCH_PENDING';
export const PROJECT_PRESENTATIONS_FETCH_SUCCESS = 'PROJECT_PRESENTATIONS_FETCH_SUCCESS';
export const PROJECT_PRESENTATIONS_FETCH_ERROR = 'PROJECT_PRESENTATIONS_FETCH_ERROR';

export const PRESENTATION_FETCH_PENDING = 'PRESENTATION_FETCH_PENDING';
export const PRESENTATION_FETCH_SUCCESS = 'PRESENTATION_FETCH_SUCCESS';
export const PRESENTATION_FETCH_ERROR = 'PRESENTATION_FETCH_ERROR';

export const projectPresentationsFetchPending = () => ({
	type: PROJECT_PRESENTATIONS_FETCH_PENDING
});

export const projectPresentationsFetchSuccess = (presentations) => ({
	type: PROJECT_PRESENTATIONS_FETCH_SUCCESS,
	presentations
});

export const projectPresentationsFetchError = (error) => ({
	type: PROJECT_PRESENTATIONS_FETCH_ERROR,
	error
});

export const presentationFetchPending = () => ({
	type: PRESENTATION_FETCH_PENDING
});

export const presentationFetchSuccess = (presentation) => ({
	type: PRESENTATION_FETCH_SUCCESS,
	presentation
});

export const presentationFetchError = (error) => ({
	type: PRESENTATION_FETCH_ERROR,
	error
});
