import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, useTheme, useMediaQuery } from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		paddingTop: '10vh',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center'
	},
	imageContainer: {
		marginTop: theme.spacing(6),
		display: 'flex',
		justifyContent: 'center'
	},
	image: {
		maxWidth: '100%',
		width: 560,
		maxHeight: 300,
		height: 'auto'
	},
	buttonContainer: {
		marginTop: theme.spacing(6),
		display: 'flex',
		justifyContent: 'center'
	}
}));

function Error404() {
	const classes = useStyles();
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Page
			className={classes.root}
			title="Error 404"
		>
			<Typography
				align="center"
				variant={mobileDevice ? 'h4' : 'h1'}
			>
				You do not have access to any projects
			</Typography>
			<Typography
				align="center"
				variant="subtitle2"
			>
				Please contact with your manager
			</Typography>
			<div className={classes.imageContainer}>
				<img
					alt="Under development"
					className={classes.image}
					src="/images/undraw_page_not_found_su7k.svg"
				/>
			</div>
		</Page>
	);
}

export default Error404;
