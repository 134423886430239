import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	reminders: null,
	remindersItem: null,
	reminder: null,
	error: null
};

const reminderReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CREATE_REMINDER_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.CREATE_REMINDER_SUCCESS: {
			return {
				...state,
				pending: false
			};
		}
		case actionTypes.CREATE_REMINDER_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_REMINDER_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.FETCH_REMINDER_SUCCESS: {
			return {
				...state,
				pending: false,
				reminders: action.reminders
			};
		}
		case actionTypes.FETCH_REMINDER_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_REMINDER_ITEM_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.FETCH_REMINDER_ITEM_SUCCESS: {
			return {
				...state,
				pending: false,
				remindersItem: action.remindersItem
			};
		}
		case actionTypes.FETCH_REMINDER_ITEM_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		// fetch reminder by id
		case actionTypes.FETCH_REMINDER_ELEM_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.FETCH_REMINDER_ELEM_SUCCESS: {
			return {
				...state,
				pending: false,
				reminder: action.reminder
			};
		}
		case actionTypes.FETCH_REMINDER_ELEM_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.DELETE_REMINDER_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.DELETE_REMINDER_SUCCESS: {
			return {
				...state,
				pending: false
			};
		}
		case actionTypes.DELETE_REMINDER_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.UPDATE_REMINDER_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.UPDATE_REMINDER_SUCCESS: {
			return {
				...state,
				pending: false
			};
		}
		case actionTypes.UPDATE_REMINDER_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default reminderReducer;
