import React from 'react';
import '../assets/scss/siteErrorFallback.scss';
import { appVersion, appVersionHash } from 'src/Constants';

const classes = {
	container: {
		background: 'black',
		color: 'white',
		textAlign: 'center',
		width: '100%',
		height: '100%',
		zIndex: 999
	},
	heading: {
		fontSize: '30px'
	},
	button: {
		textDecoration: 'underline',
		cursor: 'pointer',
		'&:hover': {
			color: 'gray'
		}
	},
	logoImage: {
		width: '256px'
	},
	errorImage: {
		width: '256px'
	}
};

export default function SiteErrorFallback({ error, resetErrorBoundary, errorCode, saved }) {
	const savedMessage = saved ? (
		<i>The error has been automatically logged.</i>
	) : (
		<i>The error was not logged, please send it to your administrator.</i>
	);

	const onRefreshPage = () => {
		window?.location.reload();
	};

	return (
		<div style={classes.container}>
			<img
				alt="Logo"
				style={classes.logoImage}
				src="/images/logos/intrahub logo-05.png"
			/>
			<br />
			<br />
			<h1 style={classes.heading}>Oops something went wrong :(</h1>
			<br />
			<br />
			<img
				alt="Logo"
				style={classes.errorImage}
				src="/images/error-image.png"
			/>
			<br />
			<br />
			<br />
			Error Code:
			<br />
			{errorCode}
			<br />
			<br />
			Error Details:
			<pre>{error.message}</pre>
			<br />
			<br />
			<pre>
				Please contact your administrator and quote the Error Code <b>{errorCode}</b>
			</pre>
			{savedMessage}
			<br />
			<br />
			<button
				onClick={resetErrorBoundary}
				style={classes.button}
			>
				Restart Application
			</button>{' '}
			<button
				onClick={onRefreshPage}
				style={classes.button}
			>
				Refresh Page
			</button>
			<br />
			<br />
			Intrahub {appVersion} ({appVersionHash})
		</div>
	);
}
