import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	items: {},
	subItems: {},
	pendingDocumentTypes: false,
	errorDocumentTypes: null,
	documentTypes: {},
	documentTypesList: [],
	documentsAll: []
};

const documentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DOCUMENTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.DOCUMENTS_FETCH_SUCCESS: {
			const { entityType, entityId, documents = [] } = action.payload;
			return {
				...state,
				pending: false,
				items: {
					...state.documents,
					[`${entityType}/${entityId}`]: documents.map((d) => ({
						...d,
						typeName: d.typeObject && d.typeObject.displayName
					}))
				},
				error: null
			};
		}

		case actionTypes.SUB_DOCUMENTS_FETCH_SUCCESS: {
			const { entityType, entityId, documents = [] } = action.payload;
			return {
				...state,
				pending: false,
				subItems: {
					...state.documents,
					[`${entityType}/${entityId}`]: documents.map((d) => ({
						...d,
						typeName: d.typeObject && d.typeObject.displayName
					}))
				},
				error: null
			};
		}

		case actionTypes.DOCUMENTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.DOCUMENT_TYPES_FETCH_PENDING: {
			return {
				...state,
				pendingDocumentTypes: true
			};
		}

		case actionTypes.DOCUMENT_TYPES_FETCH_SUCCESS: {
			const { entityType, documentTypes } = action.payload;
			if (entityType) {
				return {
					...state,
					pendingDocumentTypes: false,
					documentTypes: {
						...state.documentTypes,
						[entityType]: documentTypes
					},
					errorDocumentTypes: null
				};
			}
			return {
				...state,
				pendingDocumentTypes: false,
				documentTypesList: documentTypes,
				errorDocumentTypes: null
			};
		}

		case actionTypes.DOCUMENT_TYPES_FETCH_ERROR: {
			return {
				...state,
				pendingDocumentTypes: false,
				errorDocumentTypes: action.error
			};
		}

		case actionTypes.DOCUMENTS_ALL_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.DOCUMENTS_ALL_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				documentsAll: action.documents
			};
		}

		case actionTypes.DOCUMENTS_ALL_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default documentReducer;
