import axios from 'axios';

export const fetchUsersSharedFolder = (projectId) => axios.get(`/api/outlook-emails/${projectId}`);

export const checkGeneralOutlookUserAPi = (projectId, data) =>
	axios.put(`/api/general-outlook-emails/${projectId}`, data);

export const addUserToSharedFolder = (projectId, data) =>
	axios.post(`/api/outlook-emails/${projectId}`, { data });

export const deleteUserToSharedFolder = (projectId, data) =>
	axios.delete(`/api/outlook-emails/${projectId}`, { data });

export const updateAmountFetchMessages = (projectId, fetchMessages) =>
	axios.put(`/api/outlook-emails/set-fetch-messages/${projectId}`, { fetchMessages });

export const updateDefaultMailBox = (projectId, data) =>
	axios.put(`/api/outlook-emails/${projectId}/default-mail-box`, { data });

export const createUserSignatureEmailApi = (projectId, data) =>
	axios.post(`/api/outlook-emails/${projectId}/email-signature/id`, data);

export const updateUserSignatureEmailApi = (projectId, data) =>
	axios.put(`/api/outlook-emails/${projectId}/email-signature`, data);

export const setPrimaryUserSignatureEmailApi = (projectId, data) =>
	axios.put(`/api/outlook-emails/${projectId}/email-signature-primary`, data);

export const deleteUserSignatureEmailApi = (projectId, signatureId) =>
	axios.delete(`/api/outlook-emails/email-signature/${projectId}/signature/${signatureId}`);
