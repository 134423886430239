import { fetchContactGroupsList, renameContactGroups } from 'src/api/contactGroupsApi';
import * as actionTypes from '../actions';

export const renameContactGroup = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await renameContactGroups(projectId, data);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Rename Contact Group error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactGroupsListRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.contactGroupsListFetchPending());
	try {
		const contactGroupsList = await fetchContactGroupsList(projectId);
		await dispatch(actionTypes.contactGroupsListFetchSuccess(contactGroupsList));
	} catch (error) {
		await dispatch(actionTypes.contactGroupsListFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Contact Groups List error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};
