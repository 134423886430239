import * as actionTypes from 'src/actions';
import {
	fetchBuilderPortalContacts,
	createBuilderUser,
	fetchBuilderPortalUsers,
	fetchDocumentTracking,
	updateBuilderUser,
	changeBuilderUserPasswordApi,
	fetchBuilderPortalUser,
	fetchBuilderUserApp,
	removeBuilderUser,
	registrationBuilderUserApi,
	resendVerificationEmailApi
} from 'src/api/builderPortal';

export const fetchBuilderContactsRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.builderContactsFetchPending());
	try {
		const builderContacts = await fetchBuilderPortalContacts(data);
		await dispatch(actionTypes.builderContactsFetchSuccess(builderContacts));
	} catch (error) {
		await dispatch(actionTypes.builderContactsFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch builder contacts error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const createBuilderUserRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await createBuilderUser(data);
		await dispatch(
			actionTypes.showToast({
				body: 'Create builder user success'
			})
		);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Create builder user error'
			})
		);
		await dispatch(actionTypes.setLoader(false));

		throw error;
	}
};

export const fetchBuilderUsersRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.builderUsersFetchPending());
	try {
		const builderUsers = await fetchBuilderPortalUsers(data);
		await dispatch(actionTypes.builderUsersFetchSuccess(builderUsers.data));
	} catch (error) {
		await dispatch(actionTypes.builderUsersFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Builder Contacts error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const resendVerificationEmail = (userId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await resendVerificationEmailApi(userId, data);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Builder Contacts error'
			})
		);
		throw error;
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const fetchBuilderUserRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.builderUserFetchPending());
	try {
		const builderUser = await fetchBuilderPortalUser(data);
		await dispatch(actionTypes.builderUserFetchSuccess(builderUser.data));
	} catch (error) {
		await dispatch(actionTypes.builderUserFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Builder User error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchDocumentTrackingRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.documentTrackingFetchPending());
	try {
		const documentTracking = await fetchDocumentTracking(data);
		await dispatch(actionTypes.documentTrackingFetchSuccess(documentTracking));
	} catch (error) {
		await dispatch(actionTypes.documentTrackingFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Builder Downloads error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateBuilderUserRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await updateBuilderUser(data);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const deleteBuilderUser = (projectId, userId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await removeBuilderUser(projectId, userId);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.message || 'Remove Builder User Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const changeBuilderUserPasswordRequest =
	(data, userId, onClose, open) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await changeBuilderUserPasswordApi({ ...data }, userId);
			onClose(!open);
			await dispatch(actionTypes.showToast({ body: 'Password changed successfully' }));
		} catch (error) {
			await dispatch(
				actionTypes.showToast({ type: 'error', body: error.response.data.message })
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchBuilderUserAppRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.builderUserAppFetchPending());
	try {
		const builderUserApp = await fetchBuilderUserApp(data);
		await dispatch(actionTypes.builderUserAppFetchSuccess(builderUserApp.data));
	} catch (error) {
		await dispatch(actionTypes.builderUserAppFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Builder DAP Applications error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const registrationBuilderUserRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await registrationBuilderUserApi(data);
		await dispatch(
			actionTypes.showToast({
				body: 'User registered successfully, check Email'
			})
		);
	} catch (e) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: e.message || 'Error while register new user'
			})
		);
		await dispatch(actionTypes.setLoader(false));
		throw e;
	}
	await dispatch(actionTypes.setLoader(false));
};
