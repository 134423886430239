export const getBuilderContacts = (state) => state.builderPortal.builderContacts;

export const getBuilderUsers = (state) => state.builderPortal.builderUsers;

export const getDocumentTracking = (state) =>
	state.builderPortal.documentTracking && state.builderPortal.documentTracking.data;

export const getBuilderUserDetails = (state) => state.builderPortal.builderUser;

export const getBuilderUserApp = (state) => state.builderPortal.builderUserApp;
