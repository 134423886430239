/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-template */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Config } from '../utils/config';

function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

function getPolygon(bounds) {
	let ne = bounds.getNorthEast();
	ne = [ne.lng(), ne.lat()];
	let sw = bounds.getSouthWest();
	sw = [sw.lng(), sw.lat()];
	const coordinates = [[ne, [sw[0], ne[1]], sw, [ne[0], sw[1]]]];
	const polygon = {
		type: 'Polygon',
		coordinates
	};
	return polygon;
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2)
	}
}));

function GoogleMaps({ onSelect }) {
	const classes = useStyles();
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const loaded = React.useRef(false);

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_AUTOCOMPLETE_API_KEY}&libraries=places&language=en`,
				document.querySelector('head'),
				'google-maps'
			);
		}

		loaded.current = true;
	}

	const fetch = React.useMemo(
		() =>
			// eslint-disable-next-line implicit-arrow-linebreak
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(
					{
						...request,
						componentRestrictions: {
							country: 'au'
						}
					},
					callback
				);
			}, 200),
		[]
	);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	return (
		<Autocomplete
			id="google-map-demo"
			// style={{ width: 300 }}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				const geocoder = new window.google.maps.Geocoder();
				geocoder.geocode({ placeId: newValue?.place_id }, (results, status) => {
					if (status === 'OK') {
						if (!results[0]) {
							onSelect({
								address: '',
								coordinates: '',
								name: ''
							});
							return;
						}
						const polygon = getPolygon(results[0].geometry.viewport);
						const ac = results[0].address_components;

						const aType = (type) =>
							ac.find((c) => c.types.indexOf(type) >= 0)?.short_name || '';

						let address = '';
						if (aType('street_number')) address += aType('street_number') + ' ';
						if (aType('route')) address += aType('route') + ', ';
						if (aType('locality')) address += aType('locality') + ' ';
						address += aType('administrative_area_level_1');

						onSelect({
							address,
							coordinates: {
								type: 'Point',
								coordinates: [
									results[0].geometry.location.lng(),
									results[0].geometry.location.lat()
								]
							},
							polygon,
							name: newValue?.structured_formatting?.main_text,
							ac
						});
					}
				});

				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Search Business Name and Address"
					variant="outlined"
					fullWidth
				/>
			)}
			renderOption={(option) => {
				const matches = option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				);

				return (
					<Grid
						container
						alignItems="center"
					>
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid
							item
							xs
						>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{ fontWeight: part.highlight ? 700 : 400 }}
								>
									{part.text}
								</span>
							))}

							<Typography
								variant="body2"
								color="textSecondary"
							>
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
}

GoogleMaps.propTypes = {
	onSelect: PropTypes.func
};

export default GoogleMaps;
