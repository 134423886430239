/* eslint-disable max-len */
export const getReportSystem = (state) =>
	state.reportSystem.reportSystem && state.reportSystem.reportSystem.data;

export const getReportSystemPending = (state) => state.reportSystem.getReportSystemPending;

export const getWeeklySales = (state) =>
	state.reportSystem.weeklySales && state.reportSystem.weeklySales.data;

export const getSalesSummary = (state) =>
	state.reportSystem.salesSummary && state.reportSystem.salesSummary.data;

export const getFilteredSalesSummary = (state) => state.reportSystem.setSalesSummary;

export const getPackagesHometown = (state) =>
	state.reportSystem.packageTownhome && state.reportSystem.packageTownhome.data;

export const getCancellations = (state) =>
	state.reportSystem.cancellations && state.reportSystem.cancellations.data;

export const getFilteredSales = (state) =>
	state.reportSystem.filteredSales && state.reportSystem.filteredSales.data;

export const getPastSettlement = (state) =>
	state.reportSystem.pastSettlement && state.reportSystem.pastSettlement.data;

export const getFutureSettlement = (state) =>
	state.reportSystem.futureSettlement && state.reportSystem.futureSettlement.data;

export const getStageAnalysis = (state) =>
	state.reportSystem.stageAnalysis && state.reportSystem.stageAnalysis.data;

export const getPurchaserProfilesReport = (state) => state.reportSystem.purchaserProfiles;

export const getContactSourcesReport = (state) =>
	state.reportSystem.contactSources && state.reportSystem.contactSources.data;

export const getPurchaserMappingReport = (state) =>
	state.reportSystem.purchaserMapping && state.reportSystem.purchaserMapping.data;

export const getBuilderDocumentsReport = (state) =>
	state.reportSystem.builderDocuments && state.reportSystem.builderDocuments.data;

export const getWeekReportAll = (state) =>
	state.reportSystem.weekReportAll && state.reportSystem.weekReportAll.data;

export const getSalesSummaryAll = (state) =>
	state.reportSystem.salesSummaryAll && state.reportSystem.salesSummaryAll.data;

export const getSettlementAll = (state) =>
	state.reportSystem.settlementAll && state.reportSystem.settlementAll.data;

export const getWeekReportAllContractsPending = (state) =>
	state.reportSystem.weekReportAllContractsPending;

export const getWeekReportAllWeekSummeryPending = (state) =>
	state.reportSystem.weekReportAllSummaryPending;

export const getAllOverdueDeposits = (state) => state?.reportSystem?.allOutstandingDeposits?.data;

export const getSunsetDatesReport = (state) =>
	state?.reportSystem?.upcomingOverdueSunsetDates?.data;

export const getSunsetDatesReportPending = (state) =>
	state?.reportSystem?.upcomingOverdueSunsetDatesPending;

export const getCommissionsReport = (state) =>
	state.reportSystem.commissionsReport && state.reportSystem.commissionsReport.data;
