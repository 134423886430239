import moment from 'moment';
import * as actionTypes from 'src/actions';
import { defaultCurrentWeek } from 'src/utils/AzureProvider';
import { PageIterator } from '@microsoft/microsoft-graph-client';
import { showToast } from 'src/actions';
import { Config } from 'src/utils/config';
import {
	fetchUsersSharedFolder,
	addUserToSharedFolder,
	deleteUserToSharedFolder,
	updateAmountFetchMessages,
	updateDefaultMailBox,
	checkGeneralOutlookUserAPi,
	createUserSignatureEmailApi,
	updateUserSignatureEmailApi,
	deleteUserSignatureEmailApi,
	setPrimaryUserSignatureEmailApi
} from './serverOutlookApi';

export const initOutlookUser = { displayName: 'My mailbox', id: 'me', mail: null };

const graph = require('@microsoft/microsoft-graph-client');

const getAuthenticatedClient = (accessToken) => {
	// Initialize Graph client
	const client = graph.Client.init({
		// Use the provided access token to authenticate
		// requests
		authProvider: (done) => {
			done(null, accessToken);
		}
	});

	return client;
};

export const getUserDetails = async (accessToken) => {
	const client = getAuthenticatedClient(accessToken);
	const user = await client
		.api('/me')
		.select('displayName,mail,mailboxSettings,userPrincipalName')
		.get();

	return user;
};

export const getReminderObject = (reminder, user, textLink) => ({
	subject: reminder.subject || '',
	attendees:
		reminder.link.type === 'contact' && reminder.sendInvitationToContact
			? reminder?.link?.ids?.map((i) => ({
					emailAddress: {
						address: i.email
					}
			  }))
			: undefined,
	start: {
		dateTime: moment(reminder.dueDate).format('YYYY-MM-DD HH:mm'),
		timeZone: user?.mailboxSettings.timeZone
	},
	end: {
		dateTime: moment(reminder.dueDate)
			.add(reminder?.eventDuration || 30, 'm')
			.format('YYYY-MM-DD HH:mm'),
		timeZone: user?.mailboxSettings.timeZone
	},
	body: {
		contentType: 'html',
		// eslint-disable-next-line no-underscore-dangle
		content: `
    ${reminder.note && `<p>${reminder.note}</p>`}
      ${
			reminder.sendInvitationToContact
				? ''
				: `<p><a href="${Config.APP_URL}/admin/reminders/${reminder._id}">${textLink}</a></p>`
		}
      <hr />
      ${
			!reminder.sendInvitationToContact && reminder.link.type === 'contact'
				? `<p>Contacts: ${reminder?.link?.ids?.map(
						(i) => ` <a href="mailto:${i.email}">${i.fullName}</a>`
				  )} </p>`
				: ''
		}`
	}
});

export const getUserWeekCalendar = (accessToken, timeZone, date) => async (dispatch) => {
	const client = getAuthenticatedClient(accessToken);

	const startDateTime = date
		? moment(date[0]).format('YYYY-MM-DD HH:mm')
		: defaultCurrentWeek.startDate;
	const endDateTime = date
		? moment(date[1]).format('YYYY-MM-DD HH:mm')
		: defaultCurrentWeek.endDate;

	try {
		dispatch(actionTypes.setLoader(true));
		const response = await client
			.api('/me/calendarview')
			.header('Prefer', `outlook.timezone="${timeZone}"`)
			.query({ startDateTime, endDateTime })
			.select('subject,organizer,start,end,attendees,body,changeKey,lastModifiedDateTime')
			.orderby('start/dateTime')
			.top(25)
			.get();

		if (response['@odata.nextLink']) {
			const events = [];
			const options = {
				headers: { Prefer: `outlook.timezone="${timeZone}"` }
			};
			const pageIterator = new PageIterator(
				client,
				response,
				(event) => {
					events.push(event);
					return true;
				},
				options
			);

			await pageIterator.iterate();
			await dispatch(actionTypes.getOutlookEventsSuccess(events));
		} else {
			await dispatch(actionTypes.getOutlookEventsSuccess(response.value));
		}
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.message || 'An error was encountered getting reminders from Outlook'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getUserMailFolders = (accessToken, userData) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const client = getAuthenticatedClient(accessToken);
		const link =
			userData && userData.mail ? `/users/${userData.mail}/mailFolders` : '/me/mailFolders';
		const response = await client.api(link).get();

		if (response['@odata.nextLink']) {
			const folders = [];
			const options = {
				headers: { Prefer: 'outlook.body-content-type="text"' }
			};
			const pageIterator = new PageIterator(
				client,
				response,
				(folder) => {
					folders.push(folder);
					return true;
				},
				options
			);

			await pageIterator.iterate();
			await dispatch(actionTypes.getOutlookMailFoldersSuccess(folders));
		} else {
			await dispatch(actionTypes.getOutlookMailFoldersSuccess(response.value));
		}
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.message || 'An error has occured loading Outlook email'
			})
		);
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const getSharedFolders = (accessToken, user) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		if (user && user.mail) {
			const client = getAuthenticatedClient(accessToken);
			await client.api(`/users/${user.mail}/messages`).get();
		} else {
			await dispatch(actionTypes.getSelectedSharedUser(initOutlookUser));
		}
		await dispatch(getUserMailFolders(accessToken, user));
		await dispatch(getUserMessages(accessToken, 'Inbox', user));
	} catch (e) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: 'You do not have access to this Mailbox' })
		);
		throw e;
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const findUserByName =
	(accessToken, { str }) =>
	async (dispatch) => {
		try {
			if (str) {
				const client = getAuthenticatedClient(accessToken);
				const link = str
					? `/users?$search="mail:${str}" OR "displayName:${str}"&$orderby=displayName&$count=true`
					: '/users';
				const users = await client
					.api(link)
					.header('ConsistencyLevel', 'eventual')
					.filter("UserType eq 'Member'")
					.top(20)
					.get();

				const filterUser = users.value.filter((i) => i.mail);
				filterUser.sort((a, b) => a.displayName.localeCompare(b.displayName));
				dispatch(actionTypes.getOutlookUsers(users.value));
			} else {
				dispatch(actionTypes.getOutlookUsers(null));
			}
		} catch (e) {
			await dispatch(
				actionTypes.showToast({ type: 'error', body: e?.message || 'An error has occured' })
			);
		}
	};

export const getUserMailSubFolders =
	(accessToken, folderId, elemPath, userData) => async (dispatch) => {
		try {
			const client = getAuthenticatedClient(accessToken);
			const link =
				userData && userData.mail
					? `/users/${userData.mail}/mailFolders/${folderId}/childFolders`
					: `/me/mailFolders/${folderId}/childFolders`;
			const childFolders = await client.api(link).get();

			if (childFolders['@odata.nextLink']) {
				const folders = [];
				const options = {
					headers: { Prefer: 'outlook.body-content-type="text"' }
				};
				const pageIterator = new PageIterator(
					client,
					childFolders,
					(folder) => {
						folders.push(folder);
						return true;
					},
					options
				);

				await pageIterator.iterate();
				await dispatch(actionTypes.getOutlookSubfolders(folderId, folders, elemPath));
			} else {
				await dispatch(
					actionTypes.getOutlookSubfolders(folderId, childFolders.value, elemPath)
				);
			}
		} catch (error) {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: error?.message || 'An error has occured'
				})
			);
		}
	};

export const getUserMessages =
	(accessToken, mailFolder, userData) => async (dispatch, getState) => {
		try {
			await dispatch(actionTypes.setLoader(true));
			const {
				outlook: { amountFetchMessages }
			} = getState();
			const link =
				userData && userData.mail
					? `/users/${userData.mail}/mailFolders/${mailFolder}/messages`
					: `/me/mailFolders/${mailFolder}/messages`;

			const client = getAuthenticatedClient(accessToken);
			const response = await client
				.api(link)
				.header('Prefer', 'outlook.body-content-type="html"')
				.select(
					'sender,subject,body,toRecipients,sentDateTime,ccRecipients, replyTo, importance'
				)
				.top(amountFetchMessages)
				.get();

			await dispatch(actionTypes.getOutlookMessagesSuccess(response.value));
		} catch (error) {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: error?.message || 'An error has occured getting messages from Outlook'
				})
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const getUserEventById = async (accessToken, eventId) => {
	try {
		if (!eventId) return null;
		const client = getAuthenticatedClient(accessToken);

		const response = await client
			.api(`/me/calendar/events/${eventId}`)
			.select('subject,organizer,start,end,attendees,body,changeKey,lastModifiedDateTime')
			.get();

		return response;
	} catch (error) {
		return null;
	}
};

export const createEvent = (accessToken, newEvent) => async (dispatch) => {
	const client = getAuthenticatedClient(accessToken);
	try {
		const res = await client.api('/me/events').post(newEvent);
		return res;
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Error occured while creating reminder in Outlook Calendar'
			})
		);
		throw error;
	}
};

export const updateEvent = (accessToken, updatedEvent, eventId) => async (dispatch) => {
	const client = getAuthenticatedClient(accessToken);
	try {
		const res = await client.api(`/me/events/${eventId}`).patch(updatedEvent);
		return res;
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Error occured while updating reminder to Outlook Calendar'
			})
		);
		throw error;
	}
};

export const deleteEvent = (accessToken, eventId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	const client = getAuthenticatedClient(accessToken);
	try {
		await client.api(`/me/events/${eventId}`).delete();
	} catch (error) {
		if (error?.code !== 'ErrorInvalidIdMalformed') {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: 'Error occured while deleting reminder from Outlook Calendar'
				})
			);
		}
	}
	await dispatch(actionTypes.setLoader(false));
};

export const checkOnAccessSharedFolder = (accessToken, user) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const { mail } = user;
		const client = getAuthenticatedClient(accessToken);
		const response = await client.api(`/users/${mail}/messages`).top(10).get();
		return response;
	} catch (e) {
		dispatch(
			actionTypes.showToast({ type: 'error', body: 'You do not have access to this Mailbox' })
		);
		return null;
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const fetchUserOutlookSharedMailFolder = (projectId) => async (dispatch) => {
	try {
		dispatch(actionTypes.sharedMailBoxesAcition([]));
		await dispatch(actionTypes.setLoader(true));
		const response = await fetchUsersSharedFolder(projectId);
		if (response.data) {
			dispatch(actionTypes.sharedMailBoxesAcition(response.data.emails));
			dispatch(actionTypes.setAmountFetchMessages(response.data.amountFetchMessages || 250));
		}
		if (response.data.defaultMailBox) {
			dispatch(actionTypes.getSelectedSharedUser(response.data.defaultMailBox));
		} else {
			dispatch(actionTypes.getSelectedSharedUser([initOutlookUser]));
		}
	} catch (e) {
		dispatch(
			actionTypes.showToast({ type: 'error', body: e.message || 'An error has occured' })
		);
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const addUserToSharedMailFolder =
	({ currentUser, sharedUser, currentProject }) =>
	async (dispatch) => {
		try {
			await dispatch(actionTypes.setLoader(true));
			const { mail, displayName, id } = sharedUser;

			const data = {
				sharedUser: {
					mail,
					displayName,
					id
				},
				currentUser: {
					// eslint-disable-next-line no-underscore-dangle
					id: currentUser._id
				}
			};

			const response = await addUserToSharedFolder(currentProject, data);
			await dispatch(
				actionTypes.showToast({
					type: response.data.isExistEmail ? 'error' : 'success',
					body: response.data.message
				})
			);
		} catch (e) {
			dispatch(
				actionTypes.showToast({ type: 'error', body: e.message || 'An error has occured' })
			);
		} finally {
			await dispatch(actionTypes.setLoader(false));
		}
	};

export const removeUserSharedMailBox =
	({ currentUser, currentProject, emails }) =>
	async (dispatch) => {
		try {
			await dispatch(actionTypes.setLoader(true));
			const res = await deleteUserToSharedFolder(currentProject, { currentUser, emails });
			await dispatch(actionTypes.showToast({ type: 'success', body: res.data.message }));
		} catch (e) {
			dispatch(
				actionTypes.showToast({ type: 'error', body: e.message || 'An error has occured' })
			);
		} finally {
			await dispatch(actionTypes.setLoader(false));
		}
	};

export const setAmountFetchMessages =
	({ projectId, fetchMessages }) =>
	async (dispatch) => {
		try {
			await dispatch(actionTypes.setLoader(true));
			dispatch(actionTypes.setAmountFetchMessages(fetchMessages));
			const response = await updateAmountFetchMessages(projectId, fetchMessages);
			return response;
		} catch (e) {
			dispatch(
				actionTypes.showToast({ type: 'error', body: e.message || 'An error has occured' })
			);
			return null;
		} finally {
			await dispatch(actionTypes.setLoader(false));
		}
	};

export const setDefaultMailBox = async (projectId, { currentUser, mailbox }) => {
	await updateDefaultMailBox(projectId, { currentUser, mailbox });
};

export const sendMessage = (accessToken, data, userData) => async (dispatch) => {
	const messageTemplate = {
		subject: data.subject,
		importance: data.importance,
		body: {
			contentType: 'HTML',
			content: data.messageText
		},
		toRecipients: data.recipients.map((recipient) => ({
			emailAddress: { address: recipient.mail }
		})),
		ccRecipients:
			data?.ccRecipients?.map((recipient) => ({
				emailAddress: { address: recipient.mail }
			})) || []
	};

	try {
		dispatch(actionTypes.setLoader(true));
		const link = userData && userData.mail ? `/users/${userData.id}/messages` : `/me/messages`;
		const client = getAuthenticatedClient(accessToken);
		const createdMesssage = await client.api(link).post(messageTemplate);

		const sendLink =
			userData && userData.mail
				? `/users/${userData.id}/messages/${createdMesssage.id}/send`
				: `/me/messages/${createdMesssage.id}/send`;
		await client.api(sendLink).post();

		dispatch(showToast({ body: 'Message sent' }));
	} catch (e) {
		throw e;
	} finally {
		dispatch(actionTypes.setLoader(false));
	}
};

export const sendReplyMessage = (accessToken, data, userData) => async (dispatch) => {
	const reply = {
		message: {
			toRecipients: data.recipients.map((recipient) => ({
				emailAddress: { address: recipient.mail }
			})),
			ccRecipients:
				data?.ccRecipients?.map((recipient) => ({
					emailAddress: { address: recipient.mail }
				})) || [],
			body: {
				contentType: 'HTML',
				content: data.messageReply
			}
		},
		importance: data.importance
	};

	try {
		dispatch(actionTypes.setLoader(true));
		const client = getAuthenticatedClient(accessToken);
		const link =
			userData && userData.mail
				? `/users/${userData.id}/messages/${data.id}/reply`
				: `/me/messages/${data.id}/reply`;
		await client.api(link).post(reply);

		dispatch(showToast({ body: 'Reply sent' }));
	} catch (e) {
		throw e;
	} finally {
		dispatch(actionTypes.setLoader(false));
	}
};

export const checkGeneralOutlookUser = (projectId, data) => async (dispatch) => {
	try {
		const res = await checkGeneralOutlookUserAPi(projectId, data);
		dispatch(
			actionTypes.setLoggedOutlookUser({
				...data.user,
				emailSignatures: res?.data?.foundSignatures || []
			})
		);
	} catch (error) {
		dispatch(actionTypes.setLoader(false));
	}
};

export const createUserSignatureEmail = (projectId, data) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const res = await createUserSignatureEmailApi(projectId, data);
		await dispatch(actionTypes.showToast({ type: res?.data?.type, body: res?.data?.message }));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: error?.response?.data?.message })
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateUserSignatureEmail = (userId, data) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const res = await updateUserSignatureEmailApi(userId, data);
		await dispatch(actionTypes.showToast({ type: res?.data?.type, body: res?.data?.message }));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: error?.response?.data?.message })
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const setPrimaryUserSignatureEmail = (userId, data) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const res = await setPrimaryUserSignatureEmailApi(userId, data);
		await dispatch(actionTypes.showToast({ type: res?.data?.type, body: res?.data?.message }));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: error?.response?.data?.message })
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteUserSignatureEmail = (projectId, signatureId) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const res = await deleteUserSignatureEmailApi(projectId, signatureId);
		await dispatch(actionTypes.showToast({ type: res?.data?.type, body: res?.data?.message }));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: error?.response?.data?.message })
		);
	}
	await dispatch(actionTypes.setLoader(false));
};
export const getCalendarEvents = (accessToken, date) => async (dispatch) => {
	try {
		const client = getAuthenticatedClient(accessToken);

		const link = date
			? `/me/calendar/calendarView?startDateTime=${new Date(
					date.start
			  ).toISOString()}&endDateTime=${new Date(date.end).toISOString()}`
			: '/me/calendar/events';

		return await client
			.api(link)
			.header(
				'Prefer',
				`outlook.timezone="${Intl.DateTimeFormat().resolvedOptions().timeZone}"`
			)
			.orderby('start/dateTime desc')
			.top(250)
			.get();
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.message || 'Fetch events from outlook error'
			})
		);
	}
};
