/* eslint-disable import/prefer-default-export */
import { fetchMessagesNotificationsApi } from 'src/api/messageApi';
import * as actionTypes from 'src/actions';

export const fetchNotifications = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.fetchNotificationsPending());
	try {
		const res = await fetchMessagesNotificationsApi(projectId, data);
		await dispatch(actionTypes.fetchNotificationsSuccess({ messages: res.data }));
	} catch (error) {
		await dispatch(actionTypes.fetchNotificationsError('Get notifications error'));
	}
};
