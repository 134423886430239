import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	optionsLists: null,
	error: null
};

const optionsListsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.OPTIONS_LISTS_FETCH_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.OPTIONS_LISTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				optionsLists: action.optionsLists
			};
		}
		case actionTypes.OPTIONS_LISTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.OPTIONS_LISTS_DUPLICATE: {
			return {
				...state,
				pending: false
			};
		}
		default: {
			return state;
		}
	}
};

export default optionsListsReducer;
