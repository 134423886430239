import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import HouseIcon from '@material-ui/icons/HouseOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import MapIcon from '@material-ui/icons/Map';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Group from '@material-ui/icons/Group';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ReminderIcon from '@material-ui/icons/Event';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import BallotIcon from '@material-ui/icons/Ballot';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NotesIcon from '@material-ui/icons/Notes';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import ListIcon from '@material-ui/icons/List';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MessageIcon from '@material-ui/icons/Message';
import { CommissionIcon } from 'src/components/Icons';

export default [
	{
		subheader: 'Menu',
		items: [
			{
				title: 'Dashboard',
				href: '/admin/dashboard',
				icon: DashboardIcon,
				permission: 'all'
			},
			{
				title: 'Reminders',
				href: '/admin/reminders',
				icon: ReminderIcon,
				permission: 'view_reminders_list'
			},
			{
				title: 'Contacts',
				href: '/admin/contacts',
				icon: PeopleIcon,
				permission: 'view_contacts_list'
			},
			{
				title: 'Contact Notes',
				href: '/admin/contact-notes',
				icon: NotesIcon,
				permission: 'view_contacts_list'
			},
			{
				title: 'Messages',
				href: '/admin/messages',
				icon: MessageIcon,
				permission: 'all'
			},
			{
				title: 'Email',
				href: '/admin/email',
				icon: MailOutlineIcon,
				permission: 'all'
			},
			{
				title: 'Calendar',
				icon: DateRangeIcon,
				href: '/admin/calendar-view',
				permission: 'all'
			},
			{
				title: 'Lots',
				href: '/admin/lots',
				icon: ViewColumnOutlinedIcon,
				permission: 'view_lot_list'
			},
			{
				title: 'Sales',
				icon: AttachMoneyIcon,
				href: '/admin/sales',
				permission: 'view_sales_list'
			},
			{
				title: 'Commissions',
				icon: CommissionIcon,
				href: '/admin/commissions-list',
				permission: 'view_commissions_list'
			},
			{
				title: 'My Commissions',
				icon: CommissionIcon,
				href: '/admin/commissions-list',
				permission: 'view_personal_commissions'
			},
			{
				title: 'Packages',
				href: '/admin/packages',
				icon: HouseIcon,
				permission: 'view_packages_list'
			},
			{
				title: 'Stages',
				href: '/admin/stages',
				icon: BarChartIcon,
				permission: 'view_stage_list'
			},
			{
				title: 'Contact Groups',
				icon: Group,
				href: '/admin/contact-groups',
				permission: 'view_contact_groups'
			},
			{
				title: 'Builders',
				href: '/admin/builders',
				icon: BusinessOutlinedIcon,
				permission: 'view_builders_list'
			},
			{
				title: 'House Types',
				href: '/admin/house-types',
				icon: HomeWorkOutlinedIcon,
				permission: 'view_house_types_list'
			},
			{
				title: 'Options Lists',
				icon: ClearAllIcon,
				href: '/admin/options-lists',
				permission: 'view_options_list'
			},
			{
				title: 'Payments',
				icon: LocalAtmIcon,
				href: '/admin/payments',
				permission: 'view_payments_list'
			},
			{
				title: 'Walk Ins',
				icon: MeetingRoomIcon,
				href: '/admin/walk-in',
				permission: 'all'
			},
			{
				title: 'Contact Profiles',
				icon: RecentActorsIcon,
				href: '/admin/contact-profiles',
				permission: 'view_purchaser_profiles'
			},
			// {
			//   title: 'Documents (TBA)',
			//   icon: ListAltIcon,
			//    href: '/admin/documents/all',
			//    permission: 'all'
			// },
			{
				title: 'Reports',
				href: '/admin/reports',
				icon: AssessmentIcon,
				permission: 'all'
			},
			{
				title: 'Project Admin',
				href: '/admin/poi',
				icon: AppsOutlinedIcon,
				permission: ['view_poi_list', 'view_poi_categories_list', 'view_survey'],
				items: [
					{
						title: 'Surveys',
						href: '/admin/surveys',
						icon: QuestionAnswerIcon,
						permission: 'view_survey'
					},
					{
						title: 'POI Categories AAA',
						href: '/admin/poi/categories',
						permission: 'view_poi_categories_list',
						icon: ListIcon
					},
					{
						title: 'External POIs',
						href: '/admin/poi/external',
						permission: 'view_poi_list',
						icon: MapIcon
					},
					{
						title: 'On-Site POIs 123',
						href: '/admin/poi/onsite',
						permission: 'view_poi_list',
						icon: ControlCameraIcon
					},
					{
						title: 'Documents',
						href: '/admin/document-manager',
						icon: LibraryBooksIcon,
						permission: 'view_user_list'
					},
					{
						title: 'Presentations',
						href: '/admin/presentations',
						icon: LibraryBooksIcon,
						permission: 'edit_presentation'
					}
				]
			},
			{
				title: 'Admin',
				icon: SettingsIcon,
				permission: [
					'view_project_list',
					'view_poi_list',
					'view_poi_categories_list',
					'view_user_list',
					'create_builder_user',
					'view_builder_user'
				],
				items: [
					{
						title: 'Projects',
						href: '/admin/projects',
						icon: FolderIcon,
						permission: 'view_project_list'
					},
					{
						title: 'Users',
						href: '/admin/users',
						icon: PersonIcon,
						permission: 'view_user_list'
					},
					{
						title: 'Document Types',
						href: '/admin/documenttypes',
						icon: ListAltIcon,
						permission: 'view_user_list'
					},
					{
						title: 'Permissions',
						href: '/admin/userPermissions',
						icon: LockOpenIcon,
						permission: 'Admin',
						isAdmin: true
					},
					// {
					//   title: 'Builder Login',
					//   href: '/admin/builder-login',
					//   icon: HomeWorkIcon,
					//   permission: 'create_builder_user'
					// },
					{
						title: 'Builder Users',
						href: '/admin/builder-users',
						icon: ViewStreamIcon,
						permission: 'view_builder_user'
					},
					{
						title: 'All Builders',
						href: '/admin/builders-list',
						permission: 'Admin',
						icon: BusinessOutlinedIcon
					},
					{
						title: 'Builder Downloads',
						href: '/admin/document-tracking',
						icon: HomeWorkIcon,
						permission: 'all'
					},
					{
						title: 'DAP Users',
						href: '/admin/dap-users',
						icon: BallotIcon,
						permission: 'view_dap_user'
					}
				]
			}
		]
	}
];

export const getItemIcon = (item) => {
	const newItem = JSON.parse(JSON.stringify(item));

	switch (newItem.icon) {
		case 'BarChartIcon':
			newItem.icon = BarChartIcon;
			break;
		case 'DashboardIcon':
			newItem.icon = DashboardIcon;
			break;
		case 'FolderIcon':
			newItem.icon = FolderIcon;
			break;
		case 'HomeWorkOutlinedIcon':
			newItem.icon = HomeWorkOutlinedIcon;
			break;
		case 'HouseIcon':
			newItem.icon = HouseIcon;
			break;
		case 'ListAltIcon':
			newItem.icon = ListAltIcon;
			break;
		case 'LockOpenIcon':
			newItem.icon = LockOpenIcon;
			break;
		case 'PeopleIcon':
			newItem.icon = PeopleIcon;
			break;
		case 'PersonIcon':
			newItem.icon = PersonIcon;
			break;
		case 'SettingsIcon':
			newItem.icon = SettingsIcon;
			break;
		case 'ViewColumnOutlinedIcon':
			newItem.icon = ViewColumnOutlinedIcon;
			break;
		case 'MapIcon':
			newItem.icon = MapIcon;
			break;
		case 'AttachMoneyIcon':
			newItem.icon = AttachMoneyIcon;
			break;
		case 'CommissionIcon':
			newItem.icon = CommissionIcon;
			break;
		case 'Group':
			newItem.icon = Group;
			break;
		case 'LocalAtmIcon':
			newItem.icon = LocalAtmIcon;
			break;
		case 'ReminderIcon':
			newItem.icon = ReminderIcon;
			break;
		case 'ClearAllIcon':
			newItem.icon = ClearAllIcon;
			break;
		case 'MeetingRoomIcon':
			newItem.icon = MeetingRoomIcon;
			break;
		case 'QuestionAnswerIcon':
			newItem.icon = QuestionAnswerIcon;
			break;
		case 'RecentActorsIcon':
			newItem.icon = RecentActorsIcon;
			break;
		case 'AssessmentIcon':
			newItem.icon = AssessmentIcon;
			break;
		case 'HomeWorkIcon':
			newItem.icon = HomeWorkIcon;
			break;
		case 'ViewStreamIcon':
			newItem.icon = ViewStreamIcon;
			break;
		case 'BallotIcon':
			newItem.icon = BallotIcon;
			break;
		case 'MessageIcon':
			newItem.icon = MessageIcon;
			break;
		case 'MailOutlineIcon':
			newItem.icon = MailOutlineIcon;
			break;
		case 'NotesIcon':
			newItem.icon = NotesIcon;
			break;
		case 'DateRangeIcon':
			newItem.icon = DateRangeIcon;
			break;
		case 'BusinessOutlinedIcon':
			newItem.icon = BusinessOutlinedIcon;
			break;
		case 'AppsOutlinedIcon':
			newItem.icon = AppsOutlinedIcon;
			break;
		case 'ListIcon':
			newItem.icon = ListIcon;
			break;
		case 'ControlCameraIcon':
			newItem.icon = ControlCameraIcon;
			break;
		case 'LibraryBooksIcon':
			newItem.icon = LibraryBooksIcon;
			break;
		default:
			return newItem;
	}

	return newItem;
};
