import { useState, useEffect } from 'react';
import axios from 'axios';
import { releaseNoteAppId } from '../../Constants';

const MAX_LOGS = 10;
export default function useChangelog({ show }) {
	const [loading, setLoading] = useState(false);
	const [changelogItems, setChangelogItems] = useState(null);

	useEffect(() => {
		(async () => {
			if (show) {
				setLoading(true);
				const response = await axios.get(
					`/api/emailSaver/releaseNotes/${releaseNoteAppId}/${MAX_LOGS}`
				);
				if (response) {
					setChangelogItems(response.data.payload);
				}
				setLoading(false);
			}
		})();
	}, [show]);

	return {
		loading,
		changelogItems
	};
}
