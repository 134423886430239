import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	lots: null,
	lotsGeo: [],
	lotDetails: null,
	lotUsers: [],
	lotPagination: null,
	lotFilters: {
		filters: {},
		search: '',
		sort: {},
		settled: false
	},
	history: null,
	lotScroll: false
};

const lotReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.LOTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				lots: action.lots,
				error: null
			};
		}

		case actionTypes.LOTS_GEO_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				lotsGeo: action.lots,
				error: null
			};
		}

		case actionTypes.LOTS_PAGINATE_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				error: null,
				lots: action.lots,
				lotPagination: action.lots.data.pagination
			};
		}

		case actionTypes.SET_LOT_FILTERS: {
			return {
				...state,
				lotFilters: {
					...state.lotFilters,
					[action.filterType]: action.data
				}
			};
		}

		case actionTypes.CLEAR_LOT_FILTERS: {
			return {
				...state,
				lotFilters: {
					...state.lotFilters,
					filters: {},
					search: '',
					sort: {}
				}
			};
		}

		case actionTypes.LOTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LOTS_HISTORY_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.LOTS_HISTORY_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				history: action.history,
				error: null
			};
		}

		case actionTypes.LOTS_HISTORY_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LOT_USERS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.LOT_USERS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				lotUsers: action.users,
				error: null
			};
		}

		case actionTypes.LOT_USERS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LOTS_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.LOTS_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				lotDetails: action.details,
				error: null
			};
		}

		case actionTypes.LOTS_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LOTS_SET_SCROLL: {
			return {
				...state,
				lotScroll: action.scroll
			};
		}

		case actionTypes.LOTS_DETAILS_RESET: {
			return {
				...state,
				lotDetails: null
			};
		}

		default: {
			return state;
		}
	}
};

export default lotReducer;
