import { Config } from './utils/config';

export const releaseNoteAppId = 'intrahub-admin-portal';
export const baseURL = Config.APP_URL;
export const appVersion = Config.APP_VERSION;
export const appVersionHash = Config.APP_VERSION_HASH;
export const mapURL = Config.MAP_URL; /* @TODO make this a per-Project setting */
export const showComponents = false;

// eslint-disable-next-line no-useless-escape
export const emailValidator =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// change roles
export const userRoles = [
	{
		value: 'super_admin',
		label: 'Super Admin'
	},
	{
		value: 'admin',
		label: 'Admin'
	},
	{
		value: 'sales_person',
		label: 'Sales Person'
	},
	{
		value: 'sales_manager',
		label: 'Sales Manager'
	},
	{
		value: 'contract_administrator',
		label: 'Contact Administrator'
	},
	{
		value: 'reporting',
		label: 'Reporting'
	}
];
export const projectStatus = ['Future', 'Active', 'Complete'];
export const productStatus = ['Unreleased', 'Available', 'On Hold', 'Deposited', 'Sold', 'Settled'];
export const contactTypes = [
	'Phone - Inbound',
	'Phone - Outbound',
	'Email',
	'Video',
	'Meeting',
	'Social Media',
	'SMS',
	'Other',
	'Note',
	'Mail'
];
export const mapTypes = ['NEARBY', 'INTERNAL'];
export const orientations = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
export const stageStatus = ['UNRELEASED', 'RELEASED', 'COMPLETE', 'COMING_SOON'];
export const paymentForm = ['Cheque', 'Bank Cheque', 'Credit Card', 'Cash', 'EFT', 'Other'];
export const stepValues = [
	{ value: -1, label: 'Cancelled (Not Refunded)' },
	{ value: 0, label: 'Cancelled' },
	{ value: 1, label: 'Initial Deposit' },
	{ value: 2, label: 'Contract Exchange' },
	{ value: 3, label: 'Deposit Payment' },
	{ value: 4, label: 'Conditional' },
	{ value: 5, label: 'Unconditional' },
	// { value: 6, label: 'Settle' },
	{ value: 7, label: 'Settled' },
	{ value: 8, label: 'Transferred' }
];
export const enquirySource = [
	'',
	'Signage',
	'Project Website',
	'Intrapac Website',
	'Google',
	'Digital H&L Collections Book',
	'REA Project Profile',
	'Domain',
	'Facebook',
	'Instagram',
	'Family/Friends',
	'Builder Referral',
	'Investment Builder',
	'Radio',
	'Herald Sun',
	'Local Paper',
	'Local Knowledge',
	'Display Village',
	'Project Newsletter',
	'Event',
	'Kiosk',
	'Other',
	'Billboards',
	'Open Lot',
	'Newspaper - Metro',
	'REA',
	'Agent Website',
	'Upgrade Promotion'
];
export const purchaseTimeframe = [
	'Unqualified',
	'0-3 months',
	'3-6 months',
	'6-9 months',
	'9-12 months',
	'12+ months',
	'Purchased Elsewhere',
	'Already Purchased',
	'Information only',
	'Next Release',
	'Cold/No Response',
	'Attempting to Contact',
	'Opt Out'
];

export const statusFilter = purchaseTimeframe.map((el, i) => ({ value: i, label: el }));

export const emailTypes = ['Personal', 'Work'];
export const phoneTypes = ['Home', 'Work', 'Mobile'];
export const titles = ['', 'Ms', 'Mr', 'Mrs', 'Dr', 'Mx'];

export const contactsRelationsOptions = [
	'',
	'Spouse',
	'Partner',
	'Conveyancer',
	'Broker',
	'Relative',
	'Co-Purchaser',
	'Colleague',
	'Friend',
	'Vendor'
];

export const saleTypes = ['Contract', 'Put & Call'];
export const conditionsList = ['Finance', 'House Sale', 'FIRB', 'Other'];
export const unconditionalOptions = ['From Exchange', 'From Unconditional', 'Fixed Date'];
export const states = ['', 'VIC', 'NSW', 'QLD', 'SA', 'WA', 'NT', 'ACT', 'TAS', 'Other (non-AUS)'];

export const reminderTypes = [
	{ value: 'call', label: 'Call' },
	{ value: 'email', label: 'Email' },
	{ value: 'appointment', label: 'Appointment' },
	{ value: 'follow-up', label: 'Follow up' }
];

export const saleStatsLabels = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

export const saleSatusesLabels = [
	'Initial Deposit',
	'Contract Exchange',
	'Conditional',
	'Unconditional'
];

export const initialContactMethod = ['Web', 'Email', 'Walk In', 'Phone'];

export const reminderLinkTypes = ['contact', 'contact-note', 'contact-timeframe'];

export const optionsListsTypes = ['Selection', 'Quantity', 'Option'];

export const surveyTypes = [
	{ type: 'select', label: 'Dropdown' },
	{ type: 'multi-select', label: 'Multi-Selection' },
	{ type: 'number', label: 'Number' },
	{ type: 'curency', label: 'Curency' },
	{ type: 'date', label: 'Date' },
	{ type: 'text', label: 'Text' },
	{ type: 'checkbox', label: 'Yes/No' }
];

export const reportTypes = [
	{ type: 'weekly-sales', label: 'Weekly Sales Report' },
	{ type: 'sales-summary', label: 'Sales Summary Report' },
	{ type: 'cancellation', label: 'Cancellation History' },
	{ type: 'sales', label: 'Sales History' },
	{ type: 'past-settlement', label: 'Past Settlements' },
	{ type: 'future-settlement', label: 'Future Settlements' },
	{ type: 'stage-analysis', label: 'Stage Analysis' },
	{ type: 'purchaser-profiles', label: 'Contact Profiles Report' },
	{ type: 'contact-sources', label: 'Enquiry Sources' },
	{ type: 'purchaser-mapping', label: 'Purchaser/Contact Maps' },
	{ type: 'builder-documents', label: 'Popular Builder Documents' },
	{ type: 'weekly-all', label: 'Weekly Report - All Projects' },
	{ type: 'sales-summary-all', label: 'Sales Summary - All Projects' },
	{ type: 'settlements-all', label: 'Settlements - All Projects' },
	{ type: 'outstanding-all', label: 'Outstanding Deposits - All Projects' },
	{ type: 'townhomes', label: 'Townhomes Report' },
	{ type: 'commissions-report', label: 'Commissions Report' }
];

export const dateFilterValues = [
	'This Month',
	'This Quarter',
	'This Year',
	'This Financial Year',
	'Last Month',
	'Last Quarter',
	'Last Year',
	'Last Financial Year',
	'All'
];

export const periodFilterValues = ['Weekly', 'Monthly', 'Quarterly', 'Annual'];

export const paymentsTypes = [
	{ value: 'Initial Deposit', label: 'Initial Deposit' },
	{ value: 'Deposit Balance', label: 'Deposit Balance' },
	{ value: 'Refund Deposit', label: 'Refund Deposit' },
	{ value: 'Settlement Balance', label: 'Settlement Balance' }
];

export const paymentFormTypes = [
	{ value: 'Cheque', label: 'Cheque' },
	{ value: 'Bank Cheque', label: 'Bank Cheque' },
	{ value: 'Credit Card', label: 'Credit Card' },
	{ value: 'Cash', label: 'Cash' },
	{ value: 'EFT', label: 'EFT' },
	{ value: 'Other', label: 'Other' }
];

export const titledFilter = [
	{ value: 'true', label: 'Titled' },
	{ value: 'false', label: 'Not Titled' }
];

export const packageEnabledFilter = [
	{
		value: 'true',
		label: 'Enabled'
	},
	{
		value: 'false',
		label: 'Disabled'
	}
];

export const modelsTypes = {
	lot: 'Lot',
	package: 'Package',
	stage: 'Stage',
	point: 'Point',
	builder: 'Builder',
	contact: 'Contact',
	project: 'Project',
	'house-type': 'HouseType'
};

export const entityEndpoints = {
	HouseType: 'house-type',
	Package: 'package',
	Lot: 'lot',
	Stage: 'stage',
	Point: 'point',
	Builder: 'builder',
	Contact: 'contact',
	Project: 'project',
	// HouseAndLandPackage: 'house-and-land-package',
	BuilderLogos: 'builder-logos'
};

export const entityRoutes = {
	HouseType: 'house-types',
	Package: 'packages',
	Lot: 'lots',
	Stage: 'stages',
	Point: {
		NEARBY: 'poi/external',
		INTERNAL: 'poi/onsite'
	},
	Builder: 'builders',
	Contact: 'contacts',
	Project: 'projects'
};
