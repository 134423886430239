import * as actionTypes from 'src/actions';
import {
	fetchProjectPresentations,
	fetchPresentation,
	createPresentation,
	updatePresentation,
	deletePresentation
} from 'src/api/presentationApi';

export const fetchProjectPresentationsRequest =
	({ projectId }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		await dispatch(actionTypes.projectPresentationsFetchPending());
		try {
			const resp = await fetchProjectPresentations({ projectId });
			await dispatch(actionTypes.projectPresentationsFetchSuccess(resp));
		} catch (error) {
			await dispatch(actionTypes.projectPresentationsFetchError(error));
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: 'Fetch presentations error'
				})
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchPresentationRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.presentationFetchPending());
	try {
		const resp = await fetchPresentation(data);
		await dispatch(actionTypes.presentationFetchSuccess(resp?.data));
	} catch (error) {
		await dispatch(actionTypes.presentationFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch presentation error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const createPresentationRequest =
	({ projectId, fromPresentationId, data }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			console.log('**** createPresentation', projectId, data);
			await createPresentation({ projectId, fromPresentationId, ...data });
			await dispatch(
				actionTypes.showToast({
					body: 'Create presentation success'
				})
			);
			await dispatch(actionTypes.setLoader(false));
		} catch (error) {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: 'Create presentation error'
				})
			);
			await dispatch(actionTypes.setLoader(false));

			throw error;
		}
	};

export const updatePresentationRequest =
	({ projectId, presentationId, data }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await updatePresentation({ projectId, presentationId, data });
			await dispatch(actionTypes.setLoader(false));
		} catch (error) {
			await dispatch(actionTypes.setLoader(false));
			throw error;
		}
	};

export const deletePresentationRequest =
	({ projectId, presentationId }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await deletePresentation({ projectId, presentationId });
		} catch (error) {
			await dispatch(actionTypes.setLoader(false));
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: error.message || 'Remove Presentation Error'
				})
			);
			throw error;
		}
		await dispatch(actionTypes.setLoader(false));
	};
