/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import {
	deatachUserFromProject,
	atachUsersToProjectApi,
	setSelectedProjectApi,
	updateUserProfileApi,
	changeUserPasswordApi,
	updateUserProjectPreferencesApi,
	updateUserSecondaryEmails,
	updateUserReportOrderApi
} from 'src/api/userApi';
import { fetchUsers, fetchProjects } from 'src/api';
import jwtDecode from 'jwt-decode';
import Authentication from 'src/utils/Authentication';
import { setUserAuth, setUserPermissions } from 'src/actions';
import * as actionTypes from '../actions';

export const detachUsersProject =
	({ userId, projectId }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));

		try {
			await deatachUserFromProject({ userId, projectId });
			await dispatch(fetchUsers());
		} catch (error) {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
			throw error;
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const atachUsersToProject =
	({ userIds, projectId }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await atachUsersToProjectApi({ userIds, projectId });
			await dispatch(fetchUsers());
		} catch (error) {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
			throw error;
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const updateUserProjectPreferences =
	({ userId, projectId, userProjectPreference }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await updateUserProjectPreferencesApi({ userId, projectId, userProjectPreference });
			await dispatch(fetchUsers());
			await dispatch(actionTypes.setLoader(false));
		} catch (error) {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
			await dispatch(actionTypes.setLoader(false));
			throw error;
		}
	};

export const setSelectedProject = (user) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		if (user.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(user.selectedProject));
		} else {
			const reqObj = { projectId: user.projects[0], userId: user._id };
			await setSelectedProjectApi(reqObj);
			dispatch(actionTypes.changeSelectedProject(user.projects[0]));
		}
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateUserReportOrder =
	({ reportOrder, userId }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));

		try {
			await updateUserReportOrderApi(reportOrder, userId);
		} catch (error) {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
			throw error;
		}
		Authentication.checkLogin().then((res) => {
			const userData = jwtDecode(res.token);

			dispatch(setUserPermissions(res.uniquePermissions));
			dispatch(setUserAuth(userData));
		});
		await dispatch(actionTypes.setLoader(false));
	};

export const changeSelectedProjectApi =
	({ userId, projectId }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await setSelectedProjectApi({ userId, projectId });
			await dispatch(fetchProjects());
		} catch (error) {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
			throw error;
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const updateUserProfile =
	({ userId, ...rest }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await updateUserProfileApi({ userId, ...rest });
		} catch (error) {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Save Profile error' }));
			throw error;
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const changeUserPasswordRequest =
	(data, userId, onClose, open, isAdmin) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await changeUserPasswordApi({ ...data, isAdmin }, userId);
			onClose(!open);
			await dispatch(actionTypes.showToast({ body: 'Password changed successfully' }));
		} catch (error) {
			await dispatch(
				actionTypes.showToast({ type: 'error', body: error.response.data.message })
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const updateSecondaryEmails = (userId, data) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		const {
			data: { message, type }
		} = await updateUserSecondaryEmails(data, userId);
		await dispatch(actionTypes.showToast({ type, body: message }));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: error?.response?.data?.message })
		);
	}
	await dispatch(actionTypes.setLoader(false));
};
