import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	reportSystem: null,
	weeklySales: null,
	salesSummary: null,
	setSalesSummary: null,
	cancellations: null,
	filteredSales: null,
	pastSettlement: null,
	futureSettlement: null,
	stageAnalysis: null,
	purchaserProfiles: {
		pending: false,
		data: null,
		error: null
	},
	contactSources: null,
	purchaserMapping: null,
	builderDocuments: null,
	weekReportAll: null,
	salesSummaryAll: null,
	settlementAll: null,
	packageTownhome: null,
	error: null,
	weekReportAllSummaryPending: false,
	weekReportAllContractsPending: false,
	allOutstandingDeposits: null,
	upcomingOverdueSunsetDates: null,
	upcomingOverdueSunsetDatesPending: false,
	commissionsReport: null
};

const reportSystemReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ALL_OVERDUE_DEPOSITS_SUCCESS: {
			return {
				...state,
				pending: false,
				allOutstandingDeposits: action.allOutstandingDeposits
			};
		}
		case actionTypes.FETCH_ALL_OVERDUE_DEPOSITS_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_ALL_OVERDUE_DEPOSITS_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_SUNSET_DATES_REPORT_SUCCESS: {
			return {
				...state,
				upcomingOverdueSunsetDatesPending: false,
				upcomingOverdueSunsetDates: action.sunsetDates
			};
		}
		case actionTypes.FETCH_SUNSET_DATES_REPORT_PENDING: {
			return {
				...state,
				upcomingOverdueSunsetDatesPending: action.pending
			};
		}
		case actionTypes.FETCH_SUNSET_DATES_REPORT_ERROR: {
			return {
				...state,
				upcomingOverdueSunsetDatesPending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_HOMETOWNPACKAGE_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_HOMETOWNPACKAGE_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_HOMETOWNPACKAGE_SUCCESS: {
			return {
				...state,
				pending: false,
				packageTownhome: action.packageTownhome,
				error: null
			};
		}
		case actionTypes.FETCH_REPORT_SYSTEM_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_REPORT_SYSTEM_SUCCESS: {
			return {
				...state,
				pending: false,
				reportSystem: action.reportSystem,
				error: null
			};
		}
		case actionTypes.FETCH_REPORT_SYSTEM_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_WEEKLY_SALES_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_WEEKLY_SALES_SUCCESS: {
			return {
				...state,
				pending: false,
				weeklySales: action.weeklySales,
				error: null
			};
		}
		case actionTypes.FETCH_WEEKLY_SALES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_SALES_SUMMARY_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_SALES_SUMMARY_SUCCESS: {
			return {
				...state,
				pending: false,
				salesSummary: action.salesSummary,
				error: null
			};
		}
		case actionTypes.FETCH_SALES_SUMMARY_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SET_SALES_SUMMARY: {
			return {
				...state,
				setSalesSummary: action.setSales
			};
		}
		case actionTypes.FETCH_CANCELLATIONS_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_CANCELLATIONS_SUCCESS: {
			return {
				...state,
				pending: false,
				cancellations: action.cancellations,
				error: null
			};
		}
		case actionTypes.FETCH_CANCELLATIONS_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_FILTERED_SALES_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_FILTERED_SALES_SUCCESS: {
			return {
				...state,
				pending: false,
				filteredSales: action.filteredSales,
				error: null
			};
		}
		case actionTypes.FETCH_FILTERED_SALES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_PAST_SETTLEMENT_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_PAST_SETTLEMENT_SUCCESS: {
			return {
				...state,
				pending: false,
				pastSettlement: action.pastSettlement,
				error: null
			};
		}
		case actionTypes.FETCH_PAST_SETTLEMENT_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_FUTURE_SETTLEMENT_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_FUTURE_SETTLEMENT_SUCCESS: {
			return {
				...state,
				pending: false,
				futureSettlement: action.futureSettlement,
				error: null
			};
		}
		case actionTypes.FETCH_FUTURE_SETTLEMENT_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_STAGE_ANALYSIS_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_STAGE_ANALYSIS_SUCCESS: {
			return {
				...state,
				pending: false,
				stageAnalysis: action.stageAnalysis,
				error: null
			};
		}
		case actionTypes.FETCH_STAGE_ANALYSIS_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_PURCHASER_PROFILES_PENDING: {
			return {
				...state,
				purchaserProfiles: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_PURCHASER_PROFILES_SUCCESS: {
			return {
				...state,
				purchaserProfiles: {
					pending: false,
					data: action.purchaserProfiles.data
				}
			};
		}
		case actionTypes.FETCH_PURCHASER_PROFILES_ERROR: {
			return {
				...state,
				purchaserProfiles: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_CONTACT_SOURCES_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_CONTACT_SOURCES_SUCCESS: {
			return {
				...state,
				pending: false,
				contactSources: action.contactSources,
				error: null
			};
		}
		case actionTypes.FETCH_CONTACT_SOURCES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_PURCHASER_MAPPING_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_PURCHASER_MAPPING_SUCCESS: {
			return {
				...state,
				pending: false,
				purchaserMapping: action.purchaserMapping,
				error: null
			};
		}
		case actionTypes.FETCH_PURCHASER_MAPPING_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_BUILDER_DOCUMENTS_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_BUILDER_DOCUMENTS_SUCCESS: {
			return {
				...state,
				pending: false,
				builderDocuments: action.builderDocuments,
				error: null
			};
		}
		case actionTypes.FETCH_BUILDER_DOCUMENTS_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_WEEK_REPORT_ALL_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_WEEK_REPORT_ALL_SUCCESS: {
			return {
				...state,
				pending: false,
				weekReportAll: action.weekReportAll,
				error: null
			};
		}
		case actionTypes.FETCH_WEEK_REPORT_ALL_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_SALES_SUMMARY_ALL_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_SALES_SUMMARY_ALL_SUCCESS: {
			return {
				...state,
				pending: false,
				salesSummaryAll: action.salesSummaryAll,
				error: null
			};
		}
		case actionTypes.FETCH_SALES_SUMMARY_ALL_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_SETTLEMENT_ALL_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_SETTLEMENT_ALL_SUCCESS: {
			return {
				...state,
				pending: false,
				settlementAll: action.settlementAll,
				error: null
			};
		}
		case actionTypes.FETCH_SETTLEMENT_ALL_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.FETCH_WEEK_REPORT_ALL_CONTRACTS_PENDING: {
			return {
				...state,
				weekReportAllContractsPending: action.pending
			};
		}
		case actionTypes.FETCH_WEEK_REPORT_ALL_WEEK_SUMMARY_PENDING: {
			return {
				...state,
				weekReportAllSummaryPending: action.pending
			};
		}
		case actionTypes.FETCH_COMMISSIONS_REPORT_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_COMMISSIONS_REPORT_SUCCESS: {
			return {
				...state,
				pending: false,
				commissionsReport: action.filteredSales,
				error: null
			};
		}
		case actionTypes.FETCH_COMMISSIONS_REPORT_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default reportSystemReducer;
