/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
import moment from 'moment';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import short from 'short-uuid';
import { format as dateFormat } from 'date-fns';

export const checkIsHasDelete = (multiUpdate) => {
	if (typeof multiUpdate === 'undefined') {
		return true;
	}
	if (multiUpdate.type === 'lots' || multiUpdate.type === 'contacts') {
		return !!multiUpdate.removeItem;
	}
	return true;
};

export const formatDateWithoutTz = (date) => dateFormat(date, 'yyyy-MM-dd HH:mm:ss');

export const dateFormatter = (date, formatType = 'DD MMM YYYY, HH:mm') =>
	moment(date).format(formatType);

export const dateReminderFormatter = (date) => {
	const dateHours = new Date(date).getHours();
	const dateMins = new Date(date).getMinutes();
	return moment(date).format(dateHours || dateMins ? 'HH:mm / DD MMM YYYY ' : 'DD MMM YYYY');
};

export const dateFormatterDays = (date, formatType = 'DD MMM YYYY') =>
	moment(date).format(formatType);

export const dateFormatterMonth = (date, formatType = 'MMM YYYY') =>
	date ? moment(date).format(formatType) : '-';

export const actualDate = () => moment(moment().toDate()).format('L');

export const priceFormatter = (price) => {
	if (price === 0) {
		return '$0';
	}
	if (!price) return;

	return (
		'$' +
		Number(price)
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
};

export const priceFormatterDecimal = (price) => {
	if (!price) return null;
	const val = typeof price === 'string' ? parseFloat(price.replace(/,/g, '')) : price;
	return val.toLocaleString('en-AU', { currency: 'AUD', style: 'currency' });
};

export const priceFormatterNull = (price) => {
	if (Number(price) === 0) {
		return 0;
	} else if (!price) {
		return null;
	}

	return '$' + price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatStageName = (stage) =>
	stage?.name ? `${stage?.number} (${stage?.name})` : `${stage?.number}`;

export const checkStatus = (status) => {
	if (status === 'Available' || status === 'Active') {
		return 'success';
	} else if (status === 'Deposited') {
		return 'warning';
	} else if (status === 'Sold') {
		return 'error';
	} else if (status === 'Unreleased') {
		return 'info';
	} else if (status === 'On Hold') {
		return 'onHold';
	} else if (status === 'Settled') {
		return 'settled';
	}
};

export const checkTimeframe = (status) => {
	if (status === '0-3 months' || status === '3-6 months') {
		return 'success';
	} else if (status === '6-9 months' || status === '9-12 months' || status === '12+ months') {
		return 'warning';
	} else if (status === 'Unqualified' || status === 'Attempting to Contact') {
		return 'settled';
	} else if (status === 'Cold/No Response' || status === 'Information only') {
		return 'onHold';
	} else if (status === 'Next Release' || status === 'Opt Out') {
		return 'info';
	} else if (status === 'Purchased Elsewhere') {
		return 'black';
	} else if (status === 'Already Purchased') {
		return 'error';
	}
};

export const checkStep = (status) => {
	if (
		status === 'Contract Exchange' ||
		status === 'Deposit Payment' ||
		status === 'Conditional'
	) {
		return 'warning';
	} else if (status === 'Initial Deposit') {
		return 'settled';
	} else if (status === 'Unconditional') {
		return 'error';
	} else if (status === 'Settled') {
		return 'onHold';
	} else if (status === 'Cancelled') {
		return 'defaultGrey';
	} else if (status === 'Cancelled (Not Refunded)') {
		return 'black';
	} else if (status === 'Transferred') {
		return 'transferred';
	}
};

export const getColumnValue = (data, prop) => {
	if (!prop) return null;
	let result = data;
	const propArray = prop.split('.');

	propArray.forEach((p) => {
		if (result && result[p] instanceof Array) {
			result = getColumnValue(result[p][0], 'name');
		} else if (result) {
			result = result[p];
		} else {
			return result;
		}
	});

	return result;
};

export const isAdmin = () => {
	const user = jwtDecode(localStorage.getItem('token'));
	return user && user.permissions.indexOf('admin') > -1;
};

export const checkIsPositiveNumber = (event) => {
	const value = String.fromCharCode(event.which);

	if (!/[0-9,.]/.test(value)) {
		event.preventDefault();
	}
};

export const checkIsPositiveInteger = (event) => {
	const value = String.fromCharCode(event.which);

	if (!/[0-9]/.test(value)) {
		event.preventDefault();
	}
};

export const getPrimaryPhone = (phones) => {
	const primaryPhone = _.find(phones, { phonePrimary: true })?.phoneNumber;
	const displayPhone = primaryPhone?.length > 0 ? `${primaryPhone}` : ' - ';
	return displayPhone;
};

export const getDiff = (obj) => {
	const arr = [];
	if (obj) {
		Object.entries(obj).map((key) =>
			arr.push({
				name: `${key[0]} changed to: ${key[1]}`
			})
		);
	} else {
		arr.push({ name: '' });
	}

	return arr;
};

export const slugify = (string, id) => {
	if (!string) return '';
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');
	return (
		string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w-]+/g, '') // Remove all non-word characters
			.replace(/--+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') + // Trim - from end of text
		(id
			? '_' +
			  short(short.constants.flickrBase58, {
					consistentLength: false
			  }).fromUUID(id)
			: '')
	);
};

function fallbackCopyTextToClipboard(text) {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	document.execCommand('copy');
	document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	navigator.clipboard.writeText(text);
}

export function videoToImageURL(url = '') {
	return url.replace(
		/\.(3gp|3g2|asf|wmv|avi|divx|evo|f4v|flv|mkv|mk3d|mp4|mpg|mpeg|m2p|ps|ts|m2ts|mxf|ogg|ogv|ogx|mov|qt|rmvb|vob|webm)$/gi,
		'.png'
	);
}

export const projectsDomains = [
	'@intrapac.com.au',
	'@aureus.com.au',
	'@banyanhill.com.au',
	'@ellarook.com.au',
	'@harvestrise.com.au',
	'@kingscoast.com.au',
	'@kinley.com.au',
	'@mirawood.com.au',
	'@quay2.com.au',
	'@somerfield.com.au',
	'@mywhiterock.com.au'
];
export function checkIsImageFormat(format) {
	if (format?.match(/.(jpg|jpeg|png|gif)$/i)) {
		return true;
	}
	return false;
}

const getSortData = (sort, type) => {
	const direction = sort.sortDirection === 'ascending' ? -1 : 1;
	let { sortBy } = sort;

	if (type === 'lots') {
		if (sort.sortBy === 'dimensionsW') {
			sortBy = 'dimensions.width';
		}
		if (sort.sortBy === 'dimensionsD') {
			sortBy = 'dimensions.depth';
		}
		if (sort.sortBy === 'stageLot') {
			sortBy = 'stage.number';
		}
		if (sort.sortBy === 'titled') {
			sortBy = 'stage.titlesAvailable';
		}
	}
	if (type === 'contacts') {
		if (sort.sortBy === 'number') {
			sortBy = 'primaryPhone';
		}
		if (sort.sortBy === 'contactGroup') {
			sortBy = 'contactGroupLength';
		}
	}
	if (type === 'reminder') {
		if (sort.sortBy === 'reminderDate') {
			sortBy = 'dueDate';
		}
		if (sort.sortBy === 'userName') {
			sortBy = 'userId.name';
		}
	}
	if (type === 'sales') {
		if (sort.sortBy === 'salePrice') {
			sortBy = 'salePriceTotal';
		}
		if (sort.sortBy === 'onlyDate') {
			sortBy = 'process.initialDeposit.paymentDate';
		}
		if (sort.sortBy === 'lot') {
			sortBy = 'lotNumber.humanId';
		}
		if (sort.sortBy === 'seller') {
			sortBy = 'salesPerson.name';
		}
		if (sort.sortBy === 'contact') {
			sortBy = 'purchaserContactLength';
		}
		if (sort.sortBy === 'stepValues') {
			sortBy = 'step';
		}
	}
	if (type === 'contactNotes') {
		if (sort.sortBy === 'userName') {
			sortBy = 'user.name';
		}
		if (sort.sortBy === 'contactName') {
			sortBy = 'contact.name';
		}
		if (sort.sortBy === 'serializedMemo') {
			sortBy = 'memoDesc';
		}
	}

	return { direction, sortBy };
};

export const getBodyPaginate = (data, type) => {
	const { page, perPage, defaultLimit, filtersData = {}, packageCheck, myMessages } = data;

	const {
		filters: { surveyId, selectedOptions, repeatEnquiries, ...filters } = {},
		search,
		sort,
		settled,
		hotLeads,
		inactiveContacts,
		id,
		completedReminders,
		allReminders,
		reminderDateRange,
		communicationsEnabled,
		dwellingLots,
		saleSettled,
		saleCancellation
	} = filtersData;

	let reqBody = {};
	if (id) {
		reqBody.id = id;
	}
	if (completedReminders) {
		reqBody.completedReminders = completedReminders;
	}
	if (allReminders) {
		reqBody.allReminders = allReminders;
	}
	if (reminderDateRange) {
		reqBody.reminderDateRange = reminderDateRange;
	}
	if (page && perPage) {
		reqBody.page = page;
		reqBody.perPage = perPage;
		reqBody.defaultLimit = defaultLimit || 25;
	}
	if (settled !== undefined) {
		reqBody.settled = settled;
	}
	if (dwellingLots !== undefined) {
		reqBody.dwellingLots = dwellingLots;
	}
	if (hotLeads !== undefined) {
		reqBody.hotLeads = hotLeads;
	}
	if (inactiveContacts !== undefined) {
		reqBody.inactiveContacts = inactiveContacts;
	}
	if (communicationsEnabled) {
		reqBody.communicationsEnabled = communicationsEnabled;
	}
	if (myMessages) {
		reqBody.myMessages = myMessages;
	}
	if (saleSettled) {
		reqBody.saleSettled = saleSettled;
	}
	if (saleCancellation) {
		reqBody.saleCancellation = saleCancellation;
	}
	if (surveyId) {
		reqBody.surveyId = surveyId;
	}
	if (selectedOptions) {
		reqBody.selectedOptions = selectedOptions;
	}
	if (repeatEnquiries) {
		reqBody.repeatEnquiries = repeatEnquiries;
	}
	if (filters && Object.keys(filters).length) {
		Object.keys(filters).forEach((value) => {
			if (filters[value].length) {
				reqBody = {
					...reqBody,
					[value]: []
				};
				filters[value].map((itm) => reqBody[value].push(itm));
			}
		});
	}
	if (sort?.sortDirection && sort?.sortBy) {
		const { direction, sortBy } = getSortData(sort, type);
		reqBody.sortDirection = direction;
		reqBody.sortBy = sortBy;
	}
	if (search) {
		reqBody.search = search;
	}
	if (filters?.read) {
		if (filters?.read?.length === 1) {
			// both selected or no one selected
			if (filters?.read?.includes(true)) {
				reqBody.read = true;
			} else {
				reqBody.read = false;
			}
		} else {
			delete reqBody.read;
		}
	}
	if (filters?.settlementDate === true) {
		reqBody.settlementDate = [null, new Date()];
	}
	if (packageCheck || packageCheck === false) {
		reqBody.package = packageCheck;
	}

	return reqBody;
};

export const sliceIntoChunks = (arr, chunkSize) => {
	const res = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		const chunk = arr.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
};

export const calculateSaleUnconditionalDate = (saleData) => {
	const exchangedDatePurchaser = saleData?.purchaserSignedDate;
	const exchangedDateVendor = saleData?.vendorSignedDate;
	const lastDepositPayment = saleData.deposit[saleData.deposit.length - 1]?.paymentDate;
	const latestConditionMet =
		saleData.conditionalCheckbox &&
		saleData.conditions.find((el) => el.conditionsMet)?.conditionDate;

	const dates = [];

	if (exchangedDatePurchaser) dates.push(moment(exchangedDatePurchaser));
	if (exchangedDateVendor) dates.push(moment(exchangedDateVendor));
	if (lastDepositPayment) dates.push(moment(lastDepositPayment));
	if (latestConditionMet) dates.push(moment(latestConditionMet));

	const unconditionalDate = moment.max(dates);

	return unconditionalDate;
};

export const isBlackColor = (color) => {
	let c = color;
	c = c.substring(1);
	const rgb = parseInt(c, 16);
	const r = (rgb >> 16) & 0xff; // eslint-disable-line no-bitwise
	const g = (rgb >> 8) & 0xff; // eslint-disable-line no-bitwise
	const b = (rgb >> 0) & 0xff; // eslint-disable-line no-bitwise

	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
	if (luma < 61) {
		return true;
	}

	return false;
};

export const exchangedDate = (one, two) => {
	// eslint-disable-next-line no-underscore-dangle
	const date = moment.max([moment(one), moment(two)])._i;

	return date;
};
