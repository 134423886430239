import * as actionTypes from '../actions/index';

const initialState = {
	builderContacts: null,
	builderUsers: null,
	builderUser: null,
	builderUserApp: null,
	documentTracking: null,
	pending: false,
	error: false
};

const builderPortalReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.BUILDER_CONTACTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.BUILDER_CONTACTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				builderContacts: action.builderContacts,
				error: null
			};
		}

		case actionTypes.BUILDER_CONTACTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDER_USERS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.BUILDER_USERS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				builderUsers: action.builderUsers,
				error: null
			};
		}

		case actionTypes.BUILDER_USERS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDER_USER_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.BUILDER_USER_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				builderUser: action.builderUser,
				error: null
			};
		}

		case actionTypes.BUILDER_USER_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDER_USER_APP_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.BUILDER_USER_APP_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				builderUserApp: action.builderUserApp,
				error: null
			};
		}

		case actionTypes.BUILDER_USER_APP_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.DOCUMENT_TRACKING_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.DOCUMENT_TRACKING_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				documentTracking: action.documentTracking,
				error: null
			};
		}

		case actionTypes.DOCUMENT_TRACKING_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default builderPortalReducer;
