import axios from 'axios';

export const deatachUserFromProject = ({ userId, projectId }) =>
	axios.put(`/api/projects/${projectId}/detach-user/${userId}`);

export const atachUsersToProjectApi = ({ userIds, projectId }) =>
	axios.post(`/api/projects/${projectId}/atach-users`, { userIds });

export const updateUserProjectPreferencesApi = ({ userId, projectId, userProjectPreference }) =>
	axios.put(
		`/api/projects/${projectId}/user-project-preferences/${userId}`,
		userProjectPreference
	);

export const setSelectedProjectApi = ({ userId, projectId }) =>
	axios.put(`/api/projects/${projectId}/set-selected-project/${userId}`);

export const updateUserProfileApi = ({ userId, ...rest }) =>
	axios.put(`/api/user/edit/${userId}`, rest);

export const changeUserPasswordApi = (data, userId) =>
	axios.put(`/api/user/password/${userId}`, data);

export const updateUserSecondaryEmails = (data, userId) =>
	axios.put(`/api/user/secondary-email/${userId}`, data);

export const updateUserCheckBoxes = (data) => axios.put('/api/user/change-check-boxes/', data);

export const getUserById = (id) => axios.get(`api/user/${id}`);

export const updateUserReportOrderApi = (reportOrder, userId) =>
	axios.put(`api/user/${userId}/reportOrder`, reportOrder);
