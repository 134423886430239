export const COMMISSIONS_FETCH_PENDING = 'COMMISSIONS_FETCH_PENDING';
export const COMMISSIONS_FETCH_SUCCESS = 'COMMISSIONS_FETCH_SUCCESS';
export const COMMISSIONS_FETCH_ERROR = 'COMMISSIONS_FETCH_ERROR';

export const SET_COMMISSIONS_FILTERS = 'SET_COMMISSIONS_FILTERS';
export const CLEAR_COMMISSIONS_FILTERS = 'CLEAR_COMMISSIONS_FILTERS';

export const COMMISSIONS_DATA_FETCH_PENDING = 'COMMISSIONS_DATA_FETCH_PENDING';
export const COMMISSIONS_DATA_FETCH_SUCCESS = 'COMMISSIONS_DATA_FETCH_SUCCESS';
export const COMMISSIONS_DATA_FETCH_ERROR = 'COMMISSIONS_DATA_FETCH_ERROR';

export const COMMISSIONS_ITEM_FETCH_PENDING = 'COMMISSIONS_ITEM_FETCH_PENDING';
export const COMMISSIONS_ITEM_FETCH_SUCCESS = 'COMMISSIONS_ITEM_FETCH_SUCCESS';
export const COMMISSIONS_ITEM_FETCH_ERROR = 'COMMISSIONS_ITEM_FETCH_ERROR';

export const COMMISSIONS_DETAILS_FETCH_PENDING = 'COMMISSIONS_DETAILS_FETCH_PENDING';
export const COMMISSIONS_DETAILS_FETCH_SUCCESS = 'COMMISSIONS_DETAILS_FETCH_SUCCESS';
export const COMMISSIONS_DETAILS_FETCH_ERROR = 'COMMISSIONS_DETAILS_FETCH_ERROR';

export const COMMISSIONS_DETAILS_RESET = 'COMMISSIONS_DETAILS_RESET';

export const COMMISSIONS_PAGINATE_FETCH_SUCCESS = 'COMMISSIONS_PAGINATE_FETCH_SUCCESS';

export const USERS_COMMISSIONS_FETCH_PENDING = 'USERS_COMMISSIONS_FETCH_PENDING';
export const USERS_COMMISSIONS_FETCH_SUCCESS = 'USERS_COMMISSIONS_FETCH_SUCCESS';
export const USERS_COMMISSIONS_FETCH_ERROR = 'USERS_COMMISSIONS_FETCH_ERROR';

export const commissionsFetchPending = () => ({
	type: COMMISSIONS_FETCH_PENDING
});

export const commissionsFetchSuccess = (commissions) => ({
	type: COMMISSIONS_FETCH_SUCCESS,
	commissions
});

export const commissionsFetchError = (error) => ({
	type: COMMISSIONS_FETCH_ERROR,
	error
});

export const usersCommissionsFetchPending = () => ({
	type: USERS_COMMISSIONS_FETCH_PENDING
});

export const usersCommissionsFetchSuccess = (usersCommissions) => ({
	type: USERS_COMMISSIONS_FETCH_SUCCESS,
	usersCommissions
});

export const usersCommissionsFetchError = (error) => ({
	type: USERS_COMMISSIONS_FETCH_ERROR,
	error
});

export const commissionsPaginateFetchSuccess = (commissions) => ({
	type: COMMISSIONS_PAGINATE_FETCH_SUCCESS,
	commissions
});

export const commissionsDataFetchPending = () => ({
	type: COMMISSIONS_DATA_FETCH_PENDING
});

export const commissionsDataFetchSuccess = (commissionsData) => ({
	type: COMMISSIONS_DATA_FETCH_SUCCESS,
	commissionsData
});

export const commissionsDataFetchError = (error) => ({
	type: COMMISSIONS_DATA_FETCH_ERROR,
	error
});

export const commissionsItemFetchPending = () => ({
	type: COMMISSIONS_ITEM_FETCH_PENDING
});

export const commissionsItemFetchSuccess = (commissionsItem) => ({
	type: COMMISSIONS_ITEM_FETCH_SUCCESS,
	commissionsItem
});

export const commissionsItemFetchError = (error) => ({
	type: COMMISSIONS_ITEM_FETCH_ERROR,
	error
});

export const commissionsDetailsFetchPending = () => ({
	type: COMMISSIONS_DETAILS_FETCH_PENDING
});

export const commissionsDetailsFetchSuccess = (commissionsDetails) => ({
	type: COMMISSIONS_DETAILS_FETCH_SUCCESS,
	commissionsDetails
});

export const commissionsDetailsFetchError = (error) => ({
	type: COMMISSIONS_DETAILS_FETCH_ERROR,
	error
});

export const commissionsDetailsReset = () => ({
	type: COMMISSIONS_DETAILS_RESET
});

export const setCommissionsFilters = (filterType, data) => ({
	type: SET_COMMISSIONS_FILTERS,
	filterType,
	data
});

export const clearCommissionsFilters = () => ({
	type: CLEAR_COMMISSIONS_FILTERS
});
