export const CUSTOMERS_FETCH_PENDING = 'CUSTOMERS_FETCH_PENDING';
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS';
export const CUSTOMERS_PAGINATE_FETCH_SUCCESS = 'CUSTOMERS_PAGINATE_FETCH_SUCCESS';
export const CUSTOMERS_FETCH_ERROR = 'CUSTOMERS_FETCH_ERROR';
export const CUSTOMER_DETAILS_FETCH_PENDING = 'CUSTOMER_DETAILS_FETCH_PENDING';
export const CUSTOMER_DETAILS_FETCH_SUCCESS = 'CUSTOMER_DETAILS_FETCH_SUCCESS';
export const CUSTOMER_DETAILS_FETCH_ERROR = 'CUSTOMER_DETAILS_FETCH_ERROR';
export const CUSTOMER_NOTES_FETCH_PENDING = 'CUSTOMER_NOTES_FETCH_PENDING';
export const CUSTOMER_NOTES_FETCH_SUCCESS = 'CUSTOMER_NOTES_FETCH_SUCCESS';
export const CUSTOMER_NOTES_FETCH_ERROR = 'CUSTOMER_NOTES_FETCH_ERROR';
export const CUSTOMER_MESSAGES_FETCH_PENDING = 'CUSTOMER_MESSAGES_FETCH_PENDING';
export const CUSTOMER_MESSAGES_FETCH_SUCCESS = 'CUSTOMER_MESSAGES_FETCH_SUCCESS';
export const CUSTOMER_MESSAGES_FETCH_ERROR = 'CUSTOMER_MESSAGES_FETCH_ERROR';
export const CUSTOMER_FETCH_FAVOURITES_PENDING = 'CUSTOMER_FETCH_FAVOURITES_PENDING';
export const CUSTOMER_FETCH_FAVOURITES_SUCCESS = 'CUSTOMER_FETCH_FAVOURITES_SUCCESS';
export const CUSTOMER_FETCH_FAVOURITES_ERROR = 'CUSTOMER_FETCH_FAVOURITES_ERROR';

export const CONTACT_GROUPS_FETCH_PENDING = 'CONTACT_GROUPS_FETCH_PENDING';
export const CONTACT_GROUPS_FETCH_SUCCESS = 'CONTACT_GROUPS_FETCH_SUCCESS';
export const CONTACT_GROUPS_FETCH_ERROR = 'CONTACT_GROUPS_FETCH_ERROR';

export const CONTACT_GROUPS_LIST_FETCH_PENDING = 'CONTACT_GROUPS_LIST_FETCH_PENDING';
export const CONTACT_GROUPS_LIST_FETCH_SUCCESS = 'CONTACT_GROUPS_LIST_FETCH_SUCCESS';
export const CONTACT_GROUPS_LIST_FETCH_ERROR = 'CONTACT_GROUPS_LIST_FETCH_ERROR';

export const CONTACT_GROUP_DETAILS_FETCH_PENDING = 'CONTACT_GROUP_DETAILS_FETCH_PENDING';
export const CONTACT_GROUP_DETAILS_FETCH_SUCCESS = 'CONTACT_GROUP_DETAILS_FETCH_SUCCESS';
export const CONTACT_GROUP_DETAILS_FETCH_ERROR = 'CONTACT_GROUP_DETAILS_FETCH_ERROR';

export const CONTACT_GROUP_DETAILS_MODAL_FETCH_PENDING =
	'CONTACT_GROUP_DETAILS_MODAL_FETCH_PENDING';
export const CONTACT_GROUP_DETAILS_MODAL_FETCH_SUCCESS =
	'CONTACT_GROUP_DETAILS_MODAL_FETCH_SUCCESS';
export const CONTACT_GROUP_DETAILS_MODAL_FETCH_ERROR = 'CONTACT_GROUP_DETAILS_MODAL_FETCH_ERROR';

export const CONTACT_RELATIONS_LINKING_PENDING = 'CONTACT_RELATIONS_LINKING_PENDING';
export const CONTACT_RELATIONS_LINKING_SUCCESS = 'CONTACT_RELATIONS_LINKING_SUCCESS';
export const CONTACT_RELATIONS_LINKING_ERROR = 'CONTACT_RELATIONS_LINKING_ERROR';

export const CONTACT_RELATIONS_DELETE_PENDING = 'CONTACT_RELATIONS_DELETE_PENDING';
export const CONTACT_RELATIONS_DELETE_SUCCESS = 'CONTACT_RELATIONS_DELETE_SUCCESS';
export const CONTACT_RELATIONS_DELETE_ERROR = 'CONTACT_RELATIONS_DELETE_ERROR';

export const LINKED_CONTACTS_NOTES_FETCH_PENDING = 'LINKED_CONTACTS_NOTES_FETCH_PENDING';
export const LINKED_CONTACTS_NOTES_FETCH_SUCCESS = 'LINKED_CONTACTS_NOTES_FETCH_SUCCESS';
export const LINKED_CONTACTS_NOTES_FETCH_ERROR = 'LINKED_CONTACTS_NOTES_FETCH_ERROR';

export const CAMPAIGN_FETCH_PENDING = 'CAMPAIGN_FETCH_PENDING';
export const CAMPAIGN_FETCH_SUCCESS = 'CAMPAIGN_FETCH_SUCCESS';
export const CAMPAIGN_FETCH_ERROR = 'CAMPAIGN_FETCH_ERROR';

export const LINKED_CONTACT_ID = 'LINKED_CONTACT_ID';

export const CONTACT_DETAILS_RESET = 'CONTACT_DETAILS_RESET';
export const CONTACT_GROUP_RESET = 'CONTACT_GROUP_RESET';

export const SET_CUSTOMERS_FILTERS = 'SET_CUSTOMERS_FILTERS';
export const SET_MULTIPLE_CUSTOMERS_FILTERS = 'SET_MULTIPLE_CUSTOMERS_FILTERS';
export const CLEAR_CUSTOMERS_FILTERS = 'CLEAR_CUSTOMERS_FILTERS';

export const FETCH_ALL_CONTACTS_PENDING = 'FETCH_ALL_CONTACTS_PENDING';
export const FETCH_ALL_CONTACTS_SUCCESS = 'FETCH_ALL_CONTACTS_SUCCESS';
export const FETCH_ALL_CONTACTS_ERROR = 'FETCH_ALL_CONTACTS_ERROR';

export const FETCH_PROJECT_CONTACT_NOTES_PENDING = 'FETCH_PROJECT_CONTACT_NOTES_PENDING';
export const FETCH_PROJECT_CONTACT_NOTES_SUCCESS = 'FETCH_PROJECT_CONTACT_NOTES_SUCCESS';
export const FETCH_PROJECT_CONTACT_NOTES_ERROR = 'FETCH_PROJECT_CONTACT_NOTES_ERROR';

export const SET_NOTES_FILTERS = 'SET_NOTES_FILTERS';
export const CLEAR_NOTES_FILTERS = 'CLEAR_NOTES_FILTERS';

export const SET_CUSTOMER_MESSAGES_FILTERS = 'SET_CUSTOMER_MESSAGES_FILTERS';
export const CLEAR_CUSTOMER_MESSAGES_FILTERS = 'CLEAR_CUSTOMER_MESSAGES_FILTERS';

export const UPDATE_CONTACT_DETAILS = 'UPDATE_CONTACT_DETAILS';

export const fetchAllContactsPending = () => ({
	type: FETCH_ALL_CONTACTS_PENDING
});

export const fetchAllContactsSuccess = (contacts) => ({
	type: FETCH_ALL_CONTACTS_SUCCESS,
	contacts
});

export const fetchAllContactsError = (error) => ({
	type: FETCH_ALL_CONTACTS_ERROR,
	error
});

export const customersFetchPending = () => ({
	type: CUSTOMERS_FETCH_PENDING
});

export const customersFetchSuccess = (customers) => ({
	type: CUSTOMERS_FETCH_SUCCESS,
	customers
});

export const customersPaginateFetchSuccess = (customers) => ({
	type: CUSTOMERS_PAGINATE_FETCH_SUCCESS,
	customers
});

export const setCustomersFilters = (filterType, data) => ({
	type: SET_CUSTOMERS_FILTERS,
	filterType,
	data
});

export const setMultipleCustomersFilters = (data) => ({
	type: SET_MULTIPLE_CUSTOMERS_FILTERS,
	data
});

export const clearCustomersFilters = () => ({
	type: CLEAR_CUSTOMERS_FILTERS
});

export const customersFetchError = (error) => ({
	type: CUSTOMERS_FETCH_ERROR,
	error
});

export const campaignDetailsFetchPending = () => ({
	type: CAMPAIGN_FETCH_PENDING
});

export const campaignDetailsFetchSuccess = (campaignDetails) => ({
	type: CAMPAIGN_FETCH_SUCCESS,
	campaignDetails
});

export const campaignDetailsFetchError = (error) => ({
	type: CAMPAIGN_FETCH_ERROR,
	error
});

export const customerDetailsFetchPending = () => ({
	type: CUSTOMER_DETAILS_FETCH_PENDING
});

export const customerDetailsFetchSuccess = (details) => ({
	type: CUSTOMER_DETAILS_FETCH_SUCCESS,
	details
});

export const customerDetailsFetchError = (error) => ({
	type: CUSTOMER_DETAILS_FETCH_ERROR,
	error
});

export const customerNotesFetchPending = () => ({
	type: CUSTOMER_NOTES_FETCH_PENDING
});

export const customerNotesFetchSuccess = (notes) => ({
	type: CUSTOMER_NOTES_FETCH_SUCCESS,
	notes
});

export const customerNotesFetchError = (error) => ({
	type: CUSTOMER_NOTES_FETCH_ERROR,
	error
});

export const customerMessagesFetchPending = () => ({
	type: CUSTOMER_MESSAGES_FETCH_PENDING
});

export const customerMessagesFetchSuccess = (messages) => ({
	type: CUSTOMER_MESSAGES_FETCH_SUCCESS,
	messages
});

export const customerMessagesFetchError = (error) => ({
	type: CUSTOMER_MESSAGES_FETCH_ERROR,
	error
});

export const customerFetchFavouritesPending = () => ({
	type: CUSTOMER_FETCH_FAVOURITES_PENDING
});

export const customerFetchFavouritesSuccess = (favourites) => ({
	type: CUSTOMER_FETCH_FAVOURITES_SUCCESS,
	favourites
});

export const customerFetchFavouritesError = (error) => ({
	type: CUSTOMER_FETCH_FAVOURITES_ERROR,
	error
});

export const contactGroupsFetchPending = () => ({
	type: CONTACT_GROUPS_FETCH_PENDING
});

export const contactGroupsFetchSuccess = (contactGroups) => ({
	type: CONTACT_GROUPS_FETCH_SUCCESS,
	contactGroups
});

export const contactGroupsFetchError = (error) => ({
	type: CONTACT_GROUPS_FETCH_ERROR,
	error
});

export const contactGroupsListFetchPending = () => ({
	type: CONTACT_GROUPS_LIST_FETCH_PENDING
});

export const contactGroupsListFetchSuccess = (contactGroupsList) => ({
	type: CONTACT_GROUPS_LIST_FETCH_SUCCESS,
	contactGroupsList
});

export const contactGroupsListFetchError = (error) => ({
	type: CONTACT_GROUPS_LIST_FETCH_ERROR,
	error
});

export const contactGroupDetailsFetchPending = () => ({
	type: CONTACT_GROUP_DETAILS_FETCH_PENDING
});

export const contactGroupDetailsFetchSuccess = (contactGroupDetails) => ({
	type: CONTACT_GROUP_DETAILS_FETCH_SUCCESS,
	contactGroupDetails
});

export const contactGroupDetailsFetchError = (error) => ({
	type: CONTACT_GROUP_DETAILS_FETCH_ERROR,
	error
});

export const contactGroupDetailsModalFetchPending = () => ({
	type: CONTACT_GROUP_DETAILS_MODAL_FETCH_PENDING
});

export const contactGroupDetailsModalFetchSuccess = (contactGroupDetailsModal) => ({
	type: CONTACT_GROUP_DETAILS_MODAL_FETCH_SUCCESS,
	contactGroupDetailsModal
});

export const contactGroupDetailsModalFetchError = (error) => ({
	type: CONTACT_GROUP_DETAILS_MODAL_FETCH_ERROR,
	error
});

export const contactRelationsLinkingPending = () => ({
	type: CONTACT_RELATIONS_LINKING_PENDING
});

export const contactRelationsLinkingSuccess = (data) => ({
	type: CONTACT_RELATIONS_LINKING_SUCCESS,
	data
});

export const contactRelationsLinkingError = (error) => ({
	type: CONTACT_RELATIONS_LINKING_ERROR,
	error
});

export const contactRelationsDeletePending = () => ({
	type: CONTACT_RELATIONS_DELETE_PENDING
});

export const contactRelationsDeleteSuccess = (data) => ({
	type: CONTACT_RELATIONS_DELETE_SUCCESS,
	data
});

export const contactRelationsDeleteError = (error) => ({
	type: CONTACT_RELATIONS_DELETE_ERROR,
	error
});

export const linkedContactsNotesFetchPending = () => ({
	type: LINKED_CONTACTS_NOTES_FETCH_PENDING
});

export const linkedContactsNotesFetchSuccess = (data) => ({
	type: LINKED_CONTACTS_NOTES_FETCH_SUCCESS,
	data
});

export const linkedContactsNotesFetchError = (error) => ({
	type: LINKED_CONTACTS_NOTES_FETCH_ERROR,
	error
});

export const setLinkedContactId = (id) => ({
	type: LINKED_CONTACT_ID,
	id
});

export const contactDetailsReset = () => ({
	type: CONTACT_DETAILS_RESET
});

export const contactGroupReset = () => ({
	type: CONTACT_GROUP_RESET
});

export const fetchProjectContactNotesPending = () => ({
	type: FETCH_PROJECT_CONTACT_NOTES_PENDING
});

export const fetchProjectContactNotesSuccess = ({ items, pagination }) => ({
	type: FETCH_PROJECT_CONTACT_NOTES_SUCCESS,
	items,
	pagination
});

export const fetchProjectContactNotesError = (error) => ({
	type: FETCH_PROJECT_CONTACT_NOTES_ERROR,
	error
});

export const setNotesFilters = (filterType, data) => ({
	type: SET_NOTES_FILTERS,
	filterType,
	data
});

export const clearNotesFilters = () => ({
	type: CLEAR_NOTES_FILTERS
});

export const setCustomerMessagesFilters = (filterType, data) => ({
	type: SET_CUSTOMER_MESSAGES_FILTERS,
	filterType,
	data
});

export const clearCustomerMessagesFilters = () => ({
	type: CLEAR_CUSTOMER_MESSAGES_FILTERS
});

export const updateContactDetails = (details) => ({
	type: UPDATE_CONTACT_DETAILS,
	details
});
