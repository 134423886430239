import * as actionTypes from '../actions/index';

const initialState = {
	toast: {
		show: false,
		body: 'Success',
		type: 'success',
		id: null,
		onClose: null
	},
	isLoading: false,
	salesDataForm: null,
	changeProject: false,
	isMicrosoftLogin: false
};

const commonReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SHOW_TOAST:
			return {
				...state,
				toast: {
					show: true,
					body: action.payload.body || initialState.toast.body,
					type: action.payload.type || initialState.toast.type,
					id: action.payload.id || initialState.toast.id,
					onClose: action.payload.onClose || initialState.toast.onClose
				}
			};
		case actionTypes.HIDE_TOAST:
			return {
				...state,
				toast: {
					show: false
				}
			};
		case actionTypes.SET_LOADER:
			return {
				...state,
				isLoading: action.isLoading
			};
		case actionTypes.SET_SALES_STEPPER_DATA_FORM:
			return {
				...state,
				salesDataForm: {
					...state.salesDataForm,
					...action.data
				}
			};
		case actionTypes.CHANGE_PROJECT:
			return {
				...state,
				changeProject: action.projectBool
			};
		case actionTypes.SET_MICROSOFT_LOGIN:
			return {
				...state,
				isMicrosoftLogin: action.value
			};
		default: {
			return state;
		}
	}
};

export default commonReducer;
