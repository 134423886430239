import * as actionTypes from '../actions/index';

const initialState = {
	saleStats: {
		pending: false,
		data: [],
		error: null
	},
	pastSettlements: {
		pending: false,
		data: [],
		error: null
	},
	saleStatuses: {
		pending: false,
		data: [],
		error: null
	},
	dueCommissionsStats: {
		pending: false,
		data: null,
		error: null
	},
	outstandingContracts: {
		pending: false,
		data: null,
		error: null
	},
	overdueDeposits: {
		pending: false,
		data: null,
		error: null
	},
	conditionalContracts: {
		pending: false,
		data: null,
		error: null
	},
	sunsetDates: {
		pending: false,
		data: null,
		error: null
	},
	overdueConditions: {
		pending: false,
		data: null,
		error: null
	},
	settlementDates: {
		pending: false,
		data: null,
		error: null
	},
	remindersDashboard: {
		pending: false,
		data: null,
		error: null
	},
	newEnquiries: {
		pending: false,
		data: null,
		error: null
	},
	repeatEnquiry: {
		pending: false,
		data: null,
		error: null
	},
	fullDepositPaid: {
		pending: false,
		data: [],
		error: null
	},
	contractsExchange: {
		pending: false,
		data: {},
		error: null
	},
	canceledSales: {
		pending: false,
		data: {},
		error: null
	},
	walkIn: {
		pending: false,
		data: [],
		error: null
	},
	lotStatusByProject: {
		pending: false,
		data: [],
		error: null
	},
	dashboardSettings: {
		pending: false,
		data: [],
		byRole: [],
		error: null
	},
	weekSales: {
		pending: false,
		data: {},
		error: null
	},
	weekSignedContracts: {
		pending: false,
		data: {},
		error: null
	},
	weekEnquires: {
		pending: false,
		data: {},
		error: null
	},
	weekCancelations: {
		pending: false,
		data: {},
		error: null
	},
	weekOverdueDeposits: {
		pending: false,
		data: {},
		error: null
	},
	weekContactNotes: {
		pending: false,
		data: {},
		error: null
	},
	weekToBeSignedContracts: {
		pending: false,
		data: {},
		error: null
	},
	projectStatuses: {
		pending: false,
		data: {},
		error: null
	},
	listOverdueDeposits: {
		pending: false,
		data: null,
		error: null
	},
	listToBeSignedContracts: {
		pending: false,
		data: null,
		error: null
	},
	overdueContracts: {
		pending: false,
		data: null,
		error: null
	},
	upcomingSettlements: {
		pending: false,
		data: null,
		error: null
	}
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SALE_STATS_PENDING: {
			return {
				...state,
				saleStats: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_SALE_STATS_SUCCESS: {
			return {
				...state,
				saleStats: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_SALE_STATS_ERROR: {
			return {
				...state,
				saleStats: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_PAST_SETTLEMENTS_PENDING: {
			return {
				...state,
				pastSettlements: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_PAST_SETTLEMENTS_SUCCESS: {
			return {
				...state,
				pastSettlements: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_PAST_SETTLEMENTS_ERROR: {
			return {
				...state,
				pastSettlements: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_DUE_COMMISSIONS_STATS_PENDING: {
			return {
				...state,
				dueCommissionsStats: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_DUE_COMMISSIONS_STATS_SUCCESS: {
			return {
				...state,
				dueCommissionsStats: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_DUE_COMMISSIONS_STATS_ERROR: {
			return {
				...state,
				commissionsStats: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_OUTSTANDING_CONTRACTS_PENDING: {
			return {
				...state,
				outstandingContracts: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_OUTSTANDING_CONTRACTS_SUCCESS: {
			return {
				...state,
				outstandingContracts: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_OUTSTANDING_CONTRACTS_ERROR: {
			return {
				...state,
				outstandingContracts: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_SALE_STATUSES_PENDING: {
			return {
				...state,
				saleStatuses: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_SALE_STATUSES_SUCCESS: {
			return {
				...state,
				saleStatuses: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_SALE_STATUSES_ERROR: {
			return {
				...state,
				saleStatuses: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_DEPOSITS_PENDING: {
			return {
				...state,
				overdueDeposits: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_DEPOSITS_SUCCESS: {
			return {
				...state,
				overdueDeposits: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_DEPOSITS_ERROR: {
			return {
				...state,
				overdueDeposits: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_CONDITIONAL_CONTRACTS_PENDING: {
			return {
				...state,
				conditionalContracts: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_CONDITIONAL_CONTRACTS_SUCCESS: {
			return {
				...state,
				conditionalContracts: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_CONDITIONAL_CONTRACTS_ERROR: {
			return {
				...state,
				conditionalContracts: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_SUNSET_DATES_PENDING: {
			return {
				...state,
				sunsetDates: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_SUNSET_DATES_SUCCESS: {
			return {
				...state,
				sunsetDates: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_SUNSET_DATES_ERROR: {
			return {
				...state,
				sunsetDates: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_CONDITIONS_PENDING: {
			return {
				...state,
				overdueConditions: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_CONDITIONS_SUCCESS: {
			return {
				...state,
				overdueConditions: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_CONDITIONS_ERROR: {
			return {
				...state,
				overdueConditions: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_SETTLEMENT_DATES_PENDING: {
			return {
				...state,
				settlementDates: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_SETTLEMENT_DATES_SUCCESS: {
			return {
				...state,
				settlementDates: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_SETTLEMENT_DATES_ERROR: {
			return {
				...state,
				settlementDates: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_REMINDERS_DASHBOARD_PENDING: {
			return {
				...state,
				remindersDashboard: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_REMINDERS_DASHBOARD_SUCCESS: {
			return {
				...state,
				remindersDashboard: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_REMINDERS_DASHBOARD_ERROR: {
			return {
				...state,
				remindersDashboard: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_NEW_ENQUIRIES_PENDING: {
			return {
				...state,
				newEnquiries: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_NEW_ENQUIRIES_SUCCESS: {
			return {
				...state,
				newEnquiries: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_NEW_ENQUIRIES_ERROR: {
			return {
				...state,
				newEnquiries: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_REPEAT_ENQUIRY_PENDING: {
			return {
				...state,
				repeatEnquiry: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_REPEAT_ENQUIRY_SUCCESS: {
			return {
				...state,
				repeatEnquiry: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_REPEAT_ENQUIRY_ERROR: {
			return {
				...state,
				repeatEnquiry: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_FULL_DEPOSIT_PAID_PENDING: {
			return {
				...state,
				fullDepositPaid: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_FULL_DEPOSIT_PAID_SUCCESS: {
			return {
				...state,
				fullDepositPaid: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_FULL_DEPOSIT_PAID_ERROR: {
			return {
				...state,
				fullDepositPaid: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_CONTRACTS_EXCHANGE_PENDING: {
			return {
				...state,
				contractsExchange: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_CONTRACTS_EXCHANGE_SUCCESS: {
			return {
				...state,
				contractsExchange: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_CONTRACTS_EXCHANGE_ERROR: {
			return {
				...state,
				contractsExchange: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_CANCELED_SALES_PENDING: {
			return {
				...state,
				canceledSales: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_CANCELED_SALES_SUCCESS: {
			return {
				...state,
				canceledSales: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_CANCELED_SALES_ERROR: {
			return {
				...state,
				canceledSales: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_WALK_IN_PENDING: {
			return {
				...state,
				walkIn: {
					pending: true,
					error: null
				}
			};
		}
		case actionTypes.FETCH_WALK_IN_SUCCESS: {
			return {
				...state,
				walkIn: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WALK_IN_ERROR: {
			return {
				...state,
				walkIn: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_DASHBOARD_SETTINGS_PENDING: {
			return {
				...state,
				dashboardSettings: {
					pending: true,
					error: null,
					data: [],
					byRole: []
				}
			};
		}
		case actionTypes.FETCH_DASHBOARD_SETTINGS_SUCCESS: {
			return {
				...state,
				dashboardSettings: {
					pending: false,
					data: action.data.data.uniqueDashboardSettings,
					byRole: action.data.data.dashboardSettingsByRole
				}
			};
		}
		case actionTypes.FETCH_DASHBOARD_SETTINGS_ERROR: {
			return {
				...state,
				dashboardSettings: {
					pending: false,
					error: action.error,
					data: []
				}
			};
		}
		case actionTypes.REMOVE_DASHBOARD_ITEM: {
			const newArr = state.dashboardSettings.data;
			newArr.splice(action.id, 1);
			return {
				...state,
				dashboardSettings: {
					...state.dashboardSettings,
					data: [...newArr]
				}
			};
		}
		case actionTypes.SET_REORDERED_ITEMS: {
			return {
				...state,
				dashboardSettings: {
					...state.dashboardSettings,
					data: [...action.arr]
				}
			};
		}
		case actionTypes.HANDLE_GRID_CHANGE: {
			const { id, value } = action.data;
			const newArr = state.dashboardSettings.data;
			newArr[id].grid = value;
			return {
				...state,
				dashboardSettings: {
					...state.dashboardSettings,
					data: [...newArr]
				}
			};
		}
		case actionTypes.FETCH_WEEK_SALES_PENDING: {
			return {
				...state,
				weekSales: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_SALES_SUCCESS: {
			return {
				...state,
				weekSales: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_SALES_ERROR: {
			return {
				...state,
				weekSales: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_WEEK_SIGNED_CONTRACTS_PENDING: {
			return {
				...state,
				weekSignedContracts: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_SIGNED_CONTRACTS_SUCCESS: {
			return {
				...state,
				weekSignedContracts: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_SIGNED_CONTRACTS_ERROR: {
			return {
				...state,
				weekSignedContracts: {
					pending: false,
					error: action.error,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_ENQUIRES_PENDING: {
			return {
				...state,
				weekEnquires: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_ENQUIRES_SUCCESS: {
			return {
				...state,
				weekEnquires: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_ENQUIRES_ERROR: {
			return {
				...state,
				weekEnquires: {
					pending: false,
					data: {},
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_WEEK_CANCELATIONS_PENDING: {
			return {
				...state,
				weekCancelations: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_CANCELATIONS_SUCCESS: {
			return {
				...state,
				weekCancelations: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_CANCELATIONS_ERROR: {
			return {
				...state,
				weekCancelations: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_WEEK_OVERDUE_DEPOSITS_PENDING: {
			return {
				...state,
				weekOverdueDeposits: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_OVERDUE_DEPOSITS_SUCCESS: {
			return {
				...state,
				weekOverdueDeposits: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_OVERDUE_DEPOSITS_ERROR: {
			return {
				...state,
				weekOverdueDeposits: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_WEEK_CONTACT_NOTES_PENDING: {
			return {
				...state,
				weekContactNotes: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_CONTACT_NOTES_SUCCESS: {
			return {
				...state,
				weekContactNotes: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_CONTACT_NOTES_ERROR: {
			return {
				...state,
				weekContactNotes: {
					pending: false,
					error: action.error,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_TOBE_SIGNED_CONTRACTS_PENDING: {
			return {
				...state,
				weekToBeSignedContracts: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_WEEK_TOBE_SIGNED_CONTRACTS_SUCCESS: {
			return {
				...state,
				weekToBeSignedContracts: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_WEEK_TOBE_SIGNED_CONTRACTS_ERROR: {
			return {
				...state,
				weekToBeSignedContracts: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_PROJECT_STATUSES_PENDING: {
			return {
				...state,
				projectStatuses: {
					pending: true,
					error: null,
					data: {}
				}
			};
		}
		case actionTypes.FETCH_PROJECT_STATUSES_SUCCESS: {
			return {
				...state,
				projectStatuses: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_PROJECT_STATUSES_ERROR: {
			return {
				...state,
				projectStatuses: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_LIST_OVERDUE_DEPOSITS_PENDING: {
			return {
				...state,
				listOverdueDeposits: {
					pending: true,
					error: null,
					data: null
				}
			};
		}
		case actionTypes.FETCH_LIST_OVERDUE_DEPOSITS_SUCCESS: {
			return {
				...state,
				listOverdueDeposits: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_LIST_OVERDUE_DEPOSITS_ERROR: {
			return {
				...state,
				listOverdueDeposits: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_LIST_TOBE_SIGNED_CONTRACTS_PENDING: {
			return {
				...state,
				listToBeSignedContracts: {
					pending: true,
					error: null,
					data: null
				}
			};
		}
		case actionTypes.FETCH_LIST_TOBE_SIGNED_CONTRACTS_SUCCESS: {
			return {
				...state,
				listToBeSignedContracts: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_LIST_TOBE_SIGNED_CONTRACTS_ERROR: {
			return {
				...state,
				listToBeSignedContracts: {
					pending: false,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_CONTRACTS_PENDING: {
			return {
				...state,
				overdueContracts: {
					pending: true,
					error: null,
					data: null
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_CONTRACTS_SUCCESS: {
			return {
				...state,
				overdueContracts: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_OVERDUE_CONTRACTS_ERROR: {
			return {
				...state,
				overdueContracts: {
					pending: false,
					data: null,
					error: action.error
				}
			};
		}
		case actionTypes.FETCH_UPCOMING_SETTLEMENTS_PENDING: {
			return {
				...state,
				upcomingSettlements: {
					pending: true,
					error: null,
					data: null
				}
			};
		}
		case actionTypes.FETCH_UPCOMING_SETTLEMENTS_SUCCESS: {
			return {
				...state,
				upcomingSettlements: {
					pending: false,
					data: action.data.data
				}
			};
		}
		case actionTypes.FETCH_UPCOMING_SETTLEMENTS_ERROR: {
			return {
				...state,
				upcomingSettlements: {
					pending: false,
					error: action.error
				}
			};
		}
		default: {
			return state;
		}
	}
};

export default dashboardReducer;
