export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SET_LOADER = 'SET_LOADER';
export const SET_SALES_STEPPER_DATA_FORM = 'SET_SALES_STEPPER_DATA_FORM';
export const CHANGE_PROJECT = 'CHANGE_PROJECT';
export const SET_MICROSOFT_LOGIN = 'SET_MICROSOFT_LOGIN';

export const showToast = (payload) => ({
	type: SHOW_TOAST,
	payload
});

export const hideToast = () => ({
	type: HIDE_TOAST
});

export const setLoader = (isLoading) => ({
	type: SET_LOADER,
	isLoading
});

export const setSalesStepperDataForm = (data) => ({
	type: SET_SALES_STEPPER_DATA_FORM,
	data
});

export const setChangeProject = (projectBool) => ({
	type: CHANGE_PROJECT,
	projectBool
});

export const setMicrosoftLogin = (value) => ({
	type: SET_MICROSOFT_LOGIN,
	value
});
