import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	maps: null,
	categories: [],
	mapDetails: null
};

const mapReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.NEARBY_AND_WHITEROCK_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.NEARBY_AND_WHITEROCK_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				maps: action.data,
				error: null
			};
		}

		case actionTypes.NEARBY_AND_WHITEROCK_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.NEARBY_BY_ID_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.NEARBY_BY_ID_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				mapDetails: action.data,
				error: null
			};
		}
		case actionTypes.NEARBY_BY_ID_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.FETCH_POINT_CATEGORIES: {
			return {
				...state,
				categories: action.categories.data
			};
		}

		default: {
			return state;
		}
	}
};

export default mapReducer;
