import axios from 'axios';

export const fetchOptionsList = ({ projectId }) =>
	axios.get(`/api/projects/${projectId}/optionsList`);
export const createOptionsList = ({ projectId, ...rest }) =>
	axios.post(`/api/projects/${projectId}/optionsList`, rest);
export const updateOptionsList = ({ projectId, listId, ...rest }) =>
	axios.put(`/api/projects/${projectId}/optionsList/${listId}`, rest);
export const removeOptionsList = ({ projectId, listId, data }) =>
	axios.delete(`/api/projects/${projectId}/optionsList/${listId}`, { data });
export const duplicateOptionsList = ({ projectId, listId, ...duplicateName }) =>
	axios.post(`/api/projects/${projectId}/optionsList/${listId}`, duplicateName);
