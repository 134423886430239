export const FETCH_MESSAGES_PENDING = 'FETCH_MESSAGES_PENDING';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

export const SET_MESSAGES_PAGINATION = 'SET_MESSAGES_PAGINATION';

export const fetchMessagesPending = () => ({
	type: FETCH_MESSAGES_PENDING
});

export const fetchMessagesSuccess = (data) => ({
	type: FETCH_MESSAGES_SUCCESS,
	data
});

export const setMessagesPagination = (data) => ({
	type: SET_MESSAGES_PAGINATION,
	data
});

export const fetchMessagesError = (error) => ({
	type: FETCH_MESSAGES_ERROR,
	error
});
