import * as actionTypes from '../actions';

const initialState = {
	pending: false,
	error: null,
	data: [],
	assignedContacts: [],
	purchaserProfiles: [],
	purchaserProfile: {}
};

const surveyReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SURVEY_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_SURVEY_SUCCESS: {
			return {
				...state,
				pending: false,
				data: action.data.data
			};
		}
		case actionTypes.GET_SURVEY_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.GET_ASSIGNED_SURVEY_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_ASSIGNED_SURVEY_SUCCESS: {
			return {
				...state,
				pending: false,
				assignedContacts: action.data.data
			};
		}
		case actionTypes.GET_ASSIGNED_SURVEY_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.GET_PURCHASER_PROFILES_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_PURCHASER_PROFILES_SUCCESS: {
			return {
				...state,
				pending: false,
				purchaserProfiles: action.data.data
			};
		}
		case actionTypes.GET_PURCHASER_PROFILES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.GET_PURCHASER_PROFILE_BYID_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_PURCHASER_PROFILE_BYID_SUCCESS: {
			return {
				...state,
				pending: false,
				purchaserProfile: action.data
			};
		}
		case actionTypes.GET_PURCHASER_PROFILE_BYID_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default surveyReducer;
