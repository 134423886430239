export const GET_SURVEY_PENDING = 'GET_SURVEY_PENDING';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_ERROR = 'GET_SURVEY_ERROR';

export const GET_ASSIGNED_SURVEY_PENDING = 'GET_ASSIGNED_SURVEY_PENDING';
export const GET_ASSIGNED_SURVEY_SUCCESS = 'GET_ASSIGNED_SURVEY_SUCCESS';
export const GET_ASSIGNED_SURVEY_ERROR = 'GET_ASSIGNED_SURVEY_ERROR';

export const GET_PURCHASER_PROFILES_PENDING = 'GET_PURCHASER_PROFILES_PENDING';
export const GET_PURCHASER_PROFILES_SUCCESS = 'GET_PURCHASER_PROFILES_SUCCESS';
export const GET_PURCHASER_PROFILES_ERROR = 'GET_PURCHASER_PROFILES_ERROR';

export const GET_PURCHASER_PROFILE_BYID_PENDING = 'GET_PURCHASER_PROFILE_BYID_PENDING';
export const GET_PURCHASER_PROFILE_BYID_SUCCESS = 'GET_PURCHASER_PROFILE_BYID_SUCCESS';
export const GET_PURCHASER_PROFILE_BYID_ERROR = 'GET_PURCHASER_PROFILE_BYID_ERROR';

export const getSurveyPending = () => ({
	type: GET_SURVEY_PENDING
});

export const getSurveySuccess = (data) => ({
	type: GET_SURVEY_SUCCESS,
	data
});

export const getSurveyError = (error) => ({
	type: GET_SURVEY_ERROR,
	error
});

export const getAssignedSurveyPending = () => ({
	type: GET_ASSIGNED_SURVEY_PENDING
});

export const getAssignedSurveySuccess = (data) => ({
	type: GET_ASSIGNED_SURVEY_SUCCESS,
	data
});

export const getAssignedSurveyError = (error) => ({
	type: GET_ASSIGNED_SURVEY_ERROR,
	error
});

export const getPurchaserProfilesPending = () => ({
	type: GET_PURCHASER_PROFILES_PENDING
});

export const getPurchaserProfilesSuccess = (data) => ({
	type: GET_PURCHASER_PROFILES_SUCCESS,
	data
});

export const getPurchaserProfilesError = (error) => ({
	type: GET_PURCHASER_PROFILES_ERROR,
	error
});

export const getPurchaserProfileByIdPending = () => ({
	type: GET_PURCHASER_PROFILE_BYID_PENDING
});

export const getPurchaserProfileByIdSuccess = (data) => ({
	type: GET_PURCHASER_PROFILE_BYID_SUCCESS,
	data
});

export const getPurchaserProfileByIdError = (error) => ({
	type: GET_PURCHASER_PROFILE_BYID_ERROR,
	error
});
