import * as Sentry from "@sentry/react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://b1be0cfdf2a05963ad0338933a209f03@sentry.intrapac.io/4',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
