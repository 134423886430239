import React, { useMemo, useState, useCallback } from 'react';
import {
	createEditor,
	Editor,
	Transforms,
	Node,
	Element as SlateElement,
	Range,
	Text
} from 'slate';
import {
	Slate,
	Editable,
	withReact,
	useSlate,
	useSelected,
	useFocused,
	useSlateStatic
} from 'slate-react';
import ImageIcon from '@material-ui/icons/Image';
import imageExtensions from 'image-extensions';
import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';
import isUrl from 'is-url';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import StatusBullet from './StatusBullet';

export const editorStyles = {
	'& h1, & h2': {
		fontWeight: 'bold',
		lineHeight: '100%'
	},
	'& h1': {
		fontSize: '2em'
	},
	'& h2': {
		fontSize: '1.5em'
	},
	'& ol': {
		listStyle: 'decimal'
	},
	'& ul': {
		listStyle: 'disc'
	},
	'& a': {
		color: '#0000EE'
	}
};

const useStyles = makeStyles(() => ({
	toolbar: {
		width: '100%',
		padding: '10px 5px',
		display: 'flex',
		borderBottom: '1px solid #eeeeee'
	},
	editorWrap: {
		padding: '10px',
		...editorStyles
	},
	editor: {
		maxHeight: '500px',
		overflowY: 'auto'
	},
	btn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '12px',
		cursor: 'pointer',
		fontSize: '12px',
		borderRadius: '4px',
		padding: '2px'
	},
	btnActive: {
		backgroundColor: '#eeeeee'
	},
	wrapperColors: {
		display: 'flex',
		alignItems: 'center'
	},
	colorBlock: {
		width: '20px',
		height: '20px',
		border: '1px solid #000',
		borderRadius: '2px',
		marginRight: '6px'
	},
	colorList: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		transition: 'all 0.3s ease'
	},
	bulletWrap: {
		cursor: 'pointer',
		marginRight: '6px',
		height: '20px'
	},
	noMargins: {
		margin: '0'
	},
	image: {
		display: 'block',
		maxWidth: '100%',
		maxHeight: '20em'
	}
}));

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline'
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const colors = [
	{ type: 'primary', color: '#3949ab' },
	{ type: 'info', color: '#039be5' },
	{ type: 'success', color: '#66bb6a' },
	{ type: 'warning', color: '#ffa726' },
	{ type: 'error', color: '#f44336' },
	{ type: 'settled', color: '#ffeb3b' },
	{ type: 'onHold', color: '#9c27b0' },
	{ type: 'black', color: '#212121' },
	{ type: 'transferred', color: '#00bcd4' }
];

export function Element(props) {
	const { attributes, children, element } = props;
	switch (element.type) {
		case 'block-quote':
			return <blockquote {...attributes}>{children}</blockquote>;
		case 'bulleted-list':
			return (
				<ul
					style={{ paddingLeft: '20px' }}
					{...attributes}
				>
					{children}
				</ul>
			);
		case 'numbered-list':
			return (
				<ol
					style={{ paddingLeft: '20px' }}
					{...attributes}
				>
					{children}
				</ol>
			);
		case 'list-item':
			return <li {...attributes}>{children}</li>;
		case 'heading-one':
			return <h1 {...attributes}>{children}</h1>;
		case 'heading-two':
			return <h2 {...attributes}>{children}</h2>;
		case 'link':
			return (
				<a
					{...attributes}
					href={element.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</a>
			);
		case 'image':
			return <Image {...props} />;
		default:
			return <p {...attributes}>{children}</p>;
	}
}

export function Leaf({ attributes, children, leaf }) {
	if (leaf.bold) {
		// eslint-disable-next-line
		children = <strong>{children}</strong>;
	}
	if (leaf.italic) {
		// eslint-disable-next-line
		children = <em>{children}</em>;
	}
	if (leaf.underline) {
		// eslint-disable-next-line
		children = <u>{children}</u>;
	}
	return (
		<span
			{...attributes}
			style={{ color: `${leaf.color && leaf.color}` }}
		>
			{children}
		</span>
	);
}

const ELEM_TYPES = [
	{ link: 'A' },
	{ quote: 'BLOCKQUOTE' },
	{ paragraph: 'P' },
	{ image: 'IMG' },
	{ 'heading-one': 'H1' },
	{ 'heading-two': 'H2' },
	{ 'heading-three': 'H3' },
	{ 'heading-four': 'H4' },
	{ 'heading-five': 'H5' },
	{ 'heading-six': 'H6' },
	{ 'list-item': 'LI' },
	{ 'numbered-list': 'OL' },
	{ 'bulleted-list': 'UL' }
];

const ELEMENT_TAGS = {
	A: (el) => ({ type: 'link', url: el.getAttribute('href') }),
	BLOCKQUOTE: () => ({ type: 'quote' }),
	P: () => ({ type: 'paragraph' }),
	IMG: (el) => ({ type: 'image', url: el.getAttribute('src') }),
	H1: () => ({ type: 'heading-one' }),
	H2: () => ({ type: 'heading-two' }),
	H3: () => ({ type: 'heading-three' }),
	H4: () => ({ type: 'heading-four' }),
	H5: () => ({ type: 'heading-five' }),
	H6: () => ({ type: 'heading-six' }),
	LI: () => ({ type: 'list-item' }),
	OL: () => ({ type: 'numbered-list' }),
	UL: () => ({ type: 'bulleted-list' })
};

const TEXT_TAGS = {
	DEL: () => ({ strikethrough: true }),
	EM: () => ({ italic: true }),
	I: () => ({ italic: true }),
	S: () => ({ strikethrough: true }),
	STRONG: () => ({ bold: true }),
	B: () => ({ bold: true }),
	U: () => ({ underline: true }),
	SPAN: (el) => (el?.style?.color ? { color: el?.style?.color } : {})
};

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};

const isColorActive = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? !!(marks.color === format) : false;
};

const isBlockActive = (editor, format) => {
	const [match] = Editor.nodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
	});

	return !!match;
};

const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else if (format.startsWith('#')) {
		Editor.addMark(editor, 'color', format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n) =>
			LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
		split: true
	});
	// eslint-disable-next-line
	Transforms.setNodes(editor, { type: isActive ? 'paragraph' : isList ? 'list-item' : format });

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const isLinkActive = (editor) => {
	const [link] = Editor.nodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
	});
	return !!link;
};

const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
	});
};

const wrapLink = (editor, url) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor);
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);

	const link = {
		type: 'link',
		url,
		children: isCollapsed ? [{ text: url }] : []
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: 'end' });
	}
};

const withLinks = (editor) => {
	const { insertData, insertText, isInline } = editor;

	// eslint-disable-next-line
	editor.isInline = (element) => {
		return element.type === 'link' ? true : isInline(element);
	};

	// eslint-disable-next-line
	editor.insertText = (text) => {
		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertText(text);
		}
	};

	// eslint-disable-next-line
	editor.insertData = (data) => {
		const text = data.getData('text/plain');

		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertData(data);
		}
	};

	return editor;
};

const insertLink = (editor, url) => {
	if (editor.selection) {
		wrapLink(editor, url);
	}
};

function BlockButton({ format, icon, classes, activeStyle }) {
	const editor = useSlate();
	return (
		<div
			className={clsx(...classes, isBlockActive(editor, format) && activeStyle)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleBlock(editor, format);
			}}
		>
			{icon}
		</div>
	);
}

function MarkButton({ format, icon, classes, activeStyle }) {
	const editor = useSlate();
	return (
		<div
			className={clsx(...classes, isMarkActive(editor, format) && activeStyle)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			{icon}
		</div>
	);
}

function ColorButton({ format, icon, classes, callback }) {
	const editor = useSlate();
	if (isColorActive(editor, format)) {
		callback(format);
	}
	return (
		<div
			className={clsx(...classes)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			{icon}
		</div>
	);
}

function LinkButton({ type, icon, classes, activeStyle }) {
	const editor = useSlate();
	return (
		<div
			className={clsx(...classes, isLinkActive(editor) && type === 'wrap' && activeStyle)}
			onMouseDown={(event) => {
				event.preventDefault();
				if (type === 'wrap') {
					const url = window.prompt('Enter the URL of the link:');
					if (!url) return;
					insertLink(editor, url);
				} else if (isLinkActive(editor)) {
					unwrapLink(editor);
				}
			}}
		>
			{icon}
		</div>
	);
}

export const serialize = (value) => value?.map((n) => Node.string(n)).join('\n');

export const deserialize = (string) =>
	string.split('\n').map((line) => ({
		children: [{ text: line }]
	}));

export const deserializeHtml = (el) => {
	if (el.nodeType === 3) {
		return el.textContent;
		// eslint-disable-next-line
	} else if (el.nodeType !== 1) {
		return null;
	} else if (el.nodeName === 'BR') {
		return '\n';
	}

	const { nodeName } = el;
	let parent = el;

	if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
		// eslint-disable-next-line
		parent = el.childNodes[0];
	}
	const children = Array.from(parent.childNodes).map(deserializeHtml).flat();

	if (el.nodeName === 'IMG') {
		const attrs = ELEMENT_TAGS[nodeName](el);
		return jsx('element', attrs, [{ text: '' }]);
	}

	if (!children.length) {
		return jsx('element', { type: 'paragraph' }, { text: '' });
	}

	if (
		el.nodeName === 'BODY' ||
		el.nodeType === 'DIV' ||
		el.nodeType === 'SPAN' ||
		el.nodeType === 'FONT'
	) {
		return jsx('fragment', {}, children);
	}

	if (ELEMENT_TAGS[nodeName]) {
		const attrs = ELEMENT_TAGS[nodeName](el);
		return jsx('element', attrs, children);
	}

	if (TEXT_TAGS[nodeName]) {
		const attrs = TEXT_TAGS[nodeName](el);
		return children.map((child) => {
			const elem = ELEM_TYPES.find((itm) => Object.keys(itm)[0] === child?.type);
			return elem
				? jsx('element', ELEMENT_TAGS[elem[Object.keys(elem)[0]]](el), child)
				: jsx('text', attrs, child);
		});
	}

	return children;
};

export const serializeHtml = (node) => {
	if (Text.isText(node)) {
		let string = escapeHtml(node.text);
		if (node.bold) {
			string = `<strong>${string}</strong>`;
		}
		if (node.italic) {
			string = `<i>${string}</i>`;
		}
		if (node.underline) {
			string = `<u>${string}</u>`;
		}
		if (node.type === 'list-item') {
			string = `<li>${string}</li>`;
		}

		if (node.color) {
			string = `<span style="color: ${node.color}">${string}</span>`;
		}
		return string;
	}

	const children = node?.children?.map((n) => serializeHtml(n)).join('');

	switch (node.type) {
		case 'quote':
			return `<blockquote><p>${children}</p></blockquote>`;
		case 'paragraph':
			return `<p>${children}</p>`;
		case 'bulleted-list':
			return `<ul>${children}</ul>`;
		case 'numbered-list':
			return `<ol>${children}</ol>`;
		case 'list-item':
			return `<li>${children}</li>`;
		case 'heading-one':
			return `<h1>${children}</h1>`;
		case 'heading-two':
			return `<h2>${children}</h2>`;
		case 'link':
			return `<a href="${escapeHtml(node.url)}">${children}</a>`;
		case 'image':
			return `<div><img alt="" src="${node.url}" style="display: block; max-width: 100%; max-height: 20em;" />${children}</div>`;
		default:
			return children;
	}
};

function Image({ attributes, children, element }) {
	const classes = useStyles();
	const selected = useSelected();
	const focused = useFocused();

	return (
		<div {...attributes}>
			<div contentEditable={false}>
				<img
					alt=""
					src={element.url}
					style={selected && focused ? { border: '1px solid red' } : {}}
					className={classes.image}
				/>
			</div>
			{children}
		</div>
	);
}

const isImageUrl = (url) => {
	if (!url) return false;
	if (!isUrl(url)) return false;
	const ext = new URL(url).pathname.split('.').pop();
	return imageExtensions.includes(ext);
};

export const insertImage = (editor, url) => {
	const text = { text: '' };
	const image = { type: 'image', url, children: [text] };
	Transforms.insertNodes(editor, image);
};

function InsertImageButton() {
	const editor = useSlateStatic();
	return (
		<Button
			onMouseDown={(event) => {
				event.preventDefault();
				// eslint-disable-next-line no-alert
				const url = window.prompt('Enter the URL of the image:');
				if (url && !isImageUrl(url)) {
					alert('URL is not an image');
					return;
				}
				insertImage(editor, url);
			}}
		>
			<ImageIcon />
		</Button>
	);
}

export const withImages = (editor) => {
	const { insertData, isVoid } = editor;

	// eslint-disable-next-line
	editor.isVoid = (element) => {
		return element.type === 'image' ? true : isVoid(element);
	};

	// eslint-disable-next-line
	editor.insertData = (data) => {
		const text = data.getData('text/plain');
		const { files } = data;

		if (files && files.length > 0) {
			// eslint-disable-next-line no-restricted-syntax
			for (const file of files) {
				const reader = new FileReader();
				const [mime] = file.type.split('/');

				if (mime === 'image') {
					reader.addEventListener('load', () => {
						const url = reader.result;
						insertImage(editor, url);
					});

					reader.readAsDataURL(file);
				}
			}
		} else if (isImageUrl(text)) {
			insertImage(editor, text);
		} else {
			insertData(data);
		}
	};

	return editor;
};

function MessageEditor({ value, setValue }) {
	const classes = useStyles();
	const renderElement = useCallback((props) => <Element {...props} />, []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
	const editor = useMemo(() => withImages(withLinks(withReact(createEditor()))), []);
	const [activeColor, setActiveColor] = useState('#000');

	const editorKeyDown = (event) => {
		// eslint-disable-next-line no-restricted-syntax
		for (const hotkey in HOTKEYS) {
			if (isHotkey(hotkey, event)) {
				event.preventDefault();
				const mark = HOTKEYS[hotkey];
				toggleMark(editor, mark);
			}
		}
	};

	return (
		<Slate
			editor={editor}
			value={value}
			onChange={(newValue) => setValue(newValue)}
		>
			<div className={classes.toolbar}>
				<InsertImageButton />
				<MarkButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="bold"
					icon={<FormatBoldIcon fontSize="small" />}
				/>
				<MarkButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="italic"
					icon={<FormatItalicIcon fontSize="small" />}
				/>
				<MarkButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="underline"
					icon={<FormatUnderlinedIcon fontSize="small" />}
				/>
				<BlockButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="heading-one"
					icon={<Filter1Icon fontSize="small" />}
				/>
				<BlockButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="heading-two"
					icon={<Filter2Icon fontSize="small" />}
				/>
				<LinkButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					type="wrap"
					icon={<LinkIcon fontSize="small" />}
				/>
				<LinkButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					type="unwrap"
					icon={<LinkOffIcon fontSize="small" />}
				/>
				<BlockButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="numbered-list"
					icon={<FormatListNumberedIcon fontSize="small" />}
				/>
				<BlockButton
					classes={[classes.btn]}
					activeStyle={classes.btnActive}
					format="bulleted-list"
					icon={<FormatListBulletedIcon fontSize="small" />}
				/>
				<div className={classes.wrapperColors}>
					<div
						className={classes.colorBlock}
						style={{ backgroundColor: activeColor }}
					/>
					<div className={classes.colorList}>
						{colors.map((item) => (
							<ColorButton
								key={item.type}
								classes={[classes.bulletWrap]}
								format={item.color}
								icon={
									<StatusBullet
										color={item.type}
										size="medium"
										className={classes.noMargins}
									/>
								}
								callback={(color) => setActiveColor(color)}
							/>
						))}
					</div>
				</div>
			</div>
			<div className={classes.editorWrap}>
				<Editable
					{...{ spellCheck: true }}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					autoFocus
					placeholder="Type message here..."
					onKeyDown={(event) => editorKeyDown(event)}
					className={classes.editor}
				/>
			</div>
		</Slate>
	);
}

MessageEditor.propTypes = {
	value: PropTypes.array,
	setValue: PropTypes.func
};

ColorButton.propTypes = {
	format: PropTypes.string,
	icon: PropTypes.node,
	classes: PropTypes.array,
	callback: PropTypes.func
};

MarkButton.propTypes = {
	format: PropTypes.string,
	icon: PropTypes.node,
	classes: PropTypes.array,
	activeStyle: PropTypes.string
};

BlockButton.propTypes = {
	format: PropTypes.string,
	icon: PropTypes.node,
	classes: PropTypes.array,
	activeStyle: PropTypes.string
};

LinkButton.propTypes = {
	type: PropTypes.string,
	icon: PropTypes.node,
	classes: PropTypes.array,
	activeStyle: PropTypes.string
};

Leaf.propTypes = {
	attributes: PropTypes.object,
	children: PropTypes.node,
	leaf: PropTypes.object
};

Element.propTypes = {
	attributes: PropTypes.object,
	children: PropTypes.node,
	element: PropTypes.object
};

Image.propTypes = {
	attributes: PropTypes.object,
	children: PropTypes.node,
	element: PropTypes.object
};

export default MessageEditor;
