export const PROJECT_FETCH_PENDING = 'PROJECT_FETCH_PENDING';
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS';
export const PROJECT_FETCH_ERROR = 'PROJECT_FETCH_ERROR';
export const CHANGE_SELECTED_PROJECT = 'CHANGE_SELECTED_PROJECT';

export const OPTIONS_FETCH_PENDING = 'OPTIONS_FETCH_PENDING';
export const OPTIONS_FETCH_SUCCESS = 'OPTIONS_FETCH_SUCCESS';
export const OPTIONS_FETCH_ERROR = 'OPTIONS_FETCH_ERROR';

export const projectFetchPending = () => ({
	type: PROJECT_FETCH_PENDING
});

export const projectFetchSuccess = (projects) => ({
	type: PROJECT_FETCH_SUCCESS,
	projects
});

export const projectFetchError = (error) => ({
	type: PROJECT_FETCH_ERROR,
	error
});

export const changeSelectedProject = (project) => ({
	type: CHANGE_SELECTED_PROJECT,
	project
});

export const optionsFetchPending = () => ({
	type: OPTIONS_FETCH_PENDING
});

export const optionsFetchSuccess = (options) => ({
	type: OPTIONS_FETCH_SUCCESS,
	options
});

export const optionsFetchError = (error) => ({
	type: OPTIONS_FETCH_ERROR,
	error
});
