import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	menu: [],
	error: null
};

const sideMenuReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SIDE_MENU_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_SIDE_MENU_SUCCESS: {
			return {
				...state,
				menu: action.menu,
				pending: false
			};
		}
		case actionTypes.FETCH_SIDE_MENU_ERROR: {
			return {
				...state,
				pending: false,
				menu: [],
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default sideMenuReducer;
