import axios from 'axios';
import uuid from 'uuid/v1';
import { stepValues, entityEndpoints } from 'src/Constants';
import { getBodyPaginate } from '../utils';
import * as actionTypes from '../actions';
import { fetchRemindersPaginationApi } from './reminderApi';
import { replyActionApi } from './confirmationActionsApi';
import { Config } from '../utils/config';

// **************** PROJECT *****************

export const fetchProjects = () => async (dispatch) => {
	await dispatch(actionTypes.projectFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const projects = await axios.get('/api/projects');
		const mappedProjects = projects.data.map(({ project, stages, lots }) => ({
			...project,
			stages,
			lots
		}));
		await dispatch(actionTypes.projectFetchSuccess({ data: mappedProjects }));
	} catch (e) {
		await dispatch(actionTypes.projectFetchError(e));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteProject = (id) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.delete(`/api/projects/${id}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateProjectDetails = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));

		if (error.response.data && error.response.data.zoomLevel.kind === 'Number') {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: "ZoomLevel must be a number! Use '.' instead of ','"
				})
			);
		} else {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		}

		throw error;
	}

	await dispatch(actionTypes.setLoader(false));
};

export const addProject = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.post('/api/projects', data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));

		if (error.response.data && error.response.data.zoomLevel.kind === 'Number') {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: "ZoomLevel must be a number! Use '.' instead of ','"
				})
			);
		} else {
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		}
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteShowcasedProject = (projectId, showcasedProjectId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.delete(`/api/projects/${projectId}/showcasedProject/${showcasedProjectId}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateShowcasedProject = (projectId, showcasedProjectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/showcasedProject/${showcasedProjectId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const addShowcasedProject = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.post(`/api/projects/${projectId}/showcasedProject`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const reorderShowcasedProjects = (projectId, orderedIds) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/showcasedProject/reorder`, {
			orderedIds
		});
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchOptions = (params) => async (dispatch) => {
	await dispatch(actionTypes.optionsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const users = await axios.get('/api/user', { params });

		await dispatch(actionTypes.optionsFetchSuccess(users));
	} catch (e) {
		await dispatch(actionTypes.optionsFetchError(e));
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** CUSTOMERS / CONTACTS *****************

// eslint-disable-next-line max-len
export const fetchCustomers =
	(projectId, page, perPage, filtersData = {}) =>
	async (dispatch) => {
		if (!projectId) return;
		dispatch(actionTypes.customersFetchPending());
		const defaultPage = page || 1;
		const defaultPerPage = perPage || 25;
		const body = getBodyPaginate(
			{ page: defaultPage, perPage: defaultPerPage, filtersData },
			'contacts'
		);
		try {
			const customers = await axios.get(`/api/projects/${projectId}/contacts`, {
				params: body
			});
			await dispatch(actionTypes.customersFetchSuccess(customers));
		} catch (error) {
			dispatch(actionTypes.customersFetchError(error));
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Fetch Customers Error' }));
		}
	};

// eslint-disable-next-line max-len
export const fetchMergeContacts =
	(firstContactId, secondContactId, projectId, onSuccess) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await axios.put(`/api/projects/${projectId}/merge-contacts`, {
				firstContactId,
				secondContactId
			});
			await dispatch(actionTypes.setLoader(false));
			onSuccess();
		} catch (error) {
			const { type, message } = error.response.data;
			await dispatch(actionTypes.setLoader(false));
			await dispatch(
				actionTypes.showToast({
					type: type || 'error',
					body: message || 'Add Customer Error'
				})
			);
			throw error;
		}
	};

export const fetchCustomersAll =
	(projectId, filtersData = {}) =>
	async (dispatch) => {
		if (!projectId) return;
		await dispatch(actionTypes.setLoader(true));

		const body = getBodyPaginate({ filtersData }, 'contacts');
		try {
			const res = await axios.get(`/api/projects/${projectId}/contacts-all-export`, {
				params: body
			});
			await dispatch(actionTypes.setLoader(false));
			// eslint-disable-next-line
			return res.data.items;
		} catch (error) {
			await dispatch(actionTypes.setLoader(false));
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Fetch Customers Error' }));
			throw error;
		}
	};

export const fetchCustomersData =
	(projectId, page = 1, perPage = 25, filtersData = {}) =>
	async (dispatch) => {
		if (!projectId) return;
		// dispatch(actionTypes.customersFetchPending())

		const body = getBodyPaginate({ page, perPage, filtersData }, 'contacts');
		try {
			// eslint-disable-next-line consistent-return
			return await axios.get(`/api/projects/${projectId}/contacts`, {
				params: body
			});
		} catch (error) {
			dispatch(actionTypes.customersFetchError(error));
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Fetch Customers Error' }));
		}
	};

export const postCustomer = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const contact = await axios.post(`/api/projects/${projectId}/contacts`, data);
		await dispatch(actionTypes.setLoader(false));
		return contact;
	} catch (error) {
		const { type, message } = error.response.data;
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: type || 'error',
				body: message || 'Add Customer Error'
			})
		);
		throw error;
	}
};

export const fetchCustomerDetails = (projectId, customerId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.customerDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const details = await axios.get(`/api/projects/${projectId}/contacts/${customerId}`);
		dispatch(actionTypes.customerDetailsFetchSuccess(details));
		if (details?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(details?.data?.selectedProject));
		}
	} catch (error) {
		dispatch(actionTypes.customerDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteCustomers = (projectId, customerId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/contacts/${customerId}`, {
			data
		});

		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateCustomers = (projectId, customerId, data, onClose) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	const { favourites, ...rest } = data;
	try {
		await axios.put(`/api/projects/${projectId}/contacts/${customerId}`, rest);
	} catch (error) {
		const { type, message, id } = error.response.data;
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: type || 'error',
				body: message || 'Update Customer Error',
				id,
				onClose
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchCustomerNotes = (projectId, customerId) => async (dispatch) => {
	dispatch(actionTypes.customerNotesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const notes = await axios.get(`api/projects/${projectId}/${customerId}/contact-notes`);
		dispatch(actionTypes.customerNotesFetchSuccess(notes));
	} catch (error) {
		dispatch(actionTypes.customerNotesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchProjectContactNotes =
	(projectId, page, perPage, filtersData) => async (dispatch) => {
		dispatch(actionTypes.fetchProjectContactNotesPending());
		await dispatch(actionTypes.setLoader(true));
		const body = getBodyPaginate({ page, perPage, filtersData }, 'contactNotes');

		try {
			const res = await axios.get(`api/projects/${projectId}/contact-notes`, {
				params: body
			});
			dispatch(actionTypes.fetchProjectContactNotesSuccess(res.data));
		} catch (error) {
			dispatch(actionTypes.fetchProjectContactNotesError(error));
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchCampaignDetails = (projectId, campaignSibId) => async (dispatch) => {
	dispatch(actionTypes.campaignDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const { data } = await axios.get(`api/projects/${projectId}/campaign/${campaignSibId}`);
		dispatch(actionTypes.campaignDetailsFetchSuccess(data));
	} catch (error) {
		dispatch(actionTypes.campaignDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postCustomerNote = (projectId, customerId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.post(`/api/projects/${projectId}/${customerId}/contact-notes`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteCustomerNotes = (projectId, noteId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await axios.delete(`/api/projects/${projectId}/contact-notes/${noteId}`, {
			data
		});
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const editCustomerNote = (projectId, noteId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/contact-notes/${noteId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchCustomerMessages = (projectId, customerId) => async (dispatch) => {
	dispatch(actionTypes.customerMessagesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const { data: messages } = await axios.get(
			`api/projects/${projectId}/messages?userId=${customerId}`
		);
		dispatch(actionTypes.customerMessagesFetchSuccess(messages));
	} catch (error) {
		dispatch(actionTypes.customerMessagesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchFavourites = (projectId, hash) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.customerFetchFavouritesPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const favourites = await axios.get(`/api/projects/${projectId}/favourites/${hash}`);

		dispatch(actionTypes.customerFetchFavouritesSuccess(favourites));
	} catch (error) {
		dispatch(actionTypes.customerFetchFavouritesError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postFavourite = (projectId, customerId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.post(`/api/projects/${projectId}/contactFavourite/${customerId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const removeFavourite = (projectId, customerId, elem) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/contactFavourite/${customerId}`, elem);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** LOTS *****************

export const fetchLots = (projectId, params) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.lotsFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const lots = await axios.get(
			`/api/projects/${projectId}/lots${params ? `?${params}` : ''}`
		);
		dispatch(actionTypes.lotsFetchSuccess(lots));
	} catch (error) {
		dispatch(actionTypes.lotsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchLotsPaginate = (projectId, page, perPage, filtersData) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.lotsFetchPending());
	const body = getBodyPaginate({ page, perPage, filtersData }, 'lots');
	try {
		const lots = await axios.put(`/api/projects/${projectId}/lots-paginate`, {
			...body
		});
		dispatch(actionTypes.lotsPaginateFetchSuccess(lots));
	} catch (error) {
		dispatch(actionTypes.lotsFetchError(error));
	}
};

export const fetchLotsPaginateData =
	(projectId, page, perPage, filtersData) => async (dispatch) => {
		if (!projectId) return;
		const body = getBodyPaginate({ page, perPage, filtersData }, 'lots');
		try {
			// eslint-disable-next-line consistent-return
			return await axios.put(`/api/projects/${projectId}/lots-paginate`, {
				...body
			});
		} catch (error) {
			dispatch(actionTypes.lotsFetchError(error));
		}
	};

export const fetchReminderPaginateData =
	(projectId, page, perPage, filtersData) => async (dispatch) => {
		if (!projectId) return;
		const body = {
			...getBodyPaginate({ page, perPage, filtersData }, 'reminder'),
			allReminders: true
		};
		try {
			// eslint-disable-next-line consistent-return
			return await fetchRemindersPaginationApi(projectId, body);
		} catch (error) {
			dispatch(actionTypes.lotsFetchError(error));
		}
	};

export const fetchLotsAll =
	(projectId, filtersData, packageCheck, dwellingLots) => async (dispatch) => {
		if (!projectId) return;
		await dispatch(actionTypes.setLoader(true));
		const body = getBodyPaginate({ filtersData, packageCheck }, 'lots');
		try {
			const lots = await axios.put(`/api/projects/${projectId}/lots`, {
				...body,
				dwellingLots
			});
			await dispatch(actionTypes.setLoader(false));
			// eslint-disable-next-line
			return lots;
		} catch (error) {
			dispatch(actionTypes.lotsFetchError(error));
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchLotsGeo = (projectId) => async (dispatch) => {
	if (!projectId) return;
	await dispatch(actionTypes.setLoader(true));
	try {
		const lots = await axios.get(`/api/projects/${projectId}/lots-geo`);
		dispatch(actionTypes.lotsGeoFetchSuccess(lots));
	} catch (error) {
		console.log('error while fetching lots-geo', error);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchLotDetails = (projectId, lotId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.lotsDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const details = await axios.get(`/api/projects/${projectId}/lots/${lotId}`);
		dispatch(actionTypes.lotsDetailsFetchSuccess(details));
		if (details?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(details?.data?.selectedProject));
		}
	} catch (error) {
		dispatch(actionTypes.lotsDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postLot = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.post(`/api/projects/${projectId}/lots`, data);
	} catch (error) {
		dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.toString().includes('code 409')
					? 'Error: Given Lot number exists in our database!'
					: 'Error'
			})
		);
		throw await dispatch(actionTypes.setLoader(false));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateLot = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/lots/${id}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteLots = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/lots/${id}`, { data });
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchLotPackages = (projectId, lotId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.lotPackagesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const packages = await axios.get(`/api/projects/${projectId}/lot/${lotId}/packages`);
		dispatch(actionTypes.lotPackagesFetchSuccess(packages));
	} catch (error) {
		dispatch(actionTypes.lotPackagesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchLotHistory = (projectId, lotId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.lotsHistoryFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const lotsHistory = await axios.get(`/api/projects/${projectId}/lot-history/${lotId}`);
		dispatch(actionTypes.lotsHistoryFetchSuccess(lotsHistory));
	} catch (error) {
		dispatch(actionTypes.lotsHistoryFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchLotUsers = (projectId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.lotUsersFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const lotUsers = await axios.get(`/api/projects/${projectId}/lots-users`);
		dispatch(actionTypes.lotUsersFetchSuccess(lotUsers));
	} catch (error) {
		dispatch(actionTypes.lotUsersFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** BUILDERS *****************

export const fetchBuilders = (projectId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.buildersFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const builders = await axios.get(`/api/projects/${projectId}/builder`);
		dispatch(actionTypes.buildersFetchSuccess(builders));
	} catch (error) {
		dispatch(actionTypes.buildersFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuildersAll = (projectId, projects) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.buildersFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const builders = await axios.post(`/api/projects/${projectId}/builder-all`, {
			...projects
		});
		dispatch(actionTypes.buildersAllFetchSuccess(builders));
	} catch (error) {
		dispatch(actionTypes.buildersFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuilderById = (projectId, builderId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.builderByIdFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.get(`/api/projects/${projectId}/builder/${builderId}`);
		const { selectedProject, ...rest } = res.data;
		dispatch(actionTypes.builderByIdFetchSuccess(rest));
		if (selectedProject) {
			dispatch(actionTypes.changeSelectedProject(selectedProject));
		}
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Fetch Builder Error' }));
		dispatch(actionTypes.builderByIdFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postBuilder = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.post(`/api/projects/${projectId}/builder`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}

	await dispatch(actionTypes.setLoader(false));
};

export const deleteBuilder = (projectId, id) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.delete(`/api/projects/${projectId}/builder/${id}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Builder delete error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateBuilder = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/builder/${id}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Builder update error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuilderHouseTypes = (projectId, builderId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.buildersHouseTypesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const buildersHouseTypes = await axios.get(
			`/api/projects/${projectId}/builder-house-type/${builderId}`
		);
		dispatch(actionTypes.buildersHouseTypesFetchSuccess(buildersHouseTypes));
	} catch (error) {
		dispatch(actionTypes.buildersHouseTypesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuilderPackages = (projectId, builderId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.buildersPackagesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const buildersPackages = await axios.get(
			`/api/projects/${projectId}/builder-packages/${builderId}`
		);
		dispatch(actionTypes.buildersPackagesFetchSuccess(buildersPackages));
	} catch (error) {
		dispatch(actionTypes.buildersPackagesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuilderContacts = (projectId, builderId) => async (dispatch) => {
	if (!projectId) return;
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.buildersContactsFetchPending());

	try {
		const buildersContacts = await axios.get(
			`/api/projects/${projectId}/builder-contacts/${builderId}`
		);
		dispatch(actionTypes.buildersContactsFetchSuccess(buildersContacts));
	} catch (error) {
		dispatch(actionTypes.buildersContactsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateBuilderContacts = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/builder-contacts/${id}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const removeBuilderContacts = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.delete(`/api/projects/${projectId}/builder-contacts/${id}/${data}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchBuilderLots = (projectId, builderId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.buildersLotsFetchPending());

	try {
		const buildersLots = await axios.get(
			`/api/projects/${projectId}/builder-lots/${builderId}`
		);
		dispatch(actionTypes.buildersLotsFetchSuccess(buildersLots));
	} catch (error) {
		dispatch(actionTypes.buildersLotsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateBuilderLots = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/builder-lots/${id}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const removeBuilderLots = (projectId, lotId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.delete(`/api/projects/${projectId}/builder-lots/${lotId}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** USERS *****************

export const fetchUsers = (params) => async (dispatch) => {
	dispatch(actionTypes.usersFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const users = await axios.get('/api/user', { params });
		dispatch(actionTypes.usersFetchSuccess(users));
	} catch (error) {
		dispatch(actionTypes.usersFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUserDetails = (userId) => async (dispatch) => {
	dispatch(actionTypes.userDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const userDetails = await axios.get(`/api/user/${userId}`);
		dispatch(actionTypes.userDetailsFetchSuccess(userDetails));
	} catch (error) {
		dispatch(actionTypes.userDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postUser = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.post('/api/user', data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteUser = (id) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.delete(`/api/user/${id}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateUser = (id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/user/${id}`, data);
		await dispatch(fetchProjects());
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUserPackageHistory = (projectId, userId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.usersHistoryPackageFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const userHistory = await axios.get(
			`/api/projects/${projectId}/package-history/user/${userId}`
		);
		dispatch(actionTypes.usersHistoryPackageFetchSuccess(userHistory));
	} catch (error) {
		dispatch(actionTypes.usersHistoryPackageFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUserRows = (userId) => async (dispatch) => {
	dispatch(actionTypes.userRowsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const user = await axios.get(`/api/user/${userId}/rows`);
		dispatch(actionTypes.userRowsFetchSuccess(user));
	} catch (error) {
		dispatch(actionTypes.userRowsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUserShowSettled = (userId) => async (dispatch) => {
	dispatch(actionTypes.userSettledLotsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const user = await axios.get(`/api/user/${userId}/settled`);
		dispatch(actionTypes.userSettledLotsFetchSuccess(user.data.showSettledLots));
	} catch (error) {
		dispatch(actionTypes.userSettledLotsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUserShowInactiveUser = (userId) => async (dispatch) => {
	dispatch(actionTypes.userShowInactiveContactsFetchSuccess());
	await dispatch(actionTypes.setLoader(true));

	try {
		const user = await axios.get(`/api/user/${userId}/show-inactive-contacts`);
		dispatch(
			actionTypes.userShowInactiveContactsFetchSuccess(user.data.showInactiveUser || false)
		);
		dispatch(
			actionTypes.setCustomersFilters('inactiveContacts', user.data.showInactiveUser || false)
		);
	} catch (error) {
		dispatch(actionTypes.userShowInactiveContactsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateUserRows = (userId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/user/${userId}/rows`, data);
		dispatch(actionTypes.updateUserRows(data.userRows));
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** PACKAGES *****************

export const fetchPackages = (projectId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.packagesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const packages = await axios.get(`/api/projects/${projectId}/packages`);
		dispatch(actionTypes.packagesFetchSuccess(packages));
	} catch (error) {
		dispatch(actionTypes.packagesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchPackageById = (projectId, packageId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.packageByIdFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.get(`/api/projects/${projectId}/packages/${packageId}`);
		dispatch(actionTypes.packageByIdFetchSuccess(res.data.package));
		if (res?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(res?.data?.selectedProject));
		}
	} catch (error) {
		dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error || 'Fetch package error'
			})
		);
		dispatch(actionTypes.packageByIdFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const copyPackage = (projectId, lot, packageId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const copy = await axios.put(`/api/projects/${projectId}/copy-package`, {
			lot,
			packageId
		});
		if (copy === 'error') {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: 'this lot is already connected to the package'
				})
			);
			return copy;
		}
		return copy;
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: error }));
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const postPackage = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const packagePost = await axios.post(`/api/projects/${projectId}/packages`, data);
		return packagePost.data;
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.shortMessage ?? 'Error'
			})
		);
		throw error;
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const updatePackageDocument = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/package-update-docs/${id}`, {
			data
		});
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updatePackage = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/packages/${id}`, {
			...data
			// eslint-disable-next-line no-unneeded-ternary
			// enabled: data?.enabled ? true : false
		});
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const checkPackage = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const isPackage = await axios.post(`/api/projects/${projectId}/check-package/${id}`, {
			...data
		});
		await dispatch(actionTypes.setLoader(false));
		return isPackage;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const deletePackage = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/packages/${id}`, { data });
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message || 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchPackageHistory = (projectId, packageId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.packageHistoryFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const packageHistory = await axios.get(
			`/api/projects/${projectId}/package-history/${packageId}`
		);
		dispatch(actionTypes.packageHistoryFetchSuccess(packageHistory));
	} catch (error) {
		dispatch(actionTypes.packageHistoryFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const sendEmailPackage = (projectId, contactId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.post(`/api/projects/${projectId}/send-package/${contactId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** HOUSE TYPES *****************

export const fetchHouseTypes = (projectId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.houseTypesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const packages = await axios.get(`/api/projects/${projectId}/house-type`);
		dispatch(actionTypes.houseTypesFetchSuccess(packages));
	} catch (error) {
		dispatch(actionTypes.houseTypesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchHousePackages = (projectId, houseTypeId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.housePackagesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const packages = await axios.get(
			`/api/projects/${projectId}/house-type/${houseTypeId}/packages`
		);
		dispatch(actionTypes.housePackagesFetchSuccess(packages));
	} catch (error) {
		dispatch(actionTypes.housePackagesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const posthouseType = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.post(`/api/projects/${projectId}/house-type`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteHouseType = (projectId, packageId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/house-type/${packageId}`, {
			data
		});
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message || 'Error'
			})
		);
		throw error;
	}
	await dispatch(fetchHouseTypes(projectId));
	await dispatch(actionTypes.setLoader(false));
};

export const updateHouseType = (projectId, itemId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/house-type/${itemId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		// await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }))
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** MAP *****************

export const fetchPois = (projectId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.nearbyAndWhiterockFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const data = await axios.get(`/api/projects/${projectId}/points`);
		dispatch(actionTypes.nearbyAndWhiterockFetchSuccess(data));
	} catch (error) {
		dispatch(actionTypes.nearbyAndWhiterockFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchPoiById = (projectId, pointId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.nearbyByIdFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.get(`/api/projects/${projectId}/points/${pointId}`);
		dispatch(actionTypes.nearbyByIdFetchSuccess(res.data.point));
		if (res?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(res.data.selectedProject));
		}
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Fetch Point Error' }));
		dispatch(actionTypes.nearbyByIdFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postPoi = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const points = await axios.post(`/api/projects/${projectId}/points`, data);
		await dispatch(actionTypes.setLoader(false));
		return points;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
};

export const deleteNearbyAndWhiterock = (projectId, dataId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/points/${dataId}`, {
			data
		});
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateNearbyAndWhiterock = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/points/${id}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchPointCategories = (projectId) => async (dispatch) => {
	if (!projectId) return;
	await dispatch(actionTypes.setLoader(true));

	try {
		const categories = await axios.get(`/api/projects/${projectId}/point-category`);
		dispatch(actionTypes.fetchPointCategories(categories));
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deletePointCategory = (projectId, dataId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/point-category/${dataId}`, {
			data
		});
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postPointCategory = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const category = await axios.post(`/api/projects/${projectId}/point-category`, data);
		await dispatch(actionTypes.setLoader(false));
		return category;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
};

export const updatePointCategory = (projectId, categoryId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/point-category/${categoryId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** STAGES *****************

export const fetchStages = (projectId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.stagesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const stages = await axios.get(`/api/projects/${projectId}/stages`);
		dispatch(actionTypes.stagesFetchSuccess(stages));
	} catch (error) {
		dispatch(actionTypes.stagesFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchStagesDetails = (projectId, stageId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.stagesDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const stages = await axios.get(`/api/projects/${projectId}/stages/${stageId}`);
		dispatch(actionTypes.stagesDetailsFetchSuccess(stages));
		if (stages?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(stages?.data?.selectedProject));
		}
	} catch (error) {
		dispatch(actionTypes.stagesDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateStageDetails = (projectId, stageId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/stages/${stageId}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postStages = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.post(`/api/projects/${projectId}/stages`, data);
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
};

export const deleteStage = (projectId, stageId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const response = await axios.delete(`/api/projects/${projectId}/stages/${stageId}`, {
			data
		});
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const reorderStages = (projectId, stageIds) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/stages/reorder`, { stageIds });
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const activateAccount = (hash) => axios.get(`/api/user/activate/${hash}`);

export const fetchUserLotHistory = (projectId, userId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.usersHistoryLotFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const userHistory = await axios.get(
			`/api/projects/${projectId}/lot-history/user/${userId}`
		);
		dispatch(actionTypes.usersHistoryLotFetchSuccess(userHistory));
	} catch (error) {
		dispatch(actionTypes.usersHistoryLotFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUserSaleHistory = (projectId, userId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.usersHistorySaleFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const userSaleHistory = await axios.get(
			`/api/projects/${projectId}/sale-history/user/${userId}`
		);
		dispatch(actionTypes.usersHistorySaleFetchSuccess(userSaleHistory));
	} catch (error) {
		dispatch(actionTypes.usersHistorySaleFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

// **************** DOCUMENTS *****************

export const fetchDocumentsForEntity =
	(projectId, entityType, entityId, isSubEntity) => async (dispatch) => {
		dispatch(actionTypes.documentsFetchPending());
		await dispatch(actionTypes.setLoader(true));

		try {
			const { data } = await axios.get(
				`/api/projects/${projectId}/${entityEndpoints[entityType]}/${entityId}/documents`
			);
			if (isSubEntity) {
				dispatch(actionTypes.subDocumentsFetchSuccess(entityType, entityId, data));
			} else {
				dispatch(actionTypes.documentsFetchSuccess(entityType, entityId, data));
			}
		} catch (error) {
			dispatch(actionTypes.documentsFetchError(error));
			await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchAllDocuments = (projectId) => async (dispatch) => {
	dispatch(actionTypes.documentsAllFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const { data } = await axios.get(`/api/projects/${projectId}/documents`);
		dispatch(actionTypes.documentsAllFetchSuccess(data));
	} catch (error) {
		dispatch(actionTypes.documentsAllFetchError(error || 'Error Documents Fetch'));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error Documents Fetch' }));
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchDocumentTypesForEntityType = (projectId, entityType) => async (dispatch) => {
	dispatch(actionTypes.documentTypesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const { data } = await axios.get(
			`/api/projects/${projectId}/${entityEndpoints[entityType]}/documentTypes`
		);
		dispatch(actionTypes.documentTypesFetchSuccess(entityType, data));
	} catch (error) {
		dispatch(actionTypes.documentTypesFetchError(error));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchDocumentTypes = (projectId) => async (dispatch) => {
	dispatch(actionTypes.documentTypesFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const { data } = await axios.get(`/api/projects/${projectId}/documentTypes`);
		dispatch(actionTypes.documentTypesFetchSuccess(undefined, data));
	} catch (error) {
		dispatch(actionTypes.documentTypesFetchError(error));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
	}
	await dispatch(actionTypes.setLoader(false));
};
export const postDocumentType = (projectId, documentType) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await axios.post(`/api/projects/${projectId}/documentTypes`, {
			documentType
		});
		console.log('**** response', response);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		console.log('error', error?.response);
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const updateDocumentType = (projectId, documentTypeId, documentType) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await axios.put(
			`/api/projects/${projectId}/documentTypes/${documentTypeId}`,
			{ documentType }
		);
		console.log('**** response', response);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		console.log('error', error?.response);
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const deleteDocumentType = (projectId, documentTypeId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.delete(`/api/projects/${projectId}/documentTypes/${documentTypeId}`);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		console.log('error', error?.response);
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const fetchDocumentUploadSignature =
	(
		projectId,
		entityType,
		entityId,
		params
		// eslint-disable-next-line consistent-return
	) =>
	async (dispatch) => {
		try {
			const { data } = await axios.get(
				`/api/projects/${projectId}/${entityEndpoints[entityType]}/${entityId}/document/signUploadUrl`,
				{ params }
			);
			return data;
		} catch (error) {
			console.log('Error fetching signature');
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const postDocumentForEntity =
	(projectId, entityType, entityId, document) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			const response = await axios.post(
				`/api/projects/${projectId}/${entityEndpoints[entityType]}/${entityId}/document`,
				{ document }
			);
			await dispatch(actionTypes.setLoader(false));
			return response;
		} catch (error) {
			console.log('error', error?.response);
			await dispatch(actionTypes.setLoader(false));
			throw error;
		}
	};

export const uploadAndUpdateDocumentForEntity =
	(projectId, entityType, entityId, file, documentType) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			const url = `https://api.cloudinary.com/v1_1/${Config.CLOUDINARY_CLOUD}/auto/upload`;
			const instance = axios.create({
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			// eslint-disable-next-line
			const public_id = Date.now() + '-' + uuid();
			const timestamp = Math.round(Date.now() / 1000);
			const folder = `${entityType}/${entityId}/`;

			// get signature
			const response = await dispatch(
				fetchDocumentUploadSignature(projectId, entityType, entityId, {
					public_id,
					folder,
					timestamp
				})
			);
			const formData = new FormData();
			formData.append('public_id', public_id);
			formData.append('folder', folder);
			formData.append('signature', response.signature);
			formData.append('timestamp', timestamp);
			formData.append('api_key', Config.CLOUDINARY_API_KEY);
			formData.append('file', file);

			const { data } = await instance.post(url, formData);
			await dispatch(
				postDocumentForEntity(projectId, entityType, entityId, {
					displayName: file.name,
					type: documentType,
					visible: true,
					salesModeOnly: false,
					byteSize: file.size,
					fileName: file.name,
					url: data.secure_url,
					thumbnailUrl: data.secure_url.replace(
						'/upload/',
						'/upload/c_fill,h_100,w_200/'
					),
					format: data.format
				})
			);
			await dispatch(actionTypes.setLoader(false));
			return response;
		} catch (error) {
			console.log('error', error?.response);
			await dispatch(actionTypes.setLoader(false));
			throw error;
		}
	};

export const updateDocumentForEntity =
	(projectId, entityType, entityId, documentId, document) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			const response = await axios.put(
				`/api/projects/${projectId}/${entityEndpoints[entityType]}/${entityId}/documents/${documentId}`,
				{ document }
			);
			await dispatch(actionTypes.setLoader(false));
			return response;
		} catch (error) {
			console.log('error', error?.response);
			await dispatch(actionTypes.setLoader(false));
			throw error;
		}
	};
export const updateDocumentOrderForEntity =
	(projectId, entityType, entityId, documents) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			const response = await axios.put(
				`/api/projects/${projectId}/${entityEndpoints[entityType]}/${entityId}/documents/reorder`,
				{ documents }
			);
			await dispatch(actionTypes.setLoader(false));
			return response;
		} catch (error) {
			console.log('error', error?.response);
			await dispatch(actionTypes.setLoader(false));
			throw error;
		}
	};
export const deleteDocumentFromEntity =
	(projectId, entityType, entityId, documentId, data) => async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			const response = await axios.delete(
				`/api/projects/${projectId}/${entityEndpoints[entityType]}/${entityId}/documents/${documentId}`,
				{ data }
			);
			await dispatch(actionTypes.setLoader(false));
			await dispatch(
				actionTypes.showToast({
					type: 'success',
					body: response.data?.message ?? 'Your request has been successfully sent'
				})
			);
			return response;
		} catch (error) {
			await dispatch(actionTypes.setLoader(false));
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: error?.response?.data?.message ?? 'Error'
				})
			);
			throw error;
		}
	};

// *******************SALES******************
export const fetchSaleData =
	({ selectedProject, id, disable }) =>
	async (dispatch) => {
		console.log('thunk', selectedProject, id);
		const params = id || disable ? { params: { id, disable } } : null;
		dispatch(actionTypes.saleDataFetchPending());
		await dispatch(actionTypes.setLoader(true));

		try {
			const saleData = await axios.get(
				`/api/projects/${selectedProject}/saleData`,
				params && params
			);
			dispatch(actionTypes.saleDataFetchSuccess(saleData));
		} catch (error) {
			dispatch(actionTypes.saleDataFetchError(error));
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const postPurchaserContacts = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const saleCont = await axios.post(`/api/projects/${projectId}/saleContacts`, {
			saleContacts: data
		});
		await dispatch(actionTypes.setLoader(false));
		return saleCont;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
};

export const fetchSale = (projectId, data) => async (dispatch) => {
	dispatch(actionTypes.saleFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const sales = await axios.put(`/api/projects/${projectId}/sale`, data);
		dispatch(actionTypes.saleFetchSuccess(sales));
	} catch (error) {
		dispatch(actionTypes.saleFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchSalePaginate = (projectId, page, perPage, filtersData) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.saleFetchPending());

	const body = getBodyPaginate({ page, perPage, filtersData }, 'sales');
	if (body.stepValues?.length) {
		body.stepValues = body.stepValues.map(
			(item) => stepValues.find((el) => el.label === item).value
		);
	}
	try {
		const sales = await axios.put(`/api/projects/${projectId}/sale-paginate`, {
			...body
		});
		await dispatch(actionTypes.salePaginateFetchSuccess(sales));
	} catch (error) {
		dispatch(actionTypes.saleFetchError(error));
	}
};

export const fetchSaleItem = (projectId, saleId) => async (dispatch) => {
	dispatch(actionTypes.saleItemFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const saleItem = await axios.get(`/api/projects/${projectId}/sale/${saleId}`);
		dispatch(actionTypes.saleItemFetchSuccess(saleItem));
	} catch (error) {
		dispatch(actionTypes.saleItemFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchSaleDetails = (projectId, saleId) => async (dispatch) => {
	dispatch(actionTypes.saleDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const saleDetails = await axios.get(`/api/projects/${projectId}/saleDetails/${saleId}`);
		dispatch(actionTypes.saleDetailsFetchSuccess(saleDetails));
		if (saleDetails?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(saleDetails?.data?.selectedProject));
		}
	} catch (error) {
		dispatch(actionTypes.saleDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchCommissions = (projectId, data) => async (dispatch) => {
	dispatch(actionTypes.commissionsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const userId = data?.userId;
		if (userId) {
			// eslint-disable-next-line no-param-reassign
			delete data.userId;
		}
		const commissions = await axios.get(
			`/api/projects/${projectId}/commissions${userId ? `/${userId}` : ''}`,
			{ params: data }
		);
		dispatch(actionTypes.commissionsFetchSuccess(commissions));
	} catch (error) {
		dispatch(actionTypes.commissionsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchUsersCommissions = (projectId, settlementDate, userId) => async (dispatch) => {
	dispatch(actionTypes.usersCommissionsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const { data: commissions } = await axios.get(
			`/api/projects/${projectId}/users-commissions${userId ? `/${userId}` : ''}`,
			{
				params: settlementDate
			}
		);
		dispatch(actionTypes.usersCommissionsFetchSuccess(commissions));
	} catch (error) {
		dispatch(actionTypes.usersCommissionsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchCommissionsData =
	({ selectedProject }) =>
	async (dispatch) => {
		dispatch(actionTypes.commissionsDataFetchPending());
		await dispatch(actionTypes.setLoader(true));

		try {
			const { data } = await axios.get(`/api/projects/${selectedProject}/commissionsData`);
			dispatch(actionTypes.commissionsDataFetchSuccess(data));
		} catch (error) {
			dispatch(actionTypes.commissionsDataFetchError(error));
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchCommissionItem = (projectId, commissionId) => async (dispatch) => {
	dispatch(actionTypes.commissionsItemFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const commissionItem = await axios.get(
			`/api/projects/${projectId}/commission/${commissionId}`
		);
		dispatch(actionTypes.commissionsItemFetchSuccess(commissionItem.data));
	} catch (error) {
		dispatch(actionTypes.saleItemFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchCommissionDetails = (projectId, commissionId) => async (dispatch) => {
	dispatch(actionTypes.commissionsDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const commissionDetails = await axios.get(
			`/api/projects/${projectId}/saleDetails/${commissionId}`
		);
		dispatch(actionTypes.commissionsDetailsFetchSuccess(commissionDetails));
		if (commissionDetails?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(commissionDetails?.data?.selectedProject));
		}
	} catch (error) {
		dispatch(actionTypes.commissionsDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postCommission = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.post(`/api/projects/${projectId}/commission`, data);
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const putCommission = (projectId, commissionId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const resp = await axios.put(`/api/projects/${projectId}/commission/${commissionId}`, data);
		await dispatch(actionTypes.setLoader(false));
		return resp;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const deleteCommission = (projectId, commissionId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.delete(`/api/projects/${projectId}/commission/${commissionId}`, { data });
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const postSale = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.post(`/api/projects/${projectId}/sale`, data);
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const putSale = (projectId, saleId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const resp = await axios.put(`/api/projects/${projectId}/sale/${saleId}`, data);
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: resp.data?.message ?? 'Your request has been successfully sent'
			})
		);
		return resp;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
};

export const putSaleStep = (projectId, saleId, step) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const resp = await axios.put(`/api/projects/${projectId}/sale/change-step/${saleId}`, step);
		await dispatch(actionTypes.setLoader(false));
		return resp;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error while step changing'
			})
		);
		throw error;
	}
};

export const putSaleDetails = (projectId, saleId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.put(`/api/projects/${projectId}/saleDetails/${saleId}`, data);
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
};

export const deleteSale = (projectId, saleId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await axios.delete(`/api/projects/${projectId}/sale/${saleId}`, { data });
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchSaleProcess = (projectId, processId) => async (dispatch) => {
	dispatch(actionTypes.saleProcessFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const saleProcess = await axios.get(`/api/projects/${projectId}/sale/${processId}`);
		dispatch(actionTypes.saleProcessFetchSuccess(saleProcess));
	} catch (error) {
		dispatch(actionTypes.saleProcessFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const putSaleProcess = (projectId, processId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios
			.put(`/api/projects/${projectId}/process/${processId}`, data)
			.then((response) => response.data);
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const removeDepositPayment = (projectId, processId, paymentId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.delete(
			`/api/projects/${projectId}/sale-process/${processId}/payments/${paymentId}`
		);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const putTransfer = (projectId, saleId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const transfer = await axios
			.put(`/api/projects/${projectId}/transferSale/${saleId}`, data)
			.then((response) => response.data);
		await dispatch(actionTypes.setLoader(false));
		return transfer;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const fetchPaymentsPaginate =
	(projectId, page, perPage, filtersData) => async (dispatch) => {
		dispatch(actionTypes.paymentsFetchPaginatePending());
		await dispatch(actionTypes.setLoader(true));

		try {
			const body = getBodyPaginate({ page, perPage, filtersData }, 'payments');
			const paymentsPaginate = await axios.put(
				`/api/projects/${projectId}/payments-paginate`,
				{ ...body }
			);
			dispatch(actionTypes.paymentsFetchPaginateSuccess(paymentsPaginate));
		} catch (error) {
			dispatch(actionTypes.paymentsFetchPaginateError(error));
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchPayments = (projectId) => async (dispatch) => {
	dispatch(actionTypes.paymentsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const payments = await axios.get(`/api/projects/${projectId}/paymentList`);
		dispatch(actionTypes.paymentsFetchSuccess(payments));
	} catch (error) {
		dispatch(actionTypes.paymentsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactPayments = (projectId, contactId) => async (dispatch) => {
	dispatch(actionTypes.contactPaymentsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const contactPayments = await axios.get(
			`/api/projects/${projectId}/paymentList/${contactId}`
		);
		dispatch(actionTypes.contactPaymentsFetchSuccess(contactPayments));
	} catch (error) {
		dispatch(actionTypes.contactPaymentsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchSaleHistory = (projectId, saleId) => async (dispatch) => {
	if (!projectId) return;
	dispatch(actionTypes.salesHistoryFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const salesHistory = await axios.get(`/api/projects/${projectId}/sale-history/${saleId}`);
		dispatch(actionTypes.salesHistoryFetchSuccess(salesHistory));
	} catch (error) {
		dispatch(actionTypes.salesHistoryFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

// **********************CONTACT GROUPS*****************

export const postContactGroup = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		const res = await axios.post(`/api/projects/${projectId}/contact-groups`, data);
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
};

export const fetchContactGroups = (projectId, flat, contactCount) => async (dispatch) => {
	dispatch(actionTypes.contactGroupsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const contactGroups = await axios.get(`/api/projects/${projectId}/contact-groups`, {
			params: { flat, contactCount }
		});
		dispatch(actionTypes.contactGroupsFetchSuccess(contactGroups));
	} catch (error) {
		dispatch(actionTypes.contactGroupsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactGroupDetails = (projectId, contactGroupId) => async (dispatch) => {
	dispatch(actionTypes.contactGroupDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const contactGroupDetails = await axios.get(
			`/api/projects/${projectId}/contact-groups/${contactGroupId}`
		);
		dispatch(actionTypes.contactGroupDetailsFetchSuccess(contactGroupDetails));
	} catch (error) {
		dispatch(actionTypes.contactGroupDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactGroupModalDetails = (projectId, contactGroupId) => async (dispatch) => {
	dispatch(actionTypes.contactGroupDetailsModalFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const contactGroupDetailsModal = await axios.get(
			`/api/projects/${projectId}/contact-groups-modal/${contactGroupId}`
		);
		dispatch(actionTypes.contactGroupDetailsModalFetchSuccess(contactGroupDetailsModal));
	} catch (error) {
		dispatch(actionTypes.contactGroupDetailsModalFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateGroupContacts = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.put(`/api/projects/${projectId}/contact-groups/${id}`, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteContactGroups = (projectId, contactGroupId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await axios.delete(
			`/api/projects/${projectId}/contact-groups/${contactGroupId}`,
			{ data }
		);
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message || 'Error deleting Contact Group'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteGroupContact = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await axios.delete(`/api/projects/${projectId}/contact-groups/${id}/${data}`);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const replyAction = (projectId, id, data) => async (dispatch) => {
	await dispatch(actionTypes.replyActionPending());

	try {
		const result = await replyActionApi(projectId, id, data);
		dispatch(actionTypes.replyActionSuccess(result.data));
	} catch (error) {
		await dispatch(actionTypes.replyActionError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message ?? 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};
