import polylabel from 'polylabel';

export const getLots = (state) => state.lots.lots;
export const getLotsPending = (state) => state.lots.pending;
export const getLotUsers = (state) => state.lots.lotUsers;
export const getLotsGeo = (state) =>
	(state.lots.lotsGeo &&
		state.lots.lotsGeo.data &&
		state.lots.lotsGeo.data.items
			.filter((item) => item.geo)
			.map((item) => ({ ...item.geo }))) ||
	[];

export const getLotsGeoNumbers = (state) =>
	(state.lots.lotsGeo &&
		state.lots.lotsGeo.data &&
		state.lots.lotsGeo.data.items
			.filter((item) => item.geo)
			.map((item) => ({
				type: 'Feature',
				properties: {
					description: item.humanId
				},
				geometry: {
					type: 'Point',
					coordinates:
						item.geo.geometry.type === 'Point'
							? item.geo.geometry.coordinates
							: polylabel(item.geo.geometry.coordinates[0], 1.0)
				}
			}))) ||
	[];

export const getLotsHistory = (state) => state.lots.history && state.lots.history.data.items;

export const getLotDetails = (state) => state.lots.lotDetails && state.lots.lotDetails.data;

export const getLotPagination = (state) => state.lots.lotPagination && state.lots.lotPagination;

export const getLotFilters = (state) => state.lots.lotFilters && state.lots.lotFilters;
