import { colors } from '@material-ui/core';

export default {
	root: {
		fontSize: 'inherit',
		margin: '0 2px',
		minWidth: '0',
		lineHeight: '100%',
		padding: '5px 10px'
	},
	contained: {
		boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
		backgroundColor: colors.grey[100],
		'&:hover': {
			backgroundColor: colors.grey[300]
		}
	},
	outlined: {
		padding: '5px 10px'
	},
	sizeSmall: {
		padding: '5px 10px',
		margin: '-2px 0px'
	},
	outlinedSizeSmall: {
		padding: '5px 10px',
		margin: '-2px 0px'
	},
	containedSizeSmall: {
		padding: '5px 10px',
		margin: '-2px 0px'
	}
};
