export const getSaleStats = (state) => state.dashboard.saleStats;
export const getPastSettlements = (state) => state.dashboard.pastSettlements;
export const getDueCommissionsStats = (state) => state.dashboard.dueCommissionsStats;
export const getOutstandingContracts = (state) => state.dashboard.outstandingContracts;
export const getSaleStatuses = (state) => state.dashboard.saleStatuses;
export const getOverdueDeposits = (state) => state.dashboard.overdueDeposits;
export const getConditionalContracts = (state) => state.dashboard.conditionalContracts;
export const getUpcomingOverdueSunsetDates = (state) => state.dashboard.sunsetDates;
export const getOverdueConditions = (state) => state.dashboard.overdueConditions;
export const getSettlementDates = (state) => state.dashboard.settlementDates;
export const getRemindersDashboard = (state) => state.dashboard.remindersDashboard;
export const getNewEnquiries = (state) => state.dashboard.newEnquiries;
export const getRepeatEnquiry = (state) => state.dashboard.repeatEnquiry;
export const getFullDepositPaid = (state) => state.dashboard.fullDepositPaid;
export const getContractsExchange = (state) => state.dashboard.contractsExchange;
export const getCanceledSales = (state) => state.dashboard.canceledSales;
export const getWalkInStats = (state) => state.dashboard.walkIn;
export const getDashboardSettings = (state) => state.dashboard.dashboardSettings.data;
export const getByRoleDashboardSettings = (state) => state.dashboard.dashboardSettings.byRole;
export const getWeekSales = (state) => state.dashboard.weekSales;
export const getWeekSignedContracts = (state) => state.dashboard.weekSignedContracts;
export const getWeekEnquires = (state) => state.dashboard.weekEnquires;
export const getWeekCancelations = (state) => state.dashboard.weekCancelations;
export const getWeekOverdueDeposits = (state) => state.dashboard.weekOverdueDeposits;
export const getWeekContactNotes = (state) => state.dashboard.weekContactNotes;
export const getWeekToBeSignedContracts = (state) => state.dashboard.weekToBeSignedContracts;
export const getProjectStatuses = (state) => state.dashboard.projectStatuses;
export const getListOverdueDeposits = (state) => state.dashboard.listOverdueDeposits;
export const getListToBeSignedContracts = (state) => state.dashboard.listToBeSignedContracts;
export const getOverdueContracts = (state) => state.dashboard.overdueContracts;
export const getLotStatus = (state) => state.dashboard.lotStatusByProject;
export const getUpcomingSettlements = (state) => state.dashboard.upcomingSettlements;
