/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from 'src/actions';

export const putTransferPurchaser = (projectId, saleId, values) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const transfer = await axios
			.put(`/api/projects/${projectId}/transfer-sale-purchaser/${saleId}`, values)
			.then((response) => response.data);
		await dispatch(actionTypes.setLoader(false));
		return transfer;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const addNewPurchasers = (projectId, saleId, values) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const transfer = await axios
			.post(`/api/projects/${projectId}/purchaser-contacts/${saleId}`, { ...values })
			.then((response) => response.data);
		await dispatch(actionTypes.setLoader(false));
		return transfer;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
};

export const saleProcessEdit = (projectId, processId, values) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await axios.put(`/api/projects/${projectId}/process-edit/${processId}`, values);
		await dispatch(
			actionTypes.showToast({
				body: 'Successfully updated'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response.data.message || 'Error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactDropdownRequest =
	({ selectedProject, ...rest }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		await dispatch(actionTypes.saleContactDropdownPending());
		try {
			const contactDropdown = await axios.get(
				`/api/projects/${selectedProject}/contact-dropdown`,
				{ params: { ...rest } }
			);
			await dispatch(actionTypes.saleContactDropdownSuccess(contactDropdown));
		} catch (error) {
			await dispatch(actionTypes.setLoader(false));
			await dispatch(actionTypes.saleContactDropdownError(error));
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: error.response.data.message || 'Error'
				})
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};
