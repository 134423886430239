import {
	createOptionsList,
	duplicateOptionsList,
	fetchOptionsList,
	removeOptionsList,
	updateOptionsList
} from 'src/api/optionsListsApi';
import * as actionTypes from '../actions';

export const fetchOptionsListRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.optionsListsFetchPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const options = await fetchOptionsList(data);
		await dispatch(actionTypes.optionsListsFetchSuccess(options));
	} catch (error) {
		await dispatch(actionTypes.optionsListsFetchError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Options Lists error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const createOptionsListRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await createOptionsList(data);
	} catch (e) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Create Options List error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const createDuplicateOptionsListRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	const { projectId } = data;
	const prodId = { projectId };
	try {
		await duplicateOptionsList(data);
		const options = await fetchOptionsList(prodId);
		await dispatch(actionTypes.optionsListsFetchSuccess(options));
	} catch (e) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'This name already exist'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateOptionsListRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await updateOptionsList(data);
	} catch (e) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Update Options List error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const removeOptionsListRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await removeOptionsList(data);
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.response?.data?.message || 'Remove Options List error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};
