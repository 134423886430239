/* eslint-disable max-len */
/* eslint-disable prefer-template */
export const getAllProjects = (state) => state.project.projects && state.project.projects.data;

export const getAllProjectsPending = (state) => state.project.pending;

export const getProject = (state) =>
	state.project.projects &&
	state.project.projects.data.map((project) => ({
		...project,
		fullLocation:
			project.location.street +
			', ' +
			project.location.suburb +
			' ' +
			project.location.state +
			' ' +
			project.location.postCode
	}));

export const getSelectedProject = (state) => state.project.selectedProject;
export const getProjectDetails = (state) =>
	state.project.projects &&
	state.project.projects.data.find((project) => project.id === state.project.selectedProject);

export const getProjectsNames = (state) =>
	state.project.projects && state.project.projects.data.map((item) => item.name);

export const getProjectsColors = (state) =>
	state.project.projects &&
	state.project.projects.data.map((item) => item.branding.primaryColor || '#3f51b5');

export const getOptions = (state) => state.project.options && state.project.options.data;
