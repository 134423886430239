/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from 'src/actions';

export const fetchSideMenu = (userId) => async (dispatch) => {
	dispatch(actionTypes.sideMenuFetchPending());
	try {
		const { data } = await axios.get(`/api/side-menu/${userId}`);
		dispatch(actionTypes.sideMenuFetchSuccess(data));
	} catch (error) {
		dispatch(actionTypes.sideMenuFetchError(error?.response?.data?.message || error.message));
		dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message || error.message
			})
		);
	}
};

export const updateSideMenu = (userId, data) => async (dispatch) => {
	try {
		const {
			data: { msg }
		} = await axios.put(`/api/side-menu/${userId}`, { data });
		dispatch(
			actionTypes.showToast({
				body: msg
			})
		);
		dispatch(fetchSideMenu(userId));
	} catch (error) {
		dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message || error.message
			})
		);
	}
};

export const defaultSideMenu = (userId) => async (dispatch) => {
	try {
		const {
			data: { msg }
		} = await axios.delete(`/api/side-menu/${userId}`);
		dispatch(
			actionTypes.showToast({
				body: msg
			})
		);
		dispatch(fetchSideMenu(userId));
	} catch (error) {
		dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message || error.message
			})
		);
	}
};
