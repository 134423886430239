import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	contactsPending: false,
	error: null,
	contacts: null,
	customers: null,
	customersPagination: null,
	customersFilters: {
		filters: {},
		sort: {},
		search: '',
		hotLeads: false,
		inactiveContacts: false
	},
	details: null,
	notes: null,
	favourites: null,
	contactGroups: null,
	contactGroupsList: null,
	contactGroupDetails: null,
	contactGroupDetailsModal: null,
	linkedContact: null,
	linkedNotes: null,
	projectContactNotes: null,
	projectContactNotesPagination: null,
	notesFilters: {
		filters: {},
		sort: {},
		search: ''
	},
	messages: null,
	messagesFilters: {
		filters: {},
		sort: {},
		search: ''
	}
};

const customerReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CUSTOMERS_FETCH_PENDING: {
			return {
				...state,
				contactsPending: true
			};
		}

		case actionTypes.CUSTOMERS_FETCH_ERROR: {
			return {
				...state,
				contactsPending: false,
				error: action.error
			};
		}

		case actionTypes.CUSTOMERS_FETCH_SUCCESS: {
			return {
				...state,
				contactsPending: false,
				customers: action.customers,
				customersPagination: action.customers.data.pagination,
				error: null
			};
		}

		case actionTypes.SET_CUSTOMERS_FILTERS: {
			return {
				...state,
				customersFilters: {
					...state.customersFilters,
					[action.filterType]: action.data
				}
			};
		}

		case actionTypes.SET_MULTIPLE_CUSTOMERS_FILTERS: {
			return {
				...state,
				customersFilters: {
					...state.customersFilters,
					...action.data
				}
			};
		}

		case actionTypes.CLEAR_CUSTOMERS_FILTERS: {
			return {
				...state,
				customersFilters: {
					filters: {},
					sort: {},
					search: '',
					hotLeads: false,
					inactiveContacts: false
				}
			};
		}

		case actionTypes.CUSTOMER_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CUSTOMER_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				details: action.details,
				error: null
			};
		}

		case actionTypes.CUSTOMER_MESSAGES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CUSTOMER_MESSAGES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CUSTOMER_MESSAGES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				messages: action.messages,
				error: null
			};
		}

		case actionTypes.CUSTOMER_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.CUSTOMER_NOTES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.CUSTOMER_NOTES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				notes: action.notes,
				error: null
			};
		}
		case actionTypes.CUSTOMER_NOTES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CUSTOMER_FETCH_FAVOURITES_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CUSTOMER_FETCH_FAVOURITES_SUCCESS: {
			return {
				...state,
				pending: false,
				favourites: action.favourites,
				error: null
			};
		}

		case actionTypes.CUSTOMER_FETCH_FAVOURITES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CONTACT_GROUPS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CONTACT_GROUPS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				contactGroups: action.contactGroups,
				error: null
			};
		}

		case actionTypes.CONTACT_GROUPS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CONTACT_GROUPS_LIST_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CONTACT_GROUPS_LIST_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				contactGroupsList: action.contactGroupsList,
				error: null
			};
		}

		case actionTypes.CONTACT_GROUPS_LIST_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CONTACT_GROUP_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CONTACT_GROUP_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				contactGroupDetails: action.contactGroupDetails,
				error: null
			};
		}

		case actionTypes.CONTACT_GROUP_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CONTACT_GROUP_DETAILS_MODAL_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CONTACT_GROUP_DETAILS_MODAL_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				contactGroupDetailsModal: action.contactGroupDetailsModal,
				error: null
			};
		}

		case actionTypes.CONTACT_GROUP_DETAILS_MODAL_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.CONTACT_RELATIONS_LINKING_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.CONTACT_RELATIONS_LINKING_SUCCESS: {
			return {
				...state,
				pending: false,
				error: null,
				linkedContacts: action.data
			};
		}
		case actionTypes.CONTACT_RELATIONS_LINKING_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LINKED_CONTACTS_NOTES_FETCH_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.LINKED_CONTACTS_NOTES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				linkedNotes: action.data.data
			};
		}
		case actionTypes.LINKED_CONTACTS_NOTES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.CAMPAIGN_FETCH_PENDING: {
			return {
				...state,
				pending: true,
				campaignDetails: null,
				error: null
			};
		}
		case actionTypes.CAMPAIGN_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				campaignDetails: action.campaignDetails,
				error: null
			};
		}
		case actionTypes.CAMPAIGN_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LINKED_CONTACT_ID: {
			return {
				...state,
				linkedContact: action.id
			};
		}

		case actionTypes.CONTACT_DETAILS_RESET: {
			return {
				...state,
				details: null
			};
		}

		case actionTypes.CONTACT_GROUP_RESET: {
			return {
				...state,
				contactGroupDetails: null
			};
		}

		case actionTypes.FETCH_ALL_CONTACTS_PENDING: {
			return {
				...state,
				contactsPending: true
			};
		}

		case actionTypes.FETCH_ALL_CONTACTS_SUCCESS: {
			return {
				...state,
				contactsPending: false,
				contacts: action.contacts
			};
		}

		case actionTypes.FETCH_ALL_CONTACTS_ERROR: {
			return {
				...state,
				contactsPending: false,
				error: action.error
			};
		}

		case actionTypes.FETCH_PROJECT_CONTACT_NOTES_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_PROJECT_CONTACT_NOTES_SUCCESS: {
			return {
				...state,
				pending: false,
				projectContactNotes: action.items,
				projectContactNotesPagination: action.pagination,
				error: null
			};
		}
		case actionTypes.FETCH_PROJECT_CONTACT_NOTES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SET_NOTES_FILTERS: {
			return {
				...state,
				notesFilters: {
					...state.notesFilters,
					[action.filterType]: action.data
				}
			};
		}
		case actionTypes.CLEAR_NOTES_FILTERS: {
			return {
				...state,
				notesFilters: {
					filters: {},
					sort: {},
					search: ''
				}
			};
		}

		case actionTypes.SET_CUSTOMER_MESSAGES_FILTERS: {
			return {
				...state,
				messagesFilters: {
					...state.messagesFilters,
					[action.filterType]: action.data
				}
			};
		}

		case actionTypes.CLEAR_CUSTOMER_MESSAGES_FILTERS: {
			return {
				...state,
				messagesFilters: {
					filters: {},
					sort: {},
					search: ''
				}
			};
		}

		case actionTypes.UPDATE_CONTACT_DETAILS: {
			return {
				...state,
				details: {
					...state.details,
					...{
						data: {
							...state.details.data,
							contact: {
								...state.details.data.contact,
								...action.details
							}
						}
					}
				}
			};
		}

		default: {
			return state;
		}
	}
};

export default customerReducer;
