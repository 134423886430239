import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

function TooltipBlock({ title, position }) {
	return (
		<Tooltip
			title={title}
			arrow
			placement={position || 'top'}
		>
			<InfoOutlinedIcon style={{ fontSize: 15 }} />
		</Tooltip>
	);
}

TooltipBlock.propTypes = {
	title: PropTypes.any,
	position: PropTypes.string
};

export default TooltipBlock;
