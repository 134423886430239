import * as actionTypes from '../actions';

const initialState = {
	dapUsers: null,
	dapUserDetails: null,
	pending: false,
	error: false
};

const dapReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DAP_USERS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.DAP_USERS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				dapUsers: action.dapUsers,
				error: null
			};
		}

		case actionTypes.DAP_USERS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.DAP_USER_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.DAP_USER_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				dapUserDetails: action.dapUserDetails,
				error: null
			};
		}

		case actionTypes.DAP_USER_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.DAP_USER_DETAILS_RESET: {
			return {
				...state,
				dapUserDetails: null
			};
		}

		default: {
			return state;
		}
	}
};

export default dapReducer;
