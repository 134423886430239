import axios from 'axios';

export const fetchBuilderPortalContacts = ({ selectedProject }) =>
	axios.get(`/api/projects/${selectedProject}/builder-login`);

export const createBuilderUser = ({ selectedProject, ...rest }) =>
	axios.post(`/api/projects/${selectedProject}/builder-login`, { ...rest });

export const fetchBuilderPortalUsers = ({ selectedProject }) =>
	axios.get(`/api/projects/${selectedProject}/builder-users`);

export const fetchBuilderPortalUser = ({ selectedProject, id }) =>
	axios.get(`/api/projects/${selectedProject}/builder-user/${id}`);

export const fetchBuilderUserApp = ({ selectedProject, id }) =>
	axios.get(`/api/projects/${selectedProject}/builder-user-app/${id}`);

export const fetchDocumentTracking = ({ selectedProject }) =>
	axios.get(`/api/projects/${selectedProject}/document-tracking`);

export const updateBuilderUser = ({ selectedProject, id, ...rest }) =>
	axios.put(`/api/projects/${selectedProject}/builder-user/${id}`, { ...rest, selectedProject });

export const removeBuilderUser = (projectId, userId) =>
	axios.delete(`/api/projects/${projectId}/builder-user/${userId}`);

export const changeBuilderUserPasswordApi = (data, userId) =>
	axios.put(`/api/portal/password/${userId}`, data);

export const registrationBuilderUserApi = ({ data }) =>
	axios.post('/api/portal/manual-register', { ...data });

export const resendVerificationEmailApi = (userId, data) =>
	axios.post(`/api/portal/resend-verification/${userId}`, { ...data });
