import axios from 'axios';

export const postDapUserApi = (data) => axios.post('/api/dap-user', data);

export const fetchDapUsersApi = (projectId) => axios.get(`/api/dap-user/${projectId}/users`);

export const fetchAllDapUsersApi = (projectId, projects) =>
	axios.post(`/api/dap-user/${projectId}/users-all`, projects);

export const fetchDapUserDetailsApi = (userId) => axios.get(`/api/dap-user/${userId}`);

export const updateDapUserApi = (userId, data) => axios.put(`/api/dap-user/${userId}`, data);

export const changeDapUserPasswordApi = (data, userId) =>
	axios.put(`/api/dap-user/password/${userId}`, data);
