export const GET_ROLES_PENDING = 'GET_ROLES_PENDING';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const GET_SETTINGS_PENDING = 'GET_SETTINGS_PENDING';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';

export const CHANGE_ROLES_PENDING = 'CHANGE_ROLES_PENDING';
export const CHANGE_ROLES_SUCCESS = 'CHANGE_ROLES_SUCCESS';
export const CHANGE_ROLES_ERROR = 'CHANGE_ROLES_ERROR';

export const SET_ROLES = 'SET_ROLES';
export const CHANGE_ROLE = 'CHANGE_ROLE';

export const fetchRolesPending = () => ({
	type: GET_ROLES_PENDING
});

export const fetchRolesSuccess = (roles) => ({
	type: GET_ROLES_SUCCESS,
	roles
});

export const fetchRolesError = (error) => ({
	type: GET_ROLES_ERROR,
	error
});

export const fetchSettingsPending = () => ({
	type: GET_SETTINGS_PENDING
});

export const fetchSettingsSuccess = (settings) => ({
	type: GET_SETTINGS_SUCCESS,
	settings
});

export const fetchSettingsError = (error) => ({
	type: GET_SETTINGS_ERROR,
	error
});

export const changeRolesPending = () => ({
	type: CHANGE_ROLES_PENDING
});

export const changeRolesSuccess = () => ({
	type: CHANGE_ROLES_SUCCESS
});

export const changeRolesError = (error) => ({
	type: CHANGE_ROLES_ERROR,
	error
});

export const setRoles = (rolesData) => ({
	type: SET_ROLES,
	rolesData
});

export const changeRoles = (data) => ({
	type: CHANGE_ROLE,
	data
});
