import React, { useEffect, createContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PublicClientApplication } from '@azure/msal-browser';
import { getUserDetails } from 'src/api/GraphServiceApi';
import * as actionTypes from 'src/actions';
import { store } from 'src/App';
import { rejectUserAuth, showToast } from 'src/actions';
import { Config } from './config';

export const config = {
	appId: Config.AZURE_APP_ID,
	redirectUri: `${Config.APP_URL}/admin/dashboard`,
	scopes: [
		'user.read',
		'mailboxsettings.read',
		'mail.read',
		'Mail.Read.Shared',
		'Mail.Send.Shared',
		'Mail.ReadWrite.Shared',
		'calendars.readwrite',
		'User.Read.All',
		'Mail.Send',
		'Mail.ReadWrite'
	]
};

export const defaultCurrentWeek = {
	startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
	endDate: moment().startOf('day').add(7, 'day').format('YYYY-MM-DD HH:mm')
};

const publicClientApplication = new PublicClientApplication({
	auth: {
		clientId: config.appId,
		redirectUri: config.redirectUri
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true
	}
});

const isInteractionRequired = (error) => {
	if (!error.message || error.message.length <= 0) {
		return false;
	}
	return (
		error.message.indexOf('consent_required') > -1 ||
		error.message.indexOf('interaction_required') > -1 ||
		error.message.indexOf('login_required') > -1 ||
		error.message.indexOf('no_account_in_silent_request') > -1
	);
};

const getAccessToken = async () => {
	try {
		const accounts = publicClientApplication.getAllAccounts();

		if (accounts.length <= 0) throw new Error('login_required');
		const silentResult = await publicClientApplication.acquireTokenSilent({
			scopes: config.scopes,
			account: accounts[0]
		});
		localStorage.setItem('accessMicrosoftToken', silentResult.accessToken);
		return silentResult.accessToken;
	} catch (error) {
		if (isInteractionRequired(error)) {
			try {
				const interactiveResult = await publicClientApplication.acquireTokenPopup({
					scopes: config.scopes
				});
				localStorage.setItem('accessMicrosoftToken', interactiveResult.accessToken);
				return interactiveResult.accessToken;
			} catch (e) {
				store.dispatch(showToast({ body: e.message, type: 'error' }));
				throw e;
			}
		}
	}
};

const getUserProfile = async () => {
	try {
		if (store.getState().outlook.rejectUserAuth) {
			return { user: null, accessToken: null };
		}
		const accessToken = await getAccessToken();
		const user = await getUserDetails(accessToken);
		return { user, accessToken };
	} catch (error) {
		if (error.errorCode === 'user_cancelled') {
			store.dispatch(rejectUserAuth(true));
		}
		return null;
	}
};

const login = () => async (dispatch) => {
	try {
		await publicClientApplication.loginPopup({
			scopes: config.scopes,
			redirectUri: config.redirectUri,
			prompt: 'select_account'
		});
		await getAccessToken();
		await dispatch(rejectUserAuth(false));
		await dispatch(actionTypes.setMicrosoftLogin(true));
		await dispatch(actionTypes.showToast({ type: 'success', body: 'Successfully login' }));
	} catch (error) {
		await dispatch(
			actionTypes.showToast({ type: 'error', body: error?.message || 'An error has occured' })
		);
	}
};

const logout = () => {
	publicClientApplication.logoutPopup();
};

export const ContextProvider = createContext();

function AzureProvider({ children }) {
	useEffect(() => {
		// If MSAL already has an account, the user is already logged in
		const accounts = publicClientApplication.getAllAccounts();
		if (accounts && accounts.length > 0) {
			getAccessToken();
		}
	}, []);
	return (
		<ContextProvider.Provider
			value={{
				login,
				logout,
				getAccessToken,
				getUserProfile
			}}
		>
			{children}
		</ContextProvider.Provider>
	);
}

AzureProvider.propTypes = {
	children: PropTypes.node
};

export default AzureProvider;
