class Configuration {
	APP_VERSION = this.cleanEnvVariable(process.env.REACT_APP_VERSION);

	APP_VERSION_HASH = this.cleanEnvVariable(process.env.REACT_APP_VERSION_HASH);

	API_BASE_URL = this.cleanEnvVariable(process.env.REACT_APP_API_BASE_URL);

	APP_URL = this.cleanEnvVariable(process.env.REACT_APP_URL);

	MAP_URL = this.cleanEnvVariable(process.env.REACT_MAP_URL);
	BUILDER_PORTAL_URL = this.cleanEnvVariable(process.env.REACT_APP_BUILDER_LINK);

	CLOUDINARY_CLOUD_NAME = this.cleanEnvVariable(process.env.REACT_APP_CLOUDINARY_CLOUD);

	CLOUDINARY_API_KEY = this.cleanEnvVariable(process.env.REACT_APP_CLOUDINARY_API_KEY);

	MAPBOX_TOKEN = this.cleanEnvVariable(process.env.REACT_APP_MAPBOX_TOKEN);

	MAPBOX_STYLE_URL = this.cleanEnvVariable(process.env.REACT_APP_MAPBOX_STYLE_URL);

	GOOGLE_AUTOCOMPLETE_API_KEY = this.cleanEnvVariable(
		process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY
	);

	GOOGLE_ANALYTICS_MEASUREMENT_ID = this.cleanEnvVariable(
		process.env.REACT_APP_GA_MEASUREMENT_ID
	);

	AZURE_APP_ID = this.cleanEnvVariable(process.env.REACT_APP_AZURE_APP_ID);

	STAGING = this.cleanEnvVariable(process.env.REACT_APP_STAGING) === 'true';
	constructor() {}

	cleanEnvVariable(envVar) {
		if (!envVar) return '';
		return envVar.replace(/^"|"$/g, '');
	}
}

export const Config = new Configuration();
