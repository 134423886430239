import {
	createReminder,
	updateSyncReminder,
	fetchReminders,
	fetchReminderItem,
	fetchReminderElem,
	deleteReminder,
	updateReminder,
	completeReminders,
	fetchRemindersByIdsApi,
	fetchRemindersPaginationApi,
	fetchSaleReminderApi
} from 'src/api/reminderApi';
import { getBodyPaginate } from 'src/utils';
import * as actionTypes from '../actions';

export const createReminderRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.createReminderPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const res = await createReminder(data);
		await dispatch(actionTypes.createReminderSuccess());
		await dispatch(actionTypes.setLoader(false));
		return res;
	} catch (error) {
		await dispatch(actionTypes.createReminderError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Create reminder error'
			})
		);
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const updateSyncReminderRequest = (data) => async (dispatch) => {
	try {
		await updateSyncReminder(data);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Update reminder error'
			})
		);
	}
};

export const fetchReminderRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.fetchReminderPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const reminders = await fetchReminders(data);
		await dispatch(actionTypes.fetchReminderSuccess(reminders));
	} catch (error) {
		await dispatch(actionTypes.fetchReminderError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Reminder Error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchReminderPaginationRequest =
	(projectId, { page, perPage }, data) =>
	async (dispatch) => {
		await dispatch(actionTypes.fetchReminderPending());
		await dispatch(actionTypes.setLoader(true));
		try {
			const body = getBodyPaginate({ page, perPage, ...data }, 'reminder');
			const reminders = await fetchRemindersPaginationApi(projectId, body);
			await dispatch(actionTypes.fetchReminderSuccess(reminders));
		} catch (error) {
			await dispatch(actionTypes.fetchReminderError(error));
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: 'Fetch Reminder Error'
				})
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const fetchReminderByIdsRequest = (data, ids) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		return await fetchRemindersByIdsApi(data, ids);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Reminder Error'
			})
		);
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};

export const fetchReminderItemRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchReminderItemPending());
	try {
		const remindersItem = await fetchReminderItem(data);
		await dispatch(actionTypes.fetchReminderItemSuccess(remindersItem));
	} catch (error) {
		await dispatch(actionTypes.fetchReminderItemError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch Reminder Error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteReminderRequest = (selectedProject, reminderId, data) => async (dispatch) => {
	await dispatch(actionTypes.deleteReminderPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await deleteReminder(selectedProject, reminderId, data);
		await dispatch(actionTypes.deleteReminderSuccess());
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.deleteReminderError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Delete reminder error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateReminderRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.updateReminderPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		await updateReminder(data);
		await dispatch(actionTypes.updateReminderSuccess());
		await dispatch(
			actionTypes.showToast({
				body: 'Reminder updated successfully'
			})
		);
	} catch (error) {
		await dispatch(actionTypes.updateReminderError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error.message || 'Update reminder error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

// fetch reminder by id
export const fetchReminderElemRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchReminderElemPending());
	try {
		const res = await fetchReminderElem(data);
		await dispatch(actionTypes.fetchReminderElemSuccess(res.data.reminder));
		if (res?.data?.selectedProject) {
			dispatch(actionTypes.changeSelectedProject(res?.data?.selectedProject));
		}
		await dispatch(actionTypes.setLoader(false));
		return res?.data?.reminder;
	} catch (error) {
		await dispatch(actionTypes.fetchReminderElemError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body:
					error?.response?.status === 404 ? 'Reminder not found' : 'Fetch Reminder Error'
			})
		);
		await dispatch(actionTypes.setLoader(false));
	}
};

export const completeRemindersRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.updateReminderPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		await completeReminders(data);
		await dispatch(actionTypes.updateReminderSuccess());
	} catch (error) {
		await dispatch(actionTypes.updateReminderError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Update reminders error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchSaleReminder = (data) => async (dispatch) => {
	try {
		const res = await fetchSaleReminderApi(data);
		return res?.data;
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body:
					error?.response?.status === 404 ? 'Reminder not found' : 'Fetch Reminder Error'
			})
		);
		throw error;
	}
};
