export const OPTIONS_LISTS_FETCH_PENDING = 'OPTIONS_LISTS_FETCH_PENDING';
export const OPTIONS_LISTS_FETCH_SUCCESS = 'OPTIONS_LISTS_FETCH_SUCCESS';
export const OPTIONS_LISTS_FETCH_ERROR = 'OPTIONS_LISTS_FETCH_ERROR';
export const OPTIONS_LISTS_DUPLICATE = 'OPTIONS_LISTS_DUPLICATE';

export const optionsListsFetchPending = () => ({
	type: OPTIONS_LISTS_FETCH_PENDING
});

export const optionsListsFetchSuccess = (optionsLists) => ({
	type: OPTIONS_LISTS_FETCH_SUCCESS,
	optionsLists
});

export const optionsListsFetchError = (error) => ({
	type: OPTIONS_LISTS_FETCH_ERROR,
	error
});

export const optionsListsDuplicate = (option) => ({
	type: OPTIONS_LISTS_DUPLICATE,
	option
});
