import axios from 'axios';

export const fetchHometownPackages = ({ selectedProject }) =>
	axios.get(`/api/report-system/${selectedProject}/townhome`);

export const fetchReportSystem = ({ selectedProject }) =>
	axios.get(`/api/report-system/${selectedProject}`);

export const fetchWeeklySales = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/weekly-sales`, { ...rest });

export const fetchSalesSummary = ({ selectedProject }) =>
	axios.get(`/api/report-system/${selectedProject}/sales-summary`);

export const fetchCancellations = ({ selectedProject }) =>
	axios.get(`/api/report-system/${selectedProject}/cancellation`);

export const fetchFilteredSales = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/sales`, { ...rest });

export const fetchPastSettlement = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/past-settlement`, { ...rest });

export const fetchFutureSettlement = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/future-settlement`, { ...rest });

export const fetchStageAnalysis = ({ selectedProject }) =>
	axios.get(`/api/report-system/${selectedProject}/stage-analysis`);

export const fetchPurchaserProfiles = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/purchaser-profiles`, { ...rest });

export const fetchContactSources = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/contact-sources`, { ...rest });

export const fetchPurchaserMapping = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/purchaser-mapping`, { ...rest });

export const fetchBuilderDocuments = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/builder-documents`, { ...rest });

export const fetchWeekReportAll = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/weekly-sales-all`, { ...rest });

export const fetchSaleSummaryAll = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/sales-summary-all`, { ...rest });

export const fetchSettlementAll = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/settlements-all`, { ...rest });

export const fetchSaleSettlementsApi = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/settlements`, { ...rest });

export const fetchWeekReportAllConditional = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/weekly-sales-all-conditional`, { ...rest });

export const fetchWeekReportAllWeek = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/weekly-sales-all-week`, { ...rest });

export const fetchWeekReportAllStage = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/weekly-sales-all-stages`, { ...rest });

export const fetchReportNoteApi = ({ ...data }) => axios.put('/api/report-note', { data });

export const saveReportNoteApi = ({ ...data }) => axios.post('/api/report-note', { data });

export const getAllOverdueDeposits = () => axios.get('/api/report-system/all-overdue-deposits');

export const fetchSunsetDatesReport = ({ selectedProject, ...rest }) =>
	axios.get(`/api/report-system/${selectedProject}/sunset-dates`, { ...rest });

export const fetchCommissionsReport = ({ selectedProject, ...rest }) =>
	axios.put(`/api/report-system/${selectedProject}/commissions`, { ...rest });
