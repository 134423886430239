export const GET_OUTLOOK_EVENTS_SUCCESS = 'GET_OUTLOOK_EVENTS_SUCCESS';
export const GET_OUTLOOK_MESSAGES_SUCCESS = 'GET_OUTLOOK_MESSAGES_SUCCESS';
export const GET_OUTLOOK_MAIL_FOLDERS_SUCCESS = 'GET_OUTLOOK_MAIL_FOLDERS_SUCCESS';
export const GET_OUTLOOK_USERS = 'GET_OUTLOOK_USERS';
export const GET_OUTLOOK_SELECTED_SHARED_USER = 'GET_OUTLOOK_SELECTED_SHARED_USER';
export const GET_OUTLOOK_SUBFOLDERS = 'GET_OUTLOOK_SUBFOLDERS';
export const GET_SHARED_MAIL_BOXES = 'GET_SHARED_MAIL_BOXES';
export const SET_AMOUNT_FETCH_MESSAGES = 'SET_AMOUNT_FOUND_MAILBOXES';
export const SET_LOGGED_OUTLOOK_USER = 'SET_LOGGED_OUTLOOK_USER';
export const REJECT_USER_AUTH = 'REJECT_USER_AUTH';

export const getOutlookEventsSuccess = (data) => ({
	type: GET_OUTLOOK_EVENTS_SUCCESS,
	data
});

export const getOutlookMessagesSuccess = (data) => ({
	type: GET_OUTLOOK_MESSAGES_SUCCESS,
	data
});

export const getOutlookMailFoldersSuccess = (data) => ({
	type: GET_OUTLOOK_MAIL_FOLDERS_SUCCESS,
	data
});

export const getOutlookUsers = (data) => ({
	type: GET_OUTLOOK_USERS,
	data
});

export const getSelectedSharedUser = (data) => ({
	type: GET_OUTLOOK_SELECTED_SHARED_USER,
	data
});

export const getOutlookSubfolders = (folderId, data, elemPath) => ({
	type: GET_OUTLOOK_SUBFOLDERS,
	folderId,
	data,
	elemPath
});

export const sharedMailBoxesAcition = (data) => ({
	type: GET_SHARED_MAIL_BOXES,
	data
});

export const setAmountFetchMessages = (data) => ({
	type: SET_AMOUNT_FETCH_MESSAGES,
	data
});

export const setLoggedOutlookUser = (data) => ({
	type: SET_LOGGED_OUTLOOK_USER,
	data
});

export const rejectUserAuth = (data) => ({
	type: REJECT_USER_AUTH,
	data
});
