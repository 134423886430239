import * as actionTypes from 'src/actions';

const initialState = {
	events: null,
	messages: [],
	mailFolders: [],
	foundUser: null,
	selectedSharedUserFolder: null,
	sharedMailBoxes: [],
	amountFetchMessages: 250,
	loggedOutlookUser: null,
	rejectUserAuth: false
};

const parsePaths = (node, currentPath = [], arr = []) => {
	const newPath = [...currentPath, node.id];
	if (node.subFolders) {
		arr.push({
			elemPath: newPath,
			...node,
			subFolders: node.subFolders?.reduce(
				(acc, n) => [...acc, ...parsePaths(n, newPath, [])],
				[]
			)
		});
	} else {
		arr.push({
			elemPath: newPath,
			...node
		});
	}
	return arr;
};

const funcParse = (arr, index = 0, pathArr, newSubFolders) => {
	const resArr = [];

	arr.forEach((el) => {
		if (el.id === pathArr[index]) {
			if (index === pathArr.length - 1) {
				// eslint-disable-next-line
				el.subFolders = newSubFolders;
			} else {
				// eslint-disable-next-line
				index++;
				funcParse(el.subFolders, index, pathArr, newSubFolders);
			}
		}
		resArr.push(el);
	});
	return resArr;
};

const outlookReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_OUTLOOK_EVENTS_SUCCESS: {
			return {
				...state,
				events: action.data
			};
		}
		case actionTypes.GET_OUTLOOK_MESSAGES_SUCCESS: {
			return {
				...state,
				messages: action.data
			};
		}
		case actionTypes.GET_OUTLOOK_MAIL_FOLDERS_SUCCESS: {
			const parsedFolders = action.data?.reduce((acc, el) => [...acc, ...parsePaths(el)], []);

			return {
				...state,
				mailFolders: parsedFolders
			};
		}
		case actionTypes.GET_OUTLOOK_SUBFOLDERS: {
			const newData = funcParse(state.mailFolders, 0, action.elemPath, action.data);
			const parsedFolders = newData?.reduce((acc, el) => [...acc, ...parsePaths(el)], []);

			return {
				...state,
				mailFolders: parsedFolders
			};
		}
		case actionTypes.GET_OUTLOOK_USERS: {
			return {
				...state,
				foundUser: action.data
			};
		}
		case actionTypes.GET_OUTLOOK_SELECTED_SHARED_USER: {
			return {
				...state,
				selectedSharedUserFolder: action.data
			};
		}
		case actionTypes.GET_SHARED_MAIL_BOXES: {
			return {
				...state,
				sharedMailBoxes: action.data
			};
		}
		case actionTypes.SET_AMOUNT_FETCH_MESSAGES: {
			return {
				...state,
				amountFetchMessages: action.data
			};
		}
		case actionTypes.SET_LOGGED_OUTLOOK_USER: {
			return {
				...state,
				loggedOutlookUser: action.data
			};
		}
		case actionTypes.REJECT_USER_AUTH: {
			return {
				...state,
				rejectUserAuth: action.data
			};
		}
		default: {
			return state;
		}
	}
};

export default outlookReducer;
