export const LOTS_FETCH_PENDING = 'LOTS_FETCH_PENDING';
export const LOTS_FETCH_SUCCESS = 'LOTS_FETCH_SUCCESS';
export const LOTS_FETCH_ERROR = 'LOTS_FETCH_ERROR';
export const LOTS_PAGINATE_FETCH_SUCCESS = 'LOTS_PAGINATE_FETCH_SUCCESS';
export const SET_LOT_FILTERS = 'SET_LOT_FILTERS';
export const CLEAR_LOT_FILTERS = 'CLEAR_LOT_FILTERS';
export const LOTS_HISTORY_FETCH_PENDING = 'LOTS_HISTORY_FETCH_PENDING';
export const LOTS_HISTORY_FETCH_SUCCESS = 'LOTS_HISTORY_FETCH_SUCCESS';
export const LOTS_HISTORY_FETCH_ERROR = 'LOTS_HISTORY_FETCH_ERROR';
export const LOTS_DETAILS_FETCH_PENDING = 'LOTS_DETAILS_FETCH_PENDING';
export const LOTS_DETAILS_FETCH_SUCCESS = 'LOTS_DETAILS_FETCH_SUCCESS';
export const LOTS_DETAILS_FETCH_ERROR = 'LOTS_DETAILS_FETCH_ERROR';
export const LOTS_GEO_FETCH_SUCCESS = 'LOTS_GEO_FETCH_SUCCESS';
export const LOTS_SET_SCROLL = 'LOTS_SET_SCROLL';
export const LOTS_DETAILS_RESET = 'LOTS_DETAILS_RESET';
export const LOT_USERS_FETCH_PENDING = 'LOT_USERS_FETCH_PENDING';
export const LOT_USERS_FETCH_SUCCESS = 'LOT_USERS_FETCH_SUCCESS';
export const LOT_USERS_FETCH_ERROR = 'LOT_USERS_FETCH_ERROR';

export const lotsFetchPending = () => ({
	type: LOTS_FETCH_PENDING
});

export const lotsFetchSuccess = (lots) => ({
	type: LOTS_FETCH_SUCCESS,
	lots
});

export const lotsPaginateFetchSuccess = (lots) => ({
	type: LOTS_PAGINATE_FETCH_SUCCESS,
	lots
});

export const setLotFilters = (filterType, data) => ({
	type: SET_LOT_FILTERS,
	filterType,
	data
});

export const clearLotFilters = () => ({
	type: CLEAR_LOT_FILTERS
});

export const lotsGeoFetchSuccess = (lots) => ({
	type: LOTS_GEO_FETCH_SUCCESS,
	lots
});

export const lotsFetchError = (error) => ({
	type: LOTS_FETCH_ERROR,
	error
});

export const lotsHistoryFetchPending = () => ({
	type: LOTS_HISTORY_FETCH_PENDING
});

export const lotsHistoryFetchSuccess = (history) => ({
	type: LOTS_HISTORY_FETCH_SUCCESS,
	history
});

export const lotsHistoryFetchError = (error) => ({
	type: LOTS_HISTORY_FETCH_ERROR,
	error
});

export const lotUsersFetchPending = () => ({
	type: LOT_USERS_FETCH_PENDING
});

export const lotUsersFetchSuccess = (users) => ({
	type: LOT_USERS_FETCH_SUCCESS,
	users
});

export const lotUsersFetchError = (error) => ({
	type: LOT_USERS_FETCH_ERROR,
	error
});

export const lotsDetailsFetchPending = () => ({
	type: LOTS_DETAILS_FETCH_PENDING
});

export const lotsDetailsFetchSuccess = (details) => ({
	type: LOTS_DETAILS_FETCH_SUCCESS,
	details
});

export const lotsDetailsFetchError = (error) => ({
	type: LOTS_DETAILS_FETCH_ERROR,
	error
});

export const lotsSetScroll = (scroll) => ({
	type: LOTS_SET_SCROLL,
	scroll
});

export const lotsDetailsReset = () => ({
	type: LOTS_DETAILS_RESET
});
