import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	stages: null,
	details: null
};

const stageReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.STAGES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.STAGES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				stages: action.stages,
				error: null
			};
		}

		case actionTypes.STAGES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.STAGES_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.STAGES_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				details: action.details,
				error: null
			};
		}

		case actionTypes.STAGES_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.STAGES_RESET: {
			return {
				...state,
				details: null
			};
		}

		default: {
			return state;
		}
	}
};

export default stageReducer;
