import axios from 'axios';

export const createSurveyApi = (data) => axios.post(`/api/projects/${data.projectId}/survey`, data);

export const getSurveyApi = (projectId) => axios.get(`/api/projects/${projectId}/survey`);

export const deleteSurveyApi = (projectId, id, data) =>
	axios.delete(`/api/projects/${projectId}/survey/${id}`, { data });

export const editSurveyApi = (data, surveyId) =>
	axios.put(`/api/projects/${data.projectId}/survey/${surveyId}`, data);

export const duplicateSurveyApi = (projectId, surveyId) =>
	axios.put(`/api/projects/${projectId}/survey/duplicate/${surveyId}`);

export const assignSurveyApi = ({ selectedProject, ...rest }) =>
	axios.post(`/api/projects/${selectedProject}/survey/assign`, { rest });

export const getAssignedSurveyApi = (projectId, surveyId) =>
	axios.get(`/api/projects/${projectId}/survey/${surveyId}`);

export const saveAnswersApi = (data) =>
	axios.post(`/api/projects/${data.projectId}/survey/${data.surveyId}/answers`, data);

export const getPurchaserProfilesApi = (selectedProject) =>
	axios.get(`/api/projects/${selectedProject}/purchaser-profiles`);

export const getPurchaserProfileByIdApi = (selectedProject, profileId) =>
	axios.get(`/api/projects/${selectedProject}/purchaser-profiles/${profileId}`);

export const deletePurchaserProfileApi = (projectId, id, contactId, data) =>
	axios.delete(`/api/projects/${projectId}/purchaser-profiles/${id}/${contactId}`, { data });

export const updateAnswersApi = (data) =>
	axios.put(`/api/projects/${data.projectId}/purchaser-profiles`, data);

export const sendUrlApi = (data) =>
	axios.post(`/api/projects/${data.selectedProject}/send-url`, data);

export const checkTokenApi = (token) => axios.post('/api/check-contact-token', token);
