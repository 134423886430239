import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%'
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: 2
	},
	noLabel: {
		marginTop: theme.spacing(3)
	}
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

function MultiSelectGroups({ title, data, defaultGroup, callBack, selectedValue, disabled }) {
	const classes = useStyles();
	const [selectedItems, setSelectedItems] = useState([]);
	const [all, setAll] = useState(false);

	useEffect(() => {
		if (selectedValue) {
			setSelectedItems([...selectedValue]);
		} else if (defaultGroup) {
			setSelectedItems([defaultGroup]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultGroup]);

	const handleChange = (e) => {
		e.stopPropagation();
		const { value } = e.target;
		const allIndex = value.indexOf('all');
		const allValues = Object.keys(data);
		if (allIndex > -1) {
			setAll(true);
			setSelectedItems(allValues);
			callBack(allValues);
			return;
		}
		if (value.length === allValues.length) {
			setAll(true);
		} else {
			setAll(false);
		}
		setSelectedItems(value);
		callBack(value);
	};

	return (
		<div>
			<FormControl className={classes.formControl}>
				<InputLabel id="select-group-label">{title}</InputLabel>
				<Select
					fullWidth
					labelId="select-group-label"
					id="select-group"
					multiple
					value={selectedItems}
					onChange={(e) => handleChange(e)}
					input={<Input />}
					renderValue={(selected) => {
						// let objectLength = Object.keys(data).length
						const selectedLength = selected.length;
						return selected.map((item, i) => (
							<Fragment key={`${item}rendered`}>
								<span>{data[item]}</span>
								{selectedLength !== 1 && i + 1 < selectedLength && ', '}
							</Fragment>
						));
					}}
					MenuProps={MenuProps}
					disabled={disabled}
				>
					<MenuItem value="all">
						<Checkbox checked={all} />
						<ListItemText primary="Select All" />
					</MenuItem>
					{Object.keys(data).map((item) =>
						item === defaultGroup ? (
							<MenuItem
								key={item}
								value={item}
								disabled
							>
								<Checkbox checked />
								<ListItemText primary={data[item]} />
							</MenuItem>
						) : (
							<MenuItem
								key={item}
								value={item}
							>
								<Checkbox
									checked={selectedItems && selectedItems.indexOf(item) > -1}
								/>
								<ListItemText primary={data[item]} />
							</MenuItem>
						)
					)}
				</Select>
			</FormControl>
		</div>
	);
}

MultiSelectGroups.propTypes = {
	title: PropTypes.string,
	data: PropTypes.object,
	defaultGroup: PropTypes.string,
	callBack: PropTypes.func,
	selectedValue: PropTypes.array,
	disabled: PropTypes.bool
};

export default MultiSelectGroups;
