import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { getToast } from '../selectors';
import { hideToast } from '../actions';

// type = error | warning | info | success
function Toast() {
	const { show, body, type, id, onClose } = useSelector(getToast);
	const dispatch = useDispatch();
	const handleClose = () => {
		dispatch(hideToast());
	};
	function Alert(props) {
		return (
			<MuiAlert
				elevation={6}
				variant="filled"
				{...props}
			/>
		);
	}

	return (
		<Snackbar
			open={show}
			autoHideDuration={3000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		>
			<Alert
				onClose={handleClose}
				severity={type}
			>
				{id ? (
					<Link
						to={`/admin/contacts/${id}`}
						onClick={() => onClose()}
					>
						{body}
					</Link>
				) : (
					body
				)}
			</Alert>
		</Snackbar>
	);
}

export default Toast;
