/* eslint-disable max-len */
export const getSales = (state) => state.sales.sale;

export const getSalePending = (state) => state.sales.pending;

export const getDataSales = (state) => state.sales.saleData;

export const getContactDropdown = (state) => state.sales.contactDropdown || [];

export const getSaleItem = (state) => state.sales.saleItem;

export const getSaleDetails = (state) => state.sales.saleDetails;

export const getSaleProcess = (state) => state.sales.saleProcess;

export const getPayments = (state) => state.sales.payments;

export const getPaymentsPagination = (state) =>
	state.sales.payments?.data.pagination && state.sales.payments?.data.pagination;

export const getPaymentsFilters = (state) => state.sales.paymentsFilters;

export const getContactPayments = (state) =>
	state.sales.contactPayments && state.sales.contactPayments.data;

export const getSaleHistory = (state) =>
	state.sales.saleHistory && state.sales.saleHistory.data.items;

export const getSalePagination = (state) =>
	state.sales.salePagination && state.sales.salePagination;

export const getSaleFilters = (state) => state.sales.saleFilters && state.sales.saleFilters;

export const getSaleCheckboxFilters = (state) => state.sales.checkboxFilters;
