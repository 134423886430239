import * as api from 'src/api/dashboardApi';
import { fetchProjects } from 'src/api';
import * as actionTypes from '../actions';

export const getSaleStatsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchSaleStatsPending());
	try {
		const resData = await api.getSaleStats(projectId);
		await dispatch(actionTypes.fetchSaleStatsSuccess(resData));
		await dispatch(fetchProjects());
	} catch (error) {
		await dispatch(actionTypes.fetchSaleStatsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getPastSettlementsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchPastSettlementsPending());
	try {
		const resData = await api.getPastSettlements(projectId);
		await dispatch(actionTypes.fetchPastSettlementsSuccess(resData));
		await dispatch(fetchProjects());
	} catch (error) {
		await dispatch(actionTypes.fetchPastSettlementsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getDueCommissionsStatsRequest = (projectId, userId) => async (dispatch) => {
	await dispatch(actionTypes.fetchDueCommissionsStatsPending());
	try {
		const resData = await api.getDueCommissionsStats(projectId, userId);
		await dispatch(actionTypes.fetchDueCommissionsStatsSuccess(resData));
		await dispatch(fetchProjects());
	} catch (error) {
		await dispatch(actionTypes.fetchDueCommissionsStatsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getOutstandingContractsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchOutsdandingContractsPending());
	try {
		const resData = await api.getOutstandingContracts(projectId);
		await dispatch(actionTypes.fetchOutsdandingContractsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchOutsdandingContractsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getSaleStatusesRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchSaleStatusesPending());
	try {
		const resData = await api.getSaleStatuses(projectId);
		await dispatch(actionTypes.fetchSaleStatusesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchSaleStatusesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getOverdueDepositsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchOverdueDepositsPending());
	try {
		const resData = await api.getOverdueDeposits(projectId);
		await dispatch(actionTypes.fetchOverdueDepositsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchOverdueDepositsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getConditionalContractsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchConditionalContractsPending());
	try {
		const resData = await api.getConditionalContracts(projectId);
		await dispatch(actionTypes.fetchConditionalContractsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchConditionalContractsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getSunsetDatesRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchSunsetDatesPending());
	try {
		const resData = await api.getSunsetDates(projectId);
		await dispatch(actionTypes.fetchSunsetDatesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchSunsetDatesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getOverdueConditionsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchOverdueConditionsPending());
	try {
		const resData = await api.getOverdueConditions(projectId);
		await dispatch(actionTypes.fetchOverdueConditionsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchOverdueConditionsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getSettlementDatesRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchSettlementDatesPending());
	try {
		const resData = await api.getSettlementDates(projectId);
		await dispatch(actionTypes.fetchSettlementDatesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchSettlementDatesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getRemindersDashboardRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchRemindersDashboardPending());
	try {
		const resData = await api.getRemindersDashboard(projectId);
		await dispatch(actionTypes.fetchRemindersDashboardSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchRemindersDashboardError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getNewEnquiriesRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchNewEnquiriesPending());
	try {
		const resData = await api.getNewEnquiries(projectId);
		await dispatch(actionTypes.fetchNewEnquiriesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchNewEnquiriesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getRepeatEnquiryRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchRepeatEnquiryPending());
	try {
		const resData = await api.getRepeatEnquiry(projectId);
		await dispatch(actionTypes.fetchRepeatEnquirySuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchRepeatEnquiryError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getFullDepositPaidRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchFullDepositPaidPending());
	try {
		const resData = await api.getFullDepositPaid(projectId);
		await dispatch(actionTypes.fetchFullDepositPaidSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchFullDepositPaidError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getContractsExchangeRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchContractsExchangePending());
	try {
		const resData = await api.getContractsExchange(projectId);
		await dispatch(actionTypes.fetchContractsExchangeSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchContractsExchangeError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getCanceledSalesRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchCanceledSalesPending());
	try {
		const resData = await api.getCanceledSales(projectId);
		await dispatch(actionTypes.fetchCanceledSalesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchCanceledSalesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWalkInStatsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.fetchWalkInPending());
	try {
		const resData = await api.getWalkInStats(projectId);
		await dispatch(actionTypes.fetchWalkInSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWalkInError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const fetchDashboardSettingsRequest = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchDashboardSettingsPending());
	try {
		const resData = await api.fetchDashboardSettingsApi(projectId);
		await dispatch(actionTypes.fetchDashboardSettingsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchDashboardSettingsError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const saveDashboardRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await api.saveDashboardApi(data);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save Dashboard error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const addDashboardItemRequest =
	({ selectedProject, ...rest }) =>
	async (dispatch) => {
		await dispatch(actionTypes.setLoader(true));
		try {
			await api.addDashboardApi(selectedProject, rest);
			dispatch(fetchDashboardSettingsRequest(selectedProject));
		} catch (error) {
			await dispatch(
				actionTypes.showToast({
					type: 'error',
					body: 'Add Dashboard error'
				})
			);
		}
		await dispatch(actionTypes.setLoader(false));
	};

export const getWeekSalesRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekSalesPending());
	try {
		const resData = await api.getWeekSales();
		await dispatch(actionTypes.fetchWeekSalesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekSalesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWeekSignedContractsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekSignedContractsPending());
	try {
		const resData = await api.getWeekSignedContracts();
		await dispatch(actionTypes.fetchWeekSignedContractsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekSignedContractsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWeekEnquiresRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekEnquiresPending());
	try {
		const resData = await api.getWeekEnquires();
		await dispatch(actionTypes.fetchWeekEnquiresSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekEnquiresError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWeekCancelationsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekCancelationsPending());
	try {
		const resData = await api.getWeekCancelations();
		await dispatch(actionTypes.fetchWeekCancelationsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekCancelationsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWeekOverdueDepositsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekOverdueDepositsPending());
	try {
		const resData = await api.getWeekOverdueDeposits();
		await dispatch(actionTypes.fetchWeekOverdueDepositsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekOverdueDepositsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWeekContactNotesRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekContactNotesPending());
	try {
		const resData = await api.getWeekContactNotes();
		await dispatch(actionTypes.fetchWeekContactNotesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekContactNotesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getWeekToBeSignedContractsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchWeekToBeSignedContractsPending());
	try {
		const resData = await api.getWeekToBeSignedContracts();
		await dispatch(actionTypes.fetchWeekToBeSignedContractsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchWeekToBeSignedContractsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getProjectStatusesRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchProjectStatusesPending());
	try {
		const resData = await api.getProjectStatuses();
		await dispatch(actionTypes.fetchProjectStatusesSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchProjectStatusesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getListOverdueDepositsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchListOverdueDepositsPending());
	try {
		const resData = await api.getListOverdueDeposits();
		await dispatch(actionTypes.fetchListOverdueDepositsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchListOverdueDepositsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getListToBeSignedContractsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchListToBeSignedContractsPending());
	try {
		const resData = await api.getListToBeSignedContracts();
		await dispatch(actionTypes.fetchListToBeSignedContractsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchListToBeSignedContractsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getOverdueContractsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchOverdueContractsPending());
	try {
		const resData = await api.getOverdueContracts();
		await dispatch(actionTypes.fetchOverdueContractsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchOverdueContractsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};

export const getUpcomingSettlementsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchUpcomingSettlementsPending());
	try {
		const resData = await api.getUpcomingSettlements();
		await dispatch(actionTypes.fetchUpcomingSettlementsSuccess(resData));
	} catch (error) {
		await dispatch(actionTypes.fetchUpcomingSettlementsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get data error'
			})
		);
	}
};
