import * as actionTypes from '../actions';
import {
	postDapUserApi,
	fetchDapUsersApi,
	fetchDapUserDetailsApi,
	updateDapUserApi,
	changeDapUserPasswordApi,
	fetchAllDapUsersApi
} from '../api/userDAP';

export const postDapUser = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await postDapUserApi(data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchDapUsers = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.dapUsersFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const data = await fetchDapUsersApi(projectId);
		await dispatch(actionTypes.dapUsersFetchSuccess(data));
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		await dispatch(actionTypes.dapUsersFetchError(error));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchAllDapUsers = (projectId, projects) => async (dispatch) => {
	await dispatch(actionTypes.dapUsersFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const data = await fetchAllDapUsersApi(projectId, projects);
		await dispatch(actionTypes.dapUsersFetchSuccess(data));
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		await dispatch(actionTypes.dapUsersFetchError(error));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchDapUserDetails = (userId) => async (dispatch) => {
	await dispatch(actionTypes.dapUserDetailsFetchPending());
	await dispatch(actionTypes.setLoader(true));

	try {
		const userDetails = await fetchDapUserDetailsApi(userId);
		await dispatch(actionTypes.dapUserDetailsFetchSuccess(userDetails));
	} catch (error) {
		await dispatch(actionTypes.dapUserDetailsFetchError(error));
	}
	await dispatch(actionTypes.setLoader(false));
};

export const updateDapUser = (id, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));

	try {
		await updateDapUserApi(id, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(actionTypes.showToast({ type: 'error', body: 'Error' }));
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const changeDapUserPasswordRequest = (data, userId, onClose, open) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await changeDapUserPasswordApi({ ...data }, userId);
		onClose(!open);
		await dispatch(actionTypes.showToast({ body: 'Password changed successfully' }));
	} catch (error) {
		await dispatch(actionTypes.showToast({ type: 'error', body: error.response.data.message }));
	}
	await dispatch(actionTypes.setLoader(false));
};
