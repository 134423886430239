export const USERS_FETCH_PENDING = 'USERS_FETCH_PENDING';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_ERROR = 'USERS_FETCH_ERROR';

export const USER_DETAILS_FETCH_PENDING = 'USER_DETAILS_FETCH_PENDING';
export const USER_DETAILS_FETCH_SUCCESS = 'USER_DETAILS_FETCH_SUCCESS';
export const USER_DETAILS_FETCH_ERROR = 'USER_DETAILS_FETCH_ERROR';

export const SET_USER_AUTH = 'SET_USER_AUTH';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const RESET_USER_STORE = 'RESET_USER_STORE';
export const USERS_HISTORY_LOT_FETCH_PENDING = 'USERS_HISTORY_LOT_FETCH_PENDING';
export const USERS_HISTORY_LOT_FETCH_SUCCESS = 'USERS_HISTORY_LOT_FETCH_SUCCESS';
export const USERS_HISTORY_LOT_FETCH_ERROR = 'USERS_HISTORY_LOT_FETCH_ERROR';
export const USERS_HISTORY_PACKAGE_FETCH_PENDING = 'USERS_HISTORY_PACKAGE_FETCH_PENDING';
export const USERS_HISTORY_PACKAGE_FETCH_SUCCESS = 'USERS_HISTORY_PACKAGE_FETCH_SUCCESS';
export const USERS_HISTORY_PACKAGE_FETCH_ERROR = 'USERS_HISTORY_PACKAGE_FETCH_ERROR';
export const USERS_HISTORY_SALE_FETCH_PENDING = 'USERS_HISTORY_SALE_FETCH_PENDING';
export const USERS_HISTORY_SALE_FETCH_SUCCESS = 'USERS_HISTORY_SALE_FETCH_SUCCESS';
export const USERS_HISTORY_SALE_FETCH_ERROR = 'USERS_HISTORY_SALE_FETCH_ERROR';

export const USER_ROWS_FETCH_PENDING = 'USER_ROWS_FETCH_PENDING';
export const USER_ROWS_FETCH_SUCCESS = 'USER_ROWS_FETCH_SUCCESS';
export const USER_ROWS_FETCH_ERROR = 'USER_ROWS_FETCH_ERROR';
export const UPDATE_USER_ROWS = 'UPDATE_USER_ROWS';

export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_SETTLED_LOTS_FETCH_PENDING = 'USER_SETTLED_LOTS_FETCH_PENDING';
export const USER_SETTLED_LOTS_FETCH_SUCCESS = 'USER_SETTLED_LOTS_FETCH_SUCCESS';
export const USER_SETTLED_LOTS_FETCH_ERROR = 'USER_SETTLED_LOTS_FETCH_ERROR';

export const USER_SHOW_INACTIVE_CONTACTS_FETCH_PENDING =
	'USER_SHOW_INACTIVE_CONTACTS_FETCH_PENDING';
export const USER_SHOW_INACTIVE_CONTACTS_FETCH_SUCCESS =
	'USER_SHOW_INACTIVE_CONTACTS_FETCH_SUCCESS';
export const USER_SHOW_INACTIVE_CONTACTS_FETCH_ERROR = 'USER_SHOW_INACTIVE_CONTACTS_FETCH_ERROR';

export const usersFetchPending = () => ({
	type: USERS_FETCH_PENDING
});

export const usersFetchSuccess = (users) => ({
	type: USERS_FETCH_SUCCESS,
	users
});

export const usersFetchError = (error) => ({
	type: USERS_FETCH_ERROR,
	error
});

export const userDetailsFetchPending = () => ({
	type: USER_DETAILS_FETCH_PENDING
});

export const userDetailsFetchSuccess = (userDetails) => ({
	type: USER_DETAILS_FETCH_SUCCESS,
	userDetails
});

export const userDetailsFetchError = (error) => ({
	type: USER_DETAILS_FETCH_ERROR,
	error
});

export const setUserAuth = (user) => ({
	type: SET_USER_AUTH,
	user
});

export const usersHistoryLotFetchPending = () => ({
	type: USERS_HISTORY_LOT_FETCH_PENDING
});

export const usersHistoryLotFetchSuccess = (history) => ({
	type: USERS_HISTORY_LOT_FETCH_SUCCESS,
	history
});

export const usersHistoryLotFetchError = (error) => ({
	type: USERS_HISTORY_LOT_FETCH_ERROR,
	error
});

export const usersHistorySaleFetchPending = () => ({
	type: USERS_HISTORY_SALE_FETCH_PENDING
});

export const usersHistorySaleFetchSuccess = (saleHistory) => ({
	type: USERS_HISTORY_SALE_FETCH_SUCCESS,
	saleHistory
});

export const usersHistorySaleFetchError = (error) => ({
	type: USERS_HISTORY_SALE_FETCH_ERROR,
	error
});

export const usersHistoryPackageFetchPending = () => ({
	type: USERS_HISTORY_PACKAGE_FETCH_PENDING
});

export const usersHistoryPackageFetchSuccess = (history) => ({
	type: USERS_HISTORY_PACKAGE_FETCH_SUCCESS,
	history
});

export const usersHistoryPackageFetchError = (error) => ({
	type: USERS_HISTORY_PACKAGE_FETCH_ERROR,
	error
});

export const userRowsFetchPending = () => ({
	type: USER_ROWS_FETCH_PENDING
});

export const updateUserRows = (userRows) => ({
	type: UPDATE_USER_ROWS,
	userRows
});

export const userRowsFetchSuccess = (user) => ({
	type: USER_ROWS_FETCH_SUCCESS,
	user
});

export const userRowsFetchError = (error) => ({
	type: USER_ROWS_FETCH_ERROR,
	error
});

export const setUserPermissions = (data) => ({
	type: SET_USER_PERMISSIONS,
	data
});

export const resetUserStore = () => ({
	type: RESET_USER_STORE
});

export const userDetailsReset = () => ({
	type: USER_DETAILS_RESET
});

export const userSettledLotsFetchPending = () => ({
	type: USER_SETTLED_LOTS_FETCH_PENDING
});

export const userSettledLotsFetchSuccess = (data) => ({
	type: USER_SETTLED_LOTS_FETCH_SUCCESS,
	data
});

export const userSettledLotsFetchError = () => ({
	type: USER_SETTLED_LOTS_FETCH_ERROR
});

export const userShowInactiveContactsFetchPending = (data) => ({
	type: USER_SHOW_INACTIVE_CONTACTS_FETCH_PENDING,
	data
});

export const userShowInactiveContactsFetchSuccess = (data) => ({
	type: USER_SHOW_INACTIVE_CONTACTS_FETCH_SUCCESS,
	data
});

export const userShowInactiveContactsFetchError = (data) => ({
	type: USER_SHOW_INACTIVE_CONTACTS_FETCH_SUCCESS,
	data
});
