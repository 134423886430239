import axios from 'axios';

export const createReminder = ({ selectedProject, ...rest }) =>
	axios.post(`/api/projects/${selectedProject}/reminders`, { ...rest });
export const updateSyncReminder = ({ selectedProject, reminderId, ...rest }) =>
	axios.put(`/api/projects/${selectedProject}/sync-reminders/${reminderId}`, {
		...rest
	});
export const fetchReminders = ({ selectedProject }) =>
	axios.get(`/api/projects/${selectedProject}/reminders`);
export const fetchRemindersPaginationApi = (selectedProject, data) =>
	axios.put(`/api/projects/${selectedProject}/reminders-pagination`, data);
export const fetchRemindersByIdsApi = ({ selectedProject, ids }) =>
	axios.put(`/api/projects/${selectedProject}/reminders`, ids);
export const fetchReminderItem = ({ selectedProject, contactId }) =>
	axios.get(`/api/projects/${selectedProject}/reminders/${contactId}`);
export const fetchReminderElem = ({ selectedProject, reminderId }) =>
	axios.get(`/api/projects/${selectedProject}/reminders/reminder/${reminderId}`);
export const deleteReminder = (selectedProject, reminderId, data) =>
	axios.delete(`/api/projects/${selectedProject}/reminders/${reminderId}`, { data });
export const updateReminder = ({ selectedProject, reminderId, ...rest }) =>
	axios.put(`/api/projects/${selectedProject}/reminders/${reminderId}`, {
		...rest
	});
export const completeReminders = ({ selectedProject, ...rest }) =>
	axios.put(`/api/projects/${selectedProject}/complete-reminders`, { ...rest });
export const fetchSaleReminderApi = ({ selectedProject, ...rest }) =>
	axios.put(`/api/projects/${selectedProject}/salesReminders`, { ...rest });
