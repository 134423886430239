export const NEARBY_AND_WHITEROCK_FETCH_PENDING = 'NEARBY_AND_WHITEROCK_FETCH_PENDING';
export const NEARBY_AND_WHITEROCK_FETCH_SUCCESS = 'NEARBY_AND_WHITEROCK_FETCH_SUCCESS';
export const NEARBY_AND_WHITEROCK_FETCH_ERROR = 'NEARBY_AND_WHITEROCK_FETCH_ERROR';

export const NEARBY_BY_ID_FETCH_PENDING = 'NEARBY_BY_ID_FETCH_PENDING';
export const NEARBY_BY_ID_FETCH_SUCCESS = 'NEARBY_BY_ID_FETCH_SUCCESS';
export const NEARBY_BY_ID_FETCH_ERROR = 'NEARBY_BY_ID_FETCH_ERROR';

export const FETCH_POINT_CATEGORIES = 'FETCH_POINT_CATEGORIES';

export const nearbyAndWhiterockFetchPending = () => ({
	type: NEARBY_AND_WHITEROCK_FETCH_PENDING
});

export const nearbyAndWhiterockFetchSuccess = (data) => ({
	type: NEARBY_AND_WHITEROCK_FETCH_SUCCESS,
	data
});

export const nearbyAndWhiterockFetchError = (error) => ({
	type: NEARBY_AND_WHITEROCK_FETCH_ERROR,
	error
});

export const nearbyByIdFetchPending = () => ({
	type: NEARBY_BY_ID_FETCH_PENDING
});

export const nearbyByIdFetchSuccess = (data) => ({
	type: NEARBY_BY_ID_FETCH_SUCCESS,
	data
});

export const nearbyByIdFetchError = (error) => ({
	type: NEARBY_BY_ID_FETCH_ERROR,
	error
});

export const fetchPointCategories = (categories) => ({
	type: FETCH_POINT_CATEGORIES,
	categories
});
