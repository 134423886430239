import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	sale: null,
	salePagination: null,
	saleFilters: {
		filters: {},
		sort: {},
		search: ''
	},
	checkboxFilters: {
		saleSettled: false,
		saleCancellation: false
	},
	contactDropdown: null,
	saleData: null,
	saleItem: null,
	saleDetails: null,
	saleProcess: null,
	payments: null,
	paymentsPagination: null,
	paymentsFilters: {
		filters: {},
		sort: {},
		search: ''
	},
	contactPayments: null,
	saleHistory: null,
	error: null
};

const saleReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PAYMENTS_PAGINATE_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_PAYMENTS_PAGINATE_SUCCESS: {
			return {
				...state,
				pending: false,
				payments: action.payments,
				paymentsPagination: action.payments.data.pagination,
				error: null
			};
		}
		case actionTypes.FETCH_PAYMENTS_PAGINATE_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SET_PAYMENTS_FILTERS: {
			return {
				...state,
				paymentsFilters: {
					...state.paymentsFilters,
					[action.filterType]: action.data
				}
			};
		}
		case actionTypes.CLEAR_PAYMENTS_FILTERS: {
			return {
				...state,
				paymentsFilters: {
					...state.paymentsFilters,
					filters: {},
					search: '',
					sort: {}
				}
			};
		}
		case actionTypes.SALE_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				sale: action.sale,
				error: null
			};
		}

		case actionTypes.SALE_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.SALE_PAGINATE_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				error: null,
				sale: action.sales,
				salePagination: action.sales.data.pagination
			};
		}

		case actionTypes.SET_SALE_FILTERS: {
			return {
				...state,
				saleFilters: {
					...state.saleFilters,
					[action.filterType]: action.data
				}
			};
		}

		case actionTypes.CLEAR_SALE_FILTERS: {
			return {
				...state,
				saleFilters: {
					filters: {},
					sort: {},
					search: ''
				}
			};
		}

		case actionTypes.SALE_DATA_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_DATA_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				saleData: action.saleData,
				error: null
			};
		}

		case actionTypes.SALE_DATA_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SALE_CONTACT_DROPDOWN_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_CONTACT_DROPDOWN_SUCCESS: {
			return {
				...state,
				pending: false,
				contactDropdown: action.contactDropdown.data,
				error: null
			};
		}

		case actionTypes.SALE_CONTACT_DROPDOWN_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SALE_ITEM_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_ITEM_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				saleItem: action.saleItem,
				error: null
			};
		}

		case actionTypes.SALE_ITEM_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SALE_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				saleDetails: action.saleDetails,
				error: null
			};
		}

		case actionTypes.SALE_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SALE_PROCESS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_PROCESS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				saleProcess: action.saleProcess,
				error: null
			};
		}

		case actionTypes.SALE_PROCESS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.PAYMENTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.PAYMENTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				payments: action.payments,
				error: null
			};
		}

		case actionTypes.PAYMENTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.CONTACT_PAYMENTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.CONTACT_PAYMENTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				contactPayments: action.contactPayments,
				error: null
			};
		}

		case actionTypes.CONTACT_PAYMENTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SALE_HISTORY_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.SALE_HISTORY_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				saleHistory: action.saleHistory,
				error: null
			};
		}

		case actionTypes.SALE_HISTORY_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.SALE_DETAILS_RESET: {
			return {
				...state,
				saleDetails: null
			};
		}

		case actionTypes.SET_CHECKBOX_SALE_FILTER: {
			return {
				...state,
				checkboxFilters: {
					...state.checkboxFilters,
					[action.name]: action.value
				}
			};
		}

		case actionTypes.CLEAR_SALE_CHECKBOX_FILTERS: {
			return {
				...state,
				checkboxFilters: {
					...initialState.checkboxFilters
				}
			};
		}

		default: {
			return state;
		}
	}
};

export default saleReducer;
