import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

export default [
	{
		path: '/survey/:token',
		exact: true,
		component: lazy(() => import('src/pages/Survey/CustomerProcess'))
	},
	{
		path: '/survey/answers',
		exact: true,
		component: lazy(() => import('src/pages/Survey/CustomerProcess'))
	},
	{
		path: '/thank-you',
		exact: true,
		component: lazy(() => import('src/pages/Survey/ThankYouPage'))
	},
	{
		path: '/admin/auth',
		component: AuthLayout,
		routes: [
			{
				path: '/admin/auth/login',
				exact: true,
				component: lazy(() => import('src/pages/Login'))
			},
			{
				component: () => <Redirect to="/admin/dashboard" />
			}
		]
	},
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/admin/dashboard" />
	},
	{
		path: '/admin/',
		component: DashboardLayout,
		routes: [
			{
				path: '/admin/projects',
				exact: true,
				component: lazy(() => import('src/pages/Projects'))
			},
			{
				path: '/admin/approve/:id',
				exact: true,
				component: lazy(() => import('src/pages/ActionApprove'))
			},
			{
				path: '/admin/projects/:id',
				exact: true,
				component: lazy(() => import('src/pages/Projects/details'))
			},
			{
				path: '/admin/users',
				exact: true,
				component: lazy(() => import('src/pages/Users'))
			},
			{
				path: '/admin/userPermissions',
				exact: true,
				component: lazy(() => import('src/pages/UserPermissions'))
			},
			{
				path: '/admin/users/:id',
				exact: true,
				component: lazy(() => import('src/pages/Users/details'))
			},
			{
				path: '/admin/profile/edit',
				exact: true,
				component: lazy(() => import('src/pages/Users/Edit'))
			},
			{
				path: '/admin/dashboard',
				exact: true,
				component: lazy(() => import('src/pages/Dashboard'))
			},
			{
				path: '/admin/dashboard/edit',
				exact: true,
				component: lazy(() => import('src/pages/Dashboard/EditPage'))
			},
			{
				path: '/admin/reminders',
				exact: true,
				component: lazy(() => import('src/pages/Reminders'))
			},
			{
				path: '/admin/reminders/:id',
				exact: true,
				component: lazy(() => import('src/pages/Reminders/details'))
			},
			{
				path: '/admin/contacts',
				exact: true,
				component: lazy(() => import('src/pages/Contacts'))
			},
			{
				path: '/admin/messages',
				exact: true,
				component: lazy(() => import('src/pages/Message'))
			},
			{
				path: '/admin/contacts/:id',
				exact: true,
				component: lazy(() => import('src/pages/Contacts/details'))
			},
			{
				path: '/admin/contacts/:contactId/survey/:surveyId',
				exact: true,
				component: lazy(() => import('src/pages/Survey/ManualProcess'))
			},
			{
				path: '/admin/stages',
				exact: true,
				component: lazy(() => import('src/pages/Stages'))
			},
			{
				path: '/admin/stages/:id',
				exact: true,
				component: lazy(() => import('src/pages/Stages/details'))
			},
			{
				path: '/admin/stages/:id/details/:lotId',
				exact: true,
				component: lazy(() => import('src/pages/Lots/details'))
			},
			{
				path: '/admin/lots',
				exact: true,
				component: lazy(() => import('src/pages/Lots'))
			},
			{
				path: '/admin/lots/:id',
				exact: true,
				component: lazy(() => import('src/pages/Lots/details'))
			},
			{
				path: '/admin/packages',
				exact: true,
				component: lazy(() => import('src/pages/Packages'))
			},
			{
				path: '/admin/packages/:id/history',
				exact: true,
				component: lazy(() => import('src/pages/Packages/History'))
			},
			{
				path: '/admin/packages/:id',
				exact: true,
				component: lazy(() => import('src/pages/Packages/details'))
			},
			{
				path: '/admin/house-types',
				exact: true,
				component: lazy(() => import('src/pages/Houses'))
			},
			{
				path: '/admin/house-types/:id',
				exact: true,
				component: lazy(() => import('src/pages/Houses/details'))
			},
			{
				path: '/admin/builders',
				exact: true,
				component: lazy(() => import('src/pages/Houses/Builders'))
			},
			{
				path: '/admin/builders/:id',
				exact: true,
				component: lazy(() => import('src/pages/Houses/Builders/details'))
			},
			{
				path: '/admin/builders-list',
				exact: true,
				component: lazy(() => import('src/pages/BuilderList'))
			},
			{
				path: '/admin/builders-list/:id',
				exact: true,
				component: lazy(() => import('src/pages/BuilderList'))
			},
			{
				path: '/admin/poi/categories',
				exact: true,
				component: lazy(() => import('src/pages/Map/Category'))
			},
			{
				path: '/admin/poi/external',
				exact: true,
				component: lazy(() => import('src/pages/Map/Nearby'))
			},
			{
				path: '/admin/poi/external/:id',
				exact: true,
				component: lazy(() => import('src/pages/Map/Nearby/details'))
			},
			{
				path: '/admin/poi/onsite',
				exact: true,
				component: lazy(() => import('src/pages/Map/Whiterock'))
			},
			{
				path: '/admin/poi/onsite/:id',
				exact: true,
				component: lazy(() => import('src/pages/Map/Whiterock/details'))
			},
			{
				path: '/admin/documents/:type',
				exact: true,
				component: lazy(() => import('src/pages/Documents'))
			},
			{
				path: '/admin/sales',
				exact: true,
				component: lazy(() => import('src/pages/Sales'))
			},
			{
				path: '/admin/sales-details/:id',
				exact: true,
				component: lazy(() => import('src/pages/Sales/details'))
			},
			{
				path: '/admin/sales/create',
				exact: true,
				component: lazy(() => import('src/pages/Sales/SalesCreate'))
			},
			{
				path: '/admin/sales/create/:id',
				exact: true,
				component: lazy(() => import('src/pages/Sales/SalesWizard'))
			},
			{
				path: '/admin/sales/process-edit/:id',
				exact: true,
				component: lazy(() => import('src/pages/Sales/details/ProcessEdit'))
			},
			{
				path: '/admin/commissions-list',
				exact: true,
				component: lazy(() => import('src/pages/CommissionsList'))
			},
			{
				path: '/admin/my-commissions',
				exact: true,
				component: lazy(() => import('src/pages/MyCommissions'))
			},
			{
				path: '/admin/contact-groups',
				exact: true,
				component: lazy(() => import('src/pages/ContactGroups'))
			},
			{
				path: '/admin/contact-groups/:id',
				exact: true,
				component: lazy(() => import('src/pages/ContactGroups/details'))
			},
			{
				path: '/admin/payments',
				exact: true,
				component: lazy(() => import('src/pages/PaymentsList'))
			},
			{
				path: '/admin/walk-in',
				exact: true,
				component: lazy(() => import('src/pages/WalkIn'))
			},
			{
				path: '/admin/documenttypes',
				exact: true,
				component: lazy(() => import('src/pages/DocumentTypeManager'))
			},
			{
				path: '/admin/document-manager',
				exact: true,
				component: lazy(() => import('src/pages/DocumentManager'))
			},
			{
				path: '/admin/presentations/:id',
				exact: true,
				component: lazy(() => import('src/pages/Presentations/details/index'))
			},
			{
				path: '/admin/presentations',
				exact: true,
				component: lazy(() => import('src/pages/Presentations'))
			},
			{
				path: '/admin/options-lists',
				exact: true,
				component: lazy(() => import('src/pages/OptionsLists'))
			},
			{
				path: '/admin/options-list/create',
				exact: true,
				component: lazy(() => import('src/pages/OptionsLists/OptionsListCreate'))
			},
			{
				path: '/admin/options-list/create/:id',
				exact: true,
				component: lazy(() => import('src/pages/OptionsLists/OptionsListCreate'))
			},
			{
				path: '/admin/surveys',
				exact: true,
				component: lazy(() => import('src/pages/Survey'))
			},
			{
				path: '/admin/surveys/create',
				exact: true,
				component: lazy(() => import('src/pages/Survey/CreateSurvey'))
			},
			{
				path: '/admin/surveys/edit/:id',
				exact: true,
				component: lazy(() => import('src/pages/Survey/CreateSurvey'))
			},
			{
				path: '/admin/surveys/:id',
				exact: true,
				component: lazy(() => import('src/pages/Survey/details'))
			},
			{
				path: '/admin/contact-profiles',
				exact: true,
				component: lazy(() => import('src/pages/PurchaserProfiles'))
			},
			{
				path: '/admin/contact-profiles/:id',
				exact: true,
				component: lazy(() => import('src/pages/PurchaserProfiles/details'))
			},
			{
				path: '/admin/reports',
				exact: true,
				component: lazy(() => import('src/pages/ReportSystem'))
			},
			{
				path: '/admin/reports/:id',
				exact: true,
				component: lazy(() => import('src/pages/ReportSystem/details'))
			},
			{
				path: '/admin/reports/:id/no-settled',
				exact: true,
				component: lazy(() =>
					import('src/pages/ReportSystem/details/FutureSettlement/NoSettlementTable')
				)
			},
			{
				path: '/admin/reports/:id/overdue-settlements',
				exact: true,
				component: lazy(() =>
					import('src/pages/ReportSystem/details/FutureSettlement/NoSettlementTable')
				)
			},
			{
				path: '/admin/builder-login',
				exact: true,
				component: lazy(() => import('src/pages/BuilderLogin'))
			},
			{
				path: '/admin/builder-users',
				exact: true,
				component: lazy(() => import('src/pages/BuilderUsers'))
			},
			{
				path: '/admin/builder-users/:id',
				exact: true,
				component: lazy(() => import('src/pages/BuilderUsers/details'))
			},
			{
				path: '/admin/document-tracking',
				exact: true,
				component: lazy(() => import('src/pages/DocumentTracking'))
			},
			{
				path: '/admin/dap-users',
				exact: true,
				component: lazy(() => import('src/pages/UsersDAP'))
			},
			{
				path: '/admin/dap-users/:id',
				exact: true,
				component: lazy(() => import('src/pages/UsersDAP/details'))
			},
			{
				path: '/admin/email',
				exact: true,
				component: lazy(() => import('src/pages/Emails'))
			},
			{
				path: '/admin/contact-notes',
				exact: true,
				component: lazy(() => import('src/pages/ContactNotes'))
			},
			{
				path: '/admin/calendar-view',
				exact: true,
				component: lazy(() => import('src/pages/CalendarView'))
			}
		]
	},
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	}
];
