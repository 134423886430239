import { combineReducers } from 'redux';
import projectReducer from './projectReducer';
import customerReducer from './customerReducer';
import commonReducer from './commonReducer';
import lotReducer from './lotReducer';
import packageReducer from './packageReducer';
import mapReducer from './mapReducer';
import stageReducer from './stageReducer';
import userReducer from './userReducer';
import documentReducer from './documentReducer';
import saleReducer from './saleReducer';
import commissionsReducer from './commissionsReducer';
import reminderReducer from './reminderReducer';
import dashboardReducer from './dashboardReducer';
import permissionReducer from './permissionReducer';
import optionsListsReducer from './optionsListsReducer';
import walkInReducer from './walkInReducer';
import surveyReducer from './surveyReducer';
import reportSystemReducer from './reportSystemReducer';
import builderPortalReduces from './builderPortal';
import dapReducer from './userDAP';
import outlookReducer from './outlookReducer';
import sideMenuReducer from './sideMenuReducer';
import presentationsReducer from './presentationReducer';
import messageReducer from './messageReducer';
import notificationsReducer from './notificationsReducer';
import confirmationActionsReducer from './confirmationActionsReducer';

const rootReducer = combineReducers({
	project: projectReducer,
	sideMenu: sideMenuReducer,
	customers: customerReducer,
	common: commonReducer,
	lots: lotReducer,
	packages: packageReducer,
	maps: mapReducer,
	stages: stageReducer,
	users: userReducer,
	documents: documentReducer,
	sales: saleReducer,
	commissions: commissionsReducer,
	reminders: reminderReducer,
	dashboard: dashboardReducer,
	permissions: permissionReducer,
	optionsLists: optionsListsReducer,
	walkIn: walkInReducer,
	survey: surveyReducer,
	reportSystem: reportSystemReducer,
	builderPortal: builderPortalReduces,
	dap: dapReducer,
	outlook: outlookReducer,
	presentations: presentationsReducer,
	message: messageReducer,
	notifications: notificationsReducer,
	confirmationActions: confirmationActionsReducer
});

export default rootReducer;
