import axios from 'axios';

export const saveContactRelation = ({ contactId, linkedContact, selectedProject }) =>
	axios.post(`/api/projects/${selectedProject}/link-contact`, { contactId, linkedContact });

export const deleteContactRelation = ({ id, customerId, selectedProject }) =>
	axios.delete(`/api/projects/${selectedProject}/link-contact/${customerId}/${id}`);

export const updateContactRelation = ({ linkedContact, contactId, selectedProject }, edit) =>
	axios.put(`/api/projects/${selectedProject}/link-contact/update`, {
		linkedContact,
		contactId,
		relationId: edit.id,
		previousContact: edit.contactId.id
	});

export const sendFavouriteEmail = ({ selectedProject, ...rest }) =>
	axios.put(`/api/projects/${selectedProject}/send-favourite-email`, { ...rest });

export const getLinkedContactsNotes = (currentProject, linkedContacts) =>
	axios.post(`/api/projects/${currentProject}/contact-notes/linked-contacts`, { linkedContacts });

export const getContactNoteByOutlookId = (currentProject, outlookId) =>
	axios.get(`/api/projects/${currentProject}/contact-notes-outlook/${outlookId}`);

export const postCustomerOutlookNoteApi = (currentProject, data) =>
	axios.post(`/api/projects/${currentProject}/contacts/contact-notes`, { ...data });

export const getAllContacts = (currentProject) =>
	axios.get(`/api/projects/${currentProject}/contacts-all`);

export const getContactByEmailApi = (currentProject, data) =>
	axios.put(`/api/projects/${currentProject}/contacts-mail`, { ...data });
