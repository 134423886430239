import {
	saveContactRelation,
	deleteContactRelation,
	updateContactRelation,
	sendFavouriteEmail,
	getLinkedContactsNotes,
	getContactNoteByOutlookId,
	postCustomerOutlookNoteApi,
	getAllContacts,
	getContactByEmailApi
} from 'src/api/contactApi';
import { fetchCustomerDetails } from 'src/api';
import * as actionTypes from '../actions';

export const saveRelation = (data, edit) => async (dispatch) => {
	await dispatch(actionTypes.contactRelationsLinkingPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const linked = edit.id
			? await updateContactRelation(data, edit)
			: await saveContactRelation(data);
		await dispatch(actionTypes.contactRelationsLinkingSuccess(linked));
		await dispatch(fetchCustomerDetails(data.selectedProject, data.contactId));
	} catch (error) {
		await dispatch(actionTypes.contactRelationsLinkingError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save relation error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const deleteRelation = (data) => async (dispatch) => {
	await dispatch(actionTypes.contactRelationsDeletePending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const linked = await deleteContactRelation(data);
		await dispatch(actionTypes.contactRelationsDeleteSuccess(linked));
		await dispatch(fetchCustomerDetails(data.selectedProject, data.customerId));
	} catch (error) {
		await dispatch(actionTypes.contactRelationsDeleteError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Delete relation error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const sendFavouriteEmailRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await sendFavouriteEmail(data);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Send Email error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchLinkedContactsNotes = (currentProject, linkedContacts) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.linkedContactsNotesFetchPending());
	try {
		const data = await getLinkedContactsNotes(currentProject, linkedContacts);
		await dispatch(actionTypes.linkedContactsNotesFetchSuccess(data));
	} catch (error) {
		await dispatch(actionTypes.linkedContactsNotesFetchError());
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get notes error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchContactNoteByOutlookId = (currentProject, outlookId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const res = await getContactNoteByOutlookId(currentProject, outlookId);
		await dispatch(actionTypes.setLoader(false));
		return res.data;
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		return null;
	}
};

export const postCustomerOutlookNote = (currentProject, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await postCustomerOutlookNoteApi(currentProject, data);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error;
	}
};

export const fetchContacts = (currentProject) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchAllContactsPending());

	try {
		const res = await getAllContacts(currentProject);
		await dispatch(actionTypes.fetchAllContactsSuccess(res.data));
	} catch (error) {
		await dispatch(actionTypes.fetchAllContactsError('Fetch contacts error'));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Fetch contacts error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};

export const getContactByEmail = (currentProject, data) => async (dispatch) => {
	try {
		await dispatch(actionTypes.setLoader(true));
		return await getContactByEmailApi(currentProject, data);
	} catch (e) {
		console.log(e);
	} finally {
		await dispatch(actionTypes.setLoader(false));
	}
};
