/* eslint-disable no-confusing-arrow */
export const getCustomers = (state) => state.customers.customers;
export const getContacts = (state) => state.customers.contacts;
export const getContactsPending = (state) => state.customers.contactsPending;
export const getCustomerPending = (state) => state.customers.pending;
export const getCustomersDetails = (state) => state.customers.details;
export const getCustomerId = (state) => state.customers.details && state.customers.details.data.id;
export const getCustomerNotes = (state) =>
	state.customers.notes &&
	state.customers.notes.data.items.map((item) => ({
		...item,
		createdAt: item.noteDate ? item.noteDate : item.createdAt
	}));
export const getCustomerfavourites = (state) => state.customers.details.data.favourites;
export const getCampaignDetails = (state) => state.customers.campaignDetails;

export const getFavourites = (state) =>
	state.customers.favourites ? state.customers.favourites.data.lots : [];

export const getFavouritesPackages = (state) =>
	state.customers.favourites ? state.customers.favourites.data.packages : [];

export const getContactGroups = (state) =>
	state.customers.contactGroups
		? state.customers.contactGroups.data?.filter((el) => !el.isGlobalOnly)
		: [];

export const getAllContactGroups = (state) =>
	state.customers.contactGroups ? state.customers.contactGroups.data : [];

export const getContactGroupsList = (state) =>
	state.customers.contactGroupsList ? state.customers.contactGroupsList.data : [];

export const getContactGroupPending = (state) => state.customers.pending;

export const getContactGroupDetails = (state) =>
	state.customers.contactGroupDetails ? state.customers.contactGroupDetails.data : null;

export const getContactGroupDetailsModal = (state) =>
	state.customers.contactGroupDetailsModal ? state.customers.contactGroupDetailsModal.data : [];

export const getLinkedContactId = (state) => state.customers.linkedContact;

export const getLinkedNotes = (state) => state.customers.linkedNotes;

export const getContactsPagination = (state) =>
	state.customers.customersPagination && state.customers.customersPagination;

export const getCustomersFilters = (state) =>
	state.customers.customersFilters && state.customers.customersFilters;

export const getNotesFilters = (state) => state.customers.notesFilters;

export const getProjectContactNotes = (state) => state.customers.projectContactNotes;

export const getProjectContactNotesPagination = (state) =>
	state.customers.projectContactNotesPagination;

export const getCustomerMessages = (state) => state.customers.messages;

export const getCustomerMessagesFilters = (state) => state.customers.messagesFilters;
