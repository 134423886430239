import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	users: null,
	userDetails: null,
	auth: null,
	lotHistory: null,
	packageHistory: null,
	saleHistory: null,
	userRows: null,
	userShowSettled: false,
	showInactiveContacts: null,
	userPermissions: null
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.USERS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USERS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				users: action.users,
				error: null
			};
		}

		case actionTypes.USERS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.USER_DETAILS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USER_DETAILS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				userDetails: action.userDetails,
				error: null
			};
		}

		case actionTypes.USER_DETAILS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.SET_USER_AUTH: {
			return {
				...state,
				auth: action.user,
				userRows: { data: { selectedRows: action.user.selectedRows } }
			};
		}

		case actionTypes.USERS_HISTORY_LOT_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USERS_HISTORY_LOT_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				lotHistory: action.history,
				error: null
			};
		}

		case actionTypes.USERS_HISTORY_LOT_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.USERS_HISTORY_PACKAGE_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USERS_HISTORY_PACKAGE_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				packageHistory: action.history,
				error: null
			};
		}

		case actionTypes.USERS_HISTORY_PACKAGE_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.USERS_HISTORY_SALE_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USERS_HISTORY_SALE_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				saleHistory: action.saleHistory,
				error: null
			};
		}

		case actionTypes.USERS_HISTORY_SALE_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.USER_ROWS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USER_ROWS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				userRows: action.user,
				error: null
			};
		}

		case actionTypes.UPDATE_USER_ROWS: {
			return {
				...state,
				userRows: {
					...state.userRows,
					data: {
						...state.userRows.data,
						selectedRows: action.userRows
					}
				}
			};
		}

		case actionTypes.USER_ROWS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.SET_USER_PERMISSIONS: {
			return {
				...state,
				userPermissions: action.data
			};
		}

		case actionTypes.RESET_USER_STORE: {
			return {
				...state,
				...initialState
			};
		}

		case actionTypes.USER_DETAILS_RESET: {
			return {
				...state,
				userDetails: null
			};
		}

		case actionTypes.USER_SETTLED_LOTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USER_SETTLED_LOTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				userShowSettled: action.data,
				error: null
			};
		}

		case actionTypes.USER_SETTLED_LOTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.USER_SHOW_INACTIVE_CONTACTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.USER_SHOW_INACTIVE_CONTACTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				showInactiveContacts: action.data,
				error: null
			};
		}

		case actionTypes.USER_SHOW_INACTIVE_CONTACTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default userReducer;
