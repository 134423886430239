import * as actionTypes from '../actions';

const initialState = {
	pending: false,
	error: null,
	data: []
};

const permissionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_WALK_IN_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_WALK_IN_SUCCESS: {
			return {
				...state,
				pending: false,
				data: action.data
			};
		}
		case actionTypes.GET_WALK_IN_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default permissionReducer;
