import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedProject, getCustomers, getCustomerPending } from 'src/selectors';
import { getPrimaryPhone } from 'src/utils';
import { fetchCustomers } from 'src/api';

function ContactAutocomplete({ onSelect, filter, label, value, defaultValue, id, ...props }) {
	const dispatch = useDispatch();
	const contacts = useSelector(getCustomers);
	const contactPending = useSelector(getCustomerPending);
	const selectedProject = useSelector(getSelectedProject);
	const [searchString, setSearchString] = useState('');
	const items = contacts?.data?.items || [];
	const filteredContacts = filter ? items.filter(filter) : items;

	useEffect(() => {
		dispatch(fetchCustomers(selectedProject, undefined, 50, { search: searchString }));
	}, [dispatch, selectedProject, searchString]);

	const handleSearchDebounce = _.debounce(
		(v) => {
			setSearchString(v);
		},
		300,
		{ trailing: true }
	);

	if (
		defaultValue &&
		value === defaultValue.id &&
		!filteredContacts.find((c) => c.id === defaultValue.id)
	) {
		// add default value to list if it's not there
		filteredContacts.unshift(defaultValue);
	}

	return (
		<Autocomplete
			fullWidth
			onInputChange={(event, newInputValue) => {
				if (event?.target?.name === 'contactId' || !newInputValue) {
					handleSearchDebounce(newInputValue);
				}
			}}
			name="contactId"
			options={filteredContacts}
			getOptionLabel={(option) =>
				`${option.fullName} (${option.email}) ${getPrimaryPhone(option.phones)}`
			}
			// eslint-disable-next-line no-underscore-dangle
			getOptionSelected={(option) => option._id === value || option.id === value}
			loading={contactPending}
			renderInput={(params) => (
				<TextField
					{...params}
					fullWidth
					label={label || 'Select Contact'}
					value={params}
					variant="outlined"
					name="contactId"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{contactPending ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
			renderOption={(option) => {
				const s = `${option.fullName} ( ${option.email} ) ${getPrimaryPhone(
					option.phones
				)}`;
				const matches = match(s, searchString);
				const parts = parse(s, matches);
				return (
					<div>
						{parts.map((part, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<span
								key={index}
								style={{ fontWeight: part.highlight ? 700 : 400 }}
							>
								{part.text}
							</span>
						))}
					</div>
				);
			}}
			defaultValue={defaultValue}
			onChange={(e, v) => onSelect(e, v)}
			{...props}
		/>
	);
}
ContactAutocomplete.propTypes = {
	label: PropTypes.string,
	onSelect: PropTypes.func,
	filter: PropTypes.func,
	value: PropTypes.any,
	defaultValue: PropTypes.any,
	id: PropTypes.string
};
export default ContactAutocomplete;
