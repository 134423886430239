import { createTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const baseTheme = {
	palette,
	typography,
	overrides,
	props: {
		MuiButton: {
			size: 'small'
		},
		MuiFilledInput: {
			margin: 'dense'
		},
		MuiFormControl: {
			margin: 'dense'
		},
		MuiFormHelperText: {
			margin: 'dense'
		},
		MuiIconButton: {
			size: 'small'
		},
		MuiInputBase: {
			margin: 'dense'
		},
		MuiInputLabel: {
			margin: 'dense'
		},
		MuiListItem: {
			dense: true
		},
		MuiOutlinedInput: {
			margin: 'dense'
		},
		MuiFab: {
			size: 'small'
		},
		MuiTable: {
			size: 'small'
		},
		MuiTextField: {
			margin: 'dense'
		},
		MuiToolbar: {
			variant: 'dense'
		}
	}
};

export const theme = createTheme(baseTheme);

export const defaultTheme = baseTheme;
