/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Modal,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
	Divider,
	Typography,
	TextField,
	Switch,
	Button
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { checkIsPositiveInteger } from 'src/utils';
import MultiSelectGroups from 'src/customComponents/MultiSelectGroups';
import TooltipBlock from 'src/customComponents/TooltipBlock';
import SearchLocationInput from 'src/components/SearchLocationInput';
import {
	getSelectedProject,
	getContactGroups,
	getLinkedContactId,
	getProjectDetails
} from 'src/selectors';
import { setLinkedContactId, showToast } from 'src/actions';
import { postCustomer, fetchContactGroups } from 'src/api';
import {
	emailValidator,
	enquirySource,
	purchaseTimeframe,
	titles,
	states,
	emailTypes,
	phoneTypes,
	initialContactMethod,
	contactsRelationsOptions
} from 'src/Constants';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%'
	},
	actions: {
		justifyContent: 'flex-end'
	},
	textareaAutosize: {
		width: '100%',
		fontSize: '14px',
		fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'
	},
	noteParagraph: {
		marginRight: '10px'
	},
	noteType: {
		display: 'flex',
		alignItems: 'center'
	},
	statusParagraph: {
		marginRight: '10px'
	},
	status: {
		display: 'flex',
		alignItems: 'center'
	}
}));

const S = {
	TextField: styled(TextField)`
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	`,
	SectionHeader: styled.h2`
		font-weight: 700;
	`
};

function AddContactModal({ open, onClose, className, id, ...rest }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const currentProject = useSelector(getSelectedProject);
	const currentProjectDetails = useSelector(getProjectDetails);
	const contactGroups = useSelector(getContactGroups);
	const linkedContactId = useSelector(getLinkedContactId);
	const [values, setValues] = useState({
		howDidYouHearAboutUs: '',
		communicationsEnabled: true,
		trackingEnabled: true,
		yearOfBirth: null,
		contactGroup: [],
		title: '',
		purchaseTimeframe: 'Unqualified',
		// contactMethod: 'Web',
		emails: [
			{
				emailAddress: '',
				emailType: 'Personal',
				emailPrimary: true
			}
		],
		phones: [
			{
				phoneNumber: '',
				phoneType: 'Mobile',
				phonePrimary: true
			}
		],
		addresses: [
			{
				street: '',
				suburb: '',
				state: '',
				country: 'Australia',
				postCode: '',
				addressPrimary: true,
				addressType: 'Personal'
			}
		]
	});
	const [groupValues, setGroupValues] = useState(null);
	const [defaultGroup, setDefaultGroup] = useState('');

	useEffect(() => {
		if (currentProject) {
			dispatch(fetchContactGroups(currentProject, true));
		}
	}, [dispatch, currentProject, open]);

	useEffect(() => {
		if (contactGroups) {
			const obj = {};
			// eslint-disable-next-line array-callback-return
			contactGroups.map((el) => {
				const ids = el._id;
				const name = el.groupName;
				obj[ids] = name;
				if (el.defaultGroup) {
					setDefaultGroup(el._id);
					setValues((currentValues) => ({
						...currentValues,
						contactGroup: [el._id]
					}));
				}
			});
			setGroupValues(obj);
		}
	}, [contactGroups]);

	const callbackMultiselect = (childData) =>
		setValues((currentValues) => ({
			...currentValues,
			contactGroup: childData
		}));

	const handleFieldChange = (event) => {
		event.persist();
		setValues((currentValues) => ({
			...currentValues,
			[event.target.name]:
				event.target.type === 'checkbox' ? event.target.checked : event.target.value
		}));
	};

	const handleDateChange = (date, type) => {
		setValues((currentValues) => ({
			...currentValues,
			[type]: date
		}));
	};

	const handleEmailChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...values.emails];
		list[index][name] = value;
		setValues((currentValues) => ({
			...currentValues,
			emails: list
		}));
	};

	const handlePhoneChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...values.phones];
		list[index][name] = value;
		setValues((currentValues) => ({
			...currentValues,
			phones: list
		}));
	};

	const handleAddressChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...values.addresses];
		list[index][name] = value;
		setValues((currentValues) => ({
			...currentValues,
			addresses: list
		}));
	};

	const setAddress = (val) => {
		const findData = (type) => val.find((c) => c.types.indexOf(type) >= 0)?.short_name || '';
		const findDataL = (type) => val.find((c) => c.types.indexOf(type) >= 0)?.long_name || '';
		const postCode = findData('postal_code');
		const street = findDataL('route');
		const subpremise = findDataL('subpremise');
		const streetNumber = findData('street_number');
		const country = findDataL('country');
		const suburb = findDataL('locality');
		const state = findData('administrative_area_level_1');
		const data = [...values.addresses];

		setValues((currentValues) => ({
			...currentValues,
			addresses: [
				{
					...data[0],
					postCode,
					street: `${subpremise ? `${subpremise}/` : ''}${streetNumber} ${street}`,
					country,
					suburb,
					state
				}
			]
		}));
	};

	const onAdd = async () => {
		if (
			values.firstName &&
			values.lastName &&
			values.emails[0].emailAddress &&
			values.howDidYouHearAboutUs &&
			values.contactMethod
		) {
			if (emailValidator.test(values.emails[0].emailAddress)) {
				await dispatch(
					postCustomer(currentProject, {
						...values,
						...(linkedContactId && { linkedContact: linkedContactId })
					})
				).then((res) => {
					dispatch(
						showToast({
							body: 'New Contact added'
						})
					);
					dispatch(setLinkedContactId(null));
					onClose();
					history.push(`/admin/contacts/${res.data.id}`);
				});
			} else {
				dispatch(
					showToast({
						body: 'Invalid email address',
						type: 'warning'
					})
				);
			}
		} else {
			dispatch(
				showToast({
					body: 'First Name, Last Name, Email Address and Initial Enquiry Source are required fields',
					type: 'warning'
				})
			);
		}
	};

	const addLinked = async () => {
		if (
			values.firstName &&
			values.lastName &&
			values.emails[0].emailAddress &&
			values.howDidYouHearAboutUs &&
			values.contactMethod
		) {
			if (emailValidator.test(values.emails[0].emailAddress)) {
				await dispatch(postCustomer(currentProject, values))
					.then((res) => {
						dispatch(setLinkedContactId(res.data?.id));
					})
					.then(() => {
						dispatch(
							showToast({
								body: 'New Contact added'
							})
						);
						setValues((prevValues) => ({
							...prevValues,
							title: '',
							firstName: '',
							lastName: '',
							middleName: '',
							prefferedName: '',
							companyName: '',
							relationType: 'Partner',
							emails: [
								{
									emailAddress: '',
									emailType: 'Personal',
									emailPrimary: true
								}
							],
							phones: [
								{
									phoneNumber: '',
									phoneType: 'Mobile',
									phonePrimary: true
								}
							],
							// howDidYouHearAboutUs: '',
							yearOfBirth: null,
							// contactMethod: '',
							purchaseTimeframe: 'Unqualified',
							communicationsEnabled: true,
							trackingEnabled: true
						}));
					});
			} else {
				dispatch(
					showToast({
						body: 'Invalid email address',
						type: 'warning'
					})
				);
			}
		} else {
			dispatch(
				showToast({
					body: 'First Name, Last Name, Email Address and Initial Enquiry Source are required fields',
					type: 'warning'
				})
			);
		}
	};

	const onCloseModal = async () => {
		await dispatch(setLinkedContactId(null));
		onClose();
	};

	if (!open) {
		return null;
	}

	return (
		<Modal
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					onClose(event, reason);
				}
			}}
			open={open}
		>
			<Card
				{...rest}
				className={clsx(classes.root, className)}
			>
				<form>
					<CardHeader title="Add Contact" />
					<Divider />
					<CardContent>
						<Typography variant="subtitle2">
							<p>
								<b>First Name</b>, <b>Last Name</b> ,<b>Email Address</b>,{' '}
								<b>Initial Enquiry Source</b> and <b>Initial Contact Method</b> are
								required.
							</p>
						</Typography>
					</CardContent>
					<Divider />
					<CardContent>
						<Grid
							container
							spacing={3}
						>
							<Grid
								item
								md={12}
								xs={12}
							>
								<S.SectionHeader>Name</S.SectionHeader>
							</Grid>
							<Grid
								item
								md={2}
								xs={12}
							>
								<div>
									<TextField
										fullWidth
										label="Title"
										name="title"
										onChange={handleFieldChange}
										value={values.title}
										select
										variant="outlined"
										SelectProps={{ native: true }}
									>
										{titles.map((option) => (
											<option
												key={option}
												value={option}
											>
												{option}
											</option>
										))}
									</TextField>
								</div>
							</Grid>
							<Grid
								item
								md={5}
								xs={12}
							>
								<TextField
									fullWidth
									label="First Name *"
									name="firstName"
									value={values?.firstName}
									onChange={handleFieldChange}
									variant="outlined"
								/>
							</Grid>
							<Grid
								item
								md={5}
								xs={12}
							>
								<TextField
									fullWidth
									label="Last Name *"
									name="lastName"
									value={values?.lastName}
									onChange={handleFieldChange}
									variant="outlined"
								/>
							</Grid>
							<Grid
								item
								md={4}
								xs={12}
							>
								<TextField
									fullWidth
									label="Middle Name"
									name="middleName"
									value={values?.middleName}
									onChange={handleFieldChange}
									variant="outlined"
								/>
							</Grid>
							<Grid
								item
								md={4}
								xs={12}
							>
								<TextField
									fullWidth
									label="Preferred Name"
									name="prefferedName"
									value={values?.prefferedName}
									onChange={handleFieldChange}
									variant="outlined"
								/>
							</Grid>
							<Grid
								item
								md={4}
								xs={12}
							>
								<TextField
									fullWidth
									label="Company Name"
									name="companyName"
									value={values?.companyName}
									onChange={handleFieldChange}
									variant="outlined"
								/>
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
							>
								<S.SectionHeader>Contact Details</S.SectionHeader>
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<div>
									<TextField
										fullWidth
										label="Email Type"
										name="emailType"
										variant="outlined"
										onChange={(e) => handleEmailChange(e, 0)}
										select
										SelectProps={{ native: true }}
										value={values.emails && values.emails[0].emailType}
									>
										{emailTypes.map((option) => (
											<option
												key={option}
												value={option}
											>
												{option}
											</option>
										))}
									</TextField>
								</div>
							</Grid>
							<Grid
								item
								md={6}
								xs={12}
							>
								<TextField
									fullWidth
									label="Email Address *"
									name="emailAddress"
									value={values?.emails[0].emailAddress}
									onChange={(e) => handleEmailChange(e, 0)}
									variant="outlined"
									type="email"
								/>
							</Grid>
							<Grid
								item
								md={3}
								xs={false}
							/>
							<Grid
								item
								md={3}
								xs={12}
							>
								<div>
									<TextField
										fullWidth
										label="Phone Type"
										name="phoneType"
										variant="outlined"
										onChange={(e) => handlePhoneChange(e, 0)}
										select
										SelectProps={{ native: true }}
										value={values.phones && values.phones[0].phoneType}
									>
										{phoneTypes.map((option) => (
											<option
												key={option}
												value={option}
											>
												{option}
											</option>
										))}
									</TextField>
								</div>
							</Grid>
							<Grid
								item
								md={6}
								xs={12}
							>
								<S.TextField
									fullWidth
									label="Phone"
									name="phoneNumber"
									value={values?.phones[0].phoneNumber}
									onChange={(e) => handlePhoneChange(e, 0)}
									variant="outlined"
									type="text"
									onKeyPress={checkIsPositiveInteger}
								/>
							</Grid>
							<Grid
								item
								md={3}
								xs={false}
							/>
							<Grid
								item
								md={12}
								xs={12}
							>
								<S.SectionHeader>Address</S.SectionHeader>
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<div>
									<TextField
										fullWidth
										label="Address Type"
										name="addressType"
										variant="outlined"
										onChange={(e) => handleAddressChange(e, 0)}
										select
										SelectProps={{ native: true }}
										value={values.addresses && values.addresses[0].addressType}
									>
										{emailTypes.map((option) => (
											<option
												key={option}
												value={option}
											>
												{option}
											</option>
										))}
									</TextField>
								</div>
							</Grid>
							<Grid
								item
								md={9}
								xs={12}
							>
								<SearchLocationInput onSelect={({ ac }) => setAddress(ac)} />
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<TextField
									fullWidth
									label="Street"
									name="street"
									onChange={(e) => handleAddressChange(e, 0)}
									variant="outlined"
									value={values.addresses && values.addresses[0].street}
								/>
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<TextField
									fullWidth
									label="Suburb"
									name="suburb"
									onChange={(e) => handleAddressChange(e, 0)}
									variant="outlined"
									value={values.addresses && values.addresses[0].suburb}
								/>
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<TextField
									fullWidth
									label="State"
									name="state"
									variant="outlined"
									onChange={(e) => handleAddressChange(e, 0)}
									select
									SelectProps={{ native: true }}
									value={values.addresses && values.addresses[0].state}
								>
									{states.map((option) => (
										<option
											key={option}
											value={option}
										>
											{option}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<TextField
									fullWidth
									label="Postcode"
									name="postCode"
									onChange={(e) => handleAddressChange(e, 0)}
									variant="outlined"
									value={values.addresses && values.addresses[0].postCode}
								/>
							</Grid>
							<Grid
								item
								md={6}
								xs={12}
							>
								<TextField
									fullWidth
									label="Country"
									name="country"
									onChange={(e) => handleAddressChange(e, 0)}
									variant="outlined"
									value={values.addresses && values.addresses[0].country}
								/>
							</Grid>
							<Grid
								item
								md={12}
								xs={12}
							>
								<S.SectionHeader>Contact Management</S.SectionHeader>
							</Grid>
							<Grid
								item
								md={5}
								xs={12}
							>
								<TextField
									fullWidth
									label="Initial Enquiry Source *"
									name="howDidYouHearAboutUs"
									onChange={handleFieldChange}
									select
									variant="outlined"
									SelectProps={{ native: true }}
									value={values.howDidYouHearAboutUs}
								>
									{currentProjectDetails.frontendEnquirySources.map((option) => (
										<option
											key={option.name}
											value={option.name}
										>
											{option.name}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid
								item
								md={3}
								xs={12}
							>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										fullWidth
										id="date-picker-dialog"
										label="Birthday"
										format="dd/MM/yyyy"
										name="yearOfBirth"
										value={values.yearOfBirth}
										variant="outlined"
										onChange={(date) => handleDateChange(date, 'yearOfBirth')}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid
								item
								md={4}
								xs={12}
							>
								<TextField
									fullWidth
									label="Initial Contact Method *"
									name="contactMethod"
									onChange={handleFieldChange}
									select
									variant="outlined"
									SelectProps={{ native: true }}
									value={values.contactMethod}
								>
									<option value="" />
									{initialContactMethod.map((option) => (
										<option
											key={option}
											value={option}
										>
											{option}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid
								item
								md={5}
								xs={12}
							>
								<div>
									<TextField
										fullWidth
										label="Purchaser Status"
										name="purchaseTimeframe"
										onChange={handleFieldChange}
										select
										SelectProps={{ native: true }}
										value={values.purchaseTimeframe}
									>
										{purchaseTimeframe.map((option) => (
											<option
												key={option}
												value={option}
											>
												{option}
											</option>
										))}
									</TextField>
								</div>
							</Grid>
							<Grid
								item
								md={7}
								xs={12}
							>
								<MultiSelectGroups
									title="Contact Groups"
									data={groupValues}
									defaultGroup={defaultGroup}
									name="contactGroup"
									callBack={callbackMultiselect}
								/>
							</Grid>
							<Grid
								item
								md={4}
								xs={6}
							>
								Correspondence{' '}
								<Switch
									checked={values.communicationsEnabled}
									color="secondary"
									edge="start"
									name="communicationsEnabled"
									onChange={handleFieldChange}
								/>
								<TooltipBlock
									title={<span>Determines whether Contact receives EDMs</span>}
								/>
							</Grid>
							<Grid
								item
								md={4}
								xs={6}
							>
								Tracking{' '}
								<Switch
									checked={values.trackingEnabled}
									color="secondary"
									edge="start"
									name="trackingEnabled"
									onChange={handleFieldChange}
								/>
								<TooltipBlock
									title={
										<span>
											Determines whether Contact is tracked on website/front
											end (useful for ignoring staff)
										</span>
									}
								/>
							</Grid>
							{linkedContactId && (
								<Grid
									item
									md={4}
									xs={12}
								>
									<TextField
										fullWidth
										label="Relationship"
										name="relationType"
										variant="outlined"
										select
										SelectProps={{ native: true }}
										onChange={handleFieldChange}
										value={values?.relationType || ''}
										// defaultValue={editItem.relationType}
									>
										{contactsRelationsOptions.map((option) => (
											<option
												key={option}
												value={option}
											>
												{option}
											</option>
										))}
									</TextField>
								</Grid>
							)}
						</Grid>
					</CardContent>
					<CardActions className={classes.actions}>
						<Button onClick={onCloseModal}>Close</Button>
						<Button
							color="primary"
							onClick={addLinked}
							variant="contained"
							disabled={linkedContactId}
						>
							Save & Create Linked Contact
						</Button>
						<Button
							color="primary"
							onClick={onAdd}
							variant="contained"
						>
							Add
						</Button>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
}

AddContactModal.propTypes = {
	className: PropTypes.string,
	customer: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	id: PropTypes.string
};

AddContactModal.defaultProps = {
	open: false,
	onClose: () => {}
};

export default AddContactModal;
