export const FETCH_SIDE_MENU_PENDING = 'FETCH_SIDE_MENU_PENDING';
export const FETCH_SIDE_MENU_SUCCESS = 'FETCH_SIDE_MENU_SUCCESS';
export const FETCH_SIDE_MENU_ERROR = 'FETCH_SIDE_MENU_ERROR';

export const sideMenuFetchPending = () => ({
	type: FETCH_SIDE_MENU_PENDING
});

export const sideMenuFetchSuccess = (menu) => ({
	type: FETCH_SIDE_MENU_SUCCESS,
	menu
});

export const sideMenuFetchError = (error) => ({
	type: FETCH_SIDE_MENU_ERROR,
	error
});
