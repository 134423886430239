/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Suspense } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme } from './theme';
import { configureStore } from './store';
import routes from './routes';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/main.scss';
import Toast from './components/Toast';
import { fetchProjects } from './api/index';
import {
	setUserAuth,
	setUserPermissions,
	changeSelectedProject,
	setChangeProject
} from './actions';
import { getProjectChange, getUserPermissions } from './selectors';

import Authentication from './utils/Authentication';
import configureAxios from './utils/configureAxios';
import PermissionProvider from './utils/PermissionProvider';
import AzureProvider from './utils/AzureProvider';
import { msalConfig } from './utils/msalConfig';
import WhatsNewPopup from './customComponents/WhatsNew/WhatsNewPopup';
import { Config } from './utils/config';
import ErrorBoundary from './customComponents/ErrorBoundary';
import SiteErrorFallback from './customComponents/SiteErrorFallback';

const history = createBrowserHistory();
const store = configureStore();

function AppInside() {
	const dispatch = useDispatch();
	const userPermissions = useSelector(getUserPermissions);
	const projectAction = useSelector(getProjectChange);

	axios.defaults.baseURL = Config.API_BASE_URL;

	useEffect(() => {
		const onRequest = (config) => {
			if (Authentication.isAuthenticated && !config.headers.Authorization) {
				// eslint-disable-next-line no-param-reassign
				config.headers.Authorization = `Bearer ${Authentication.token}`;
			}
			return config;
		};

		const onError = (error) => {
			if (error.response.data.name === 'PermissionError') {
				Authentication.checkLogin().then((res) => {
					const userData = jwtDecode(res.token);

					dispatch(setUserPermissions(res.uniquePermissions));
					dispatch(setUserAuth(userData));
				});
				history.replace('/admin/dashboard');
			}
		};

		const onUnauthorized = (error) => {
			console.log(error.response.data.message);
		};
		configureAxios(
			{
				onRequest,
				onError,
				onUnauthorized
			},
			axios
		);

		let currPath;

		history.listen((location) => {
			const pathArr = location.pathname.split('/');

			if (currPath === undefined || currPath !== pathArr[2]) {
				sessionStorage.clear();
			}
			// eslint-disable-next-line prefer-destructuring
			currPath = pathArr[2];
		});

		const token = localStorage.getItem('token');
		if (token) {
			Authentication.checkLogin()
				.then((res) => {
					const userData = jwtDecode(res.token);
					localStorage.setItem('token', res.token);
					dispatch(changeSelectedProject(userData.selectedProject));
					dispatch(fetchProjects());
					dispatch(setUserPermissions(res.uniquePermissions));
					dispatch(setUserAuth({ ...userData, selectedRows: res.selectedRows }));
				})
				.catch(() => {
					// if (telemetryService?.appInsights) {
					// 	telemetryService.appInsights.clearAuthenticatedUserContext();
					// }
				});
		}
	}, [dispatch]);

	const checkPathName = (val) => {
		const arr = val.split('/');
		arr.length > 3 && history.goBack();
	};

	useEffect(() => {
		if (projectAction) {
			checkPathName(history.location.pathname);
			dispatch(setChangeProject(false));
		}
	}, [projectAction]);

	return (
		<PermissionProvider.Provider value={userPermissions}>
			<Router history={history}>
				<ErrorBoundary
					FallbackComponent={SiteErrorFallback}
					track
				>
					<ScrollReset />
					<Toast />
					<Suspense fallback={<LinearProgress />}>{renderRoutes(routes)}</Suspense>
				</ErrorBoundary>
			</Router>
			<WhatsNewPopup />
		</PermissionProvider.Provider>
	);
}

function App() {
	const msalInstance = new PublicClientApplication(msalConfig);

	return (
		<ErrorBoundary FallbackComponent={SiteErrorFallback}>
			<StoreProvider store={store}>
				<ThemeProvider theme={theme}>
					<StylesProvider>
						<DndProvider backend={HTML5Backend}>
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<MsalProvider instance={msalInstance}>
									<AzureProvider>
										<AppInside />
									</AzureProvider>
								</MsalProvider>
							</MuiPickersUtilsProvider>
						</DndProvider>
					</StylesProvider>
				</ThemeProvider>
			</StoreProvider>
		</ErrorBoundary>
	);
}
export default App;
export { store };
