import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	error: null,
	packages: null,
	lotPackages: null,
	builders: null,
	buildersHouseTypes: null,
	buildersPackages: null,
	buildersContacts: null,
	buildersLots: null,
	allPackages: null,
	housePackages: null,
	history: null,
	package: null,
	builder: null,
	buildersAll: null
};

const packageReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.HOUSE_TYPES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.HOUSE_TYPES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				packages: action.houseTypes,
				error: null
			};
		}

		case actionTypes.HOUSE_TYPES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.LOT_PACKAGES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.LOT_PACKAGES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				lotPackages: action.lotPackages,
				error: null
			};
		}

		case actionTypes.LOT_PACKAGES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDERS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.BUILDERS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				builders: action.builders,
				error: null
			};
		}
		case actionTypes.BUILDERS_ALL_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				buildersAll: action.buildersAll,
				error: null
			};
		}
		case actionTypes.BUILDERS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDER_BY_ID_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.BUILDER_BY_ID_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				builder: action.data,
				error: null
			};
		}
		case actionTypes.BUILDER_BY_ID_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDERS_HOUSE_TYPES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.BUILDERS_HOUSE_TYPES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				buildersHouseTypes: action.buildersHouseTypes,
				error: null
			};
		}

		case actionTypes.BUILDERS_HOUSE_TYPES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.BUILDERS_PACKAGES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.BUILDERS_PACKAGES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				buildersPackages: action.buildersPackages,
				error: null
			};
		}

		case actionTypes.BUILDERS_PACKAGES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.BUILDERS_CONTACTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.BUILDERS_CONTACTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				buildersContacts: action.buildersContacts,
				error: null
			};
		}
		case actionTypes.BUILDERS_CONTACTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.BUILDERS_LOTS_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.BUILDERS_LOTS_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				buildersLots: action.buildersLots,
				error: null
			};
		}
		case actionTypes.BUILDERS_LOTS_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.PACKAGES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.PACKAGES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				allPackages: action.packages,
				error: null
			};
		}

		case actionTypes.PACKAGES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.HOUSE_PACKAGES_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.HOUSE_PACKAGES_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				housePackages: action.packages,
				error: null
			};
		}

		case actionTypes.HOUSE_PACKAGES_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.PACKAGE_HISTORY_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}

		case actionTypes.PACKAGE_HISTORY_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				history: action.history,
				error: null
			};
		}

		case actionTypes.PACKAGE_HISTORY_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		case actionTypes.PACKAGE_RESET: {
			return {
				...state,
				packages: null
			};
		}

		case actionTypes.BUILDER_RESET: {
			return {
				...state,
				builders: null
			};
		}

		case actionTypes.HOUSE_RESET: {
			return {
				...state,
				housePackages: null
			};
		}

		case actionTypes.PACKAGE_BY_ID_FETCH_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.PACKAGE_BY_ID_FETCH_SUCCESS: {
			return {
				...state,
				pending: false,
				package: action.data,
				error: null
			};
		}
		case actionTypes.PACKAGE_BY_ID_FETCH_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}

		default: {
			return state;
		}
	}
};

export default packageReducer;
