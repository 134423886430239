import palette from '../palette';
import typography from '../typography';

export default {
	root: {
		...typography.body1,
		borderBottom: `1px solid ${palette.divider}`,
		padding: '6px',
		lineHeight: '150%',
		letterSpacing: '0'
	},
	sizeSmall: {
		padding: '6px'
	},
	head: {
		lineHeight: '150%'
	}
};
