/* eslint-disable no-else-return */
import axios from 'axios';

export default class {
	static login = async (model) => {
		try {
			const { data } = await axios.post('/api/auth', model, {
				handleStatus: 401
			});
			localStorage.setItem('token', data.token);
			return data;
		} catch (error) {
			if (error.response && error.response.status === 403) {
				return { failed: true, status: 403 };
			} else if (error.response && error.response.status === 401) {
				return { failed: true, status: 401 };
			}
		}
		return null;
	};

	static checkLogin = async () => {
		try {
			const { data } = await axios.post('/api/auth/me', {
				handleStatus: 401
			});
			return data;
		} catch (error) {
			if (error.response && error.response.status === 403) {
				return { failed: true, status: 403 };
			} else if (error.response && error.response.status === 401) {
				return { failed: true, status: 401 };
			}
		}
		return null;
	};

	static outlookLogin = async (user) => {
		try {
			const { data } = await axios.post('/api/auth-outlook', user);
			localStorage.setItem('token', data.token);
			return data;
		} catch (error) {
			if (error.response && error.response.status === 403) {
				return { failed: true, status: 403 };
			} else if (error.response && error.response.status === 401) {
				return { failed: true, status: 401 };
			}
			return { failed: true, status: 401 };
		}
	};

	static logout = () => {
		localStorage.removeItem('token');
	};

	static get isAuthenticated() {
		return !!localStorage.getItem('token');
	}

	static get token() {
		const token = localStorage.getItem('token');
		return token;
	}
}
