import { useState } from 'react';

export default function useLocalStorage(key, initialValue = null) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === 'undefined') {
			return initialValue;
		}
		try {
			// Get from local storage by key
			const item = localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item || initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			localStorage.setItem(key, valueToStore);
		} catch (error) {
			console.log(error);
		}
	};

	const removeValue = () => {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			console.log(error);
		}
	};

	return [storedValue, setValue, removeValue];
}
