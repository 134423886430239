import palette from '../palette';

export default {
	root: {
		color: palette.icon,
		fontSize: '0.7rem',
		margin: '0 2px',
		minWidth: '0',
		lineHeight: '100%',
		padding: '5px 10px',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.03)'
		}
	},
	sizeSmall: {
		// Adjust spacing to reach minimal touch target hitbox
		marginLeft: 4,
		marginRight: 4,
		padding: 12
	}
};
