import axios from 'axios';

export const fetchMessagesNotificationsApi = (projectId, data) =>
	axios.get(`/api/projects/${projectId}/messages-notifications`, { params: data });

export const fetchMessagesPaginationApi = (projectId, data) =>
	axios.put(`/api/projects/${projectId}/messages-pagination`, data);

export const sendMessageApi = (projectId, data) =>
	axios.post(`/api/projects/${projectId}/messages`, { data });

export const updateMessageApi = (projectId, messageId, data) =>
	axios.put(`/api/projects/${projectId}/messages/${messageId}`, { data });

export const deleteMessageApi = (projectId, messageId) =>
	axios.delete(`/api/projects/${projectId}/messages/${messageId}`);
