import axios from 'axios';

export const saveWalkInApi = ({ projectId, ...rest }) =>
	axios.post(`/api/projects/${projectId}/walk-in`, { rest });

export const getWalkInApi = (projectId) => axios.get(`/api/projects/${projectId}/walk-in`);

export const deleteWalkInApi = (projectId, walkInId, data) =>
	axios.delete(`/api/projects/${projectId}/walk-in/${walkInId}`, { data });

export const updateWalkInApi = ({ projectId, ...rest }) =>
	axios.put(`/api/projects/${projectId}/walk-in`, { rest });
