import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PermissionProvider from 'src/utils/PermissionProvider';
import { isAdmin } from 'src/utils';

function ProtectedComponent({ component: Component, permission, ...rest }) {
	const userPermissions = useContext(PermissionProvider);
	let isVisible = false;
	isVisible = userPermissions?.some((el) => el.value === permission);
	const admin = isAdmin() && permission === 'Admin';

	if (permission instanceof Array) {
		isVisible = userPermissions?.some((el) => permission?.some((item) => el.value === item));
	}

	return isVisible || permission === 'all' || admin ? <Component {...rest} /> : null;
}

ProtectedComponent.propTypes = {
	component: PropTypes.func,
	permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ProtectedComponent;
