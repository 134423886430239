export const HOUSE_TYPES_FETCH_PENDING = 'HOUSE_TYPES_FETCH_PENDING';
export const HOUSE_TYPES_FETCH_SUCCESS = 'HOUSE_TYPES_FETCH_SUCCESS';
export const HOUSE_TYPES_FETCH_ERROR = 'HOUSE_TYPES_FETCH_ERROR';

export const LOT_PACKAGES_FETCH_PENDING = 'LOT_PACKAGES_FETCH_PENDING';
export const LOT_PACKAGES_FETCH_SUCCESS = 'LOT_PACKAGES_FETCH_SUCCESS';
export const LOT_PACKAGES_FETCH_ERROR = 'LOT_PACKAGES_FETCH_ERROR';

export const BUILDERS_FETCH_PENDING = 'BUILDERS_FETCH_PENDING';
export const BUILDERS_FETCH_SUCCESS = 'BUILDERS_FETCH_SUCCESS';
export const BUILDERS_ALL_FETCH_SUCCESS = 'BUILDERS_ALL_FETCH_SUCCESS';
export const BUILDERS_FETCH_ERROR = 'BUILDERS_FETCH_ERROR';

export const BUILDER_BY_ID_FETCH_PENDING = 'BUILDER_BY_ID_FETCH_PENDING';
export const BUILDER_BY_ID_FETCH_SUCCESS = 'BUILDER_BY_ID_FETCH_SUCCESS';
export const BUILDER_BY_ID_FETCH_ERROR = 'BUILDER_BY_ID_FETCH_ERROR';

export const BUILDERS_HOUSE_TYPES_FETCH_PENDING = 'BUILDERS_HOUSE_TYPES_FETCH_PENDING';
export const BUILDERS_HOUSE_TYPES_FETCH_SUCCESS = 'BUILDERS_HOUSE_TYPES_FETCH_SUCCESS';
export const BUILDERS_HOUSE_TYPES_FETCH_ERROR = 'BUILDERS_HOUSE_TYPES_FETCH_ERROR';

export const BUILDERS_PACKAGES_FETCH_PENDING = 'BUILDERS_PACKAGES_FETCH_PENDING';
export const BUILDERS_PACKAGES_FETCH_SUCCESS = 'BUILDERS_PACKAGES_FETCH_SUCCESS';
export const BUILDERS_PACKAGES_FETCH_ERROR = 'BUILDERS_PACKAGES_FETCH_ERROR';

export const BUILDERS_CONTACTS_FETCH_PENDING = 'BUILDERS_CONTACTS_FETCH_PENDING';
export const BUILDERS_CONTACTS_FETCH_SUCCESS = 'BUILDERS_CONTACTS_FETCH_SUCCESS';
export const BUILDERS_CONTACTS_FETCH_ERROR = 'BUILDERS_CONTACTS_FETCH_ERROR';

export const BUILDERS_LOTS_FETCH_PENDING = 'BUILDERS_LOTS_FETCH_PENDING';
export const BUILDERS_LOTS_FETCH_SUCCESS = 'BUILDERS_LOTS_FETCH_SUCCESS';
export const BUILDERS_LOTS_FETCH_ERROR = 'BUILDERS_LOTS_FETCH_ERROR';

export const PACKAGES_FETCH_PENDING = 'PACKAGES_FETCH_PENDING';
export const PACKAGES_FETCH_SUCCESS = 'PACKAGES_FETCH_SUCCESS';
export const PACKAGES_FETCH_ERROR = 'PACKAGES_FETCH_ERROR';

export const HOUSE_PACKAGES_FETCH_PENDING = 'HOUSE_PACKAGES_FETCH_PENDING';
export const HOUSE_PACKAGES_FETCH_SUCCESS = 'HOUSE_PACKAGES_FETCH_SUCCESS';
export const HOUSE_PACKAGES_FETCH_ERROR = 'HOUSE_PACKAGES_FETCH_ERROR';

export const PACKAGE_HISTORY_FETCH_PENDING = 'PACKAGE_HISTORY_FETCH_PENDING';
export const PACKAGE_HISTORY_FETCH_SUCCESS = 'PACKAGE_HISTORY_FETCH_SUCCESS';
export const PACKAGE_HISTORY_FETCH_ERROR = 'PACKAGE_HISTORY_FETCH_ERROR';

export const PACKAGE_RESET = 'PACKAGE_RESET';
export const BUILDER_RESET = 'BUILDER_RESET';
export const HOUSE_RESET = 'HOUSE_RESET';

export const PACKAGE_BY_ID_FETCH_PENDING = 'PACKAGE_BY_ID_FETCH_PENDING';
export const PACKAGE_BY_ID_FETCH_SUCCESS = 'PACKAGE_BY_ID_FETCH_SUCCESS';
export const PACKAGE_BY_ID_FETCH_ERROR = 'PACKAGE_BY_ID_FETCH_ERROR';

export const houseTypesFetchPending = () => ({
	type: HOUSE_TYPES_FETCH_PENDING
});

export const houseTypesFetchSuccess = (houseTypes) => ({
	type: HOUSE_TYPES_FETCH_SUCCESS,
	houseTypes
});

export const houseTypesFetchError = (error) => ({
	type: HOUSE_TYPES_FETCH_ERROR,
	error
});

export const lotPackagesFetchPending = () => ({
	type: LOT_PACKAGES_FETCH_PENDING
});

export const lotPackagesFetchSuccess = (lotPackages) => ({
	type: LOT_PACKAGES_FETCH_SUCCESS,
	lotPackages
});

export const lotPackagesFetchError = (error) => ({
	type: LOT_PACKAGES_FETCH_ERROR,
	error
});

export const buildersFetchPending = () => ({
	type: BUILDERS_FETCH_PENDING
});

export const buildersFetchSuccess = (builders) => ({
	type: BUILDERS_FETCH_SUCCESS,
	builders
});

export const buildersAllFetchSuccess = (buildersAll) => ({
	type: BUILDERS_ALL_FETCH_SUCCESS,
	buildersAll
});

export const buildersFetchError = (error) => ({
	type: BUILDERS_FETCH_ERROR,
	error
});

export const builderByIdFetchPending = () => ({
	type: BUILDER_BY_ID_FETCH_PENDING
});

export const builderByIdFetchSuccess = (data) => ({
	type: BUILDER_BY_ID_FETCH_SUCCESS,
	data
});

export const builderByIdFetchError = (error) => ({
	type: BUILDER_BY_ID_FETCH_ERROR,
	error
});

export const buildersHouseTypesFetchPending = () => ({
	type: BUILDERS_HOUSE_TYPES_FETCH_PENDING
});

export const buildersHouseTypesFetchSuccess = (buildersHouseTypes) => ({
	type: BUILDERS_HOUSE_TYPES_FETCH_SUCCESS,
	buildersHouseTypes
});

export const buildersHouseTypesFetchError = (error) => ({
	type: BUILDERS_HOUSE_TYPES_FETCH_ERROR,
	error
});

export const buildersPackagesFetchPending = () => ({
	type: BUILDERS_PACKAGES_FETCH_PENDING
});

export const buildersPackagesFetchSuccess = (buildersPackages) => ({
	type: BUILDERS_PACKAGES_FETCH_SUCCESS,
	buildersPackages
});

export const buildersPackagesFetchError = (error) => ({
	type: BUILDERS_PACKAGES_FETCH_ERROR,
	error
});

export const buildersContactsFetchPending = () => ({
	type: BUILDERS_CONTACTS_FETCH_PENDING
});

export const buildersContactsFetchSuccess = (buildersContacts) => ({
	type: BUILDERS_CONTACTS_FETCH_SUCCESS,
	buildersContacts
});

export const buildersContactsFetchError = (error) => ({
	type: BUILDERS_CONTACTS_FETCH_ERROR,
	error
});

export const buildersLotsFetchPending = () => ({
	type: BUILDERS_LOTS_FETCH_PENDING
});

export const buildersLotsFetchSuccess = (buildersLots) => ({
	type: BUILDERS_LOTS_FETCH_SUCCESS,
	buildersLots
});

export const buildersLotsFetchError = (error) => ({
	type: BUILDERS_LOTS_FETCH_ERROR,
	error
});

export const packagesFetchPending = () => ({
	type: PACKAGES_FETCH_PENDING
});

export const packagesFetchSuccess = (packages) => ({
	type: PACKAGES_FETCH_SUCCESS,
	packages
});

export const packagesFetchError = (error) => ({
	type: PACKAGES_FETCH_ERROR,
	error
});

export const housePackagesFetchPending = () => ({
	type: HOUSE_PACKAGES_FETCH_PENDING
});

export const housePackagesFetchSuccess = (packages) => ({
	type: HOUSE_PACKAGES_FETCH_SUCCESS,
	packages
});

export const housePackagesFetchError = (error) => ({
	type: HOUSE_PACKAGES_FETCH_ERROR,
	error
});

export const packageHistoryFetchPending = () => ({
	type: PACKAGE_HISTORY_FETCH_PENDING
});

export const packageHistoryFetchSuccess = (history) => ({
	type: PACKAGE_HISTORY_FETCH_SUCCESS,
	history
});

export const packageHistoryFetchError = (error) => ({
	type: PACKAGE_HISTORY_FETCH_ERROR,
	error
});

export const packageReset = () => ({
	type: PACKAGE_RESET
});

export const builderReset = () => ({
	type: BUILDER_RESET
});

export const houseReset = () => ({
	type: HOUSE_RESET
});

export const packageByIdFetchPending = () => ({
	type: PACKAGE_BY_ID_FETCH_PENDING
});

export const packageByIdFetchSuccess = (data) => ({
	type: PACKAGE_BY_ID_FETCH_SUCCESS,
	data
});

export const packageByIdFetchError = (error) => ({
	type: PACKAGE_BY_ID_FETCH_ERROR,
	error
});
