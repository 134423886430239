/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Modal,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Button,
	TextField,
	Grid,
	Checkbox,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormControlLabel
} from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import MessageEditor from 'src/customComponents/MessageEditor';
import ContactAutocomplete from 'src/components/ContactAutocomplete';
import { postCustomerNote, fetchCustomerNotes, fetchUsers, fetchCustomers } from 'src/api';
import {
	getAuth,
	getCustomersFilters,
	getSelectedProject,
	getUserProjects,
	getUserRows
} from 'src/selectors';
import { showToast } from 'src/actions';
import { contactTypes, reminderTypes } from 'src/Constants';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%'
	},
	container: {
		marginTop: theme.spacing(3),
		height: 200
	},
	actions: {
		justifyContent: 'flex-end'
	},
	textareaAutosize: {
		width: '100%',
		fontSize: '14px',
		fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'
	},
	noteType: {
		display: 'flex',
		alignItems: 'center'
	},
	noteParagraph: {
		marginRight: '10px'
	},
	borderWrapper: {
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: '4px',
		marginTop: '15px'
	}
}));

function AddNoteModal({ open, onClose, className, data, topBar, setSelected, ...rest }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const customerId = data;
	const showFollowUp = rest?.props?.withFollowUp ?? true;
	const currentProject = useSelector(getSelectedProject);
	const userRows = useSelector(getUserRows);
	const customersFilters = useSelector(getCustomersFilters);
	const auth = useSelector(getAuth);
	const users = useSelector(getUserProjects);
	const actualDate = new Date();
	const [values, setValues] = useState({
		memoDesc: [
			{
				type: 'paragraph',
				children: [{ text: '' }]
			}
		],
		repeatEnquiries: false,
		contactType: contactTypes[0],
		noteDate: actualDate,
		followUp: false,
		dueDate: '',
		dueTime: '',
		userId: auth?._id,
		type: '',
		note: '',
		contactId: null
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		dispatch(fetchUsers());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (auth && !values.userId) {
			setValues((currValues) => ({
				...currValues,
				userId: auth._id
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	useEffect(() => {
		if (!open) {
			setDefaultValue();
		}
	}, [open]);

	const schema = {
		contactType: {
			presence: { allowEmpty: false, message: 'is required' }
		},
		noteDate: {
			presence: { allowEmpty: false, message: 'is required' }
		},
		memoDesc: {
			presence: { allowEmpty: false, message: 'is required' }
		},
		dueDate: {
			presence: { allowEmpty: !values.followUp, message: 'is required' }
		},
		type: {
			presence: { allowEmpty: !values.followUp, message: 'is required' }
		},
		userId: {
			presence: { allowEmpty: !values.followUp, message: 'is required' }
		}
	};

	const handleChange = (event) => {
		event.persist();
		setValues((currentValues) => ({
			...currentValues,
			[event.target.name]:
				event.target.type === 'checkbox' ? event.target.checked : event.target.value
		}));
	};

	const handleDateChange = (date, type) => {
		setValues((currentValues) => ({
			...currentValues,
			[type]: date
		}));
	};

	const onUserSelectChange = (e, v) => {
		setValues({
			...values,
			userId: v ? v.id : ''
		});
	};

	const onContactSelectChange = (e, v) => {
		setValues({
			...values,
			contactId: v.id
		});
	};

	const setDefaultValue = () => {
		setValues({
			memoDesc: [
				{
					type: 'paragraph',
					children: [{ text: '' }]
				}
			],
			repeatEnquiries: false,
			contactType: contactTypes[0],
			noteDate: actualDate,
			followUp: false,
			dueDate: '',
			dueTime: '',
			type: '',
			note: '',
			userId: auth?._id
		});
	};

	const onConfirm = async () => {
		const { memoDesc, contactType, noteDate, followUp, repeatEnquiries } = values;

		const sendData = followUp
			? { ...values, contactIds: [values.contactId ?? customerId?.id] }
			: {
					memoDesc,
					contactType,
					noteDate,
					followUp,
					repeatEnquiries
			  };

		const errorsMessages = validate(values, schema);

		setErrors(errorsMessages);

		if (errorsMessages) {
			dispatch(
				showToast({
					type: 'error',
					body: 'Please select All options'
				})
			);
		} else {
			try {
				if (Array.isArray(customerId)) {
					const promises = [];
					customerId.forEach((el) => {
						promises.push(dispatch(postCustomerNote(currentProject, el, sendData)));
					});
					await Promise.all(promises).then(() =>
						dispatch(fetchCustomers(currentProject, 1, userRows, customersFilters))
					);
					setSelected([]);
				} else {
					await dispatch(
						postCustomerNote(
							currentProject,
							customerId?.id || values.contactId,
							sendData
						)
					);
					await dispatch(
						fetchCustomerNotes(currentProject, customerId?.id || values.contactId)
					);
				}
				setDefaultValue();
				dispatch(
					showToast({
						body: 'New note added'
					})
				);
				onClose();
			} catch (error) {
				dispatch(
					showToast({
						type: 'error',
						body: 'An error occurred while creating Contact Note.'
					})
				);
			}
		}
	};

	if (!open) {
		return null;
	}

	return (
		<Modal
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					onClose(event, reason);
				}
			}}
			open={open}
		>
			<Card
				{...rest}
				className={clsx(classes.root, className)}
			>
				<CardHeader title="Add Contact Note" />
				<Divider />
				<CardContent>
					<Grid
						container
						alignItems="center"
						spacing={3}
					>
						{topBar && (
							<Grid
								item
								md={8}
								xs={8}
							>
								<ContactAutocomplete
									onSelect={onContactSelectChange}
									name="contactId"
									label="Select Contact *"
									value={values?.contactId}
								/>
							</Grid>
						)}
						<Grid
							item
							md={4}
							xs={4}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.repeatEnquiries}
										onChange={handleChange}
										name="repeatEnquiries"
										color="primary"
									/>
								}
								label="Repeat Enquiry"
							/>
						</Grid>
						<Grid
							item
							md={4}
							xs={12}
						>
							<TextField
								fullWidth
								label="Note Type"
								name="contactType"
								onChange={handleChange}
								select
								variant="outlined"
								SelectProps={{ native: true }}
								value={values.contactType}
								error={errors?.contactType}
								helperText={errors?.contactType ? errors.contactType[0] : false}
							>
								<option
									disabled
									selected
								/>
								{contactTypes.map((option) => (
									<option
										key={option}
										value={option}
									>
										{option}
									</option>
								))}
							</TextField>
						</Grid>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid
								item
								md={4}
								xs={12}
							>
								<KeyboardDatePicker
									fullWidth
									margin="normal"
									id="date-picker-dialog"
									label="Date"
									format="dd/MM/yyyy"
									name="noteDate"
									value={values.noteDate}
									maxDate={actualDate}
									onChange={(date) => handleDateChange(date, 'noteDate')}
									KeyboardButtonProps={{
										'aria-label': 'change date'
									}}
									error={errors?.noteDate}
									helperText={errors?.noteDate ? errors.noteDate[0] : false}
								/>
							</Grid>
							<Grid
								item
								md={4}
								xs={12}
							>
								<KeyboardTimePicker
									fullWidth
									margin="normal"
									id="time-picker"
									label="Time"
									value={values.noteDate}
									name="noteDate"
									onChange={(date) => handleDateChange(date, 'noteDate')}
									KeyboardButtonProps={{
										'aria-label': 'change time'
									}}
									error={errors?.noteDate}
									helperText={errors?.noteDate ? errors.noteDate[0] : false}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid
						item
						xs={12}
						className={classes.borderWrapper}
						style={{ minHeight: '140px' }}
					>
						<MessageEditor
							value={values.memoDesc}
							setValue={(val) =>
								setValues((currentValues) => ({ ...currentValues, memoDesc: val }))
							}
						/>
					</Grid>
				</CardContent>
				{showFollowUp && (
					<>
						<Divider />
						<CardContent>
							<Grid
								container
								spacing={3}
							>
								<Grid
									item
									md={12}
									xs={12}
								>
									<Typography variant="h5">Set Follow Up</Typography>
								</Grid>
								<Grid
									item
									md={4}
									sm={12}
								>
									Follow Up
									<Checkbox
										value={values.followUp || false}
										onChange={handleChange}
										name="followUp"
									/>
								</Grid>
								<Grid
									item
									md={8}
									xs={12}
								>
									<Autocomplete
										fullWidth
										name="userId"
										options={users || []}
										getOptionLabel={(option) => `${option.name}`}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												label="Select User"
												value={params}
												variant="outlined"
												name="userId"
											/>
										)}
										renderOption={(option) => <span>{`${option.name}`}</span>}
										onChange={onUserSelectChange}
										defaultValue={users?.find((el) => el.id === auth._id)}
										disabled={!values.followUp}
										error={errors?.userId}
									/>
								</Grid>
								<Grid
									item
									md={4}
									xs={12}
								>
									<FormControl
										variant="outlined"
										fullWidth
									>
										<InputLabel id="reminderType">Reminder type</InputLabel>
										<Select
											labelId="reminderType"
											label="Reminder type"
											name="type"
											onChange={handleChange}
											value={values.type || ''}
											disabled={!values.followUp}
											error={errors?.type}
										>
											{reminderTypes.map((option) => (
												<MenuItem
													key={option.value}
													value={option.value}
												>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Grid
										item
										md={4}
										xs={12}
									>
										<FormControl
											variant="outlined"
											fullWidth
										>
											<KeyboardDatePicker
												fullWidth
												label="Due date"
												format="dd/MM/yyyy"
												name="dueDate"
												value={values.dueDate || null}
												onChange={(date) =>
													handleDateChange(date, 'dueDate')
												}
												KeyboardButtonProps={{
													'aria-label': 'change date'
												}}
												disabled={!values.followUp}
												error={errors?.dueDate}
												helperText={
													errors?.dueDate ? errors.dueDate[0] : false
												}
											/>
										</FormControl>
									</Grid>
									<Grid
										item
										md={4}
										xs={12}
									>
										<FormControl
											variant="outlined"
											fullWidth
										>
											<KeyboardTimePicker
												label="Due time"
												name="dueTime"
												value={values.dueTime || null}
												onChange={(date) =>
													handleDateChange(date, 'dueTime')
												}
												KeyboardButtonProps={{
													'aria-label': 'change time'
												}}
												disabled={!values.followUp}
											/>
										</FormControl>
									</Grid>
								</MuiPickersUtilsProvider>
								<Grid
									item
									xs={12}
								>
									<TextField
										fullWidth
										variant="outlined"
										multiline
										label="Subject"
										onChange={handleChange}
										name="subject"
										disabled={!values.followUp}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<TextField
										className={classes.textareaAutosize}
										rows={4}
										rowsMax={5}
										variant="outlined"
										multiline
										label="Type reminder note here..."
										onChange={handleChange}
										name="note"
										disabled={!values.followUp}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</>
				)}
				<Divider />
				<CardActions className={classes.actions}>
					<Button onClick={onClose}>Dismiss</Button>
					<Button
						color="primary"
						onClick={onConfirm}
						variant="contained"
					>
						Confirm
					</Button>
				</CardActions>
			</Card>
		</Modal>
	);
}

AddNoteModal.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	topBar: PropTypes.bool,
	setSelected: PropTypes.func
};

AddNoteModal.defaultProps = {
	open: false,
	onClose: () => {}
};

export default AddNoteModal;
