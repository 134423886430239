import palette from './palette';

export default {
	h1: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '2rem'
		// letterSpacing: '-0.24px',
		// lineHeight: '40px'
	},
	h2: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '1.75rem'
		// letterSpacing: '-0.24px',
		// lineHeight: '32px'
	},
	h3: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '1.5rem'
		// letterSpacing: '-0.06px',
		// lineHeight: '28px'
	},
	h4: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '1rem'
		// letterSpacing: '-0.06px',
		// lineHeight: '24px'
	},
	h5: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '0.9rem'
		// letterSpacing: '-0.05px',
		// lineHeight: '20px'
	},
	h6: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '0.75rem'
		// letterSpacing: '-0.05px',
		// lineHeight: '20px'
	},
	subtitle1: {
		color: palette.text.primary,
		fontSize: '1rem'
		// letterSpacing: '-0.05px',
		// lineHeight: '25px'
	},
	subtitle2: {
		color: palette.text.secondary,
		fontWeight: 400,
		fontSize: '14px',
		letterSpacing: '-0.05px'
		// lineHeight: '21px'
	},
	body: {
		color: palette.text.primary,
		fontFamily: '"Roboto", "Helvetica", Arial, sans-serif',
		fontSize: '0.825rem',
		lineHeight: '150%'
	},
	body1: {
		color: palette.text.primary,
		fontSize: '0.825rem'
		// letterSpacing: '-0.05px',
		// lineHeight: '21px'
	},
	body2: {
		color: palette.text.secondary,
		fontSize: '0.75rem'
		// letterSpacing: '-0.04px',
		// lineHeight: '18px'
	},
	button: {
		minWidth: '0',
		fontSize: '.825rem'
	},
	caption: {
		color: palette.text.secondary,
		fontSize: '11px',
		letterSpacing: '0.33px',
		lineHeight: '13px'
	},
	overline: {
		color: palette.text.secondary,
		fontSize: '0.75rem',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '0.825rem',
		textTransform: 'uppercase'
	}
};
