/* eslint-disable import/prefer-default-export */
import { saveWalkInApi, getWalkInApi, deleteWalkInApi, updateWalkInApi } from 'src/api/walkInApi';
import * as actionTypes from '../actions';
import { getWalkInStatsRequest } from './dashboardThunks';

export const saveWalkIn = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await saveWalkInApi(data);
		await dispatch(getWalkInStatsRequest(data.projectId));
		await dispatch(
			actionTypes.showToast({
				body: 'Save Walk-In success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Save Walk-In error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const updateWalkIn = (data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await updateWalkInApi(data);
		await dispatch(
			actionTypes.showToast({
				body: 'Edit Walk-In success'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Edit Walk-In error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const getWalkInList = (projectId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.getWalkInPending());
	try {
		const res = await getWalkInApi(projectId);
		await dispatch(actionTypes.getWalkInSuccess(res.data));
	} catch (error) {
		await dispatch(actionTypes.getWalkInError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Walk-In error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const deleteWalkIn = (projectId, walkInId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		const response = await deleteWalkInApi(projectId, walkInId, data);
		await dispatch(
			actionTypes.showToast({
				type: 'success',
				body: response.data?.message ?? 'Your request has been successfully sent'
			})
		);
	} catch (error) {
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: error?.response?.data?.message ?? 'Delete Walk-In error'
			})
		);
	}
	await dispatch(actionTypes.setLoader(false));
};
