import { SvgIcon } from '@material-ui/core';
import React from 'react';

function CommissionIcon() {
	return (
		<SvgIcon
			style={{ marginRight: '8px', color: '#546e7a' }}
			viewBox="0 0 505 503"
		>
			<g id="icon">
				<path
					d="M9.2,271.2v217.9h117.4V460l0.5,0.2c30.6,10.2,74.3,19.1,117.6,34.6l3.3,1.2l3.3-0.9c13.6-3.2,50.1-14.5,93.9-28.6
		c43.7-14,93.7-30.6,130.8-43.4c17.8-6.2,27.4-26,20.8-43.6v-0.2c-6.9-18.1-26.8-26-43.8-20.8c-14.1,4.3-80.2,24.9-123.7,37.7
		c0.4-12.4,3.7-26-6.4-34l-0.7-0.5l-0.5-0.4c-48.9-29.1-53.1-32.7-121.8-69.2l-3.3-1.6l-3.5,0.3c-23.6,2.5-63.6,9.1-66.7,9.6v-29.3
		L9.2,271.2z M32.6,294.7h70.8v171H32.6V294.7z M192.1,314.6c62.9,33.5,70,38.6,116.4,66.3c7.3,5.9,9.3,13.1,4.8,19.5
		c-2.4,3.5-4.8,4.7-8.3,5.3s-8.4,0.1-13.9-2.4c-21-9.7-32.3-15.9-55.5-28.9l-10.2-5.7L214,389.1l10.3,5.7
		c23.2,13,35.6,19.8,57.2,29.8v0.2c8.8,3.9,18.2,5.6,27.4,4.1c3.8-0.6,6.3-4.3,9.8-6.2l0.4,1l11.2-3.3
		c43.5-12.7,115.2-35,129.7-39.5c5.5-1.7,12.8,1,15,6.7c2.2,5.9-0.5,11.4-6.5,13.4c-36.8,12.7-86.7,29.2-130.2,43.2
		c-42.4,13.6-78.4,24.6-89.7,27.4c-43.6-15.1-86.9-24.7-114-33.7l-7.8-2.6l0-111.2l1.9-0.3C128.5,323.9,168.5,317.4,192.1,314.6
		L192.1,314.6z"
				/>
				<path
					d="M350.2,6c-79.3,0-143.8,64.5-143.8,143.8c0,79.3,64.5,143.8,143.8,143.8c79.3,0,143.7-64.5,143.7-143.8
		C493.9,70.5,429.5,6,350.2,6L350.2,6z M350.2,29.2c66.7,0,120.4,53.9,120.4,120.6c0,66.7-53.7,120.4-120.4,120.4
		s-120.6-53.7-120.6-120.4C229.6,83.1,283.5,29.2,350.2,29.2L350.2,29.2z"
				/>
				<path
					d="M329.9,80c-13.6-13.6-36-13.4-49.6,0.1c-13.6,13.6-13.6,35.9,0,49.5c13.6,13.6,36,13.7,49.6,0.1
		C343.5,116.1,343.5,93.6,329.9,80L329.9,80z M313.3,96.5c4.7,4.7,4.7,11.9,0,16.6c-4.7,4.7-11.8,4.5-16.4-0.1
		c-4.7-4.7-4.7-11.7,0-16.3C301.6,92,308.7,91.9,313.3,96.5L313.3,96.5z"
				/>
				<path
					d="M419.9,170c-13.6-13.6-36-13.4-49.6,0.1c-13.6,13.6-13.6,35.9,0,49.5c13.6,13.6,35.9,13.6,49.5,0
		C433.4,206,433.5,183.6,419.9,170L419.9,170z M403.4,186.6c4.7,4.7,4.5,11.8-0.1,16.4c-4.7,4.7-11.7,4.7-16.3,0
		c-4.7-4.7-4.8-11.8-0.1-16.4C391.5,181.9,398.7,181.9,403.4,186.6L403.4,186.6z"
				/>
				<path d="M391.4,92.1l-8.3,8.3l-86.5,86.5l-8.3,8.3l16.5,16.4l8.3-8.3l86.5-86.5l8.3-8.3L391.4,92.1z" />
			</g>
		</SvgIcon>
	);
}

export default CommissionIcon;
