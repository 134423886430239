import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	List,
	Typography,
	Chip,
	CircularProgress,
	Card,
	CardContent
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import useChangelog from './useChangelog';
import ChangelogChangeListItem from './ChangelogChangeListItem';

export default function Changelog({ show, onClose }) {
	const { loading, changelogItems } = useChangelog({ show });

	const Loading = <CircularProgress />;

	const ChangelogDetails = changelogItems?.map((changelogItem, index) => (
		<Card
			key={index}
			style={{ marginBottom: 10 }}
		>
			<CardContent>
				{changelogItem.hotfix && (
					<Chip
						label="Hotfix"
						color="secondary"
						size="small"
						style={{
							float: 'right',
							backgroundColor: 'rgb(244, 67, 54)',
							color: 'rgb(255, 255, 255)'
						}}
					/>
				)}
				<Typography variant="h6">
					v{changelogItem.version} ({moment(changelogItem.date).format('DD/MM/YYYY')})
				</Typography>
				<br />
				<Typography variant="body2">Changes</Typography>
				<Divider />
				<List dense>
					{changelogItem.changes.map((change) => (
						<div key={change.id}>
							<ChangelogChangeListItem change={change} />
							<Divider style={{ width: '96%', marginLeft: '2%' }} />
						</div>
					))}
				</List>
			</CardContent>
		</Card>
	));

	const currentView = loading ? Loading : ChangelogDetails;

	return (
		<Dialog
			maxWidth="md"
			fullWidth
			onClose={onClose}
			open={show}
		>
			<DialogTitle disableTypography>
				<Typography variant="h4">Release Notes</Typography>
				<IconButton
					onClick={onClose}
					style={{ position: 'absolute', right: 10, top: 3 }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ minHeight: '700px', padding: '10px', backgroundColor: '#f4f6f8' }}
			>
				{currentView}
			</DialogContent>
		</Dialog>
	);
}
