export const getNearby = (state) => state.maps;
export const getWhiterock = (state) => state.maps;
export const getCategories = (state) => state.maps.categories || [];
export const getMapDetails = (state) => state.maps.mapDetails;
export const getCategoriesValues = (state) =>
	(state.maps.categories &&
		state.maps.categories.map((category) => ({
			value: category.id,
			label: category.name
		}))) ||
	[];
export const getCategoriesNames = (state) =>
	state.maps.categories.map((category) => category.name) || [];
