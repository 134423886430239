import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	roles: null,
	settings: null,
	rolesData: null,
	error: null
};

const permissionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ROLES_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_ROLES_SUCCESS: {
			return {
				...state,
				pending: false,
				roles: action.roles
			};
		}
		case actionTypes.GET_ROLES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.GET_SETTINGS_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.GET_SETTINGS_SUCCESS: {
			return {
				...state,
				pending: false,
				settings: action.settings
			};
		}
		case actionTypes.GET_SETTINGS_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.CHANGE_ROLES_PENDING: {
			return {
				...state,
				pending: true,
				error: null
			};
		}
		case actionTypes.CHANGE_ROLES_SUCCESS: {
			return {
				...state,
				pending: false
			};
		}
		case actionTypes.CHANGE_ROLES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		case actionTypes.SET_ROLES: {
			return {
				...state,
				rolesData: action.rolesData
			};
		}
		case actionTypes.CHANGE_ROLE: {
			const { setting, permission } = action.data;
			const newData = state.rolesData.map((el) => {
				if (el.id === setting) {
					if (el.permissions.includes(permission)) {
						el.permissions.splice(el.permissions.indexOf(permission), 1);
					} else {
						el.permissions.push(permission);
					}
				}
				return el;
			});
			return {
				...state,
				rolesData: newData
			};
		}
		default: {
			return state;
		}
	}
};

export default permissionReducer;
