export const DAP_USERS_FETCH_PENDING = 'DAP_USERS_FETCH_PENDING';
export const DAP_USERS_FETCH_SUCCESS = 'DAP_USERS_FETCH_SUCCESS';
export const DAP_USERS_FETCH_ERROR = 'DAP_USERS_FETCH_ERROR';
export const DAP_USER_DETAILS_FETCH_PENDING = 'DAP_USER_DETAILS_FETCH_PENDING';
export const DAP_USER_DETAILS_FETCH_SUCCESS = 'DAP_USER_DETAILS_FETCH_SUCCESS';
export const DAP_USER_DETAILS_FETCH_ERROR = 'DAP_USER_DETAILS_FETCH_ERROR';
export const DAP_USER_DETAILS_RESET = 'DAP_USER_DETAILS_RESET';

export const dapUsersFetchPending = () => ({
	type: DAP_USERS_FETCH_PENDING
});

export const dapUsersFetchSuccess = (dapUsers) => ({
	type: DAP_USERS_FETCH_SUCCESS,
	dapUsers
});

export const dapUsersFetchError = (error) => ({
	type: DAP_USERS_FETCH_ERROR,
	error
});

export const dapUserDetailsFetchPending = () => ({
	type: DAP_USER_DETAILS_FETCH_PENDING
});

export const dapUserDetailsFetchSuccess = (dapUserDetails) => ({
	type: DAP_USER_DETAILS_FETCH_SUCCESS,
	dapUserDetails
});

export const dapUserDetailsFetchError = (error) => ({
	type: DAP_USER_DETAILS_FETCH_ERROR,
	error
});

export const dapUserDetailsReset = () => ({
	type: DAP_USER_DETAILS_RESET
});
