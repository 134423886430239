import { fetchRoles, fetchSettings, changeRoles } from 'src/api/permissionApi';
import * as actionTypes from '../actions';

export const fetchRolesRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchRolesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const roles = await fetchRoles();
		await dispatch(actionTypes.fetchRolesSuccess(roles));
	} catch (error) {
		await dispatch(actionTypes.fetchRolesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Roles error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const fetchSettingsRequest = () => async (dispatch) => {
	await dispatch(actionTypes.fetchSettingsPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		const roles = await fetchSettings();
		await dispatch(actionTypes.fetchSettingsSuccess(roles));
	} catch (error) {
		await dispatch(actionTypes.fetchSettingsError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Get Settings error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};

export const changeRolesRequest = (data) => async (dispatch) => {
	await dispatch(actionTypes.changeRolesPending());
	await dispatch(actionTypes.setLoader(true));
	try {
		await changeRoles(data);
		await dispatch(actionTypes.changeRolesSuccess());
	} catch (error) {
		await dispatch(actionTypes.changeRolesError(error));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Change Roles error'
			})
		);
	}

	await dispatch(actionTypes.setLoader(false));
};
