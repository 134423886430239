import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: 'none',
		background: 'black'
	},
	parent: {
		height: '64px'
	},
	img: {
		height: '100%'
	}
}));

function Topbar({ className, ...rest }) {
	const classes = useStyles();

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			color="primary"
		>
			<Toolbar>
				<RouterLink
					className={classes.parent}
					to="/"
				>
					<img
						alt="Logo"
						className={classes.img}
						src="/images/logos/intrahub logo-05.png"
					/>
				</RouterLink>
			</Toolbar>
		</AppBar>
	);
}

Topbar.propTypes = {
	className: PropTypes.string
};

export default Topbar;
