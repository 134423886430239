import * as actionTypes from '../actions/index';

const initialState = {
	pending: false,
	messages: [],
	pagination: null,
	error: null
};

const messageReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_MESSAGES_PENDING: {
			return {
				...state,
				pending: true
			};
		}
		case actionTypes.FETCH_MESSAGES_SUCCESS: {
			return {
				...state,
				pending: false,
				messages: action.data
			};
		}
		case actionTypes.SET_MESSAGES_PAGINATION: {
			return {
				...state,
				pagination: action.data
			};
		}
		case actionTypes.FETCH_MESSAGES_ERROR: {
			return {
				...state,
				pending: false,
				error: action.error
			};
		}
		default: {
			return state;
		}
	}
};

export default messageReducer;
