/* eslint-disable max-len */

export const getUsers = (state) => state.users.users && state.users.users.data;

export const getUserPending = (state) => state.users.pending;

export const getUserProjects = (state) =>
	state.users.users &&
	state.users.users.data.filter(
		(el) =>
			el.roles.includes('admin') ||
			el.projects.some((item) => item.id === state.project.selectedProject)
	);

export const getUserDetails = (state) =>
	state.users.userDetails && state.users.userDetails.data.users;

export const getAuth = (state) => state.users.auth;

export const getUserLotHistory = (state) =>
	state.users.lotHistory && state.users.lotHistory.data.items;

export const getUserPackageHistory = (state) =>
	state.users.packageHistory && state.users.packageHistory.data.items;

export const getUserSaleHistory = (state) =>
	state.users.saleHistory && state.users.saleHistory.data.items;

export const getUserRows = (state) =>
	state.users.userRows && state.users.userRows.data && state.users.userRows.data.selectedRows;

export const getUserShowSettled = (state) => state.users.userShowSettled;

export const getShowInactiveUser = (state) => state.users.showInactiveContacts;

export const getUserPermissions = (state) => state.users.userPermissions;

export const getUserIsSuperAdmin = (state) => state.users.auth.permissions.includes('super_admin');
