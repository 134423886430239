/* eslint-disable import/prefer-default-export */
import {
	sendMessageApi,
	deleteMessageApi,
	updateMessageApi,
	fetchMessagesPaginationApi
} from 'src/api/messageApi';
import * as actionTypes from 'src/actions';
import { getBodyPaginate } from 'src/utils';

export const sendMessage = (projectId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await sendMessageApi(projectId, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Send message error'
			})
		);
		throw error;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const deleteMessage = (projectId, messageId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await deleteMessageApi(projectId, messageId);
		await dispatch(actionTypes.setLoader(false));
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		await dispatch(
			actionTypes.showToast({
				type: 'error',
				body: 'Delete message error'
			})
		);
		throw error;
	}
};

export const editMessageData = (projectId, messageId, data) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	try {
		await updateMessageApi(projectId, messageId, data);
	} catch (error) {
		await dispatch(actionTypes.setLoader(false));
		throw error.message;
	}
	await dispatch(actionTypes.setLoader(false));
};

export const fetchMessagesPagination = (projectId, data, userId) => async (dispatch) => {
	await dispatch(actionTypes.setLoader(true));
	await dispatch(actionTypes.fetchMessagesPending());
	try {
		const body = getBodyPaginate(data, 'messages');
		const res = await fetchMessagesPaginationApi(projectId, {
			...body,
			...(userId && { userId })
		});
		await dispatch(actionTypes.fetchMessagesSuccess(res.data.messages));
		await dispatch(actionTypes.setMessagesPagination(res.data.pagination));
	} catch (error) {
		await dispatch(actionTypes.fetchMessagesError('Get messages error'));
	}
	await dispatch(actionTypes.setLoader(false));
};
