export const DOCUMENTS_FETCH_PENDING = 'DOCUMENTS_FETCH_PENDING';
export const DOCUMENTS_FETCH_SUCCESS = 'DOCUMENTS_FETCH_SUCCESS';
export const DOCUMENTS_FETCH_ERROR = 'DOCUMENTS_FETCH_ERROR';

export const DOCUMENT_TYPES_FETCH_PENDING = 'DOCUMENT_TYPES_FETCH_PENDING';
export const DOCUMENT_TYPES_FETCH_SUCCESS = 'DOCUMENT_TYPES_FETCH_SUCCESS';
export const DOCUMENT_TYPES_FETCH_ERROR = 'DOCUMENT_TYPES_FETCH_ERROR';

export const DOCUMENTS_ALL_FETCH_PENDING = 'DOCUMENTS_ALL_FETCH_PENDING';
export const DOCUMENTS_ALL_FETCH_SUCCESS = 'DOCUMENTS_ALL_FETCH_SUCCESS';
export const DOCUMENTS_ALL_FETCH_ERROR = 'DOCUMENTS_ALL_FETCH_ERROR';

export const SUB_DOCUMENTS_FETCH_SUCCESS = 'SUB_DOCUMENTS_FETCH_SUCCESS';

export const documentsFetchPending = () => ({
	type: DOCUMENTS_FETCH_PENDING
});

export const documentsFetchSuccess = (entityType, entityId, documents) => ({
	type: DOCUMENTS_FETCH_SUCCESS,
	payload: { entityType, entityId, documents }
});

export const subDocumentsFetchSuccess = (entityType, entityId, documents) => ({
	type: SUB_DOCUMENTS_FETCH_SUCCESS,
	payload: { entityType, entityId, documents }
});

export const documentsFetchError = (error) => ({
	type: DOCUMENTS_FETCH_ERROR,
	error
});

export const documentTypesFetchPending = () => ({
	type: DOCUMENT_TYPES_FETCH_PENDING
});

export const documentTypesFetchSuccess = (entityType, documentTypes) => ({
	type: DOCUMENT_TYPES_FETCH_SUCCESS,
	payload: { entityType, documentTypes }
});

export const documentTypesFetchError = (error) => ({
	type: DOCUMENT_TYPES_FETCH_ERROR,
	error
});

export const documentsAllFetchPending = () => ({
	type: DOCUMENTS_ALL_FETCH_PENDING
});

export const documentsAllFetchSuccess = (documents) => ({
	type: DOCUMENTS_ALL_FETCH_SUCCESS,
	documents
});

export const documentsAllFetchError = (error) => ({
	type: DOCUMENTS_ALL_FETCH_ERROR,
	error
});
