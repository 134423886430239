export const SALE_FETCH_PENDING = 'SALE_FETCH_PENDING';
export const SALE_FETCH_SUCCESS = 'SALE_FETCH_SUCCESS';
export const SALE_FETCH_ERROR = 'SALE_FETCH_ERROR';

export const SALE_DATA_FETCH_PENDING = 'SALE_DATA_FETCH_PENDING';
export const SALE_DATA_FETCH_SUCCESS = 'SALE_DATA_FETCH_SUCCESS';
export const SALE_DATA_FETCH_ERROR = 'SALE_DATA_FETCH_ERROR';

export const SALE_CONTACT_DROPDOWN_PENDING = 'SALE_CONTACT_DROPDOWN_PENDING';
export const SALE_CONTACT_DROPDOWN_SUCCESS = 'SALE_CONTACT_DROPDOWN_SUCCESS';
export const SALE_CONTACT_DROPDOWN_ERROR = 'SALE_CONTACT_DROPDOWN_ERROR';

export const SALE_ITEM_FETCH_PENDING = 'SALE_ITEM_FETCH_PENDING';
export const SALE_ITEM_FETCH_SUCCESS = 'SALE_ITEM_FETCH_SUCCESS';
export const SALE_ITEM_FETCH_ERROR = 'SALE_ITEM_FETCH_ERROR';

export const SALE_DETAILS_FETCH_PENDING = 'SALE_DETAILS_FETCH_PENDING';
export const SALE_DETAILS_FETCH_SUCCESS = 'SALE_DETAILS_FETCH_SUCCESS';
export const SALE_DETAILS_FETCH_ERROR = 'SALE_DETAILS_FETCH_ERROR';

export const SALE_PROCESS_FETCH_PENDING = 'SALE_PROCESS_FETCH_PENDING';
export const SALE_PROCESS_FETCH_SUCCESS = 'SALE_PROCESS_FETCH_SUCCESS';
export const SALE_PROCESS_FETCH_ERROR = 'SALE_PROCESS_FETCH_ERROR';

export const PAYMENTS_FETCH_PENDING = 'PAYMENTS_FETCH_PENDING';
export const PAYMENTS_FETCH_SUCCESS = 'PAYMENTS_FETCH_SUCCESS';
export const PAYMENTS_FETCH_ERROR = 'PAYMENTS_FETCH_ERROR';

export const CONTACT_PAYMENTS_FETCH_PENDING = 'CONTACT_PAYMENTS_FETCH_PENDING';
export const CONTACT_PAYMENTS_FETCH_SUCCESS = 'CONTACT_PAYMENTS_FETCH_SUCCESS';
export const CONTACT_PAYMENTS_FETCH_ERROR = 'CONTACT_PAYMENTS_FETCH_ERROR';

export const SALE_HISTORY_FETCH_PENDING = 'SALE_HISTORY_FETCH_PENDING';
export const SALE_HISTORY_FETCH_SUCCESS = 'SALE_HISTORY_FETCH_SUCCESS';
export const SALE_HISTORY_FETCH_ERROR = 'SALE_HISTORY_FETCH_ERROR';

export const SALE_DETAILS_RESET = 'SALE_DETAILS_RESET';

export const SALE_PAGINATE_FETCH_SUCCESS = 'SALE_PAGINATE_FETCH_SUCCESS';
export const SET_SALE_FILTERS = 'SET_SALE_FILTERS';
export const CLEAR_SALE_FILTERS = 'CLEAR_SALE_FILTERS';

export const SET_PAYMENTS_FILTERS = 'SET_PAYMENTS_FILTERS';
export const CLEAR_PAYMENTS_FILTERS = 'CLEAR_PAYMENTS_FILTERS';

export const SET_CHECKBOX_SALE_FILTER = 'SET_CHECKBOX_SALE_FILTER';
export const CLEAR_SALE_CHECKBOX_FILTERS = 'CLEAR_SALE_CHECKBOX_FILTERS';

export const FETCH_PAYMENTS_PAGINATE_PENDING = 'FETCH_PAYMENTS_PAGINATE_PENDING';
export const FETCH_PAYMENTS_PAGINATE_SUCCESS = 'FETCH_PAYMENTS_PAGINATE_SUCCESS';
export const FETCH_PAYMENTS_PAGINATE_ERROR = 'FETCH_PAYMENTS_PAGINATE_ERROR';

export const paymentsFetchPaginatePending = () => ({
	type: FETCH_PAYMENTS_PAGINATE_PENDING
});

export const paymentsFetchPaginateSuccess = (payments) => ({
	type: FETCH_PAYMENTS_PAGINATE_SUCCESS,
	payments
});

export const paymentsFetchPaginateError = (error) => ({
	type: FETCH_PAYMENTS_PAGINATE_ERROR,
	error
});

export const setPaymentsFilters = (filterType, data) => ({
	type: SET_PAYMENTS_FILTERS,
	filterType,
	data
});

export const clearPaymentsFilters = () => ({
	type: CLEAR_PAYMENTS_FILTERS
});

export const saleFetchPending = () => ({
	type: SALE_FETCH_PENDING
});

export const saleFetchSuccess = (sale) => ({
	type: SALE_FETCH_SUCCESS,
	sale
});

export const saleFetchError = (error) => ({
	type: SALE_FETCH_ERROR,
	error
});

export const salePaginateFetchSuccess = (sales) => ({
	type: SALE_PAGINATE_FETCH_SUCCESS,
	sales
});

export const setSaleFilters = (filterType, data) => ({
	type: SET_SALE_FILTERS,
	filterType,
	data
});

export const clearSaleFilters = () => ({
	type: CLEAR_SALE_FILTERS
});

export const saleDataFetchPending = () => ({
	type: SALE_DATA_FETCH_PENDING
});

export const saleDataFetchSuccess = (saleData) => ({
	type: SALE_DATA_FETCH_SUCCESS,
	saleData
});

export const saleDataFetchError = (error) => ({
	type: SALE_DATA_FETCH_ERROR,
	error
});

export const saleContactDropdownPending = () => ({
	type: SALE_CONTACT_DROPDOWN_PENDING
});

export const saleContactDropdownSuccess = (contactDropdown) => ({
	type: SALE_CONTACT_DROPDOWN_SUCCESS,
	contactDropdown
});

export const saleContactDropdownError = (error) => ({
	type: SALE_CONTACT_DROPDOWN_ERROR,
	error
});

export const saleItemFetchPending = () => ({
	type: SALE_ITEM_FETCH_PENDING
});

export const saleItemFetchSuccess = (saleItem) => ({
	type: SALE_ITEM_FETCH_SUCCESS,
	saleItem
});

export const saleItemFetchError = (error) => ({
	type: SALE_ITEM_FETCH_ERROR,
	error
});

export const saleDetailsFetchPending = () => ({
	type: SALE_DETAILS_FETCH_PENDING
});

export const saleDetailsFetchSuccess = (saleDetails) => ({
	type: SALE_DETAILS_FETCH_SUCCESS,
	saleDetails
});

export const saleDetailsFetchError = (error) => ({
	type: SALE_DETAILS_FETCH_ERROR,
	error
});

export const saleProcessFetchPending = () => ({
	type: SALE_PROCESS_FETCH_PENDING
});

export const saleProcessFetchSuccess = (saleProcess) => ({
	type: SALE_PROCESS_FETCH_SUCCESS,
	saleProcess
});

export const saleProcessFetchError = (error) => ({
	type: SALE_PROCESS_FETCH_ERROR,
	error
});

export const paymentsFetchPending = () => ({
	type: PAYMENTS_FETCH_PENDING
});

export const paymentsFetchSuccess = (payments) => ({
	type: PAYMENTS_FETCH_SUCCESS,
	payments
});

export const paymentsFetchError = (error) => ({
	type: PAYMENTS_FETCH_ERROR,
	error
});

export const contactPaymentsFetchPending = () => ({
	type: CONTACT_PAYMENTS_FETCH_PENDING
});

export const contactPaymentsFetchSuccess = (contactPayments) => ({
	type: CONTACT_PAYMENTS_FETCH_SUCCESS,
	contactPayments
});

export const contactPaymentsFetchError = (error) => ({
	type: CONTACT_PAYMENTS_FETCH_ERROR,
	error
});
export const salesHistoryFetchPending = () => ({
	type: SALE_HISTORY_FETCH_PENDING
});

export const salesHistoryFetchSuccess = (saleHistory) => ({
	type: SALE_HISTORY_FETCH_SUCCESS,
	saleHistory
});

export const salesHistoryFetchError = (error) => ({
	type: SALE_HISTORY_FETCH_ERROR,
	error
});

export const saleDetailsReset = () => ({
	type: SALE_DETAILS_RESET
});

export const setCheckboxSaleFilters = (name, value) => ({
	type: SET_CHECKBOX_SALE_FILTER,
	name,
	value
});

export const clearSaleCheckboxFilters = () => ({
	type: CLEAR_SALE_CHECKBOX_FILTERS
});
