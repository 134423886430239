/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	AppBar,
	Button,
	IconButton,
	Toolbar,
	Hidden,
	colors,
	Select,
	InputBase
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PostAddIcon from '@material-ui/icons/PostAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Authentication from 'src/utils/Authentication';
import WalkInModal from 'src/pages/WalkIn/WalkInModal';
import { ContextProvider } from 'src/utils/AzureProvider';
import { changeSelectedProjectApi } from 'src/thunks';
import AddNoteModal from 'src/pages/Contacts/details/AddNoteModal';
import AddContactModal from 'src/pages/Contacts/AddContactModal';
import SearchBar from 'src/components/SearchBar';
import { getProject, getSelectedProject, getAuth } from '../../selectors';
import { changeSelectedProject, resetUserStore, setChangeProject } from '../../actions';
import { isAdmin } from '../../utils';

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none'
	},
	flexGrow: {
		flexGrow: 1
	},
	search: {
		backgroundColor: 'rgba(255,255,255, 0.1)',
		borderRadius: 4,
		flexBasis: 300,
		height: 36,
		padding: theme.spacing(0, 2),
		display: 'flex',
		alignItems: 'center'
	},
	searchIcon: {
		marginRight: theme.spacing(2),
		color: 'inherit'
	},
	searchInput: {
		flexGrow: 1,
		color: 'inherit',
		'& input::placeholder': {
			opacity: 1,
			color: 'inherit'
		}
	},
	headerLogo: {
		color: '#FFF',
		fontSize: '24px',
		fontFamily: 'Roboto, Helvetica, Arial, sansSerif',
		fontWeight: 500,
		height: '64px'
	},
	img: {
		height: '100%'
	},
	searchPopper: {
		zIndex: theme.zIndex.appBar + 100
	},
	searchPopperContent: {
		marginTop: theme.spacing(1)
	},
	menuButton: {
		marginRight: theme.spacing(1)
	},
	chatButton: {
		marginLeft: theme.spacing(1)
	},
	notificationsButton: {
		marginLeft: theme.spacing(1)
	},
	notificationsBadge: {
		backgroundColor: colors.orange[600]
	},
	logoutButton: {
		marginLeft: theme.spacing(1)
	},
	logoutIcon: {
		marginRight: theme.spacing(1)
	},
	projectInput: {
		marginLeft: '30px',
		background: 'white',
		borderRadius: '10px'
	},
	selectInputBase: {
		height: '36px',
		marginLeft: '15px'
	},
	projectIcon: {
		fontSize: '30px',
		cursor: 'pointer',
		alignSelf: 'center',
		marginLeft: '15px',
		padding: '12px 15px'
	},
	headerBar: {
		background: 'black'
	},
	walkInButton: {
		color: 'white',
		fontSize: 12,
		display: 'flex',
		alignItems: 'center'
	}
}));

function TopBar({ onOpenNavBarMobile, className, ...rest }) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [openNote, setNoteOpen] = useState(false);
	const [addContact, setAddContact] = useState(false);
	const projects = useSelector(getProject);
	const currentProject = useSelector(getSelectedProject);
	const user = useSelector(getAuth);
	const userProjects = isAdmin()
		? projects
		: _.filter(projects, (x) => _.find(user.projects, (i) => x.id === i));
	const azureProvider = useContext(ContextProvider);

	const handleLogout = () => {
		Authentication.logout();
		dispatch(resetUserStore());
		if (localStorage.getItem('accessMicrosoftToken')) {
			azureProvider.logout();
			localStorage.removeItem('accessMicrosoftToken');
		}
		history.push('/admin/auth/login');
	};

	const redirectToPage = () => {
		if (/\/admin\/sales-details\/\S{5}/.test(window.location.pathname)) {
			history.push('/admin/sales');
		} else {
			const pathArr = window.location.pathname.split('/');
			if (pathArr.length > 3) {
				history.push(`/admin/${pathArr[2]}`);
			}
		}
	};

	const handleProjectChange = async (e) => {
		dispatch(changeSelectedProjectApi({ userId: user._id, projectId: e.target.value }));
		dispatch(changeSelectedProject(e.target.value));
		dispatch(setChangeProject(true));
		redirectToPage();
	};

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			color="primary"
		>
			<Toolbar className={classes.headerBar}>
				<Hidden lgUp>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						onClick={onOpenNavBarMobile}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
				<Link to="/">
					<div className={classes.headerLogo}>
						<img
							className={classes.img}
							src="/images/logos/intrahub logo-05.png"
							alt="Logo"
						/>
					</div>
				</Link>
				{userProjects?.length > 1 && (
					<div className={classes.projectInput}>
						<Select
							name="project"
							onChange={(e) => handleProjectChange(e)}
							native
							variant="outlined"
							value={currentProject}
							input={
								<InputBase
									classes={{
										root: classes.selectInputBase
									}}
								/>
							}
						>
							{userProjects?.map((option, idx) => (
								<option
									key={`${option.name}-${idx}`}
									value={option.id}
								>
									{option.name}
								</option>
							))}
						</Select>
					</div>
				)}
				<Hidden smDown>
					<Button
						className={classes.walkInButton}
						onClick={() => setOpen(true)}
					>
						<MeetingRoomIcon />
						Walk In
					</Button>
					<Button
						className={classes.walkInButton}
						onClick={() => setNoteOpen(true)}
					>
						<PostAddIcon />
						Contact Note
					</Button>
					<Button
						className={classes.walkInButton}
						onClick={() => setAddContact(true)}
					>
						<GroupAddIcon />
						Contact
					</Button>

					<WalkInModal
						onClose={() => setOpen(false)}
						open={open}
					/>
					<AddNoteModal
						onClose={() => setNoteOpen(false)}
						open={openNote}
						topBar
					/>
					<AddContactModal
						onClose={() => setAddContact(false)}
						open={addContact}
					/>
				</Hidden>
				<div className={classes.flexGrow} />
				<Hidden smDown>
					<SearchBar />
					<Button
						className={classes.logoutButton}
						color="inherit"
						onClick={handleLogout}
					>
						<InputIcon className={classes.logoutIcon} />
						Sign out
					</Button>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
